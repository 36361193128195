@import '../../../Share/styles/variables.scss';
@import '../../../Share/styles/mixin.scss';

.tv-single-assign-form {
  &__hr {
    border-top: 1px solid $TEXT_INACTIVE_COLOR;
  }

  &__title {
    color: #353535;
    font: rem-calc(24) / rem-calc(32) $PRIMARY_FONT;
    font-weight: 500;
    letter-spacing: -0.2px;
    text-align: center;
  }

  &__container {
    background: $WHITE_COLOR;

    &--order {
      margin: 0 auto 24px auto;

      .tv-single-assign-form__control {
        color: #313131;
      }

      .tv-single-assign-form__panel {
        padding-bottom: 30px;
      }
    }

    .tv-row {
      margin-right: -12px;
      margin-left: -12px;

      & > [class^='tv-col-'] {
        padding-right: 12px;
        padding-left: 12px;
      }

      & > .tv-single-assign-form__input--icon-add {
        padding: 10px 0;
      }
    }

    .tv-form__error {
      margin: 4px 0 3px;
      min-height: 1px;
      display: block;
    }

    .tv-form__control--error {
      margin-bottom: 0;
      border: 2px solid red !important;
    }
  }

  &__action {
    &-container {
      margin: 0 auto 32px auto;
      justify-content: flex-end;

      button {
        box-shadow: 0 2px 30px 0 rgba(153, 153, 153, 0.5);
        margin-left: 24px;
        font: 1rem $PRIMARY_FONT;
        padding: 13px 16px 12px;
        font-weight: 300;
      }
    }
  }

  &__panel {
    padding: 10px 48px;

    &--secondary {
      background: #f8f8f9;
      border-top: 1px solid #e6e6e6;
      border-bottom: 1px solid #e6e6e6;
      padding: 0px 48px 20px;
    }

    .text-normal {
      color: #313131;
      font: 0.875rem/1.5rem 'PT Serif', serif;
      line-height: 24px;
    }

    .text-bold {
      font-weight: bold;
    }

    &.tv-single-assign-form__panel--preview {
      padding: 10px 48px 50px 48px;
    }
  }

  &__sub-title {
    color: $SECONDARY_COLOR;
    font: rem-calc(18) / rem-calc(28) $PRIMARY_FONT;
    font-weight: 300;
    letter-spacing: -0.2px;
    margin: 24px 0 12px 0;
    justify-content: center;
    align-items: center;
    padding-bottom: 3px;

    .tv-single-assign-form__button-preview {
      float: right;
    }
  }

  &__label,
  &__input {
    color: $NAV_BAR_TEXT_COLOR;
    font: rem-calc(14) / rem-calc(24) $PRIMARY_FONT;
    line-height: 24px;

    .tv-date-range-picker__container {
      border: 1px solid $BORDER_COLOR;
      border-radius: 4px;
      display: block;
      width: 100%;

      .tv-form__date-field {
        font: 300 rem-calc(13) $PRIMARY_FONT;
        padding: 16px 20px;
        color: $BLACK_COLOR;
        position: relative;

        &:after {
          font: 1rem;
          position: absolute;
          content: '\e929';
          font-family: 'transvoice-icon';
          top: 19px;
          right: 20px;
        }
      }
    }

    &.h__pdb {
      padding-bottom: 16px;
    }
  }

  &__label {
    margin: 16px 0 5px 0;

    &--contact-note {
      color: $PRIMARY_COLOR;
      text-align: center;
    }
  }

  &__input {
    position: relative;

    &-arrow {
      &:after {
        pointer-events: none;
        content: '\e933';
        position: absolute;
        font: 0.7rem 'transvoice-icon';
        right: 27px;
        top: 20px;
        color: $NAV_BAR_TEXT_COLOR;
      }

      &.adornment-end {
        &.tv-col-3 {
          min-width: 180px;

          & + .tv-single-assign-form__input--icon-add {
            max-width: 15px;
            margin-right: 12px;
          }
        }

        &.tv-col-5 {
          min-width: 180px;

          & + .tv-single-assign-form__input--icon-add {
            max-width: 15px;
            margin-right: 36px;
          }
        }
      }

      &.ie-fix {
        &::after {
          display: none;
        }
      }
    }

    &-sameabove {
      justify-content: flex-end;
      align-self: center;
      margin: 0;

      .tv-toggleSwitch__wrapper {
        margin-left: 10px;
      }
    }

    &--my-home {
      margin-top: 15px;
    }

    &--icon-add {
      padding: 0;
    }

    textarea {
      resize: none;
    }
  }

  &__control {
    border: 1px solid $TEXT_INACTIVE_COLOR;
    background: $WHITE_COLOR;
    border-radius: 4px;
    width: 100%;
    font: 300 rem-calc(13) $PRIMARY_FONT;
    outline: none;
    color: $NAV_BAR_TEXT_COLOR;
    margin-bottom: 8px;
    & span[role='listbox'] {
      border-bottom: none;
    }
  }

  &__dropdownlist {
    padding: 14px 20px;
    appearance: none;

    &.ellipsis {
      padding-right: 35px;
      @include one-line-ellipsis($width: 100%);
    }
  }

  &__textfield {
    padding: 14px 20px;
  }

  &__textarea {
    height: 120px;
  }

  &__more-placeholder {
  }

  &__file-zone {
  }

  &__control {
  }

  &__file {
    &-container {
      margin: 0 0 23px 0;
      padding: 0;
      flex-flow: row wrap;
    }

    &-element {
      list-style: none;
      margin: 0 8px 0 0;
      width: calc((100% - 24px) / 4);

      &:nth-child(4n + 0) {
        margin-right: 0;
      }
    }

    &-name {
      border: 1px solid #eaeaea;
      border-radius: 0 0 5px 5px;
      background: #f8f8f8;
      font: rem-calc(10) $SECONDARY_FONT;
      color: $PRIMARY_COLOR;
      bottom: 0;
      position: absolute;
      display: flex;
      width: 100%;
      padding: 10px 0;

      &:before {
        content: '\e93c';
        color: $PRIMARY_COLOR;
        font: 0.75rem 'transvoice-icon';
        margin: 0 8px;
      }
    }

    &-item {
      min-height: 120px;
      border-radius: 5px;
      position: relative;
      justify-content: center;
      display: flex;

      &:before {
        color: $WHITE_COLOR;
        font: 2.6rem 'transvoice-icon';
        margin-top: 20px;
      }

      &--pdf {
        background: #ff9ba0;

        &:before {
          content: '\e93b';
        }
      }

      &--doc,
      &--docx {
        background: #6478e6;

        &:before {
          content: '\e93a';
        }
      }

      &--image {
        background: #454c98;

        &:before {
          content: '\e940';
        }
      }

      &--other {
        background: #6478e6;

        &:before {
          content: '';
        }
      }

      &--dragdrop {
        &:before {
          content: '\e93d';
          color: $PRIMARY_COLOR;
          font: 1.4rem 'transvoice-icon';
          margin-bottom: 4px;
        }

        background: $WHITE_COLOR;
        border: 1px dashed #cbcbcb;
        outline: none;
        font: rem-calc(12) $PRIMARY_FONT;
        font-weight: 300;
        text-transform: uppercase;
        color: #c5c5c5;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 0 20px;
        height: 120px;
        cursor: pointer;
      }
    }
  }

  &__button {
    &-save {
      .tv-single-assign-form__action-container & {
        border-color: $WHITE_COLOR;
        background: $WHITE_COLOR;
      }
    }

    &-addSession {
      border: 1px dashed $PRIMARY_COLOR;
      border-radius: 5px;
      width: 100%;
      background: $WHITE_COLOR;
      cursor: pointer;
      color: $PRIMARY_COLOR;
      font: rem-calc(13) / rem-calc(24) $SECONDARY_FONT;
      font-weight: bold;
      text-align: center;
      outline: none;
      padding: 8px 0 10px 0;
      margin-top: 16px;
    }

    &-removeSession {
      background: $PRIMARY_COLOR;
      height: 22px;
      width: 22px;
      border-radius: 50%;
      border: 0;
      display: flex;
      align-self: center;
      justify-content: center;
      cursor: pointer;
      outline: none;

      &:before {
        content: '\e926';
        color: #fff;
        font-family: 'transvoice-icon';
      }
    }

    &-removeFile {
      color: $PRIMARY_COLOR;
      border: 0;
      background: transparent;
      display: flex;
      outline: none;
      font: rem-calc(14) $SECONDARY_FONT;
      cursor: pointer;
      margin: 10px 0;

      &:before {
        background: $PRIMARY_COLOR;
        content: '\e926';
        color: #fff;
        font: 0.45rem 'transvoice-icon';
        border-radius: 50%;
        height: 16px;
        width: 16px;
        padding: 4px;
        display: inline-block;
        margin: 3px 7px 0 0;
      }
    }
  }

  &__preview {
    &-text {
      font: bold rem-calc(16) / rem-calc(24) $SECONDARY_FONT;
      color: $NAV_BAR_TEXT_COLOR;

      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-last {
      margin-bottom: 24px;
    }
    &-headings {
      font: 500 16px/20px $PRIMARY_FONT;
      display: flex;
      align-items: center;
      & > .icon-number::before {
        display: none;
      }
    }
    div:nth-child(1) > &-headings > .icon-number::before {
      content: '\278A';
    }

    div:nth-child(2) > &-headings > .icon-number::before {
      content: '\278B';
    }
    div:nth-child(3) > &-headings > .icon-number::before {
      content: '\278C';
    }

    &-sub-container {
      font-family: $PRIMARY_FONT;
      border-radius: 4px;
      padding: 10px 10px 0px 10px;
      width: 100%;
      text-align: left;
      margin: 10px 10px 0 0;
      & span {
        font-size: 1rem;
      }
    }
    &-property {
      display: flex;
      flex-direction: column;
      margin-top: 10px;
      margin-bottom: 15px;
      width: 100%;
      .property-label {
        font-weight: 700 !important;
        margin-bottom: 5px;
      }
      form {
        display: unset;
      }
      .property-value {
        font-size: 14px;
        min-width: 200px !important;
        font-weight: 100 !important;
        width: 50%;
        align-self: flex-end;
        &.edit-field {
          padding: 5px;
        }
      }

      .property-condition {
        font-size: 14px;
        font-weight: 400 !important;
      }
      input.property-value {
        background-color: $BORDER_COLOR !important;
        border-radius: 3px;
        border: 1px solid $BORDER_COLOR;
        padding: 5px 8px;
        margin-right: 5px;
        align-self: flex-start;
      }
      button.tv-buttons__element {
        font: 400 14px/18px $PRIMARY_FONT;
        align-self: center;
        padding: 2px;
        height: 15px;
        line-height: 4px;
      }
    }
  }

  &__back {
    &-corner {
      position: absolute;
      font: rem-calc(14) / rem-calc(24) $SECONDARY_FONT;
      color: $PRIMARY_COLOR;

      &::before {
        content: '\e934';
        @include tv-transvoice-font;
        font-size: 20px;
        padding: 0 5px 0 0;
      }
    }
  }

  &__modal {
    &--order {
      margin: 0 auto 24px auto;

      .tv-single-assign-form__control {
        color: #313131;
      }

      .tv-single-assign-form__panel {
        padding-bottom: 30px;
      }
    }

    &-wrap {
      padding: 0 50px;
    }

    .tv-row {
      margin-right: -12px;
      margin-left: -12px;

      & > [class^='tv-col-'] {
        padding-right: 12px;
        padding-left: 12px;
      }
    }

    .tv-form__error {
      margin: 4px 0 3px;
      min-height: 1px;
      display: block;
    }

    .tv-form__control--error {
      margin-bottom: 0;
    }

    .tv-modal__btn-close {
      font-size: 1rem;
      top: 24px;
      right: 24px;
    }

    .tv-modal__holder {
      max-width: 700px;
      background-color: $WHITE_COLOR;
      padding: 0;
      -ms-overflow-style: scrollbar;

      .icon-close::before {
        color: $WHITE_COLOR;
      }

      &.ie-fix-height {
        top: 50px;
      }
    }

    .tv-interperet-now__header-text {
      &.ie-fix-width {
        width: 516px;
      }
    }

    &-icon {
      font-size: rem-calc(48);
      margin-top: 32px;

      .icon-warning::before {
        color: $PRIMARY_COLOR;
      }
    }

    &-text {
      color: $PRIMARY_COLOR;
      font: 300 rem-calc(18) / rem-calc(28) $PRIMARY_FONT;
      letter-spacing: -0.2px;
      text-align: center;
      padding: 0 68px;
    }

    &-assignment {
      border: 1px solid $TEXT_INACTIVE_COLOR;
      background-color: $DARK_GRAY_COLOR-1;
      margin: 16px 36px 45px;
      padding: 9px 16px;

      &-id {
        color: $SECONDARY_COLOR;
      }

      &-title {
        color: #353535;
      }

      &-id,
      &-title {
        font: 300 rem-calc(16) / rem-calc(24) $PRIMARY_FONT;
        text-align: center;
      }
    }

    &-buttons {
      justify-content: center;
      margin: 10px 20px 30px;

      button {
        width: 169px;
        padding: 13px 0px;
      }
    }
  }
}

.tv-multiple-assign-form {
  &__title {
    color: #353535;
    font: rem-calc(24) / rem-calc(32) $PRIMARY_FONT;
    font-weight: 500;
    letter-spacing: -0.2px;
    text-align: center;
  }

  &__wrapper {
  }

  &__panel {
    &-left {
      margin-right: 37px;
    }

    &-right {
    }

    &-tab {
      width: 323px;
      border-radius: 5px;
      background: $WHITE_COLOR;
      box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.12);
      cursor: pointer;
      padding: 20px 24px;
      margin-bottom: 18px;
      border: 1px solid $WHITE_COLOR;

      &--error {
        border: 1px solid $PRIMARY_COLOR;

        &::before {
          left: -4px;
          top: -4px;
          border-radius: 5px;
          content: ' ';
          height: 175px;
          width: 330px;
          position: absolute;
          pointer-events: none;
        }

        &.highlight::before {
          animation: tv-anim-effect-2 2s infinite;
        }

        &.thick-hightlight {
          border: 2.5px solid $PRIMARY_COLOR !important;
        }
      }

      &--selected {
        border: 1px solid $SECONDARY_COLOR;

        &:after,
        &:before {
          left: 100%;
          top: 32px;
          border: solid transparent;
          content: ' ';
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
        }

        &:after {
          border-left-color: $WHITE_COLOR;
          border-width: 6px;
        }

        &:before {
          border-left-color: $SECONDARY_COLOR;
          border-width: 8px;
          top: 30px;
        }

        &.tv-multiple-assign-form__panel-tab--error {
          border: 1px solid $PRIMARY_COLOR;

          &:before {
            border-left-color: $PRIMARY_COLOR;
          }
        }
      }
    }

    &-title {
      color: $NAV_BAR_TEXT_COLOR;
      font: rem-calc(18) $PRIMARY_FONT;
      font-weight: 300;
      letter-spacing: -0.2px;
      border-bottom: 1px solid #e8ecef;
      margin: 0 0 10px 0;
      padding: 0 0 10px 0;
    }
  }

  &__attributes {
    margin: 0;
    padding: 0;

    li {
      list-style: none;
      margin: 5px 0;
      font: rem-calc(14) / rem-calc(24) $SECONDARY_FONT;

      &.level {
        &::before {
          content: '\e927';
          font-family: 'transvoice-icon';
          padding: 0 5px 0 0;
          color: $DARK_GRAY_COLOR;
        }
      }

      &.category {
        &::before {
          content: '\e92c';
          font-family: 'transvoice-icon';
          padding: 0 5px 0 0;
          color: $DARK_GRAY_COLOR;
        }
      }

      &.datetime {
        &::before {
          content: '\e929';
          font-family: 'transvoice-icon';
          padding: 0 5px 0 0;
          color: $DARK_GRAY_COLOR;
        }
      }
    }
  }

  &__button {
    &-add-more {
      border: 1px dashed $PRIMARY_COLOR;
      box-shadow: 0 2px 30px 0 rgba(153, 153, 153, 0.5);
      border-radius: 5px;
      color: $PRIMARY_COLOR;
      background: $WHITE_COLOR;
      font: rem-calc(16) $SECONDARY_FONT;
      font-weight: bold;
      width: 100%;
      margin-top: 6px;
      padding: 10px 0;
      cursor: pointer;
    }

    &-delete {
      color: $SECONDARY_COLOR;
      font: rem-calc(16) $SECONDARY_FONT;
      font-weight: bold;
      background: transparent;
      border: 0;
      position: absolute;
      right: 19px;
      top: 15px;
      cursor: pointer;
      display: flex;
      align-items: center;

      &:before {
        content: '\e926';
        color: $PRIMARY_COLOR;
        font: 0.5rem 'transvoice-icon';
        height: 8px;
        width: 8px;
        display: inline-block;
        margin: 0 7px 0 0;
      }
    }
  }

  &__back {
    &-corner {
      font: rem-calc(14) / rem-calc(24) $SECONDARY_FONT;
      color: $PRIMARY_COLOR;

      &::before {
        content: '\e934';
        @include tv-transvoice-font;
        font-size: 20px;
        padding: 0 5px 0 0;
      }
    }
  }
}

.tv-chooseassignment {
  &__wrapper {
    flex: 1.4 1;
    justify-content: center;
    align-items: center;
    padding-top: 38px;
  }

  &__container {
    width: 700px;
    background-color: $WHITE_COLOR;
    border-radius: 5px;
    box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.12);
  }

  &__heading {
    // border-radius: 4px 4px 0 0;
    // background-color: $SECONDARY_COLOR;
    // height: 64px;
    margin: 4vh 184px 0;
    align-items: center;

    &-text {
      color: $TEXT_TITLE_COLOR;
      font: 500 rem-calc(24) / rem-calc(35) $PRIMARY_FONT;
      letter-spacing: -0.2px;
      text-align: center;
    }
  }

  &__content {
    margin: 3vh 84px 0;
  }

  &-single {
    &__wrapper {
      width: 50%;
      padding-right: 16px;
    }

    &__button {
      height: 200px;
      width: 250px;
      border-radius: 5px;
      background-color: $LIGHT_PINK_COLOR;

      &--active {
        background-color: $PINK_COLOR;
      }
    }
  }

  &-multi {
    &__wrapper {
      width: 50%;
      padding-left: 16px;
    }

    &__button {
      height: 200px;
      width: 250px;
      border-radius: 5px;
      background-color: $PURPLE_BLOOD_COLOR;

      &--active {
        background-color: $PURPLE_BLUE_COLOR;
      }
    }
  }

  &__text {
    color: $DARK_GRAY_COLOR;
    font: rem-calc(14) / rem-calc(20) $SECONDARY_FONT;
    letter-spacing: 0.3px;
    text-align: center;
    margin: 1vh 16px 1vh 16px;

    &-button {
      // padding-top: 31px;
      justify-content: center;
      align-items: center;
      color: $WHITE_COLOR;
      font: 300 rem-calc(18) / rem-calc(28) $PRIMARY_FONT;
      letter-spacing: -0.2px;
      text-align: center;
      height: 78px;
    }
  }

  &__buttons {
    margin: 2vh 251px 54px;
    justify-content: center;

    button {
      padding: 14px 50px;
      border-radius: 5px;
      box-shadow: 0 2px 30px 0 rgba(153, 153, 153, 0.2);
    }
  }

  &__icon {
    text-align: center;
    padding-top: 61px;

    &::before {
      color: $WHITE_COLOR !important;
      font-size: rem-calc(60);
    }
  }
}

.tv-form {
  &__control {
    background-color: $WHITE-COLOR;

    &.tv-white-disabled {
      &:disabled {
        background-color: $WHITE_COLOR;
      }
    }

    &:disabled {
      background-color: $BGCOLOR-ICON;
    }
  }
}

.icon-create-new {
  font-size: 22px;

  &::before {
    color: $PURPLE_BLUE_COLOR;
  }
}

.icon-create-new--alt {
  font-size: 22px;

  &::before {
    content: '\e921';
    color: $PURPLE_BLUE_COLOR;
  }
}

.calendar-small {
  .rdrDayToday {
    cursor: not-allowed;

    .rdrDayNumber {
      span {
        color: #aeb9bf !important;
      }
    }
  }
}
.k-input,
.k-list-container {
  font: 300 rem-calc(13) $PRIMARY_FONT !important;
}

@-webkit-keyframes tv-anim-effect-1 {
  0%,
  100% {
    opacity: 0;
    border: 0px;
  }

  40%,
  60% {
    opacity: 1;
    border: 2px solid $PRIMARY_COLOR;
  }
}

@keyframes tv-anim-effect-2 {
  0%,
  100% {
    opacity: 0;
  }

  40%,
  60% {
    opacity: 1;
  }
}

@keyframes tv-anim-effect-2 {
  0% {
    -webkit-transform: scale3d(0.96, 0.96, 1);
    box-shadow: 0px 0px 11px 5px #dd59597c;
    transform: scale3d(0.96, 0.96, 1);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale3d(1.04, 1.04, 1);
    box-shadow: 0px 0px 11px 5px #dd59593d;
    transform: scale3d(1.04, 1.04, 1);
    opacity: 0;
  }
}

@-webkit-keyframes tv-anim-effect-1 {
  0%,
  100% {
    -webkit-transform: scale(0.96, 0.96, 1);
    transform: scale(0.96, 0.96, 1);
  }

  40%,
  60% {
    -webkit-transform: scale3d(1.04, 1.04, 1);
    transform: scale(1.04, 1.04, 1);
  }
}
.text-field-style {
  height: 40px;
}

.bank-id {
  margin-top: 35px;
}
.contact-description {
  font: 1rem $PRIMARY_FONT;
}

.tv-deviation-message {
  text-align: center;

  &-title {
    font: 500 32px/20px $PRIMARY_FONT;
    padding: 20px;
  }

  &-paragraph {
    font: 400 18px/23px $PRIMARY_FONT;
    color: #74737b;
    padding: 15px;
  }

  &-button {
    margin: 10px auto;
    padding: 16px;
    margin-bottom: 24px;

    width: 250px;
    border: none;
    font: 500 16px/20px $PRIMARY_FONT;
    background: $PRIMARY_COLOR;
    border-radius: 4px;
    color: #fff;
  }
}
