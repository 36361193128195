@import '../../../Share/styles/variables.scss';
@import '../../../Share/styles/mixin.scss';
@import './media_queries.scss';

@mixin resetReactTab {
  margin: 0;
  padding: 0;
  border: none;
}

$TAB_HEADER_HEIGHT: 89px;

.tv-settings {
  &__wrapper {
    margin: 32px 16px;
    font-family: $PRIMARY_FONT;
  }
  &__footer {
    position: fixed;
    bottom: 10px;
    left: 0;
    letter-spacing: 0.2px;
    z-index: 20;
    font: 15px/19px $PRIMARY_FONT;
    color: $FONT_LIGHT_GRAY_COLOR;
    width: 100%;
    background-color: $BACKGROUND_COLOR;
    padding-left: 10px;
    @media screen and (max-width: 768px) {
      bottom: 0;
    }
    @media (max-width: 1024px) and (orientation: landscape) {
      bottom: 0;
      padding-left: 15px;
    }
  }

  &-term-tabs {
    margin-top: 32px;

    ul.react-tabs__tab-list {
      padding: 0px 0 16px 0 !important;
      margin-bottom: unset !important;
      border-radius: 4px;
      li {
        margin-right: unset !important;
        font-family: $PRIMARY_FONT;
        font-style: normal;
        font-weight: normal;
        height: 48px;
        padding: 19px 14px;
        font-size: 16px;
        line-height: 20px;
        display: flex;
        background-color: #fff !important;
        color: #74737b;
        align-items: center;
        text-align: center;
        &.react-tabs__tab {
          &--selected {
            button {
              color: $TAB_SELECTED_COLOR !important;
              font-weight: 500;
            }
          }
          button {
            color: #74737b;
            background-color: #fff;
            width: 100% !important;
            height: 48px !important;
            border: 1px solid $BORDER_COLOR;
          }
        }
        &:first-child button {
          border-right: none;
        }
        &:last-child button {
          border-left: none;
        }
      }
    }
    &_1 {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
      // padding: 7px 17px 5px;
    }

    &_2 {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;

      // padding: 7px 17px 5px;
    }
  }

  &__title {
    background: transparent;

    font: 500 rem-calc(24) / rem-calc(32) $PRIMARY_FONT;
    letter-spacing: -0.2px;
    color: #353535;
    margin: 15px 0px 15px 0px;
  }

  &__tab-text-main {
    font: 17px $PRIMARY_FONT;
    color: $BLACK_COLOR;
    letter-spacing: -0.2px;
  }

  &__tab-text-sub {
    font: rem-calc(14) / rem-calc(20) $PRIMARY_FONT;
    color: #a9a9a9;
  }

  &__panel-title {
    font: 500 28px/35px $PRIMARY_FONT;
  }
  &__panel-description {
    padding: 10px 30px 0px 0px;
    font: 0.875rem/1.5rem $PRIMARY_FONT;
    color: $NAV_BAR_TEXT_COLOR;
  }

  &__account-wrapper {
    padding-top: 6px;
    font-family: $PRIMARY_FONT;

    .tx-input-text {
      font-size: 16px;
      line-height: 20px;
      font-family: $PRIMARY_FONT;
      width: 100%;
      padding: 10px 11px 10px;
    }

    .tv-form__error:empty {
      display: none;
    }

    @media (max-width: 768px) {
      margin-bottom: 20px;
    }
  }

  &__datapolicy {
    max-width: 900px;
    font-size: 14px;
    line-height: 18px;
    p {
      font-size: 14px;
      line-height: 18px;
    }
    li {
      margin-left: 16px;
    }
    &_panel {
      margin-left: unset !important;
    }
  }

  &__password-label {
    color: $COMMON_TEXT_COLOR;
    font-size: 16px;
    line-height: 20px;
    font-family: $PRIMARY_FONT;
    margin-bottom: 8px;
    margin-top: 24px;
    font-weight: 500;
  }

  &__password-field {
    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      opacity: 1;
      /* Firefox */
      @include font-size(14);
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      @include font-size(14);
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      @include font-size(14);
    }
  }

  &__change-password-btn {
    background-color: #c4c4c4;
    width: 100%;
    color: black;
    font-weight: 500;
    padding: 15px;
    margin: 30px 0 0 0px;
  }

  &__calendar-wrapper {
    padding-top: 46px;
    font-family: $SECONDARY_FONT;

    .tv-toggleSwitch {
      margin-left: auto;
    }
  }

  &__calendar-submit {
    margin: auto 0px 0px auto;

    &.tv-buttons__element {
      padding: 13px 31px;
    }
  }

  &__contact-text {
    font: rem-calc(14) / rem-calc(20) $SECONDARY_FONT;
    color: $DARK_GRAY_COLOR;
    letter-spacing: 0.3px;
    margin-top: 24px;
  }

  &__contact-btn {
    margin-top: 50px;

    &.tv-buttons__element {
      font-weight: 300;
      padding: 15px 24px;
      width: 248px;
    }
  }

  &__term-text {
    color: #a9a9a9;
    font-family: 'PT Serif';
    font-size: 14px;
    letter-spacing: 0.3px;
    line-height: 20px;
    height: 100%;
  }

  &__tabs-wrapper {
    // background-color: $WHITE_COLOR;
    // border-radius: 5px;
    // box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.12);
    // min-height: 672px;
    display: flex;
    position: relative;

    // Override react-tab CSS
    .react-tabs {
      &__tab-list {
        flex: 0.5 1;
        @include resetReactTab();
        margin-right: 10px;
      }

      &__tab {
        @include resetReactTab();
        display: flex;
        padding: 15px 0px 15px 0px;
        margin: 0px 0px 0px;
        font: 500 16px/20px $PRIMARY_FONT !important;
        [class*='icon-'] {
          &::before {
            font-size: rem-calc(35);
            display: inline-block;
            margin-right: 17px;
            line-height: 46px;
          }
        }
        .tv-settings__tab-text-main {
          color: $COLOR_NAVIGATION_ITEM !important;
        }

        &--selected {
          border-radius: 0;
          color: $TAB_SELECTED_COLOR !important;
          // border-right: 3px solid $PRIMARY_COLOR;

          [class*='icon-']::before,
          .tv-settings__tab-text-main {
            color: $PRIMARY_COLOR !important;
          }
        }

        &:focus {
          box-shadow: none;
          border-color: none;
          outline: none;

          &:after {
            content: none;
          }
        }
      }

      &__tab-panel {
        flex: 1.5;
        padding: 0px 54px 0px 54px;
        border-radius: 5px;
        @media (max-width: 768px) {
          // max-height: calc(100vh - 310px);
          // overflow-y: auto;
          // overflow-x: hidden;
        }
        @media (max-width: 1024px) and (orientation: landscape) {
          max-height: calc(100vh - 200px);
          overflow-y: auto;
          overflow-x: hidden;
        }

        &--selected {
          display: flex;
          flex-direction: column !important;
        }
      }

      &--vertical {
        .react-tabs {
          &__tab-list {
            width: 100%;
            flex: unset;
            background-color: transparent;
            position: unset;
            padding: 38px 0 38px 0;
            margin-bottom: 20px;
          }

          &__tab {
            color: $DARK_GRAY_COLOR;
            display: inline;
            border-bottom: none;
            padding: 0;
            position: unset;
            font-size: 18px;
            line-height: 28px;

            &:first-child {
              margin-right: 32px;
            }

            &--selected {
              border-right: none;
              text-decoration: underline;
              color: $TAB_SELECTED_COLOR;
            }
          }

          &__tab-panel {
            padding: 0;
            flex: unset;
            flex-direction: unset;

            .tab-panel-content {
              color: $DARK_GRAY_COLOR;
              font: 0.875rem/1.25rem 'PT Serif', serif;
              text-align: left;
              font-size: 14px;
              line-height: 20px;
              overflow-y: scroll;
              max-height: 567px;

              &__paragraph {
                font-size: 14px;
                line-height: 18px;
              }
            }
          }
        }
      }
    }
  }

  .tv-profile {
  }
}
