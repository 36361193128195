.tv-workassignment {
  &__wrapper {
    margin: 30px 20px 0 16px;

    .tv-search-box {
    }
  }

  &__status-wrapper {
    margin-left: 15px;
    margin-right: 10px;
  }

  &__main-title {
    font-family: $PRIMARY_FONT;
    font-size: 24px;
    line-height: 30px;
    display: flex;
    align-items: center;
    letter-spacing: 0.2px;
  }

  &__title {
    color: #353535;
    font-weight: 500;
    font: 1.5rem/2rem $PRIMARY_FONT;
    margin: 0 0 20px;
  }

  &__control-panel {
    margin: 0 0 20px;

    &.h-pdl--30 {
      // position: absolute;
      // right: 56px;
      padding-left: 30px;
    }

    .h-fs--16 {
      font-size: 16px;
    }
  }

  &__date_picker {
    margin: 15px 0 4px;
    font-family: $PRIMARY_FONT;

    &-title {
      font-size: 16px;
      padding: 6px 0;
    }

    &-input {
      padding: 3px 12px 0 15px;
      background: #f4f6f9;
      border: 1px solid #d2d2d2;
      border-radius: 4px;
      height: 47px;
      width: 100%;
      font: 400 16px/20px $PRIMARY_FONT;
      text-transform: capitalize;
    }

    &-icon {
      position: absolute;
      top: 45px;
      left: 288px;
      transform: rotate(270deg);
    }
  }

  &__list {
    &-item {
      cursor: pointer;
      margin: 0;
      padding: 8px;
      height: 47px;
      font: 400 12px/15px 'Circular Std', sans-serif;
      z-index: 8;
      border-radius: 0;
      background: #f1f3f4;
      display: flex;
      justify-content: space-around;
      width: 100%;
      align-items: center;

      &.selected {
        background: $SECONDARY_COLOR !important;

        .tv-reportquality__item-text {
          color: $WHITE_COLOR !important;
        }

        .icon-hearing {
          &:before {
            background-color: #ffffff;
          }
        }

        &:hover {
          // background: $SECONDARY_COLOR !important;
        }
      }
      // &:hover {
      //   background-color: #e8f3fa !important;
      // }
      &-child {
        margin: 0 13px;
        box-shadow: inset 0 10px 18px -12px rgba(58, 68, 131, 0.2),
          0 10px 18px -12px rgba(58, 68, 131, 0.2);
      }

      &-label {
        display: none;
      }

      &:first-child {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }

      &:last-child {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
      }

      &:nth-child(even) {
        background: #f1f3f4;
      }

      &:nth-child(odd) {
        background: $BACKGROUND_COLOR;
      }
    }
  }

  &__item {
    &-status {
      width: 21.6%;
      align-items: center;
      justify-content: center;
    }

    &-icon {
      padding: 15px 40px;
    }

    &-order {
      width: 30%;
      margin-right: 10px;
    }

    &-name {
      width: 29.1%;
    }

    &-category {
      width: 16.7%;
    }

    &-datetime {
      width: 27.6%;
    }

    &-type {
      width: 12%;
      margin-right: 10px;
    }

    &-more_button {
      outline: none;
      border: none;
      background: none;
      text-transform: uppercase;
      font-size: 14px;

      &:after {
        content: '\e931';
        font-family: 'transvoice-icon';
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizeLegibility;
        -moz-osx-font-smoothing: grayscale;
        font-feature-settings: 'liga';
        color: #c5c5c5;
        margin-left: 4px;
        font-size: 10px;
        color: $BLACK_COLOR;
      }
    }

    &-menu {
      width: 7%;
      align-items: center;
      justify-content: center;

      &-button {
        &:before {
          @include tv-transvoice-font;
          font-size: 1.5rem;
        }
      }
    }

    &-link-button {
      color: $PRIMARY_HOVER_COLOR;
      font-weight: 500;
      font-size: 14px;
      .selected & {
        color: #fff;
      }
    }

    &-label {
      font-size: 0.975rem;
      color: $BLACK_COLOR;
      margin: 0 0 11px;
      font-family: $PRIMARY_FONT;
    }

    &-text {
      color: $COMMON_TEXT_COLOR;
      font: 400 13px/18px $PRIMARY_FONT;

      &.text-max-lenght {
        max-width: calc(70vw / 7);
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .icon {
        text-align: center;
        font-size: 16px;

        &:before {
          color: #74737b;
        }
      }

      &-date {
        font: 400 13px/18px $PRIMARY_FONT;
      }
      @supports (-webkit-background-clip: text) {
        .stars {
          --percent: var(--rating);
          line-height: 8px;
          display: inline-block;
          font-family: Times; // make sure ★ appears correctly
          -webkit-text-stroke: 1px #ffb074;

          &::before {
            content: '★★★★★';
            font-size: 15px;
            letter-spacing: 3px;
            background: linear-gradient(
              90deg,
              #ff9b4e var(--percent),
              transparent var(--percent)
            );
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
      }

      .stars {
        color: #ffb074;
        font-family: Times; // make sure ★ appears correctly
        letter-spacing: 3px;
        font-size: 15px;
      }

      &-available-until {
        font-weight: bold;
        color: $SECONDARY_COLOR;
      }

      .selected & {
        color: #fff;

        .icon {
          text-align: center;
          font-size: 16px;

          &:before {
            color: #fff;
          }
        }
      }
    }
  }

  &__status {
    font: 12px/16px $PRIMARY_FONT;
    color: $WHITE_COLOR;
    border-radius: 4px;
    text-transform: capitalize;
    padding: 6px 8px;
    letter-spacing: 0.5px;

    &--available {
      background: $COLOR_STATUS_AVAILABLE;
    }

    &--accepted {
      background: $COLOR_STATUS_ACCEPTED;
    }

    &--rejected {
      background: $COLOR_STATUS_REJECTED;
    }

    &--fullfilled {
      background: $COLOR_STATUS_FULLILLED;
      color: $WHITE_COLOR;
    }

    &--cancelled {
      background: $COLOR_STATUS_CANCELLED;
    }

    &--losted {
      background: $COLOR_STATUS_LOSTED;
    }

    &--traveling,
    &--working {
      background: $COLOR_STATUS_WORKING;
      color: black;
    }

    &--done {
      background: $COLOR_STATUS_DONE;
      color: black;
    }

    &--submitted {
      background: $COLOR_STATUS_SUBMITTED;
    }
    // &--accepted,
    // &--submitted {
    //   &:before {
    //     content: "\e939";
    //     @include tv-transvoice-font;
    //     color: $WHITE_COLOR;
    //     margin: 0 6px 0 0;
    //   }
    // }
  }

  &__notification {
    background: #f1f3f4;
    border-radius: 4px;
    padding: 15px 8px;
    margin: 8px 0;
    display: flex;
    justify-content: space-between;

    &__title {
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      display: flex;
      align-items: center;
      color: #252525;
      margin-bottom: 7px;
    }

    &__more {
      font-size: 12px;
      text-decoration-line: underline;
      color: #677adf;
    }

    &__new-label {
      background: #e94242;
      border-radius: 30px;
      color: #ffffff;
      font-size: 8px;
      height: 16px;
      width: 35px;
      display: flex;
      align-content: center;
      justify-content: center;
      padding: 4px 8px;
      font-weight: 500;
      margin-top: 13px;
    }
  }

  &__detail {
    &-wrapper {
      font-family: $PRIMARY_FONT;
      margin-top: 8px;
    }

    &-article-icon {
      font-size: 20px;

      &--available {
        color: $COLOR_STATUS_AVAILABLE !important;
      }

      &--accepted {
        color: $COLOR_STATUS_ACCEPTED !important;
      }

      &--rejected {
        color: $COLOR_STATUS_REJECTED !important;
      }

      &--fullfilled {
        color: $COLOR_STATUS_FULLILLED !important;
      }

      &--cancelled {
        color: $COLOR_STATUS_CANCELLED !important;
      }
    }

    &-articlename {
      font-size: 0.875rem;
      margin: 5px 10px 5px 0;
    }

    &-status {
      font-size: 1rem;
      font-weight: 400;
      color: #252525;
      align-items: center;
      justify-content: space-between;
    }

    &-orderno {
      color: #454c98;
    }

    &-name {
      font: 1.5rem/2.1875rem $PRIMARY_FONT;
      font-weight: 300;
      color: $DARK_GRAY_COLOR;
      color: #353535;
      margin: 0 10px 0 0;
    }

    &-datetime {
      font-size: 0.875rem;
      color: $SECONDARY_COLOR;
      margin: 6px 0 24px 32px;

      .icon-calendar {
        margin-right: 14px;
      }
    }

    &-title {
      font: 1rem $PRIMARY_FONT;
      color: $NAV_BAR_TEXT_COLOR;
      background-color: #f9f9f9;
      padding: 14px 0 13px 32px;
    }

    &-description {
      margin: 16px 32px 0;
      font: rem-calc(14) / rem-calc(24) $SECONDARY_FONT;
      color: $NAV_BAR_TEXT_COLOR;
    }

    &-buttons {
      margin: 15px 0 24px;
      font-weight: 600;
      justify-content: space-between;
      flex-direction: row-reverse;

      .tv-buttons__element {
        padding: 13px 0 12px;
        width: 47.04%;
        font-weight: 500;
        font-size: 16px;

        &-link {
          width: 47.04%;

          button {
            width: 100%;
          }
        }

        &-primary {
          background-color: $PRIMARY_COLOR;
          color: $WHITE_COLOR;
        }

        &--report {
          padding: 13px 24px 12px;
          width: unset;
        }
      }
    }

    &__deviation-button {
    }

    &-links {
      display: flex;
      flex-direction: row;
      font-family: $PRIMARY_FONT;
      margin-right: 10px;
      @supports (display: flex) {
        align-items: flex-end;
        justify-content: flex-end;
      }

      &-item {
        display: flex;
        min-width: 70px;
        letter-spacing: 0.5px;
        font-size: 14px !important;
        color: $PRIMARY_HOVER_COLOR;
        margin-left: 10px;
        margin-top: 5px;
        cursor: pointer;

        &:hover {
          opacity: 1;
          color: $PRIMARY_HOVER_COLOR;
          text-decoration: underline $PRIMARY_HOVER_COLOR 2px;
        }

        &-button {
          font-weight: 600;
          color: $BLACK_COLOR;
          text-decoration: none;

          &:hover {
            color: $BLACK_COLOR;
            text-decoration: underline $SECONDARY_COLOR 2px;
          }
        }
      }
    }

    &-footer {
      display: flex;
      justify-content: space-between;
      width: 100%;
      position: absolute;
      bottom: -30px;
      left: 0;
      padding: 0 12px;
    }

    &-sub-status {
      font: 300 12px/15px $PRIMARY_FONT;
      padding: 8px 16px;
      border-radius: 8px;
      margin-top: 16px;
      letter-spacing: 0.4px;
      text-align: center;

      &--searching-for-interpreter {
        background-color: $COLOR_SUBSTATUS_SEARCHING_FOR_INTERPRETER;
      }

      &--interpreter-accepted {
        background-color: $COLOR_SUBSTATUS_INTERPRETER_ACCEPTED;
      }

      &--order-in-progress {
        background-color: $COLOR_SUBSTATUS_ORDER_IN_PROGRESS;
      }

      &--assigning-interpreter {
        background-color: $COLOR_SUBSTATUS_ASSIGNING_INTERPRETER;
      }

      &--awaiting-time-report {
        background-color: $COLOR_SUBSTATUS_AWAITING_TIME_REPORT;
      }

      &--time-report-received {
        background-color: $COLOR_SUBSTATUS_TIME_REPORT_RECEIVED;
      }

      &--deviation-registered {
        background-color: $COLOR_SUBSTATUS_DEVIATION_REGISTERED;
        color: $WHITE_COLOR;
      }

      &--order-cancelled {
        background-color: $COLOR_SUBSTATUS_ORDER_CANCELLED;
      }

      &--order-rejected {
        background-color: $COLOR_SUBSTATUS_ORDER_REJECTED;
      }

      &--unexpected-event-occured {
        background-color: $COLOR_SUBSTATUS_UNEXPECTED_EVENT_OCCURED;
      }

      &--unknown-status {
        background-color: $COLOR_SUBSTATUS_UNKNOWN_STATUS;
      }
    }
  }

  &__attach {
    &-title {
      padding: 14px 0 13px 32px;
      color: #a9a9a9;
      font-family: 'PT Serif';
      font-size: 14px;
      letter-spacing: 0.3px;
      line-height: 20px;
    }

    &-list {
      padding: 0 0 17px 32px;

      .tv-attachment {
        margin-right: 8px;
        margin-bottom: 8px;
        background-color: transparent;
      }
    }
  }

  &__modal {
    top: 0 !important;
    position: absolute;
    z-index: 65;

    &-header {
      font: 500 18px/20px $PRIMARY_FONT;
      text-align: left;
      padding: 18px 10px;
      height: 56px;
      color: $BLACK_COLOR;
      border-bottom: 1px solid $BORDER_COLOR;
      letter-spacing: 0.2px;

      & > .secondary-heading {
        font: 400 14px/18px $PRIMARY_FONT;
        color: #74737b;
      }
    }

    &-subheader {
      font: 600 16px/18px $PRIMARY_FONT;
      padding: 10px 0 16px;
    }

    &-detail-container {
      margin-top: 12px;
    }

    &-container {
      overflow: hidden;
      margin-top: 5px;
      border-bottom: 1px solid $BORDER_COLOR;
      font-family: $PRIMARY_FONT;
      text-align: left;
      margin-right: -10px;
      min-width: 605px;
      height: 30vh !important;
      min-height: 250px;
    }

    &-scroll-container {
      overflow: hidden;
      margin-top: 5px;
      border-top: 1px solid $BORDER_COLOR;
      border-bottom: 1px solid $BORDER_COLOR;
      margin-right: -10px;
      min-width: 605px;

      & > div {
        & > div:nth-child(2) {
          display: none;
        }
      }
    }

    .tv-modal__overlay {
      top: 0 !important;
      left: -10px !important;
      width: 100vw !important;
      height: var(--vh) !important;
    }

    .tv-modal__holder {
      border-radius: 4px;
      width: 620px;
      max-width: 620px;
      max-height: unset;
      top: 63px;
      background-color: $WHITE_COLOR;
      overflow-x: hidden;

      .icon-close::before {
        color: $NAV_BAR_TEXT_COLOR;
      }
    }

    &-info {
      &-label {
        font: 600 14px/18px $PRIMARY_FONT;
        color: $COMMON_TEXT_COLOR;
        text-align: left;
        margin-bottom: 8px;

        &.labelled {
          color: $COLOR_TEXT_LABEL_FONT;
        }
      }

      &-content {
        font: 14px/18px $PRIMARY_FONT;
        color: $COMMON_TEXT_COLOR;
        text-align: left;
        white-space: nowrap;
        margin-left: 8px;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 16px;
        &.red-text {
          color: $PRIMARY_COLOR;
        }
        &.labelled {
          color: $COLOR_TEXT_LABEL_FONT;
        }
      }

      &-condition {
        font: 400 11px/14px $PRIMARY_FONT;
        color: $COMMON_TEXT_COLOR;
        text-align: left;
        margin-bottom: 16px;
      }
    }

    &-status {
      font: 900 0.975rem $PRIMARY_FONT;
      margin-left: 0.5rem;
      padding: 10px 15px 0;
      // & > div:nth-child(1) {
      //   text-align: left;
      //   width: 70%;
      // }
      // .tv-workassignment__status {
      //   text-align: left;
      // }
    }
  }

  &__info {
    &-bank-id {
      width: 25px;
      height: 16px;
      background-image: url('/images/bankid.png');
      background-repeat: no-repeat;
      margin-right: 12px;
      margin-left: -5px;
      background-size: contain;
    }

    &-wrapper {
      margin: 0 0 10px;
      padding: 0;
      font-weight: 300;
      overflow-y: scroll;
      scroll-behavior: smooth;
    }

    &-item {
      list-style: none;
      margin: 0 0 17px;
      font-size: 12px;
      color: $COMMON_TEXT_COLOR;
      display: flex;

      &.red-text {
        color: $PRIMARY_COLOR;
        font-weight: 500;
      }

      &.labelled {
        background-color: $COLOR_TEXT_LABEL;
        padding: 8px 16px;
        border-radius: 8px;
      }

      & i {
        color: $FONT_LIGHT_GRAY_COLOR;
        margin-right: 12px;
        font-size: 14px;
        min-width: 14px;
      }

      & > span ~ a {
        margin-left: 5px;
      }

      a {
        display: inline;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: $SECONDARY_COLOR;
      }

      & .tv-workassignment__item-text > .stars {
        font-size: 24px;

        &::before {
          font-size: 24px;
        }
      }

      &.icon-none-icon:before {
        content: '';
        margin-right: 30px;
      }
    }

    &-agree {
      margin: 17px 32px 0;
      color: $NAV_BAR_TEXT_COLOR;
      font: italic rem-calc(14) / rem-calc(24) $SECONDARY_FONT;

      &-terms {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  &__drawer {
    &-overlay {
      content: '';
      bottom: 0;
      left: 0;
      position: fixed;
      right: 0;
      top: 0;
      background: rgba(0, 0, 0, 0.3);
      z-index: 16;
    }

    &-holder {
      width: 468px;
      background: $WHITE_COLOR;
      position: fixed;
      height: 100vh;
      overflow-y: auto;
      top: 0;
      right: 0;
      z-index: 20;
      box-shadow: -5px 0 15px 0 rgba(137, 136, 150, 0.27);
      transition: all 0.5s ease;
      transform: translateX(100%);

      .js-slide-in & {
        transform: translateX(0%);
      }
    }

    &-button-wrapper {
      width: 100%;
      text-align: left;
      // background: #a7a7a7;
      position: absolute;
      margin-left: 30px;
      margin-right: 30px;
      margin-top: 9px;
    }

    &-next {
      // position: absolute;
      top: 12px;
      right: 40px;
      font-size: 13px;
      margin-left: 30px;
      display: flex;
      align-content: center;

      &:before {
        color: $NAV_BAR_TEXT_COLOR;
        font-size: 1rem;
      }

      &:after {
        padding-left: 4px;
      }

      &:disabled {
        color: darkgrey;
      }
    }

    &-previous {
      // position: absolute;
      top: 12px;
      right: 140px;
      font-size: 13px;

      &:before {
        color: $NAV_BAR_TEXT_COLOR;
        font-size: 0.8rem;
        padding-right: 5px;
      }

      &:disabled {
        color: darkgrey;
      }
    }

    &--next {
      // position: absolute;
      top: 0;
      right: 0;
      font-size: 12px;
      padding-right: 8px;
      cursor: pointer;
      display: flex;
      align-content: center;

      &:after {
        padding-left: 5px;
        font-size: 1rem;
        position: absolute;
        top: -2px;
        right: 5px;
        line-height: 20px !important;
      }

      &:disabled {
        color: darkgrey;
        cursor: not-allowed;
      }
    }

    &--previous {
      // position: absolute;
      top: 0;
      left: 0;
      cursor: pointer;
      font-size: 12px;
      padding-left: 8px;
      display: flex;
      align-content: center;

      &:before {
        padding-right: 5px;
        font-size: 1rem;
        position: absolute;
        top: -2px;
        left: 5px;
        line-height: 18px !important;
      }

      &:disabled {
        color: darkgrey;
        cursor: not-allowed;
      }
    }

    &-close {
      position: absolute;
      top: 10px;
      right: 10px;

      &:before {
        color: $NAV_BAR_TEXT_COLOR;
        font-size: 1.2rem;
      }
    }
  }

  &__drawer2 {
    &-overlay2 {
      content: '';
      bottom: 0;
      left: 0;
      position: fixed;
      right: 0;
      top: 0;
      background: rgba(0, 0, 0, 0.3);
      z-index: 5;
    }

    &-holder2 {
      width: 560px;
      background: $WHITE_COLOR;
      position: fixed;
      height: 100vh;
      overflow-y: auto;
      top: 0;
      right: 0;
      z-index: 15;
      box-shadow: -5px 0 15px 0 rgba(137, 136, 150, 0.27);
      transition: all 0.5s ease;
      transform: translateX(100%);

      .js-slide-in2 & {
        transform: translateX(0%);
      }
    }

    &-close2 {
      position: absolute;
      top: 10px;
      right: 10px;

      &:before {
        color: $NAV_BAR_TEXT_COLOR;
        font-size: 1.2rem;
      }
    }
  }

  &__filter-date-range {
    // padding-right: 30px;
    position: relative;

    &__content {
      position: absolute;
      box-shadow: 1px 1px 4px 1px $TEXT_INACTIVE_COLOR;
      background: $WHITE_COLOR;
      display: flex;
      flex-direction: column;
      z-index: 150;
      border-radius: 4px;
      left: 15px;

      .calendar-small .rdrDateRangePickerWrapper {
        font-family: $PRIMARY_FONT !important;
        border-radius: 4px;
        margin-bottom: 12px;
        border: none;
        box-shadow: none;
        width: 100%;
      }

      .tv-buttons__element {
        width: 30%;
        margin: 0 auto 10px;
      }
    }
  }
}
@media (max-width: 768px) {
  .tv-workassignment {
    &__wrapper {
      .filter-border {
        border-radius: 4px;
        border: 1px solid $BORDER_COLOR;
        padding: 16px;
      }
    }

    &__item {
      &-icon {
        padding: 0 12px;
        height: 24px;
        order: 1;

        .icon {
          font-size: 24px !important;
        }
      }

      &-status {
        width: 30%;
        order: 4;
        max-width: unset;
        padding-right: 5px;
      }

      &-order {
        width: 30%;
        order: 2;
        max-width: unset;
        padding: 5px;

        .tv-workassignment__item-text {
          font-weight: 600 !important;
        }

        .label {
          display: none;
        }
      }

      &-name {
        width: 50%;
        order: 5;
        max-width: unset;
        margin-top: 10px;
        padding-right: 0;
        text-align: end;
      }

      &-category {
        width: 30%;
        order: 3;
        max-width: unset;
        padding: 4px;

        .label {
          display: none;
        }

        .tv-workassignment__item-text {
          max-width: 80px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }

      &-datetime {
        width: 50%;
        order: 5;
        max-width: unset;
        flex: 0 0 40%;
        margin-top: 10px;
      }

      &-type {
        width: 12%;
        order: 6;
        max-width: unset;
      }

      &-text {
        display: flex;
        font: 400 12px/15px $PRIMARY_FONT;
        margin-top: 17px;

        &:last-child {
          margin-bottom: 17px;
        }

        &-label {
          font: 400 12px/15px $PRIMARY_FONT;
          margin: 0 8px 0 0;

          i {
            color: #74737b;
          }
        }
      }

      .list-small-rating > label:before {
        font-size: 12px !important;
      }
    }

    &__list {
      padding-bottom: 70px;
      margin-right: auto;
      margin-bottom: 80px;

      &-item {
        height: 200px;
        border-radius: 4px;
        position: relative;
        border: 1px solid $BORDER_COLOR;
        margin: 16px 0 !important;
        padding: 16px;

        .tv-workassignment__detail-status {
          margin-top: 17px;
          font: 500 16px/20px $PRIMARY_FONT;
        }

        &.selected .tv-workassignment__detail-status {
          color: #fff !important;
        }
      }
    }

    &__filter-date-range__content {
      position: fixed;
      left: 35px;
      border-radius: 4px;
    }

    &__detail {
      &-links {
        display: flex;
        flex-direction: column;
      }

      &-footer {
        bottom: 10px;
      }
    }
  }

  .tk-assigment-list-filter-footer {
    position: fixed;
    z-index: 60;
    bottom: 0;
    padding: 14px 24px;
    left: 0;
    width: 100vw;
    background-color: #fff;
  }
}

.list-small-rating > label {
  padding-right: 0 !important;
}

.list-small-rating > label:before {
  font-size: 12px !important;
}

.tv-single-assign-form__control {
  height: 48px !important;
  font: 400 16px/20px $PRIMARY_FONT;

  .k-input {
    font: 400 16px/20px $PRIMARY_FONT !important;
    height: 100% !important;
  }
}
