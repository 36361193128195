@import '../../styles/variables.scss';

.tk-weekly-calendar {
  &__main {
  }

  &__scroll {
    height: 648px !important;
    width: inherit !important;
  }

  &__daily-planner-scroll {
    height: 70vh !important;
    width: inherit !important;
    border-bottom: 1px solid #f1f1f1;
    box-shadow: inset -5px 0px 8px 0 rgba(137, 136, 150, 0.27);
    border-top: 1px solid #f1f1f1;

    & > div:nth-child(1) {
      height: 70vh;
      overflow-x: hidden !important;
    }

    & > div:nth-child(2) {
      & > div {
        display: none !important;
      }

      height: 50vh;
    }

    & > div:nth-child(3) {
      overflow: hidden !important;
    }
  }

  &__hour-col {
    width: 69px;
    text-align: center;
    font: 0.625rem/0.875rem $PRIMARY_FONT;
    color: $TEXT_INACTIVE_COLOR;
    font-weight: 500;

    .tk-weekly-calendar__item-row {
      display: flex;
      padding: 5px;
      justify-content: center;
    }
  }

  &__availability-bar {
    width: 100%;
    background-color: blue;
    opacity: 0.5;
    color: rgb(253, 246, 246) !important;
    font-size: 0.675rem !important;
    padding: 5px 10px;
    text-align: end;
  }

  &__day-col {
    flex: 1;
  }

  &__item {
    &-add {
      display: none;
      width: 0;
      opacity: 0;
      background-color: #f1f3f4;
      color: $COMMON_TEXT_COLOR;
      font: 14px/18px $PRIMARY_FONT;
      border-radius: 4px;
      padding: 8px;
      margin: 8px 8px 0px 8px;
      text-align: center;
      cursor: pointer;
    }

    &-empty {
      margin: 5px;
      color: $COMMON_TEXT_COLOR;
      font: 14px/18px $PRIMARY_FONT;
      text-align: left;
      min-height: 50px;
      padding: 10px;
    }
  }

  &__day {
    &-header {
      background-color: $BACKGROUND_COLOR;
      border-right: 1px solid $BORDER_COLOR;
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
      .tk-weekly-calendar__day-row {
        &:nth-child(1) {
          border-top-left-radius: 8px;
        }
        &:nth-last-child(1) {
          border-top-right-radius: 8px;
        }
      }
    }

    &-row {
      flex: 1 1;
      min-width: 98px;
      text-align: center;
      min-height: 43px;
      border-top: 1px solid $BORDER_COLOR;
      border-left: 1px solid $BORDER_COLOR;
      border-bottom: 1px solid $BORDER_COLOR;
      // &:nth-child(1) {
      // border-top-left-radius: 4px;
      // border-bottom-left-radius: 4px;
      // }
      // &:nth-last-child(1) {
      // border-top-right-radius: 4px;
      // border-bottom-right-radius: 4px;
      // }
      span {
        color: $TEXT_INACTIVE_COLOR;
        font: 0.75rem/1.0625rem $PRIMARY_FONT;
        font-weight: 500;
        text-transform: capitalize;
      }
      &.week-header {
        display: flex;
        font: 400 11px/14px $PRIMARY_FONT;
        text-align: center;
        align-items: center;
        padding: unset !important;
        justify-content: center;
        text-transform: uppercase;
        color: $FONT_LIGHT_GRAY_COLOR;
      }

      &.today {
        color: $PRIMARY_COLOR;
        .round-dot {
          background-color: $PRIMARY_COLOR;
          width: 8px;
          height: 8px;
          border-radius: 50px;
          margin-right: 8px;
          margin-bottom: 1px;
        }

        span {
          color: $WHITE_COLOR;
        }
      }
      &:hover,
      &:active {
        .tk-weekly-calendar__item-add {
          width: auto;
          display: flex;
          -webkit-transform: scale(1);
          -moz-transform: scale(1);
          -ms-transform: scale(1);
          transform: scale(1);
          opacity: 1;
          transition: zoomIn 1s, display 0.5s, transform 0.2s, opacity 0.3s;
        }
      }
    }

    &-hour {
      color: $SECONDARY_COLOR;
      font: 0.875rem/1.25rem $PRIMARY_FONT;
      font-weight: 500;
      text-align: center;
      min-height: 43px;
      padding-top: 3px;
      width: 69px;
      border-top: 1px solid $BORDER_COLOR;
      border-left: 1px solid $BORDER_COLOR;
      border-bottom: 1px solid $BORDER_COLOR;
    }

    &-scroll {
      width: $SCROLLBAR_WIDTH;
      border-top: 1px solid $BORDER_COLOR;
      border-bottom: 1px solid $BORDER_COLOR;
    }
  }

  &__item-row {
    min-height: 50px;
    border-top: 1px solid $BORDER_COLOR;
    border-left: 1px solid $BORDER_COLOR;
  }

  &__container {
    position: relative;
  }

  &__event {
    &-item {
      font: 0.6875rem/1rem $SECONDARY_FONT;
      position: absolute;
      background: aliceblue;
      padding: 2px 5px 2px 5px;
      width: calc(100% + 2px);
      left: -1px;
      cursor: pointer;
      border-radius: 3px;
      line-height: 12px;
      color: $PRIMARY_COLOR;

      &--available {
        color: $COLOR_STATUS_AVAILABLE;
        border: 1px solid $COLOR_STATUS_AVAILABLE;
      }

      &--accepted {
        color: $COLOR_STATUS_ACCEPTED;
        border: 1px solid $COLOR_STATUS_ACCEPTED;
      }

      &--rejected {
        color: $COLOR_STATUS_REJECTED;
        border: 1px solid $COLOR_STATUS_REJECTED;
      }

      &--losted {
        color: $COLOR_STATUS_LOSTED;
        border: 1px solid $COLOR_STATUS_LOSTED;
      }

      &--traveling,
      &--working {
        color: $COLOR_STATUS_WORKING;
        border: 1px solid $COLOR_STATUS_WORKING;
      }

      &--fullfilled {
        color: $COLOR_STATUS_FULLILLED;
        border: 1px solid $COLOR_STATUS_FULLILLED;
      }

      &--done {
        color: $COLOR_STATUS_DONE;
        border: 1px solid $COLOR_STATUS_DONE;
      }

      &--cancelled {
        color: $COLOR_STATUS_CANCELLED;
        border: 1px solid $COLOR_STATUS_CANCELLED;
      }

      &--submitted {
        color: $COLOR_STATUS_SUBMITTED;
        border: 1px solid $COLOR_STATUS_SUBMITTED;
      }

      // &--availableRequest {
      //   border: 1px solid $PRIMARY_COLOR;
      //   border-style: dashed;
      // }
      // &--transvoice {
      //   color: $PURPLE_BLUE_COLOR;
      //   border: 1px solid $PURPLE_BLUE_COLOR;
      // }

      &--custom {
        border: 1px solid $COLOR_STATUS_CUSTOM;
        color: $COLOR_STATUS_CUSTOM;
      }
    }

    &-accepted {
      background-color: $COLOR_STATUS_ACCEPTED;
      .briefcase path {
        fill: $COLOR_STATUS_ACCEPTED;
      }
    }

    &-fullfilled {
      background-color: $COLOR_STATUS_FULLILLED;
      .briefcase path {
        fill: $COLOR_STATUS_FULLILLED;
      }
    }

    &-cancelled {
      background-color: $COLOR_STATUS_CANCELLED;
      .briefcase path {
        fill: $COLOR_STATUS_CANCELLED;
      }
    }
    &-available {
      background-color: $COLOR_STATUS_AVAILABLE;
      .briefcase path {
        fill: $COLOR_STATUS_AVAILABLE;
      }
    }
    &-rejected {
      background-color: $COLOR_STATUS_REJECTED;
      .briefcase path {
        fill: $COLOR_STATUS_REJECTED;
      }
    }
    &-submitted {
      background-color: $COLOR_STATUS_SUBMITTED;
      .briefcase path {
        fill: $COLOR_STATUS_SUBMITTED;
      }
    }
    &-working {
      background-color: $COLOR_STATUS_WORKING;
      .briefcase path {
        fill: $COLOR_STATUS_WORKING;
      }
    }
    &-selected {
      border: 3px solid $SECONDARY_COLOR !important;
    }
    &-single {
      color: $FONT_LIGHT_GRAY_COLOR;
      border-radius: 7px;
      text-align: left;
      cursor: pointer;
      margin: 8px;
      // padding: 9px;
      background-color: $WHITE_COLOR;
      filter: drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.16));
      .tv-workassignment__list-scrolltop {
        color: $COMMON_TEXT_COLOR;
        right: 16px;
      }
      &_container1 {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: space-between;
        font-family: $PRIMARY_FONT;
        margin-bottom: 5px;
        padding-inline: 9px;
        padding-top: 9px;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        .header-wrapper {
          color: $WHITE_COLOR;
          display: flex;
          // border-bottom: 1px solid #efefef;
          width: 100%;

          .notification {
            color: $WHITE_COLOR;
          }
        }
        .skill {
          font: 400 14px/18px $PRIMARY_FONT;
          margin-bottom: 8px;
          overflow: hidden;
          width: 100%;
          text-overflow: ellipsis;
        }
        .status {
          font-size: 8px;
          background: white;
          line-height: 20px;
          border-radius: 13px;
          color: black;
          padding: 1px 8px 0px;
          width: -moz-fit-content;
          width: fit-content;
          text-transform: uppercase;
        }
        @media (max-width: 1024px) {
          .skill {
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
      &_container2 {
        display: flex;
        flex-wrap: wrap;

        flex-direction: column;
        width: 100%;
        font-family: $PRIMARY_FONT;
        padding-inline: 9px;
        padding-bottom: 9px;
        .fields {
          font: 400 11px/14px $PRIMARY_FONT;
          margin: 0 auto 10px 0;
          display: block;
          overflow-x: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 100%;
          &:last-child {
            margin-bottom: 0px;
          }
          .icon-hearing {
            &:before {
              display: flex;
              -webkit-mask-size: 14px;
              background-color: $FONT_LIGHT_GRAY_COLOR;
              height: 14px;
              width: 14px;
              position: relative;
              left: -2px;
              bottom: -2px;
            }
          }
          i {
            font-size: 14px;
          }

          .briefcase {
            width: 14px;
            height: 14px;
            margin-right: 8px;
          }
        }

        .icon-hearing {
          margin-right: 4px !important;
          &:before {
            display: flex;
            -webkit-mask-size: 14px;
            background-color: $FONT_LIGHT_GRAY_COLOR;
            height: 14px;
            width: 14px;
            position: relative;
            top: 3px;
            left: -2px;
          }
        }
      }

      .time:before {
        color: white;
        margin-right: 10px;
      }

      .contactPerson:before {
        color: white;
        margin-right: 10px;
      }
    }

    &-content {
      height: 100%;
      overflow: hidden;
      line-height: 1.4rem;
    }

    &-time {
      font-size: 0.5rem;
      font-weight: bold;
    }
  }

  &-events {
    border-right: 1px solid $BORDER_COLOR;
    min-height: 500px;
    background-color: $BACKGROUND_COLOR;
  }

  &__dialog {
    &-title {
      font: 1.125rem/1.75rem $PRIMARY_FONT;
      color: $NAV_BAR_TEXT_COLOR;
      font-weight: 300;
      letter-spacing: -0.2px;
      margin: 0 0 10px 0;
    }

    &-time,
    &-location {
      color: #797979;
      font: 0.875rem/1 $SECONDARY_FONT;
      line-height: 16px;
      margin: 0 0 10px 0;
    }

    &-time {
      &:before {
        margin-right: 14px;
      }
    }
  }
}
