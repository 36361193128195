@import url(https://fonts.googleapis.com/css?family=PT+Serif:400,400i,700,700i&display=swap);
@import url(/font-awesome/css/all.min.css);
@import url(/font-awesome/css/all.min.css);
@import url(/font-awesome/css/all.min.css);
.tk-monthly-calendar__scroll-container > div > div:nth-child(1) {
  padding-right: 15px; }

.tk-monthly-calendar__scroll-container > div > div:nth-child(2) {
  overflow: hidden; }

.tk-monthly-calendar__table-wrapper {
  font-family: "Circular Std", "Circular Std";
  width: 100%;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 8px;
  display: table; }
  @supports (display: grid) {
    .tk-monthly-calendar__table-wrapper {
      display: grid;
      grid-template-columns: repeat(7, 1fr); } }
  .tk-monthly-calendar__table-wrapper tr,
  .tk-monthly-calendar__table-wrapper tbody {
    border-radius: 15px;
    width: 100%;
    color: #000; }
    @supports (display: grid) {
      .tk-monthly-calendar__table-wrapper tr,
      .tk-monthly-calendar__table-wrapper tbody {
        display: grid;
        grid-column: 1 / -1;
        grid-template-columns: repeat(7, 1fr); } }
    @supports (display: -ms-grid) {
      .tk-monthly-calendar__table-wrapper tr,
      .tk-monthly-calendar__table-wrapper tbody {
        display: -webkit-flex;
        display: flex; } }
  .tk-monthly-calendar__table-wrapper td,
  .tk-monthly-calendar__table-wrapper th {
    border-right: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
    background-color: transparent; }
  .tk-monthly-calendar__table-wrapper th {
    text-align: center;
    -webkit-align-items: center;
            align-items: center;
    display: table-cell;
    padding: unset !important;
    -webkit-justify-content: center;
            justify-content: center;
    font-size: 11px;
    line-height: 14px;
    font-weight: 400;
    padding-top: 6px;
    height: 40px;
    text-align: center;
    text-transform: uppercase;
    color: #74737b; }
    @supports (display: grid) {
      .tk-monthly-calendar__table-wrapper th {
        display: -webkit-flex;
        display: flex; } }
  .tk-monthly-calendar__table-wrapper td {
    height: 130px;
    vertical-align: top;
    padding: 8px 10px;
    position: relative;
    display: table-cell;
    min-height: 120px; }
    @supports (display: grid) {
      .tk-monthly-calendar__table-wrapper td {
        -webkit-flex-direction: column;
                flex-direction: column;
        display: -webkit-flex;
        display: flex; } }
    .tk-monthly-calendar__table-wrapper td.highlight-day {
      border-radius: 4px;
      border: 2px solid #873bff; }

.tk-monthly-calendar__table-weekdays th {
  height: 40px;
  padding: 10px 15px;
  border-top: 0px #fff;
  border-left: 0px #fff; }
  @supports not (display: grid) {
    .tk-monthly-calendar__table-weekdays th {
      display: table-cell !important; } }

.tk-monthly-calendar__table-weekdays th:nth-last-child(1) {
  border-right: 0px #fff; }

.tk-monthly-calendar__table-wrapper.interpreter .tk-monthly-calendar__table-item {
  cursor: pointer; }

.tk-monthly-calendar__item-el:hover .tk-monthly-calendar__table-item {
  background-color: white; }

.tk-monthly-calendar__table-item-week-number {
  color: #74737b;
  font: 400 11px/14px "Circular Std", "Circular Std";
  position: absolute; }

.tk-monthly-calendar__table-wrapper.interpreter .tk-monthly-calendar__table-item:hover, .tk-monthly-calendar__table-wrapper.interpreter .tk-monthly-calendar__table-item:active {
  background-color: rgba(176, 176, 240, 0.75) !important; }

.tk-monthly-calendar__table-item:hover .tk-monthly-calendar__item-create-order, .tk-monthly-calendar__table-item:active .tk-monthly-calendar__item-create-order {
  display: -webkit-flex;
  display: flex;
  width: auto;
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1;
  transition: zoomIn 1s, display 0.5s, opacity 0.3s, -webkit-transform 0.2s;
  transition: zoomIn 1s, display 0.5s, transform 0.2s, opacity 0.3s;
  transition: zoomIn 1s, display 0.5s, transform 0.2s, opacity 0.3s, -webkit-transform 0.2s; }

.tk-monthly-calendar__item_scrollable {
  height: 150px;
  overflow: scroll;
  overflow-x: auto; }

.tk-monthly-calendar__item-number {
  color: #252525;
  font: 400 11px/14px "Circular Std", "Circular Std";
  letter-spacing: 0.2px;
  text-align: right; }
  .today .tk-monthly-calendar__item-number {
    position: relative;
    color: #fff;
    z-index: 2; }
    .today .tk-monthly-calendar__item-number::before {
      position: absolute;
      top: -5px;
      right: -4px;
      content: "";
      background: #873bff;
      border-radius: 50%;
      width: 22px;
      height: 22px;
      z-index: -1; }
  .other-month .tk-monthly-calendar__item-number {
    color: #969696; }

.tk-monthly-calendar__item-text {
  display: none; }

.tk-monthly-calendar__item__header {
  display: -webkit-flex;
  display: flex;
  background-color: #f1f3f4;
  padding: 8px;
  margin-top: 5px;
  cursor: pointer;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  color: #252525;
  font: 14px/18px "Circular Std", "Circular Std";
  border-radius: 4px; }
  .highlight-pill .tk-monthly-calendar__item__header {
    background-color: #873bff;
    color: #fff; }

.tk-monthly-calendar__item-count {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  text-align: center; }

.tk-monthly-calendar__item-notification i {
  color: #873bff; }

.highlight-pill .tk-monthly-calendar__item-notification i {
  color: #fff; }

.tk-monthly-calendar__item-create-order {
  width: unset;
  opacity: 0;
  background-color: #f1f3f4;
  color: #252525;
  font: 14px/18px "Circular Std", "Circular Std";
  border-radius: 4px;
  padding: 8px;
  margin-top: 8px;
  text-align: center;
  cursor: pointer; }

.tk-monthly-calendar__item-empty {
  font: 0.875rem "Circular Std", "Circular Std";
  display: none;
  margin-top: 5px;
  opacity: 0.7;
  color: #969696;
  text-align: center;
  padding: 10px 10px 0 10px; }

.tk-monthly-calendar__item-more-btn {
  border: 0;
  background: #ff9ba0;
  font: 0.625rem/0.8125rem "Circular Std", "Circular Std";
  text-transform: uppercase;
  color: #fff;
  border-radius: 10px;
  padding: 0 8px;
  cursor: pointer;
  outline: none; }

.tk-monthly-calendar__item-view-all-btn {
  border: 0;
  font: 0.625rem/0.8125rem "PT Serif", serif;
  text-transform: uppercase;
  border-radius: 10px;
  padding: 0 9px;
  margin-bottom: 10px;
  outline: none;
  background: #ff9ba0;
  color: white; }
  .tk-monthly-calendar__item-view-all-btn:after {
    margin-left: 3px;
    content: "\e931";
    font-family: "transvoice-icon";
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    font-feature-settings: "liga";
    margin-left: 4px;
    font-size: 8px; }

.tk-monthly-calendar__item-availability-btn {
  border: 0;
  cursor: pointer;
  border-radius: 50%;
  position: absolute;
  top: 9%;
  right: 14.5%;
  font: 0px "Circular Std", "Circular Std";
  background: #6566b9;
  height: 8px;
  width: 8px; }

.tk-monthly-calendar__item-availability-icon {
  border: 0;
  border-radius: 50%;
  background: #6566b9;
  height: 8px;
  width: 8px;
  margin: 5px; }

.tk-monthly-calendar__item-unavailability-icon {
  margin: 5px;
  border: 0;
  border-radius: 50%;
  background: #873bff;
  height: 8px;
  width: 8px; }

.tk-monthly-calendar__item-unavailability-btn {
  border: 0;
  border-radius: 50%;
  position: absolute;
  cursor: pointer;
  top: 9%;
  right: 2%;
  font: 0px "Circular Std", "Circular Std";
  background: #873bff;
  width: 8px;
  height: 8px; }

.tk-monthly-calendar__item-el {
  font: 0.75rem/1.3rem "Circular Std", "Circular Std";
  padding: 3px 0 6px 10px;
  position: relative; }
  .tk-monthly-calendar__item-el:before {
    content: "";
    width: 5px;
    height: 5px;
    position: absolute;
    border-radius: 100%;
    top: 9px;
    left: 0; }
  .tk-monthly-calendar__item-el--available {
    color: #ff9b4e; }
    .tk-monthly-calendar__item-el--available:before {
      background: #ff9b4e; }
  .tk-monthly-calendar__item-el--accepted {
    color: #6478e6; }
    .tk-monthly-calendar__item-el--accepted:before {
      background: #6478e6; }
  .tk-monthly-calendar__item-el--rejected {
    color: #e94242; }
    .tk-monthly-calendar__item-el--rejected:before {
      background: #e94242; }
  .tk-monthly-calendar__item-el--losted {
    color: #696969; }
    .tk-monthly-calendar__item-el--losted:before {
      background: #696969; }
  .tk-monthly-calendar__item-el--traveling, .tk-monthly-calendar__item-el--working {
    color: #fbbcdb; }
    .tk-monthly-calendar__item-el--traveling:before, .tk-monthly-calendar__item-el--working:before {
      background: #fbbcdb; }
  .tk-monthly-calendar__item-el--fullfilled {
    color: #fff; }
    .tk-monthly-calendar__item-el--fullfilled:before {
      background: #18ad79; }
  .tk-monthly-calendar__item-el--done {
    color: #ffa500; }
    .tk-monthly-calendar__item-el--done:before {
      background: #ffa500; }
  .tk-monthly-calendar__item-el--cancelled {
    color: #74737b; }
    .tk-monthly-calendar__item-el--cancelled:before {
      background: #74737b; }
  .tk-monthly-calendar__item-el--submitted {
    color: #008000; }
    .tk-monthly-calendar__item-el--submitted:before {
      background: #008000; }
  .tk-monthly-calendar__item-el--custom {
    color: #9c69ad; }
    .tk-monthly-calendar__item-el--custom:before {
      background: #9c69ad; }

.tk-monthly-calendar__item-el:hover {
  background-color: #e1cece;
  box-shadow: 0 10px 18px -12px rgba(58, 68, 131, 0.2); }

.tk-monthly-calendar__item-title {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }

.tk-monthly-calendar__item-list .tk-monthly-calendar__item-row {
  padding: 3px 10px 5px; }
  .tk-monthly-calendar__item-list .tk-monthly-calendar__item-row:hover {
    background: #ffd2cd; }
  .tk-monthly-calendar__item-list .tk-monthly-calendar__item-row:last-child {
    border-radius: 0 0 5px 5px; }

.tk-monthly-calendar__table-wrapper .tk-monthly-calendar__item-list {
  width: calc(100% + 70px);
  padding: 0;
  top: 82px;
  left: 77px; }
  .tk-monthly-calendar__table-wrapper .tk-monthly-calendar__item-list:before {
    display: none; }

.tk-monthly-calendar__table-wrapper .tk-monthly-calendar__item-list {
  width: calc(100% + 70px);
  padding: 0;
  top: 82px;
  left: 77px; }
  .tk-monthly-calendar__table-wrapper .tk-monthly-calendar__item-list:before {
    display: none; }

.tk-monthly-calendar__list-date-month {
  margin-top: 5px;
  margin-bottom: 2px; }

.tk-monthly-calendar__list-month {
  color: #313131;
  font: 1rem/1.5rem "Circular Std", "Circular Std";
  margin: 5px 0 0 12px; }

.tk-monthly-calendar__list-day {
  margin: -4px 0 10px 4px;
  color: #873bff;
  font: 1rem/1.4375rem "Circular Std", "Circular Std";
  font-weight: 500; }

.tk-monthly-calendar__button {
  text-align: center; }

@media (max-width: 768px) {
  .other-month {
    display: none !important; }
  .tk-monthly-calendar__item__header {
    padding: 0 !important; }
  .tk-monthly-calendar__item-text {
    display: unset;
    font: 11px/16px "Circular Std", "Circular Std";
    text-transform: uppercase; }
  .tk-monthly-calendar__item-create-order {
    padding: 8px;
    margin: 8px 2px;
    text-align: left; }
  .tk-monthly-calendar__item-count {
    padding: 16px 8px; }
  .tk-monthly-calendar__item-notification {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    padding-right: 10px; }
  .tk-monthly-calendar__item-number {
    color: #74737b;
    font: 11px/16px "Circular Std", "Circular Std";
    font-weight: 400;
    text-align: center;
    background-color: #f7f9ff;
    text-align: left;
    padding: 0 0 8px 0;
    text-transform: uppercase; }
    .today .tk-monthly-calendar__item-number {
      position: relative;
      color: #fff;
      padding: 4px 2px;
      background-color: #873bff;
      z-index: 0; }
      .today .tk-monthly-calendar__item-number::before {
        content: none; }
  .tk-monthly-calendar__item-empty {
    display: -webkit-flex;
    display: flex;
    opacity: 1;
    color: #252525;
    text-align: left;
    padding: 16px;
    margin: 8px 2px; }
  .tk-monthly-calendar__item-no_event {
    background-color: #f7f9ff;
    text-align: center; }
  .tk-monthly-calendar__table {
    margin: 7px; }
    .tk-monthly-calendar__table-weekdays {
      display: none !important; }
    .tk-monthly-calendar__table-item {
      padding: 0px !important;
      height: auto;
      min-width: 40px;
      border: none !important; }
      .tk-monthly-calendar__table-item.weekend-background {
        margin-bottom: 8px; }
    @supports (display: grid) {
      .tk-monthly-calendar__table-wrapper {
        grid-template-columns: 1fr;
        border-radius: 15px;
        border: none !important;
        height: 650px;
        box-shadow: none; }
        .tk-monthly-calendar__table-wrapper td {
          min-height: 85px;
          height: auto; }
        .tk-monthly-calendar__table-wrapper tr,
        .tk-monthly-calendar__table-wrapper tbody {
          grid-template-columns: 1fr;
          border: none !important; }
        .tk-monthly-calendar__table-wrapper tbody {
          margin-bottom: 60px; }
        .tk-monthly-calendar__table-wrapper tr {
          grid-column: 1 / 2; }
        .tk-monthly-calendar__table-wrapper tr:last-child {
          margin-bottom: 80px !important; } }
    .tk-monthly-calendar__table-wrapper th,
    .tk-monthly-calendar__table-wrapper td {
      display: table-row; } }

.tk-weekly-calendar__scroll {
  height: 648px !important;
  width: inherit !important; }

.tk-weekly-calendar__daily-planner-scroll {
  height: 70vh !important;
  width: inherit !important;
  border-bottom: 1px solid #f1f1f1;
  box-shadow: inset -5px 0px 8px 0 rgba(137, 136, 150, 0.27);
  border-top: 1px solid #f1f1f1; }
  .tk-weekly-calendar__daily-planner-scroll > div:nth-child(1) {
    height: 70vh;
    overflow-x: hidden !important; }
  .tk-weekly-calendar__daily-planner-scroll > div:nth-child(2) {
    height: 50vh; }
    .tk-weekly-calendar__daily-planner-scroll > div:nth-child(2) > div {
      display: none !important; }
  .tk-weekly-calendar__daily-planner-scroll > div:nth-child(3) {
    overflow: hidden !important; }

.tk-weekly-calendar__hour-col {
  width: 69px;
  text-align: center;
  font: 0.625rem/0.875rem "Circular Std", "Circular Std";
  color: #969696;
  font-weight: 500; }
  .tk-weekly-calendar__hour-col .tk-weekly-calendar__item-row {
    display: -webkit-flex;
    display: flex;
    padding: 5px;
    -webkit-justify-content: center;
            justify-content: center; }

.tk-weekly-calendar__availability-bar {
  width: 100%;
  background-color: blue;
  opacity: 0.5;
  color: #fdf6f6 !important;
  font-size: 0.675rem !important;
  padding: 5px 10px;
  text-align: end; }

.tk-weekly-calendar__day-col {
  -webkit-flex: 1 1;
          flex: 1 1; }

.tk-weekly-calendar__item-add {
  display: none;
  width: 0;
  opacity: 0;
  background-color: #f1f3f4;
  color: #252525;
  font: 14px/18px "Circular Std", "Circular Std";
  border-radius: 4px;
  padding: 8px;
  margin: 8px 8px 0px 8px;
  text-align: center;
  cursor: pointer; }

.tk-weekly-calendar__item-empty {
  margin: 5px;
  color: #252525;
  font: 14px/18px "Circular Std", "Circular Std";
  text-align: left;
  min-height: 50px;
  padding: 10px; }

.tk-weekly-calendar__day-header {
  background-color: #f7f9ff;
  border-right: 1px solid #e0e0e0;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px; }
  .tk-weekly-calendar__day-header .tk-weekly-calendar__day-row:nth-child(1) {
    border-top-left-radius: 8px; }
  .tk-weekly-calendar__day-header .tk-weekly-calendar__day-row:nth-last-child(1) {
    border-top-right-radius: 8px; }

.tk-weekly-calendar__day-row {
  -webkit-flex: 1 1;
          flex: 1 1;
  min-width: 98px;
  text-align: center;
  min-height: 43px;
  border-top: 1px solid #e0e0e0;
  border-left: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0; }
  .tk-weekly-calendar__day-row span {
    color: #969696;
    font: 0.75rem/1.0625rem "Circular Std", "Circular Std";
    font-weight: 500;
    text-transform: capitalize; }
  .tk-weekly-calendar__day-row.week-header {
    display: -webkit-flex;
    display: flex;
    font: 400 11px/14px "Circular Std", "Circular Std";
    text-align: center;
    -webkit-align-items: center;
            align-items: center;
    padding: unset !important;
    -webkit-justify-content: center;
            justify-content: center;
    text-transform: uppercase;
    color: #74737b; }
  .tk-weekly-calendar__day-row.today {
    color: #873bff; }
    .tk-weekly-calendar__day-row.today .round-dot {
      background-color: #873bff;
      width: 8px;
      height: 8px;
      border-radius: 50px;
      margin-right: 8px;
      margin-bottom: 1px; }
    .tk-weekly-calendar__day-row.today span {
      color: #fff; }
  .tk-weekly-calendar__day-row:hover .tk-weekly-calendar__item-add, .tk-weekly-calendar__day-row:active .tk-weekly-calendar__item-add {
    width: auto;
    display: -webkit-flex;
    display: flex;
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
    transition: zoomIn 1s, display 0.5s, opacity 0.3s, -webkit-transform 0.2s;
    transition: zoomIn 1s, display 0.5s, transform 0.2s, opacity 0.3s;
    transition: zoomIn 1s, display 0.5s, transform 0.2s, opacity 0.3s, -webkit-transform 0.2s; }

.tk-weekly-calendar__day-hour {
  color: #873bff;
  font: 0.875rem/1.25rem "Circular Std", "Circular Std";
  font-weight: 500;
  text-align: center;
  min-height: 43px;
  padding-top: 3px;
  width: 69px;
  border-top: 1px solid #e0e0e0;
  border-left: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0; }

.tk-weekly-calendar__day-scroll {
  width: 10px;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0; }

.tk-weekly-calendar__item-row {
  min-height: 50px;
  border-top: 1px solid #e0e0e0;
  border-left: 1px solid #e0e0e0; }

.tk-weekly-calendar__container {
  position: relative; }

.tk-weekly-calendar__event-item {
  font: 0.6875rem/1rem "Circular Std", "Circular Std";
  position: absolute;
  background: aliceblue;
  padding: 2px 5px 2px 5px;
  width: calc(100% + 2px);
  left: -1px;
  cursor: pointer;
  border-radius: 3px;
  line-height: 12px;
  color: #873bff; }
  .tk-weekly-calendar__event-item--available {
    color: #ff9b4e;
    border: 1px solid #ff9b4e; }
  .tk-weekly-calendar__event-item--accepted {
    color: #6478e6;
    border: 1px solid #6478e6; }
  .tk-weekly-calendar__event-item--rejected {
    color: #e94242;
    border: 1px solid #e94242; }
  .tk-weekly-calendar__event-item--losted {
    color: #696969;
    border: 1px solid #696969; }
  .tk-weekly-calendar__event-item--traveling, .tk-weekly-calendar__event-item--working {
    color: #fbbcdb;
    border: 1px solid #fbbcdb; }
  .tk-weekly-calendar__event-item--fullfilled {
    color: #18ad79;
    border: 1px solid #18ad79; }
  .tk-weekly-calendar__event-item--done {
    color: #ffa500;
    border: 1px solid #ffa500; }
  .tk-weekly-calendar__event-item--cancelled {
    color: #74737b;
    border: 1px solid #74737b; }
  .tk-weekly-calendar__event-item--submitted {
    color: #008000;
    border: 1px solid #008000; }
  .tk-weekly-calendar__event-item--custom {
    border: 1px solid #9c69ad;
    color: #9c69ad; }

.tk-weekly-calendar__event-accepted {
  background-color: #6478e6; }
  .tk-weekly-calendar__event-accepted .briefcase path {
    fill: #6478e6; }

.tk-weekly-calendar__event-fullfilled {
  background-color: #18ad79; }
  .tk-weekly-calendar__event-fullfilled .briefcase path {
    fill: #18ad79; }

.tk-weekly-calendar__event-cancelled {
  background-color: #74737b; }
  .tk-weekly-calendar__event-cancelled .briefcase path {
    fill: #74737b; }

.tk-weekly-calendar__event-available {
  background-color: #ff9b4e; }
  .tk-weekly-calendar__event-available .briefcase path {
    fill: #ff9b4e; }

.tk-weekly-calendar__event-rejected {
  background-color: #e94242; }
  .tk-weekly-calendar__event-rejected .briefcase path {
    fill: #e94242; }

.tk-weekly-calendar__event-submitted {
  background-color: #008000; }
  .tk-weekly-calendar__event-submitted .briefcase path {
    fill: #008000; }

.tk-weekly-calendar__event-working {
  background-color: #fbbcdb; }
  .tk-weekly-calendar__event-working .briefcase path {
    fill: #fbbcdb; }

.tk-weekly-calendar__event-selected {
  border: 3px solid #873bff !important; }

.tk-weekly-calendar__event-single {
  color: #74737b;
  border-radius: 7px;
  text-align: left;
  cursor: pointer;
  margin: 8px;
  background-color: #fff;
  -webkit-filter: drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.16));
          filter: drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.16)); }
  .tk-weekly-calendar__event-single .tv-workassignment__list-scrolltop {
    color: #252525;
    right: 16px; }
  .tk-weekly-calendar__event-single_container1 {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    font-family: "Circular Std", "Circular Std";
    margin-bottom: 5px;
    padding-inline: 9px;
    padding-top: 9px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px; }
    .tk-weekly-calendar__event-single_container1 .header-wrapper {
      color: #fff;
      display: -webkit-flex;
      display: flex;
      width: 100%; }
      .tk-weekly-calendar__event-single_container1 .header-wrapper .notification {
        color: #fff; }
    .tk-weekly-calendar__event-single_container1 .skill {
      font: 400 14px/18px "Circular Std", "Circular Std";
      margin-bottom: 8px;
      overflow: hidden;
      width: 100%;
      text-overflow: ellipsis; }
    .tk-weekly-calendar__event-single_container1 .status {
      font-size: 8px;
      background: white;
      line-height: 20px;
      border-radius: 13px;
      color: black;
      padding: 1px 8px 0px;
      width: -moz-fit-content;
      width: -webkit-fit-content;
      width: fit-content;
      text-transform: uppercase; }
    @media (max-width: 1024px) {
      .tk-weekly-calendar__event-single_container1 .skill {
        overflow: hidden;
        text-overflow: ellipsis; } }
  .tk-weekly-calendar__event-single_container2 {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 100%;
    font-family: "Circular Std", "Circular Std";
    padding-inline: 9px;
    padding-bottom: 9px; }
    .tk-weekly-calendar__event-single_container2 .fields {
      font: 400 11px/14px "Circular Std", "Circular Std";
      margin: 0 auto 10px 0;
      display: block;
      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%; }
      .tk-weekly-calendar__event-single_container2 .fields:last-child {
        margin-bottom: 0px; }
      .tk-weekly-calendar__event-single_container2 .fields .icon-hearing:before {
        display: -webkit-flex;
        display: flex;
        -webkit-mask-size: 14px;
        background-color: #74737b;
        height: 14px;
        width: 14px;
        position: relative;
        left: -2px;
        bottom: -2px; }
      .tk-weekly-calendar__event-single_container2 .fields i {
        font-size: 14px; }
      .tk-weekly-calendar__event-single_container2 .fields .briefcase {
        width: 14px;
        height: 14px;
        margin-right: 8px; }
    .tk-weekly-calendar__event-single_container2 .icon-hearing {
      margin-right: 4px !important; }
      .tk-weekly-calendar__event-single_container2 .icon-hearing:before {
        display: -webkit-flex;
        display: flex;
        -webkit-mask-size: 14px;
        background-color: #74737b;
        height: 14px;
        width: 14px;
        position: relative;
        top: 3px;
        left: -2px; }
  .tk-weekly-calendar__event-single .time:before {
    color: white;
    margin-right: 10px; }
  .tk-weekly-calendar__event-single .contactPerson:before {
    color: white;
    margin-right: 10px; }

.tk-weekly-calendar__event-content {
  height: 100%;
  overflow: hidden;
  line-height: 1.4rem; }

.tk-weekly-calendar__event-time {
  font-size: 0.5rem;
  font-weight: bold; }

.tk-weekly-calendar-events {
  border-right: 1px solid #e0e0e0;
  min-height: 500px;
  background-color: #f7f9ff; }

.tk-weekly-calendar__dialog-title {
  font: 1.125rem/1.75rem "Circular Std", "Circular Std";
  color: #313131;
  font-weight: 300;
  letter-spacing: -0.2px;
  margin: 0 0 10px 0; }

.tk-weekly-calendar__dialog-time, .tk-weekly-calendar__dialog-location {
  color: #797979;
  font: 0.875rem/1 "Circular Std", "Circular Std";
  line-height: 16px;
  margin: 0 0 10px 0; }

.tk-weekly-calendar__dialog-time:before {
  margin-right: 14px; }

.tv-display-mobile-show {
  display: none !important; }

@media (max-width: 768px) {
  .tv-display-mobile-grid {
    display: grid !important; }
  .tv-display-mobile-hide {
    display: none !important; }
  .tv-display-mobile-show {
    display: block !important; }
  .tk-weekly-calendar__event-single {
    margin: 5px !important; }
    .tk-weekly-calendar__event-single_container1 {
      display: -webkit-flex;
      display: flex;
      padding-inline: 10px;
      padding-top: 10px; }
      .tk-weekly-calendar__event-single_container1 .skill {
        width: 100%;
        font-weight: 100; }
      .tk-weekly-calendar__event-single_container1 .status {
        width: 25%;
        text-align: center;
        font-size: 8px;
        background: white;
        border-radius: 13px;
        color: black;
        padding: 1px 8px 0px;
        margin-bottom: 5px;
        text-transform: uppercase; }
    .tk-weekly-calendar__event-single_container2 {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
              flex-direction: row;
      padding-inline: 10px;
      padding-bottom: 10px; }
      .tk-weekly-calendar__event-single_container2 .fields {
        letter-spacing: 0.7px;
        -webkit-align-items: baseline;
                align-items: baseline;
        width: 50%;
        font: 400 12px/14px "Circular Std", "Circular Std"; }
        .tk-weekly-calendar__event-single_container2 .fields i {
          font-size: 16px; }
      .tk-weekly-calendar__event-single_container2 .icon-hearing:before {
        display: -webkit-flex;
        display: flex;
        -webkit-mask-size: 14px;
        background-color: #ffffff;
        height: 14px;
        width: 14px; }
  .tk-weekly-calendar__header {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    margin-bottom: 15px;
    font-size: 12px !important; }
    .tk-weekly-calendar__header .tk-calendar__title {
      margin: 0px 5px; }
    .tk-weekly-calendar__header .tk-calendar__tabs-wrapper {
      position: relative;
      margin: 0px auto; }
  .tk-weekly-calendar-events {
    border: none;
    border-radius: 10px;
    overflow-y: scroll;
    margin-bottom: 80px; }
    .tk-weekly-calendar-events .tk-weekly-calendar__day-row {
      min-height: 90px;
      min-width: auto; }
  .tk-weekly-calendar__day-row {
    border: none !important; }
    .tk-weekly-calendar__day-row span {
      font: 11px/14px 'Circular Std', sans-serif !important;
      color: #74737b !important; }
  .tk-weekly-calendar__item-add {
    min-height: auto; }
  .tk-weekly-calendar__item-number {
    color: #74737b;
    font: 11px/16px 'Circular Std', sans-serif;
    font-weight: 400;
    text-align: center;
    background-color: #f7f9ff;
    text-align: left;
    padding: 8px 5px; }
  .tk-weekly-calendar__item-text {
    font: 1rem/1.625rem 'Circular Std', sans-serif !important;
    color: #000 !important;
    margin-right: 6px;
    text-transform: uppercase !important; } }

.tk-weekly-calendar__day-row:nth-child(1) .tk-weekly-calendar__item-number {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px; }

@charset "UTF-8";
label {
  margin: 0;
  padding: 0; }

.rating {
  border: none;
  display: inline-block; }
  .rating > input {
    display: none; }
  .rating > label:before {
    margin: 5px;
    font-size: 25px;
    font-size: 1.5625rem;
    display: inline-block;
    content: '★';
    font-family: 'transvoice-icon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }
  .rating > label {
    color: #ddd;
    float: right;
    font-size: 24px;
    padding-right: 2px; }

.rating.red > input:checked ~ label,
.rating.red > input[data-hightlight=true] ~ label,
.rating.red input:enabled + label:hover,
.rating.red input:enabled + label:hover ~ label {
  color: #f93d3d; }

.rating > input:checked ~ label,
.rating > input[data-hightlight=true] ~ label,
.rating input:enabled + label.red:hover,
.rating input:enabled + label.red:hover ~ label {
  color: #f93d3d; }

.rating.red-yellow > input:checked ~ label,
.rating.red-yellow > input[data-hightlight=true] ~ label,
.rating.red-yellow input:enabled + label:hover,
.rating.red-yellow input:enabled + label:hover ~ label {
  color: #fd974de6; }

.rating > input:checked ~ label,
.rating > input[data-hightlight=true] ~ label,
.rating input:enabled + label.red-yellow:hover,
.rating input:enabled + label.red-yellow:hover ~ label {
  color: #fd974de6; }

.rating.yellow > input:checked ~ label,
.rating.yellow > input[data-hightlight=true] ~ label,
.rating.yellow input:enabled + label:hover,
.rating.yellow input:enabled + label:hover ~ label {
  color: #ff9b4e; }

.rating > input:checked ~ label,
.rating > input[data-hightlight=true] ~ label,
.rating input:enabled + label.yellow:hover,
.rating input:enabled + label.yellow:hover ~ label {
  color: #ffd700; }

.rating.yellow-green > input:checked ~ label,
.rating.yellow-green > input[data-hightlight=true] ~ label,
.rating.yellow-green input:enabled + label:hover,
.rating.yellow-green input:enabled + label:hover ~ label {
  color: #ff9b4e; }

.rating > input:checked ~ label,
.rating > input[data-hightlight=true] ~ label,
.rating input:enabled + label.yellow-green:hover,
.rating input:enabled + label.yellow-green:hover ~ label {
  color: #ff9b4e; }

.rating.green > input:checked ~ label,
.rating.green > input[data-hightlight=true] ~ label,
.rating.green input:enabled + label:hover,
.rating.green input:enabled + label:hover ~ label {
  color: #ff9b4e; }

.rating > input:checked ~ label,
.rating > input[data-hightlight=true] ~ label,
.rating input:enabled + label.green:hover,
.rating input:enabled + label.green:hover ~ label {
  color: #ff9b4e; }

/* hover previous stars in list */
.rating > input:checked:enabled + label.red:hover,
.rating > input:checked:enabled ~ label.red:hover,
.rating > input[data-hightlight=true] ~ label.red:hover,
.rating > label.red:hover ~ input:checked:enabled ~ label,
.rating > input:checked:enabled ~ label.red:hover ~ label {
  color: #ff9b4e !important; }

.rating > input:checked:enabled + label.red-yellow:hover,
.rating > input:checked:enabled ~ label.red-yellow:hover,
.rating > input[data-hightlight=true] ~ label.red-yellow:hover,
.rating > label.red-yellow:hover ~ input:checked:enabled ~ label,
.rating > input:checked:enabled ~ label.red-yellow:hover ~ label {
  color: #ff9b4e !important; }

.rating > input:checked:enabled + label.yellow:hover,
.rating > input:checked:enabled ~ label.yellow:hover,
.rating > input[data-hightlight=true] ~ label.yellow:hover,
.rating > label.yellow:hover ~ input:checked:enabled ~ label,
.rating > input:checked:enabled ~ label.yellow:hover ~ label {
  color: #ff9b4e !important; }

.rating > input:checked:enabled + label.yellow-green:hover,
.rating > input:checked:enabled ~ label.yellow-green:hover,
.rating > input[data-hightlight=true] ~ label.yellow-green:hover,
.rating > label.yellow-green:hover ~ input:checked:enabled ~ label,
.rating > input:checked:enabled ~ label.yellow-green:hover ~ label {
  color: #ff9b4e !important; }

.rating > input:checked:enabled + label.green:hover,
.rating > input:checked:enabled ~ label.green:hover,
.rating > input[data-hightlight=true] ~ label.green:hover,
.rating > label.green:hover ~ input:checked:enabled ~ label,
.rating > input:checked:enabled ~ label.green:hover ~ label {
  color: #ff9b4e !important; }

.detailed-rating > label:before {
  font-size: 24px !important;
  -webkit-text-stroke: 1px #ffb074; }

.list-rating > label:before {
  font-size: 1rem !important; }

.list-rating > label {
  padding-right: 0px !important; }

.list-rating-container {
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  text-align: center;
  width: 21.6%;
  padding-left: 5px; }
  .list-rating-container > div {
    margin-bottom: 10px;
    margin-top: 0px; }
  .list-rating-container > div > .rating {
    margin-left: 20%; }
  @supports (display: grid) {
    .list-rating-container {
      display: grid;
      grid-template-rows: 1fr 1fr;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center;
      width: 21.6%;
      padding-left: 5px; }
      @media only screen and (max-width: 1412px) {
        .list-rating-container > div {
          margin-top: -20px !important; } }
      .list-rating-container > div {
        margin-top: -5px;
        min-width: 184px; }
      .list-rating-container > div > .rating {
        margin-left: 0px; } }
  .list-rating-container > div.tv-workassignment__item-status {
    width: 100% !important; }

.tv-rate-submit-btn {
  padding: 6px 4px 2px !important;
  font-size: 12px !important;
  margin-left: 32px; }

textarea {
  padding: 14px 20px;
  resize: none; }

.comment-text {
  font-style: italic;
  font-size: 0.875rem;
  color: #313131 !important;
  padding: 2px 6px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row; }

.icon-comment-2:before {
  color: #c0c2c3 !important;
  margin: 1px 9px 0px 0px; }

.show-remainig-char {
  font-style: italic;
  font-size: 12px;
  display: block;
  text-align: right; }

.redirect-checkbox {
  padding: 0px 5px; }

.redirect-checkbox label {
  font-size: 0.8125rem;
  font-weight: 300; }

.good-rating-message {
  border-left: 5px solid #17c917;
  font: 0.725rem 'Circular Std', sans-serif;
  padding: 5px;
  margin: 5px 0px !important;
  background-color: #c7f055; }

.rating-label {
  margin: 5px 0px;
  font: 0.875rem 'Circular Std', sans-serif; }

.send-feedback-btn {
  -webkit-align-items: flex-end;
          align-items: flex-end;
  margin-left: auto;
  width: 150px !important;
  margin-right: 15px; }

.tv-single-assign-form__dropdownlist {
  padding: 10px 10px !important;
  background-image: linear-gradient(45deg, transparent 50%, #696767 50%), linear-gradient(135deg, #696767 50%, transparent 50%), linear-gradient(to right, white, white) !important;
  background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px), 100% 0 !important;
  background-size: 5px 5px, 5px 5px, 2.5em 2.5em !important;
  background-repeat: no-repeat !important; }
  .tv-single-assign-form__dropdownlist:after {
    content: '\e933';
    position: absolute;
    font-family: 'transvoice-icon';
    top: 12px;
    left: 2px; }

.feedback-create-deviation {
  color: #873bff;
  font-family: "Circular Std", "Circular Std";
  font-size: 16px;
  line-height: 23px;
  cursor: pointer; }
  .feedback-create-deviation:hover {
    text-decoration: underline; }

.footer-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  width: 100%; }
  .footer-wrapper > .tv-rate-submit-btn {
    margin-left: 15px;
    margin-right: 0px; }

.wrapper-stars-button {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }

.tv-workassignment__list {
  overflow: hidden;
  margin-bottom: 20px;
  padding-bottom: 10px; }
  .tv-workassignment__list__mobile-fixed-bottom {
    position: fixed;
    background-color: #fff;
    text-align: center;
    display: -webkit-flex;
    display: flex;
    left: 0;
    bottom: 0;
    z-index: 20;
    width: 100%; }
  .tv-workassignment__list-empty {
    display: -webkit-flex;
    display: flex;
    margin-top: 100px;
    text-align: center;
    -webkit-flex-direction: column;
            flex-direction: column; }
    .tv-workassignment__list-empty-img {
      display: block;
      background: url("/images/search-empty -state.svg") no-repeat center;
      background-size: contain;
      width: 100px;
      height: 100px;
      margin: 10px auto; }
    .tv-workassignment__list-empty p {
      font-family: Circular Std;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 30px;
      width: 558px;
      margin: 13px auto 0; }
      @media (max-width: 768px) {
        .tv-workassignment__list-empty p {
          width: 100%; } }

.icon-sort:after {
  color: #c5c5c5;
  margin-left: 4px;
  font-size: 10px;
  color: #252525; }

@media (min-width: 768px) {
  .tv-workassignment__wrapper .tk-left-drawer,
  .tv-workassignment-calendar__wrapper .tk-left-drawer {
    top: 100px;
    height: 800px; }
    .tv-workassignment__wrapper .tk-left-drawer .tk-dialog__wrapper,
    .tv-workassignment-calendar__wrapper .tk-left-drawer .tk-dialog__wrapper {
      box-shadow: none; }
      .tv-workassignment__wrapper .tk-left-drawer .tk-dialog__wrapper .tk-dialog__wrapper--before,
      .tv-workassignment-calendar__wrapper .tk-left-drawer .tk-dialog__wrapper .tk-dialog__wrapper--before {
        display: none; }
    .tv-workassignment__wrapper .tk-left-drawer .tk-assignment-list-box__container,
    .tv-workassignment__wrapper .tk-left-drawer .tk-assignment-detail__container,
    .tv-workassignment-calendar__wrapper .tk-left-drawer .tk-assignment-list-box__container,
    .tv-workassignment-calendar__wrapper .tk-left-drawer .tk-assignment-detail__container {
      height: 100%; }
  .tv-workassignment-calendar__wrapper {
    margin-top: 0; }
    .tv-workassignment-calendar__wrapper .tk-left-drawer {
      top: 72px !important; }
  .tv-workassignment__list-scrolltop {
    background-color: white;
    height: 40px;
    -webkit-align-items: center;
            align-items: center;
    -webkit-animation-duration: 3s;
            animation-duration: 3s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    display: -webkit-flex;
    display: flex;
    padding: 8px;
    right: 100px;
    border-radius: 8px;
    text-transform: capitalize; }
  .bounce-4 {
    -webkit-animation-name: bounce-4;
            animation-name: bounce-4;
    -webkit-animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1);
            animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1); }
  @-webkit-keyframes bounce-4 {
    0% {
      -webkit-transform: scale(1, 1) translateY(0);
              transform: scale(1, 1) translateY(0); }
    10% {
      -webkit-transform: scale(1.1, 0.9) translateY(0);
              transform: scale(1.1, 0.9) translateY(0); }
    30% {
      -webkit-transform: scale(0.9, 1.1) translateY(-15px);
              transform: scale(0.9, 1.1) translateY(-15px); }
    50% {
      -webkit-transform: scale(1.05, 0.95) translateY(0);
              transform: scale(1.05, 0.95) translateY(0); }
    57% {
      -webkit-transform: scale(1, 1) translateY(-7px);
              transform: scale(1, 1) translateY(-7px); }
    64% {
      -webkit-transform: scale(1, 1) translateY(0);
              transform: scale(1, 1) translateY(0); }
    100% {
      -webkit-transform: scale(1, 1) translateY(0);
              transform: scale(1, 1) translateY(0); } }
  @keyframes bounce-4 {
    0% {
      -webkit-transform: scale(1, 1) translateY(0);
              transform: scale(1, 1) translateY(0); }
    10% {
      -webkit-transform: scale(1.1, 0.9) translateY(0);
              transform: scale(1.1, 0.9) translateY(0); }
    30% {
      -webkit-transform: scale(0.9, 1.1) translateY(-15px);
              transform: scale(0.9, 1.1) translateY(-15px); }
    50% {
      -webkit-transform: scale(1.05, 0.95) translateY(0);
              transform: scale(1.05, 0.95) translateY(0); }
    57% {
      -webkit-transform: scale(1, 1) translateY(-7px);
              transform: scale(1, 1) translateY(-7px); }
    64% {
      -webkit-transform: scale(1, 1) translateY(0);
              transform: scale(1, 1) translateY(0); }
    100% {
      -webkit-transform: scale(1, 1) translateY(0);
              transform: scale(1, 1) translateY(0); } }
  .tv-advanced-order__scroll-container {
    height: 60vh !important; }
    .tv-advanced-order__scroll-container > div {
      overflow-x: hidden !important; }
    .tv-advanced-order__scroll-container .tv-checkbox .custom-control-input ~ .custom-control-label {
      font: 400 16px/18px 'Circular Std', sans-serif !important;
      font-size: 16px !important;
      letter-spacing: 0px !important;
      text-transform: capitalize !important;
      background-color: #fff !important; } }

@media (max-width: 1024px) and (orientation: landscape) {
  .tk-left-drawer {
    position: absolute !important;
    top: 155px; }
    .tk-left-drawer .tk-dialog__wrapper {
      box-shadow: none; }
      .tk-left-drawer .tk-dialog__wrapper .tk-dialog__wrapper--before {
        display: none !important; }
    .tk-left-drawer .tv-drawer_buttons {
      top: 630px;
      height: 50px; }
      .tk-left-drawer .tv-drawer_buttons .tv_dashboard__add-button {
        margin-bottom: 0; }
    .tk-left-drawer .tk-assignment-list-box__container,
    .tk-left-drawer .tk-assignment-detail__container {
      min-height: 700px; }
    .tk-left-drawer .tv-workassignment__modal {
      height: var(--vh) !important;
      min-height: 1220px; }
      .tk-left-drawer .tv-workassignment__modal .tv-modal__holder {
        height: unset;
        min-height: 700px;
        max-height: 1024px; }
        .tk-left-drawer .tv-workassignment__modal .tv-modal__holder .tv-workassignment__modal-scroll-container {
          height: 700px !important; }
      .tk-left-drawer .tv-workassignment__modal .tv-modal__overlay {
        height: var(--vh) !important;
        min-height: 1220px; } }

.calendar-small {
  width: 320px; }
  .calendar-small .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span,
  .calendar-small .rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span,
  .calendar-small .rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span,
  .calendar-small .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
    color: #252525;
    font-weight: 500; }
  .calendar-small .rdrDayStartOfMonth .rdrInRange,
  .calendar-small .rdrDayStartOfWeek .rdrInRange {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .calendar-small .rdrDayEndOfMonth .rdrInRange,
  .calendar-small .rdrDayEndOfMonth .rdrStartEdge,
  .calendar-small .rdrDayEndOfWeek .rdrInRange,
  .calendar-small .rdrDayEndOfWeek .rdrStartEdge {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px; }
  .calendar-small .rdrDayStartOfMonth .rdrInRange,
  .calendar-small .rdrDayStartOfMonth .rdrEndEdge,
  .calendar-small .rdrDayStartOfWeek .rdrInRange,
  .calendar-small .rdrDayStartOfWeek .rdrEndEdge {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px; }
  .calendar-small .rdrDayWeekend .rdrInRange.not-weekend {
    background-color: #fff; }
  .calendar-small .rdrDayStartOfWeek .rdrEndEdge,
  .calendar-small .rdrDayEndOfWeek .rdrStartEdge {
    border-radius: 0% !important;
    left: 9px; }
  .calendar-small .rdrDayEndOfMonth .rdrInRange,
  .calendar-small .rdrDayEndOfWeek .rdrInRange {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .calendar-small .rdrDay {
    height: 40px; }
  .calendar-small .rdrDayDisabled {
    background-color: #fff; }
  .calendar-small .rdrStartEdge,
  .calendar-small .rdrEndEdge {
    background-color: #873bff;
    border-radius: 4px;
    left: 4px;
    top: 5px;
    z-index: 1; }
  .calendar-small .rdrDay:not(.rdrDayPassive) .rdrStartEdge + .rdrDayNumber {
    background: linear-gradient(to right, #ffff 0%, #e1e6ff 100%, #e1e6ff 50%, #e1e6ff 100%); }
    .calendar-small .rdrDay:not(.rdrDayPassive) .rdrStartEdge + .rdrDayNumber.not-weekend {
      background: #fff; }
    .calendar-small .rdrDay:not(.rdrDayPassive) .rdrStartEdge + .rdrDayNumber span {
      z-index: 10;
      color: white !important; }
  .calendar-small .rdrDay:not(.rdrDayPassive) .rdrStartEdge + span + .rdrDayNumber {
    background: linear-gradient(to right, #ffff 0%, #e1e6ff 100%, #e1e6ff 50%, #e1e6ff 100%); }
    .calendar-small .rdrDay:not(.rdrDayPassive) .rdrStartEdge + span + .rdrDayNumber.not-weekend {
      background: #fff; }
    .calendar-small .rdrDay:not(.rdrDayPassive) .rdrStartEdge + span + .rdrDayNumber span {
      z-index: 10;
      color: white !important; }
  .calendar-small .rdrDay:not(.rdrDayPassive) .rdrStartEdge.rdrEndEdge + .rdrDayNumber {
    background: transparent; }
  .calendar-small .rdrDay:not(.rdrDayPassive) .rdrStartEdge.rdrEndEdge + span + .rdrDayNumber {
    background: transparent; }
  .calendar-small .rdrDay:not(.rdrDayPassive) .rdrEndEdge + .rdrDayNumber {
    background: linear-gradient(to right, #ffe1ea 0%, #ffff 100%, #ffe1fa 100%, #ffff 0%); }
    .calendar-small .rdrDay:not(.rdrDayPassive) .rdrEndEdge + .rdrDayNumber.not-weekend {
      background: #fff; }
    .calendar-small .rdrDay:not(.rdrDayPassive) .rdrEndEdge + .rdrDayNumber span {
      z-index: 10;
      color: white !important; }
  .calendar-small .rdrDay:not(.rdrDayPassive) .rdrEndEdge + span + .rdrDayNumber {
    background: linear-gradient(to right, #ffe1ea 0%, #ffff 100%, #ffe1fa 100%, #ffff 0%); }
    .calendar-small .rdrDay:not(.rdrDayPassive) .rdrEndEdge + span + .rdrDayNumber.not-weekend {
      background: #fff; }
    .calendar-small .rdrDay:not(.rdrDayPassive) .rdrEndEdge + span + .rdrDayNumber span {
      z-index: 10;
      color: white !important; }
  .calendar-small .rdrDay:not(.rdrDayPassive) .rdrEndEdge.rdrStartEdge + .rdrDayNumber {
    background: transparent; }
  .calendar-small .rdrDay:not(.rdrDayPassive) .rdrEndEdge.rdrStartEdge + span + .rdrDayNumber {
    background: transparent; }
  .calendar-small .rdrDayToday .rdrDayNumber span {
    color: #873bff !important; }
    .calendar-small .rdrDayToday .rdrDayNumber span:after {
      background: none !important; }
  .calendar-small .rdrSelected,
  .calendar-small .rdrInRange,
  .calendar-small .rdrStartEdge,
  .calendar-small .rdrEndEdge,
  .calendar-small .rdrDayNumber {
    font-size: 16px; }
  .calendar-small .rdrWeekDay {
    font-size: 14px;
    line-height: 24px;
    color: #313131; }
  .calendar-small .rdrWeekDays {
    display: none;
    font-family: "Circular Std", "Circular Std"; }
  .calendar-small .rdrMonthAndYearPickers {
    width: 60px; }
    .calendar-small .rdrMonthAndYearPickers select {
      background: transparent; }
    .calendar-small .rdrMonthAndYearPickers .rdrYearPicker select {
      padding: 10px 0px 10px 0px;
      height: 100% !important; }
    .calendar-small .rdrMonthAndYearPickers .rdrMonthPicker {
      width: 100%; }
      .calendar-small .rdrMonthAndYearPickers .rdrMonthPicker select {
        color: #313131;
        background: #fff;
        -webkit-justify-content: center;
                justify-content: center;
        padding: 0;
        font-size: 16px;
        line-height: 24px;
        font-family: "Circular Std", "Circular Std";
        width: 100%;
        text-transform: capitalize;
        text-align: right;
        height: 100% !important;
        margin-top: -1px;
        text-align-last: center;
        -webkit-appearance: none !important;
                appearance: none !important; }
        .calendar-small .rdrMonthAndYearPickers .rdrMonthPicker select option {
          text-align: center; }
        .calendar-small .rdrMonthAndYearPickers .rdrMonthPicker select:before {
          display: none;
          content: '';
          font-family: 'transvoice-icon';
          -webkit-font-smoothing: antialiased;
          text-rendering: optimizeLegibility;
          -moz-osx-font-smoothing: grayscale;
          font-feature-settings: 'liga';
          margin: 0 30px 0 0;
          color: #bdbdbd;
          cursor: pointer;
          /* Better Font Rendering =========== */
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale; }
  .calendar-small .rdrCalendarWrapper {
    font-size: 14px;
    border-radius: 4px;
    text-transform: capitalize !important;
    width: 100%; }
  .calendar-small .rdrNextPrevButton {
    background: none; }
    .calendar-small .rdrNextPrevButton::before {
      content: '\e932';
      font-family: 'transvoice-icon';
      -webkit-font-smoothing: antialiased;
      text-rendering: optimizeLegibility;
      -moz-osx-font-smoothing: grayscale;
      font-feature-settings: 'liga';
      margin: 0 30px 0 0;
      color: #bdbdbd;
      cursor: pointer; }
    .calendar-small .rdrNextPrevButton i {
      display: none; }
    .calendar-small .rdrNextPrevButton:hover {
      background: none; }
  .calendar-small .rdrNextButton {
    background: none; }
    .calendar-small .rdrNextButton::before {
      content: '\e931';
      font-family: 'transvoice-icon';
      -webkit-font-smoothing: antialiased;
      text-rendering: optimizeLegibility;
      -moz-osx-font-smoothing: grayscale;
      font-feature-settings: 'liga';
      margin: 0 0 0 20px;
      color: #bdbdbd;
      cursor: pointer; }
    .calendar-small .rdrNextButton i {
      display: none; }
    .calendar-small .rdrNextButton:hover {
      background: none; }
  .calendar-small .rdrMonth {
    padding: 0;
    margin: 12px 0 20px 0;
    width: auto; }
    .calendar-small .rdrMonth .rdrWeekDays,
    .calendar-small .rdrMonth .rdrDays {
      width: auto;
      padding: 5px 10px; }
  .calendar-small .rdrDateRangePickerWrapper {
    border-radius: 4px;
    margin-bottom: 12px;
    border: 1px solid #bbb4b4;
    width: 100%; }
  .calendar-small .rdrMonthAndYearWrapper {
    padding: 0;
    margin: 16px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    height: 48px; }
    .calendar-small .rdrMonthAndYearWrapper .rdrNextPrevButton {
      height: 48px;
      margin: 0;
      padding: 16px;
      width: 48px; }
      .calendar-small .rdrMonthAndYearWrapper .rdrNextPrevButton::before {
        color: #000; }
      .calendar-small .rdrMonthAndYearWrapper .rdrNextPrevButton.rdrNextButton {
        margin-right: 15px; }
  .calendar-small .tv-calendar-time {
    background-color: #fff;
    border-bottom: 1px solid #e1e6ff;
    display: -webkit-flex;
    display: flex;
    width: 100%; }
    .calendar-small .tv-calendar-time__item {
      width: 50%;
      padding: 20px; }
      .calendar-small .tv-calendar-time__item--title {
        color: #a9a9a9;
        font-size: 14px;
        line-height: 20px; }
        .calendar-small .tv-calendar-time__item--title::before {
          padding-right: 10px;
          font-size: 16px; }
      .calendar-small .tv-calendar-time__item-value {
        text-align: center;
        color: #873bff;
        font-size: 16px;
        font-size: 1rem;
        font-weight: bold;
        line-height: 24px;
        margin: 5px 0; }
        .calendar-small .tv-calendar-time__item-value.no-icon {
          text-align: left; }
  .calendar-small .select-weekday {
    background-color: #fff;
    border-bottom: 1px solid #e1e6ff;
    -webkit-justify-content: center;
            justify-content: center;
    padding-bottom: 20px; }
    .calendar-small .select-weekday__title {
      color: #313131;
      font-size: 14px;
      line-height: 24px;
      padding-right: 10px; }
  .calendar-small .rc-time-picker-input {
    border: none;
    color: #873bff;
    font-size: 14px;
    font-weight: bold;
    line-height: 24px;
    cursor: pointer; }
  .calendar-small .rc-time-picker::after {
    color: #873bff;
    margin-top: 22px; }
  .calendar-small .rc-time-picker-panel-input {
    color: #873bff !important;
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
    cursor: pointer; }
  .calendar-small .rc-time-picker .icon-arrow-expand {
    margin-top: 22px; }

.tv-toggleSwitch {
  position: relative;
  display: inline-block; }
  .tv-toggleSwitch.tv-toggleSwitch__rounded .Slider {
    border-radius: 15rem;
    background-color: #969696; }
    .tv-toggleSwitch.tv-toggleSwitch__rounded .Slider:before {
      border-radius: 50%; }
  .tv-toggleSwitch .tv-toggleSwitch__wrapper {
    position: relative;
    width: 60px;
    height: 30px; }
    .tv-toggleSwitch .tv-toggleSwitch__wrapper .Slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      transition: .4s ease; }
      .tv-toggleSwitch .tv-toggleSwitch__wrapper .Slider:before {
        width: 24px;
        height: 24px;
        position: absolute;
        background-color: #fff;
        content: '';
        margin: 0;
        padding: 0;
        top: 50%;
        left: 0.2rem;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
        transition: .4s;
        cursor: pointer;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23); }
      .tv-toggleSwitch .tv-toggleSwitch__wrapper .Slider.isChecked {
        background: #873bff; }
        .tv-toggleSwitch .tv-toggleSwitch__wrapper .Slider.isChecked:before {
          left: calc(100% - 1.7rem);
          background: #fff; }

.rc-time-picker {
  display: inline-block;
  position: relative;
  box-sizing: border-box; }
  .rc-time-picker * {
    box-sizing: border-box; }
  .rc-time-picker-clear {
    position: absolute;
    right: 6px;
    cursor: pointer;
    overflow: hidden;
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    top: 3px;
    margin: 0; }
    .rc-time-picker-clear-icon:after {
      content: "x";
      font-size: 12px;
      font-style: normal;
      color: #aaa;
      display: inline-block;
      line-height: 1;
      height: 20px;
      width: 20px;
      transition: color 0.3s ease; }
    .rc-time-picker-clear-icon:hover:after {
      color: #666; }

.rc-time-picker-input {
  position: relative;
  display: inline-block;
  cursor: text;
  transition: border 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), box-shadow 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  height: 52px;
  width: 100px;
  border: 1px solid #C5C5C5;
  border-radius: 4px;
  background-color: #FFFFFF;
  font-family: "PT Serif";
  font-size: 14px;
  line-height: 20px;
  color: #313131;
  padding: 16px 20px;
  outline: none; }
  .rc-time-picker-input[disabled] {
    color: #A9A9A9; }
    .rc-time-picker-input[disabled] ~ .icon-arrow-expand {
      color: #c5c5c5; }

.rc-time-picker .icon-arrow-expand {
  font-family: "transvoice-icon";
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: "liga";
  font-size: 10px;
  color: #313131;
  position: absolute;
  top: 0px;
  right: 0px;
  margin-right: 20px;
  margin-top: 14px; }

.rc-time-picker-panel {
  z-index: 1070;
  width: 170px;
  position: absolute;
  box-sizing: border-box; }
  .rc-time-picker-panel * {
    box-sizing: border-box; }
  .rc-time-picker-panel-inner {
    display: inline-block;
    position: relative;
    outline: none;
    list-style: none;
    font-size: 12px;
    text-align: left;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 1px 5px #ccc;
    background-clip: padding-box;
    border: 1px solid #ccc;
    line-height: 1.5; }
  .rc-time-picker-panel-narrow {
    max-width: 113px; }

.rc-time-picker-panel-input {
  margin: 0;
  padding: 0;
  width: 100%;
  cursor: auto;
  line-height: 1.5;
  outline: 0;
  border: 1px solid transparent;
  font-family: "PT Serif";
  font-size: 14px;
  line-height: 20px; }
  .rc-time-picker-panel-input-wrap {
    box-sizing: border-box;
    position: relative;
    border-bottom: 1px solid #e9e9e9;
    height: 52px;
    width: 100px;
    border-radius: 4px;
    background-color: #FFFFFF;
    font-family: "PT Serif";
    font-size: 14px;
    line-height: 20px;
    color: #313131;
    padding: 16px 8px 16px 20px;
    display: -webkit-flex;
    display: flex; }
    .rc-time-picker-panel-input-wrap__arrow-icon {
      font-size: 10px;
      color: #313131;
      padding: 0px 7px 0px 7px;
      cursor: pointer;
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg); }
  .rc-time-picker-panel-input-invalid {
    border-color: red; }

.rc-time-picker-panel-select {
  float: left;
  font-size: 12px;
  border: 1px solid #e9e9e9;
  border-width: 0 1px;
  margin-left: -1px;
  box-sizing: border-box;
  width: 56px;
  max-height: 144px;
  overflow-y: auto;
  position: relative; }
  .rc-time-picker-panel-select-active {
    overflow-y: auto; }
  .rc-time-picker-panel-select:first-child {
    border-left: 0;
    margin-left: 0; }
  .rc-time-picker-panel-select:last-child {
    border-right: 0; }
  .rc-time-picker-panel-select ul {
    list-style: none;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    width: 100%; }
  .rc-time-picker-panel-select li {
    list-style: none;
    margin: 0;
    padding: 0 0 0 16px;
    width: 100%;
    height: 24px;
    line-height: 24px;
    text-align: left;
    cursor: pointer;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    font-family: "PT Serif";
    font-size: 14px; }
    .rc-time-picker-panel-select li:hover {
      background: #edfaff; }
  li .rc-time-picker-panel-select-option-selected {
    background: #f7f7f7;
    font-weight: bold; }
  li .rc-time-picker-panel-select-option-disabled {
    color: #ccc; }
    li .rc-time-picker-panel-select-option-disabled:hover {
      background: transparent;
      cursor: not-allowed; }

.tv-block-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 360px;
  box-shadow: 0 0 13px 0 rgba(223, 223, 223, 0.5); }
  .tv-block-wrapper__title {
    color: #313131;
    font-family: "PT Serif";
    font-size: 14px;
    line-height: 24px;
    padding: 14px 24px;
    background-color: #f9f9f9; }

.tv-date-range-picker__dialog {
  position: absolute;
  background: #fff;
  z-index: 5;
  box-shadow: 0 10px 18px -12px rgba(58, 68, 131, 0.2);
  border-radius: 0 4px 4px 4px; }

.form_textarea {
  position: relative;
  padding-top: 20px; }
  .form_textarea__form-control {
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    resize: none;
    color: #a9a9a9;
    background-clip: padding-box;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    font: 0.875rem/1.25rem "Circular Std", "Circular Std";
    border: 1px solid #DFE7EA;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.04); }
  .form_textarea__description {
    position: absolute;
    bottom: 24px;
    right: 24px;
    color: #a9a9a9;
    font: 0.875rem/1.25rem "Circular Std", "Circular Std";
    letter-spacing: 0.3px; }

.tv-not-found-page {
  width: 100%;
  height: calc(100vh - 200px);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }
  .tv-not-found-page__image {
    height: 342px;
    width: 324px;
    background-image: url("/images/not-found.svg");
    background-size: cover;
    background-position-y: center;
    margin: auto; }
  .tv-not-found-page__title {
    font: 1.5rem/2rem "Circular Std", "Circular Std";
    font-weight: 500;
    letter-spacing: -0.2px;
    color: #873bff; }

.thanks-wrapper {
  width: 700px;
  height: 600px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.12);
  margin: 0 auto;
  padding: 40px 130px 70px;
  text-align: center;
  color: #313131;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  font: 1rem/1.5rem "Circular Std", "Circular Std"; }
  .thanks-wrapper__header {
    height: 200px;
    width: 240px;
    background-image: url("/images/thank-you.svg");
    background-size: cover;
    background-position-y: center;
    margin: 0 auto; }
  .thanks-wrapper__content {
    padding-top: 20px; }
    .thanks-wrapper__content h1 {
      color: #313131;
      font: 2rem/2.5rem "Circular Std", "Circular Std";
      font-weight: 300;
      letter-spacing: -0.5px; }
  .thanks-wrapper__footer {
    margin-top: auto; }
    .thanks-wrapper__footer button {
      padding: 15px 100px; }
  .thanks-wrapper .tv-youravailability__list-item {
    width: auto; }

.dropdown {
  position: relative;
  width: auto; }

.dropdown .panel {
  display: none;
  height: 150px;
  width: 100%;
  position: absolute;
  top: 37px;
  list-style: none;
  cursor: pointer;
  text-decoration: none;
  border-radius: 4px;
  box-shadow: 0 1px 5px #ccc;
  overflow-y: scroll;
  background-color: #ffffff;
  z-index: 20;
  -webkit-padding-start: 20px;
          padding-inline-start: 20px; }
  .dropdown .panel li {
    font-size: 0.775rem;
    line-height: 24px !important; }

body .panel-overlay {
  display: block;
  position: absolute;
  overflow: hidden;
  z-index: 2;
  top: 0; }

.dropdown.active .panel {
  height: 150px;
  display: block;
  -webkit-animation: slidepanel 0.25s;
          animation: slidepanel 0.25s; }

@-webkit-keyframes slidepanel {
  0% {
    height: 0px; }
  100% {
    height: 150px; } }

@keyframes slidepanel {
  0% {
    height: 0px; }
  100% {
    height: 150px; } }

.dropdown input {
  position: absolute;
  width: 100%; }

.dropdown > .rc-time-picker-select-option {
  height: inherit;
  width: 0;
  position: absolute;
  right: 30px;
  cursor: pointer; }
  .dropdown > .rc-time-picker-select-option:after {
    position: absolute;
    content: '\e908';
    font-family: 'transvoice-icon';
    top: 12px;
    left: 2px; }

.rc-time-picker {
  display: inline-block;
  position: relative;
  box-sizing: border-box; }
  .rc-time-picker * {
    box-sizing: border-box; }
  .rc-time-picker-clear {
    position: absolute;
    right: 6px;
    cursor: pointer;
    overflow: hidden;
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    top: 3px;
    margin: 0; }
    .rc-time-picker-clear-icon:after {
      content: "x";
      font-size: 12px;
      font-style: normal;
      color: #aaa;
      display: inline-block;
      line-height: 1;
      height: 20px;
      width: 20px;
      transition: color 0.3s ease; }
    .rc-time-picker-clear-icon:hover:after {
      color: #666; }

.rc-time-picker-input {
  position: relative;
  display: inline-block;
  cursor: text;
  transition: border 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), box-shadow 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  height: 52px;
  width: 100px;
  border: 1px solid #C5C5C5;
  border-radius: 4px;
  background-color: #FFFFFF;
  font-family: "PT Serif";
  font-size: 14px;
  line-height: 20px;
  color: #313131;
  padding: 16px 20px;
  outline: none; }
  .rc-time-picker-input[disabled] {
    color: #A9A9A9; }
    .rc-time-picker-input[disabled] ~ .icon-arrow-expand {
      color: #c5c5c5; }

.rc-time-picker .icon-arrow-expand {
  font-family: "transvoice-icon";
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: "liga";
  font-size: 10px;
  color: #313131;
  position: absolute;
  top: 0px;
  right: 0px;
  margin-right: 20px;
  margin-top: 14px; }

.rc-time-picker-panel {
  z-index: 1070;
  width: 170px;
  position: absolute;
  box-sizing: border-box; }
  .rc-time-picker-panel * {
    box-sizing: border-box; }
  .rc-time-picker-panel-inner {
    display: inline-block;
    position: relative;
    outline: none;
    list-style: none;
    font-size: 12px;
    text-align: left;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 1px 5px #ccc;
    background-clip: padding-box;
    border: 1px solid #ccc;
    line-height: 1.5; }
  .rc-time-picker-panel-narrow {
    max-width: 113px; }

.rc-time-picker-panel-input {
  margin: 0;
  padding: 0;
  width: 100%;
  cursor: auto;
  line-height: 1.5;
  outline: 0;
  border: 1px solid transparent;
  font-family: "PT Serif";
  font-size: 14px;
  line-height: 20px; }
  .rc-time-picker-panel-input-wrap {
    box-sizing: border-box;
    position: relative;
    border-bottom: 1px solid #e9e9e9;
    height: 52px;
    width: 100px;
    border-radius: 4px;
    background-color: #FFFFFF;
    font-family: "PT Serif";
    font-size: 14px;
    line-height: 20px;
    color: #313131;
    padding: 16px 8px 16px 20px;
    display: -webkit-flex;
    display: flex; }
    .rc-time-picker-panel-input-wrap__arrow-icon {
      font-size: 10px;
      color: #313131;
      padding: 0px 7px 0px 7px;
      cursor: pointer;
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg); }
  .rc-time-picker-panel-input-invalid {
    border-color: red; }

.rc-time-picker-panel-select {
  float: left;
  font-size: 12px;
  border: 1px solid #e9e9e9;
  border-width: 0 1px;
  margin-left: -1px;
  box-sizing: border-box;
  width: 56px;
  max-height: 144px;
  overflow-y: auto;
  position: relative; }
  .rc-time-picker-panel-select-active {
    overflow-y: auto; }
  .rc-time-picker-panel-select:first-child {
    border-left: 0;
    margin-left: 0; }
  .rc-time-picker-panel-select:last-child {
    border-right: 0; }
  .rc-time-picker-panel-select ul {
    list-style: none;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    width: 100%; }
  .rc-time-picker-panel-select li {
    list-style: none;
    margin: 0;
    padding: 0 0 0 16px;
    width: 100%;
    height: 24px;
    line-height: 24px;
    text-align: left;
    cursor: pointer;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    font-family: "PT Serif";
    font-size: 14px; }
    .rc-time-picker-panel-select li:hover {
      background: #edfaff; }
  li .rc-time-picker-panel-select-option-selected {
    background: #f7f7f7;
    font-weight: bold; }
  li .rc-time-picker-panel-select-option-disabled {
    color: #ccc; }
    li .rc-time-picker-panel-select-option-disabled:hover {
      background: transparent;
      cursor: not-allowed; }

.release-message-cont {
  position: absolute;
  min-height: 48px !important;
  min-width: 500px;
  max-width: 600px;
  left: 110px !important;
  -webkit-justify-content: center;
          justify-content: center;
  top: 12px;
  left: 0; }
  .release-message-cont-alert {
    width: 48px;
    min-height: 48px;
    background: url("/images/bell.jpg") no-repeat center;
    display: -webkit-flex !important;
    display: flex !important;
    -webkit-align-items: center;
            align-items: center;
    font-size: 20px;
    margin-left: 10px;
    margin-right: 10px;
    -webkit-justify-content: center;
            justify-content: center;
    color: #ff9b4e; }
  .release-message-cont-close {
    width: 56px;
    min-height: 48px;
    margin-right: 10px;
    display: -webkit-flex !important;
    display: flex !important;
    -webkit-align-items: center;
            align-items: center;
    font-size: 20px;
    -webkit-text-stroke: 1px #fff;
    -webkit-justify-content: center;
            justify-content: center;
    margin-left: 10px; }
  @media screen and (max-width: 768px) {
    .release-message-cont {
      top: 86px;
      left: 0 !important;
      z-index: 50;
      min-width: unset !important;
      max-width: unset !important;
      width: calc(100% - 15px);
      margin: 0 15px; }
      .release-message-cont .body .release-summary {
        display: block;
        font: 12px 'Circular Std', sans-serif;
        width: 100%;
        margin: 16px 0;
        text-overflow: unset !important;
        white-space: pre-line !important;
        overflow: visible !important; } }
  .release-message-cont h2 {
    margin-top: 8px; }
  .release-message-cont .close {
    position: absolute;
    top: 10px;
    right: 6px;
    font-size: 31px;
    background-color: #aaa;
    border-radius: 100px;
    padding: 16px 8px 17px 7px;
    line-height: 0px;
    cursor: pointer; }
  .release-message-cont .close:hover {
    background-color: #0ac130;
    color: #fff; }
  .release-message-cont .body {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    height: auto;
    margin: 0px;
    border: 1px solid #e0e0e0;
    border-radius: 900px !important;
    min-width: 200px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 3px 4px 11px 0px rgba(11, 12, 19, 0.149);
    transition: max-height 2s ease-out;
    padding: 0; }
    .release-message-cont .body .release-summary {
      display: block;
      font: 12px 'Circular Std', sans-serif;
      width: 100%;
      text-overflow: ellipsis;
      white-space: break-spaces;
      overflow-x: hidden; }
    .release-message-cont .body .link-style {
      width: 100%;
      -webkit-text-decoration-line: underline;
              text-decoration-line: underline;
      color: #873bff;
      font: 12px 'Circular Std', sans-serif; }
    .release-message-cont .body .release-actions {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: flex-end;
              justify-content: flex-end;
      padding-left: 15px;
      min-width: 80px; }
    .release-message-cont .body .content {
      width: 100%;
      padding-right: 8px; }
      .release-message-cont .body .content ul {
        list-style-type: none;
        margin: 0px 0px 0px 20px;
        padding: 0px; }
        .release-message-cont .body .content ul li {
          display: none; }
          .release-message-cont .body .content ul li .see-more {
            font-size: 13px;
            font-style: italic;
            color: #fff;
            cursor: pointer;
            margin-left: 5px;
            background-color: #bb6e6e;
            border-radius: 100px;
            padding: 2px 7px 2px 6px; }
        .release-message-cont .body .content ul li:nth-child(1) {
          display: list-item;
          font: 1rem 'Circular Std', sans-serif; }
          .release-message-cont .body .content ul li:nth-child(1) .see-more {
            display: inline-block; }
        .release-message-cont .body .content ul li:nth-last-child() .see-more {
          display: none; }
      .release-message-cont .body .content.expanded ul li {
        display: list-item; }
      .release-message-cont .body .content.expanded ul li:nth-child(1) .see-more {
        display: none; }
      .release-message-cont .body .content.expanded ul li:nth-last-child() .see-more {
        display: inline-block; }
    .release-message-cont .body .close-button {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
              flex-direction: row;
      -webkit-justify-content: center;
              justify-content: center;
      -webkit-align-items: center;
              align-items: center; }
      .release-message-cont .body .close-button button.danger {
        background-color: red;
        color: #fff; }
  .release-message-cont .body::before {
    color: #827e7e !important;
    padding-right: 10px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center; }

.release-message-list-cont {
  height: auto;
  max-height: 688px;
  overflow-y: scroll;
  margin-top: 30px; }
  .release-message-list-cont ul {
    margin: 0 5px;
    list-style-type: none;
    padding: 0;
    color: #444; }
    .release-message-list-cont ul li {
      font-weight: 100;
      margin: 23px 0px;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      border: solid 1px #e5e5e5;
      box-shadow: 10px 10px 9px -14px rgba(0, 0, 0, 0.75);
      transition: height 0.5s ease-out;
      text-align: justify; }
      .release-message-list-cont ul li:nth-child(1) {
        margin: 0px; }
      .release-message-list-cont ul li .header {
        background-color: #f7f7f7;
        padding: 5px 11px 2px 2px; }
        .release-message-list-cont ul li .header .btn-expan {
          float: left;
          padding: 4px 8px 0px 9px;
          cursor: pointer; }
        .release-message-list-cont ul li .header .date {
          font-style: italic;
          font-size: 12px;
          float: right;
          padding-top: 5px;
          font-weight: 500; }
      .release-message-list-cont ul li p {
        margin: 5px 0;
        padding: 0 9px 5px 10px;
        line-height: 26px; }
        .release-message-list-cont ul li p.hidden-items {
          display: none; }
      .release-message-list-cont ul li.expanded p.hidden-items {
        display: block; }

.send-unrated-link {
  margin: 16px 0px;
  cursor: pointer;
  text-decoration: none !important;
  font: 16px/20px 'Circular Std', sans-serif;
  padding: 14px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 4px;
  color: #252525 !important;
  background-color: #fff;
  border: 1px solid #e0e0e0 !important; }
  @media screen and (max-width: 768px) {
    .send-unrated-link {
      margin: 0; } }

@charset "UTF-8";
label {
  margin: 0;
  padding: 0; }

.rating-summary-cont {
  padding: 16px 16px 16px 0;
  font-family: "Circular Std", "Circular Std";
  background-color: #f7f9ff; }
  .rating-summary-cont .average-rating-count {
    color: #ff9b4e;
    display: -webkit-flex;
    display: flex;
    font-size: 24px;
    -webkit-align-items: baseline;
            align-items: baseline;
    height: 30px;
    margin: 5px 0 18px; }
    .rating-summary-cont .average-rating-count > span {
      margin-right: 14px; }
  .rating-summary-cont .description {
    font: 11px/14px "Circular Std", "Circular Std";
    color: #74737b; }
  .rating-summary-cont h3 {
    font-size: 14px; }
  .rating-summary-cont .rating {
    border: none;
    display: block;
    position: relative; }
    .rating-summary-cont .rating .average-rating {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
              flex-direction: row;
      -webkit-justify-content: flex-start;
              justify-content: flex-start; }
      .rating-summary-cont .rating .average-rating span {
        padding: 9px 0 5px;
        font-size: 13px;
        color: #423c3c; }
      .rating-summary-cont .rating .average-rating label:before {
        margin: 3px;
        font-size: 20px;
        font-size: 1.25rem;
        display: inline-block;
        content: '\e912';
        font-family: 'transvoice-icon' !important;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-feature-settings: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1.5;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
      .rating-summary-cont .rating .average-rating label {
        color: #ddd;
        float: right;
        padding-right: 9px; }
      .rating-summary-cont .rating .average-rating.red label.checked {
        color: #f93d3d; }
      .rating-summary-cont .rating .average-rating.red-yellow label.checked {
        color: #fd974de6; }
      .rating-summary-cont .rating .average-rating.yellow label.checked {
        color: #ffd700; }
      .rating-summary-cont .rating .average-rating.yellow-green label.checked {
        color: #c7f055; }
      .rating-summary-cont .rating .average-rating.green label.checked {
        color: #98f23d; }
  .rating-summary-cont .rating-percentage {
    display: block;
    height: 30px;
    background-color: #ddd;
    position: relative; }
    .rating-summary-cont .rating-percentage span {
      position: absolute;
      height: 30px;
      left: 0;
      top: 0;
      border-radius: 0 9px 9px 0;
      background-color: #98f23d; }

.stars_area__container {
  width: 100%;
  -webkit-justify-content: space-between;
          justify-content: space-between; }

.stars_area__title {
  color: #252525;
  font: 400 16px/20px "Circular Std", "Circular Std" !important;
  margin-bottom: 4px; }

.stars_area__pending {
  color: #677adf;
  font-family: "Circular Std", "Circular Std";
  margin-top: 8px;
  font-size: 13.5px; }
  .stars_area__pending .send-unrated-link.tv-buttons__element {
    border-radius: 44px;
    background-color: #fff; }
    .stars_area__pending .send-unrated-link.tv-buttons__element:hover {
      border: 1px solid #a9a9a9 !important; }

@supports (-webkit-background-clip: text) {
  .stars_area .stars {
    --percent: var(--rating);
    -webkit-text-stroke: 1.5px #ff9b4e;
    display: inline-block;
    font-size: 40px;
    font-family: Times;
    line-height: 1; }
    .stars_area .stars::before {
      content: '★★★★★';
      font-size: 30px;
      letter-spacing: 3px;
      background: linear-gradient(90deg, #ff9b4e var(--percent), #fff var(--percent));
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent; } }

.stars_area .stars {
  color: #ff9b4e;
  font-size: 30px;
  letter-spacing: 3px; }

@media (max-width: 1024px) {
  .rating-summary-cont {
    padding: 28px 0px 0px; }
  .stars_area {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: space-between;
            justify-content: space-between; }
    .stars_area__title {
      font-size: 16px;
      font-weight: 500;
      font-family: "Circular Std", "Circular Std";
      margin-bottom: 4px;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center; }
    .stars_area .average-rating-count {
      margin-bottom: 0; }
      .stars_area .average-rating-count > span {
        display: none; }
    .stars_area .description,
    .stars_area .stars_area__pending {
      width: 100%; }
    .stars_area .stars {
      -webkit-text-stroke: 1.5px #ff9b4e;
      margin-left: 16px;
      font-size: 25px; }
      .stars_area .stars::before {
        font-size: 24px;
        letter-spacing: 10px; } }

.tv-cancelassignment__wrapper {
  -webkit-flex: 1.4 1;
          flex: 1.4 1;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding-top: 33px; }

.tv-cancelassignment__container {
  width: 700px;
  background-color: #fff; }

.tv-cancelassignment__heading {
  border-radius: 4px 4px 0 0;
  background-color: #873bff;
  height: 64px;
  -webkit-align-items: center;
          align-items: center; }
  .tv-cancelassignment__heading-text {
    color: #fff;
    font: 500 1.5rem/2.1875rem "Circular Std", "Circular Std";
    letter-spacing: -0.2px;
    padding-left: 24px; }

.tv-cancelassignment__warning-icon {
  -webkit-justify-content: center;
          justify-content: center;
  margin-top: 46px; }
  .tv-cancelassignment__warning-icon img {
    width: 100px;
    height: 100px; }

.tv-cancelassignment__title {
  padding: 46px 0 0 0;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }
  .tv-cancelassignment__title-text {
    color: #313131;
    font: 300 2rem/2.5rem "Circular Std", "Circular Std";
    letter-spacing: -0.5px;
    text-align: center;
    height: 80px; }
    .tv-cancelassignment__title-text.text-medium {
      font: 300 1.375rem/2.5rem "Circular Std", "Circular Std"; }

.tv-cancelassignment__reasons {
  margin: 19px 40.73px 225px 40.73px; }
  .tv-cancelassignment__reasons-list {
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap; }
    .tv-cancelassignment__reasons-list-item {
      color: #fff;
      padding: 13px 37px;
      margin: 0 18px 16px 0; }
  .tv-cancelassignment__reasons-list .tv-cancelassignment__reasons-list-item {
    border: 1px solid #873bff;
    border-radius: 5px;
    font: 300 1rem/1.4375rem "Circular Std", "Circular Std"; }
    .tv-cancelassignment__reasons-list .tv-cancelassignment__reasons-list-item.tv-toggle-button--unselected {
      color: #873bff; }
  .tv-cancelassignment__reasons-title {
    color: #313131;
    font: 0.875rem/1.5rem "Circular Std", "Circular Std"; }
  .tv-cancelassignment__reasons.no-reasons {
    margin: 19px 40.73px 40.73px 40.73px; }
    .tv-cancelassignment__reasons.no-reasons .tv-cancelassignment__reasons-title {
      text-align: center; }

.tv-cancelassignment__buttons {
  margin: 0 0 32px 0;
  -webkit-justify-content: center;
          justify-content: center; }
  .tv-cancelassignment__buttons button {
    width: 160px; }
  .tv-cancelassignment__buttons .tv-buttons__element--no-border {
    color: #873bff;
    font: 300 1.125rem/1.75rem "Circular Std", "Circular Std";
    letter-spacing: -0.2px; }

.tv-cancelassignment__modal .tv-modal__holder {
  max-width: 460px;
  text-align: center;
  background-color: #fff; }
  .tv-cancelassignment__modal .tv-modal__holder .icon-close::before {
    color: #313131; }

.tv-cancelassignment__modal-icon {
  font-size: 3rem;
  margin-top: 32px; }
  .tv-cancelassignment__modal-icon .icon-warning::before {
    color: #873bff; }

.tv-cancelassignment__modal-text {
  color: #873bff;
  font: 300 1.125rem/1.75rem "Circular Std", "Circular Std";
  letter-spacing: -0.2px;
  text-align: center;
  padding: 0 68px; }

.tv-cancelassignment__modal-assignment {
  border: 1px solid #969696;
  background-color: #f7f7f7;
  margin: 16px 36px 45px;
  padding: 9px 16px; }
  .tv-cancelassignment__modal-assignment-id {
    color: #873bff; }
  .tv-cancelassignment__modal-assignment-title {
    color: #353535; }
  .tv-cancelassignment__modal-assignment-id, .tv-cancelassignment__modal-assignment-title {
    font: 300 1rem/1.5rem "Circular Std", "Circular Std";
    text-align: center; }

.tv-cancelassignment__modal-buttons {
  margin: 0 0 32px 0;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin: 0 36px 40px; }
  .tv-cancelassignment__modal-buttons button {
    width: 169px;
    padding: 13px 0px; }

.tv-route-leaving-guard-modal {
  z-index: 56 !important;
  text-align: center; }
  .tv-route-leaving-guard-modal .tv-modal__holder {
    border-radius: 4px;
    margin: 0 10px;
    max-width: 400px;
    max-height: unset;
    background-color: #fff;
    overflow-x: hidden; }
  .tv-route-leaving-guard-modal-area-title {
    margin-top: 20px;
    font-size: 20px;
    font-weight: 500;
    font-family: "Circular Std", "Circular Std"; }
  .tv-route-leaving-guard-modal-area-text {
    font-size: 14.6px;
    margin-bottom: 25px !important;
    width: 185px;
    margin: auto;
    font-family: "Circular Std", "Circular Std"; }
  .tv-route-leaving-guard-modal-area-backBtn {
    background-color: #fff;
    margin: 0 7px; }
  .tv-route-leaving-guard-modal-area-submitBtn {
    background-color: #873bff;
    margin: 0 7px; }
  .tv-route-leaving-guard-modal__buttons {
    -webkit-justify-content: space-between;
            justify-content: space-between;
    margin: 0px 10px 15px; }
    .tv-route-leaving-guard-modal__buttons button {
      width: 120px;
      font-size: 14px;
      height: 45px; }

.tv-dailyplanner_badge-availability {
  border-radius: 5px;
  position: relative;
  text-transform: uppercase;
  font: 0.675rem/0.675rem "Circular Std", sans-serif;
  margin: 5px;
  min-width: 60px;
  max-width: 100px;
  letter-spacing: 1px;
  line-height: 0.8rem;
  cursor: pointer;
  background-color: #fff;
  padding: 8px;
  box-shadow: 0px 2px 5px 2px rgba(117, 118, 124, 0.29);
  transition: background 0.2s ease-out 0s, box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s; }
  .tv-dailyplanner_badge-availability.unavailable {
    border-left: 5px solid #e00909;
    letter-spacing: 8px;
    cursor: pointer; }
  .tv-dailyplanner_badge-availability.available {
    letter-spacing: 8px;
    cursor: pointer;
    border-left: 5px solid #6566b9; }
  .tv-dailyplanner_badge-availability.disabled {
    cursor: not-allowed;
    background-color: #d4d4d4; }
  .tv-dailyplanner_badge-availability.info {
    font-size: 0.575rem; }
  .tv-dailyplanner_badge-availability-delete {
    position: absolute;
    cursor: pointer;
    font-family: "transvoice-icon" !important;
    top: -5px;
    right: -8px;
    font-size: 0.475rem;
    letter-spacing: normal; }
    .tv-dailyplanner_badge-availability-delete::before {
      content: "\e926";
      color: #fff;
      padding: 4px;
      border-radius: 50%;
      background-color: #e00909;
      border: 1px solid #fff;
      box-shadow: 4px 0px 4px 0px #9e9e9e; }

.input-group-text input[type="checkbox"] {
  margin-top: 0; }

.tv-control {
  margin: 12px 0px;
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  -webkit-print-color-adjust: exact; }

.tv-checkbox .custom-control-label::before {
  border-radius: 0.12rem;
  box-sizing: border-box; }

.tv-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  box-sizing: border-box; }

.tv-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
  box-sizing: border-box; }

.tv-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-color: #e9ecef;
  box-shadow: none;
  box-sizing: border-box; }

.tv-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E");
  box-sizing: border-box; }

.tv-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: #fff;
  box-sizing: border-box; }

.tv-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: #fff;
  box-sizing: border-box; }

.tv-checkbox .custom-control-input ~ .custom-control-label {
  cursor: pointer;
  font: 400 16px/20px "Circular Std", "Circular Std" !important;
  background: #d2d2d2;
  color: #252525;
  padding: 3px 12px 1px;
  border-radius: 30px;
  text-transform: capitalize; }
  .tv-checkbox .custom-control-input ~ .custom-control-label:before {
    width: 20px;
    height: 20px;
    top: 0.1rem; }
  .tv-checkbox .custom-control-input ~ .custom-control-label.label--accepted::before {
    border-color: #6478e6 !important; }
  .tv-checkbox .custom-control-input ~ .custom-control-label.label--fullfilled::before {
    border-color: #18ad79 !important;
    color: #fff; }
  .tv-checkbox .custom-control-input ~ .custom-control-label.label--cancelled::before {
    color: #fff;
    border-color: #74737b !important; }
  .tv-checkbox .custom-control-input ~ .custom-control-label.label--available::before {
    border-color: #ff9b4e !important; }
  .tv-checkbox .custom-control-input ~ .custom-control-label.label--rejected::before {
    border-color: #e94242 !important; }
  .tv-checkbox .custom-control-input ~ .custom-control-label.label--Nyregistrerad::before {
    border-color: #ffa500 !important; }
  .tv-checkbox .custom-control-input ~ .custom-control-label.label--Underutredning::before {
    border-color: #007bff !important; }
  .tv-checkbox .custom-control-input ~ .custom-control-label.label--Avslutad::before {
    border-color: #008000 !important; }
  .tv-checkbox .custom-control-input ~ .custom-control-label:after {
    width: 20px;
    height: 20px;
    top: 0.1rem;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"); }

.tv-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"); }

.tv-checkbox .custom-control-input:disabled ~ .custom-control-label::before {
  border-color: #e9ecef; }

.tv-checkbox .custom-control-input:disabled:checked::before {
  border-color: #d2d2d2; }

.tv-checkbox .custom-control-input ~ .custom-control-label {
  cursor: pointer;
  font-size: 0.875rem; }

.tv-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #d2d2d2; }

.tv-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"); }

.tv-checkbox .custom-control-input:disabled ~ .custom-control-label::before {
  border-color: #e9ecef; }

.tv-checkbox .custom-control-input:disabled:checked::before {
  border-color: #e9ecef; }

.tv-checkbox .custom-control-input:checked ~ .custom-control-label.label--accepted::before {
  background-color: #6478e6 !important; }

.tv-checkbox .custom-control-input:checked ~ .custom-control-label.label--accepted::after {
  -webkit-filter: invert(0) !important;
          filter: invert(0) !important; }

.tv-checkbox .custom-control-input:checked ~ .custom-control-label.label--fullfilled::before {
  background-color: #18ad79 !important; }

.tv-checkbox .custom-control-input:checked ~ .custom-control-label.label--cancelled::before {
  background-color: #74737b !important; }

.tv-checkbox .custom-control-input:checked ~ .custom-control-label.label--available::before {
  background-color: #ff9b4e !important; }

.tv-checkbox .custom-control-input:checked ~ .custom-control-label.label--rejected::before {
  background-color: #e94242 !important; }

.tv-checkbox .custom-control-input:checked ~ .custom-control-label.label--Nyregistrerad::before {
  background-color: #ffa500 !important; }

.tv-checkbox .custom-control-input:checked ~ .custom-control-label.label--Underutredning::before {
  background-color: #007bff !important; }

.tv-checkbox .custom-control-input:checked ~ .custom-control-label.label--Avslutad::before {
  background-color: #008000 !important; }

.tv-checkbox .custom-control-input:checked ~ .custom-control-label.label--fullfilled::after {
  -webkit-filter: invert(0) !important;
          filter: invert(0) !important; }

.tv-checkbox .custom-control-input:checked ~ .custom-control-label.label--cancelled::after {
  -webkit-filter: invert(0) !important;
          filter: invert(0) !important; }

.tv-checkbox .custom-control-input:checked ~ .custom-control-label.label--available::after {
  -webkit-filter: invert(0) !important;
          filter: invert(0) !important; }

.tv-checkbox .custom-control-input:checked ~ .custom-control-label.label--rejected::after {
  -webkit-filter: invert(0) !important;
          filter: invert(0) !important; }

.tv-checkbox .custom-control-input:checked ~ .custom-control-label.label--Nyregistrerad::after {
  -webkit-filter: invert(0) !important;
          filter: invert(0) !important; }

.tv-checkbox .custom-control-input:checked ~ .custom-control-label.label--UnderUtredning::after {
  -webkit-filter: invert(0) !important;
          filter: invert(0) !important; }

.tv-checkbox .custom-control-input:checked ~ .custom-control-label.label--Avslutad::after {
  -webkit-filter: invert(0) !important;
          filter: invert(0) !important; }

.tv-checkbox .custom-control-input:checked ~ .custom-control-label.label--altService::before {
  background-color: #873bff !important; }

.tv-checkbox .custom-control-input:checked ~ .custom-control-label.label--altService::after {
  -webkit-filter: invert(0) !important;
          filter: invert(0) !important; }

.tv-tooltip {
  display: inherit;
  margin-left: 4px; }
  .tv-tooltip i {
    font-size: 15px; }

@charset "UTF-8";
.rate-container {
  font: 500 16px/20px "Circular Std", "Circular Std";
  padding: 16px 16px 16px 0px;
  height: auto;
  background-color: #f7f9ff;
  box-sizing: border-box;
  border-radius: 4px; }
  @supports (-webkit-background-clip: text) {
    .rate-container .stars {
      --percent: var(--rating);
      line-height: 25px;
      -webkit-text-stroke: 1.5px #ff9b4e;
      display: inline-block;
      font-family: Times; }
      .rate-container .stars::before {
        content: '★★★★★';
        font-size: 25px;
        letter-spacing: 3px;
        background: linear-gradient(90deg, #ff9b4e var(--percent), #fff var(--percent));
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent; } }
  .rate-container .stars {
    color: #ff9b4e;
    font-family: Times;
    font-size: 25px;
    letter-spacing: 3px; }
  .rate-container .custom-control-label {
    display: -webkit-flex;
    display: flex; }

.rate-title {
  font: 500 16px/20px "Circular Std", "Circular Std";
  margin-bottom: 15px; }

.rate-star {
  color: transparent;
  text-shadow: 0 0 #ff9b4e;
  font-size: 16px; }

.rate-title-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between; }

.rate-title-sub {
  font: 400 14px "Circular Std", "Circular Std";
  line-height: 18px;
  color: #74737b;
  cursor: pointer; }

@charset "UTF-8";
.icon-hearing:before {
  display: -webkit-flex;
  display: flex;
  -webkit-mask-image: url("/images/hearing-icon.svg");
  -webkit-mask-size: 16px;
  mask-size: 16px;
  mask-image: url("/images/hearing-icon.svg");
  background-color: #74737b;
  content: '';
  height: 16px;
  width: 16px; }
  @media (max-width: 768px) {
    .icon-hearing:before {
      background-size: 22px 22px;
      height: 20px;
      width: 20px;
      -webkit-mask-size: 20px;
      mask-size: 20px;
      padding: 5px; } }

@font-face {
  font-family: 'Circular Std';
  src: url("/fonts/Circular Font/CircularStd-Medium.otf");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'Circular Std';
  src: url("/fonts/Circular Font/CircularStd-Book.otf");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'transvoice-icon';
  src: url("/fonts/transvoice-icon/transvoice-icon.eot?ysq9j9");
  src: url("/fonts/transvoice-icon/transvoice-icon.eot?ysq9j9#iefix") format("embedded-opentype"), url("/fonts/transvoice-icon/transvoice-icon.ttf?ysq9j9") format("truetype"), url("/fonts/transvoice-icon/transvoice-icon.woff?ysq9j9") format("woff"), url("/fonts/transvoice-icon/transvoice-icon.svg?ysq9j9#transvoice-icon") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^='icon-']:before,
[class*=' icon-']:before,
[class^='icon-']:after,
[class*=' icon-']:after {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'transvoice-icon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-filter:before {
  content: '\e900';
  color: #c5c5c5; }

.icon-your-compensation:before {
  content: '\e901';
  color: #c5c5c5; }

.icon-warning:before {
  content: '\e902';
  color: #c0c2c3; }

.icon-waiting-to-be-submitted:before {
  content: '\e903';
  color: #fff; }

.icon-trophy:before {
  content: '\e904';
  color: #bdbdbd; }

.icon-timer:before {
  content: '\e905';
  color: #c5c5c5; }

.icon-the-envelope:before {
  content: '\e906';
  color: #c5c5c5; }

.icon-task:before {
  content: '\e907';
  color: #c5c5c5; }

.icon-submit-time:before {
  content: '\e908';
  color: #c5c5c5; }

.icon-small:before {
  content: '\e909';
  color: #bdbdbd; }

.icon-share:before {
  content: '\e90a';
  color: #c5c5c5; }

.icon-search:before {
  content: '\e90b'; }

.icon-schedule:before {
  content: '\e90c';
  color: #bdbdbd; }

.icon-report-quality:before {
  content: '\e90d';
  color: #c5c5c5; }

.icon-question:before {
  content: '\e90e';
  color: #c0c2c3; }

.icon-profile:before {
  content: '\e90f';
  color: #c5c5c5; }

.icon-pointer:before {
  content: '\e910';
  color: #c5c5c5; }

.icon-photo:before {
  content: '\e911';
  color: #9e9e9e; }

.icon-news:before {
  content: '\e912';
  color: #c5c5c5; }

.icon-lock:before {
  content: '\e913';
  color: #c0c2c3; }

.icon-interprete-now:before {
  content: '\e914';
  color: #c5c5c5; }

.icon-interpret-now-2:before {
  content: '\e915';
  color: #fff; }

.icon-user:before {
  content: '\e916';
  color: #bdbdbd; }

.icon-hourglass:before {
  content: '\e917';
  color: #c9c9c9; }

.icon-home:before {
  content: '\e918';
  color: #c5c5c5; }

.icon-handset:before {
  content: '\e919';
  color: #c5c5c5; }

.icon-handset-2:before {
  content: '\e91a';
  color: #c5c5c5; }

.icon-flag:before {
  content: '\e91b';
  color: #bdbdbd; }

.icon-fire:before {
  content: '\e91c';
  color: #fff; }

.icon-drawing-pin:before {
  content: '\e91d';
  color: #bdbdbd; }

.icon-download-to-cloud:before {
  content: '\e91e';
  color: #c0c2c3; }

.icon-dollar:before {
  content: '\e91f';
  color: #fff; }

.icon-dollar-2:before {
  content: '\e920';
  color: #d1d1d1; }

.icon-create-new:before {
  content: '\e921';
  color: #fff; }

.icon-contact:before {
  content: '\e922';
  color: #c5c5c5; }

.icon-contact-2:before {
  content: '\e923';
  color: #c5c5c5; }

.icon-comment:before {
  content: '\e924';
  color: #c0c2c3; }

.icon-comment-2:before {
  content: '\e925';
  color: #fff; }

.icon-close:before {
  content: '\e926';
  color: #313131; }

.icon-next:after {
  content: '\e931';
  color: #313131; }

.icon-previous:before {
  content: '\e932';
  color: #313131; }

.icon-certificate:before {
  content: '\e927';
  color: #bdbdbd; }

.icon-camera:before {
  content: '\e928';
  color: #20175b; }

.icon-calendar:before {
  content: '\e929';
  color: #a5a4a4; }

.icon-browse-assignments:before {
  content: '\e92a';
  color: #c5c5c5; }

.icon-book:before {
  content: '\e92b';
  color: #c5c5c5; }

.icon-book-2:before {
  content: '\e92c';
  color: #c0c2c3; }

.icon-bell:before {
  content: '\e92d';
  color: #c5c5c5; }

.icon-available-request:before {
  content: '\e92e';
  color: #c5c5c5; }

.icon-available-request-2:before {
  content: '\e92f';
  color: #fff; }

.icon-attach:before {
  content: '\e930';
  color: #9e9e9e; }

.icon-arrow-more:before {
  content: '\e931';
  color: #c5c5c5; }

.icon-arrow-more-2:before {
  content: '\e932';
  color: #c5c5c5; }

.icon-arrow-expand:before {
  content: '\e933'; }

.icon-arrow-down:after {
  content: '\e933'; }

.icon-arrow-top:after {
  content: '\e931';
  display: inline-block;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg); }

.icon-arrow-back:before {
  content: '\e934'; }

.icon-settings:before {
  content: '\e935';
  color: #c5c5c5; }

.icon-envelope:before {
  content: '\e936';
  color: #c5c5c5; }

.icon-log-out:before {
  content: '\e937';
  color: #20175b; }

.icon-more:before {
  content: '\e938';
  color: #c5c5c5; }

.icon-check:before {
  content: '\e939';
  color: #c5c5c5; }

.icon-doc:before {
  content: '\e93a';
  color: #c5c5c5; }

.icon-pdf:before {
  content: '\e93b';
  color: #c5c5c5; }

.icon-download-file:before {
  content: '\e93c';
  color: #c5c5c5; }

.icon-upload-to-cloud:before {
  content: '\e93d';
  color: #c5c5c5; }

.icon-single:before {
  content: '\e93e';
  color: #c5c5c5; }

.icon-multi:before {
  content: '\e93f';
  color: #c5c5c5; }

.icon-image-file:before {
  content: '\e940';
  color: #c5c5c5; }

.icon-mobile-phone:before {
  content: '\e941';
  color: #c5c5c5; }

.icon-customer-order-number:before {
  content: '\e942';
  color: #c5c5c5; }

.icon-edit:before {
  content: '\2639';
  color: '#000'; }

.icon-task-list:before {
  content: '\2630';
  color: #c5c5c5; }

html,
body {
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  box-sizing: border-box;
  font-family: "PT Serif"; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

* {
  outline: none; }

a {
  text-decoration: none; }
  a:active, a:focus {
    text-decoration: none; }
  a:hover {
    text-decoration: underline; }

.bn_topbar a:hover {
  text-decoration: none; }

input {
  /*-webkit-appearance:none;*/
  box-sizing: border-box; }

.padding-15 {
  padding: 15px; }

.padding-30 {
  padding: 30px; }

.padding-45 {
  padding: 45px; }

.padding-50 {
  padding: 50px; }

.padding-right-15 {
  padding-right: 15px; }

.padding-right-30 {
  padding-right: 30px; }

.padding-right-45 {
  padding-right: 45px; }

.padding-right-50 {
  padding-right: 50px; }

.padding-left-15 {
  padding-left: 15px; }

.padding-left-30 {
  padding-left: 30px; }

.padding-left-45 {
  padding-left: 45px; }

.padding-left-50 {
  padding-left: 50px; }

.padding-top-15 {
  padding-top: 15px; }

.padding-top-30 {
  padding-top: 30px; }

.padding-top-45 {
  padding-top: 45px; }

.padding-top-50 {
  padding-top: 50px; }

.padding-bottom-15 {
  padding-bottom: 15px; }

.padding-bottom-30 {
  padding-bottom: 30px; }

.padding-bottom-45 {
  padding-bottom: 45px; }

.padding-bottom-50 {
  padding-bottom: 50px; }

*,
body {
  font-family: 'Circular Std'; }

*,
*:before,
*:after {
  box-sizing: inherit; }

body {
  background-color: #f7f9ff;
  margin: 0;
  color: #252525; }

html {
  box-sizing: border-box;
  font-size: 16px; }

*::-webkit-scrollbar {
  width: 8px;
  height: 8px; }

*::-webkit-scrollbar-track {
  background-color: #fff; }

*::-webkit-scrollbar-thumb {
  background: #d9d9d9;
  border-radius: 8px; }

*::-webkit-scrollbar-thumb:hover {
  background: #c9c9c9; }

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #e5e5e5;
  margin: 1em 0;
  padding: 0; }

.tv-container {
  margin: 0 auto; }

.tv-capitalize {
  text-transform: capitalize; }

.tv-uppercase {
  text-transform: uppercase; }

.tv-common-fs {
  font-size: 0.875rem; }
  .tv-common-fs button,
  .tv-common-fs li,
  .tv-common-fs span {
    font-size: 0.875rem !important; }

.tv-headline-0 {
  font-family: "Circular Std", "Circular Std";
  font-size: 48px;
  font-weight: 500;
  letter-spacing: -0.5px;
  line-height: 54px; }

.tv-headline-1 {
  font-family: "Circular Std", "Circular Std";
  font-size: 32px;
  font-weight: 300;
  letter-spacing: -0.5px;
  line-height: 40px; }

.tv-headline-2 {
  font-family: "Circular Std", "Circular Std";
  font-size: 24px;
  font-weight: 500;
  letter-spacing: -0.2px;
  line-height: 32px; }

.tv-title-text {
  font-family: "Circular Std", "Circular Std";
  font-size: 18px;
  font-weight: 300;
  letter-spacing: -0.2px;
  line-height: 28px; }
  .tv-title-text.bold {
    font-weight: 600; }
  .tv-title-text .copy-to-clipboard {
    cursor: pointer; }

.tv-booking-success {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 25px;
  gap: 25px;
  padding: 25px 20px 0 20px;
  color: #74737B;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  font: 400 16px "Circular Std", "Circular Std"; }
  .tv-booking-success-item {
    display: -webkit-flex;
    display: flex;
    grid-gap: 10px;
    gap: 10px; }

.tv-body-text {
  font-family: "Circular Std", "Circular Std";
  font-size: 16px;
  line-height: 24px; }

.tv-small-text {
  font-family: "Circular Std", "Circular Std";
  font-size: 14px;
  line-height: 24px; }

.tv-display-flex {
  display: -webkit-flex;
  display: flex; }
  .tv-display-flex-coloumn {
    -webkit-flex-direction: column;
            flex-direction: column; }

.tv-position-rel {
  position: relative; }

.tv-w-50 {
  width: 50%; }

.tv-w-100 {
  width: 100%; }

.tv-primary-color {
  color: #873bff; }

.tv-secondary-color {
  color: #873bff; }

.tv-text-color {
  color: #323232; }

.tv-text-inactive-color {
  color: #969696; }

.tv-space-40 {
  padding-right: 40px; }

[role='button'],
[class^='tv-buttons__type'],
[class*=' tv-buttons__type'] {
  cursor: pointer;
  outline: none;
  font-size: 16px; }

.tv-border-radius-shadow, .tv-filter-list__menu, .tv-youravailability__list-item, .tv-youravailability__list-item-drawer {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 10px 18px -12px rgba(58, 68, 131, 0.2); }

.tv-bottom-line {
  border-top: 1px solid #f1f1f1; }

.tv-is-clipped {
  overflow: scroll;
  overflow-x: hidden; }

.tv-is-clipped2 {
  overflow: hidden; }

.tv-loading__wrapper:before {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  content: '';
  background: rgba(0, 0, 0, 0.3);
  z-index: 10000; }

.tv-loading__content {
  position: fixed;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 10010; }

.tv-loading__spinner {
  width: 75px;
  height: 75px;
  display: inline-block;
  border-width: 6px;
  border-color: #fff;
  border-top-color: #873bff;
  border-bottom-color: #873bff;
  -webkit-animation: spin 1.5s infinite linear;
          animation: spin 1.5s infinite linear;
  border-radius: 100%;
  border-style: solid; }

.m-auto {
  margin: auto; }

.w-100 {
  width: 100%; }

.tv-modal__wrapper {
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0; }

.tv-modal__overlay {
  content: '';
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 12; }

.tv-modal__holder {
  max-height: calc(100vh - 70px);
  z-index: 20;
  padding: 0 10px;
  -webkit-animation: zoomIn 0.5s;
          animation: zoomIn 0.5s;
  max-width: calc(100vh - 100px);
  overflow-y: auto;
  position: fixed;
  left: calc(50% - 310px); }
  @supports (display: flex) {
    .tv-modal__holder {
      left: unset; } }

.tv-modal__btn-close {
  position: absolute;
  right: 0;
  top: 0;
  padding: 18px !important;
  height: 56px;
  z-index: 4; }

.tv-row {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-right: -12px;
  margin-left: -12px; }

.tv-row > [class^='tv-col-'] {
  position: relative;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px; }

.tv-col-1 {
  -webkit-flex: 0 0 8.33333%;
          flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.tv-col-2 {
  -webkit-flex: 0 0 16.66667%;
          flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.tv-col-3 {
  -webkit-flex: 0 0 25%;
          flex: 0 0 25%;
  max-width: 25%; }

.tv-col-4 {
  -webkit-flex: 0 0 33.33333%;
          flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.tv-col-5 {
  -webkit-flex: 0 0 41.66667%;
          flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.tv-col-6 {
  -webkit-flex: 0 0 50%;
          flex: 0 0 50%;
  max-width: 50%; }

.tv-col-7 {
  -webkit-flex: 0 0 58.33333%;
          flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.tv-col-8 {
  -webkit-flex: 0 0 66.66667%;
          flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.tv-col-9 {
  -webkit-flex: 0 0 75%;
          flex: 0 0 75%;
  max-width: 75%; }

.tv-col-10 {
  -webkit-flex: 0 0 83.33333%;
          flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.tv-col-11 {
  -webkit-flex: 0 0 91.66667%;
          flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.tv-col-12 {
  -webkit-flex: 0 0 100%;
          flex: 0 0 100%;
  max-width: 100%; }

.tv-detail-customer-name {
  font: 1rem 'Circular Std', sans-serif;
  color: #a9a9a9; }

.rate-detail-box {
  border-radius: 4px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: auto;
  font: 1rem 'Circular Std', sans-serif;
  background-color: white; }
  .rate-detail-box > span {
    margin: 5px 0px;
    font: 0.875rem 'Circular Std', sans-serif; }

.disabled {
  color: darkgrey; }
  .disabled:after {
    color: darkgrey !important; }
  .disabled:before {
    color: darkgrey !important; }

.text-box-content {
  min-height: 70px; }

input,
select {
  background-color: #fff !important; }

@supports (display: grid) {
  .weekend-background {
    background-color: #f1f4ff !important; } }

.primary-color {
  color: #873bff; }

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@-webkit-keyframes zoomIn {
  0% {
    -webkit-transform: scale(0.9);
            transform: scale(0.9); }
  to {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes zoomIn {
  0% {
    -webkit-transform: scale(0.9);
            transform: scale(0.9); }
  to {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@-webkit-keyframes slide-in {
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%); } }

@keyframes slide-in {
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%); } }

.tv-buttons__normal, .tk-calendar__button {
  border: 0;
  background: transparent; }

.tv-buttons__element {
  padding: 11px 10px 9px;
  font-family: "Circular Std", "Circular Std";
  border-radius: 5px;
  height: 48px;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  border: 0;
  background: transparent;
  cursor: pointer;
  outline: none; }
  .tv-buttons__element:hover {
    text-decoration: none; }
  .tv-buttons__element--primary {
    color: #fff;
    background: #873bff;
    border: 1px solid #873bff; }
    .tv-buttons__element--primary.tv-buttons__element--disable {
      border: 1px solid #969696; }
  .tv-buttons__element--secondary {
    color: #fff;
    background: #873bff; }
  .tv-buttons__element--line-default {
    border: 1px solid #e0e0e0;
    color: #fff; }
  .tv-buttons__element--line-primary {
    color: #252525;
    border: 1px solid #e0e0e0; }
  .tv-buttons__element--line-secondary {
    color: #313131;
    border: 1px solid #323232; }
  .tv-buttons__element--disable {
    cursor: context-menu;
    color: #fff;
    background: #969696; }
  .tv-buttons__element--text {
    color: #873bff; }
  .tv-buttons__element--icon {
    padding: 6px 4px 2px;
    border-radius: 100%;
    font-size: 12px; }

.tv-buttons__type {
  background: transparent;
  border: 0;
  padding: 0; }

.tv-filter-list__element {
  position: relative;
  font-size: 0.875rem; }

.tv-filter-list__user-name {
  padding-left: 20px;
  color: #873bff;
  cursor: pointer;
  position: absolute;
  top: 10px;
  width: max-content;
  width: -webkit-max-content;
  width: -o-max-content;
  /* width: -ms-max-content;*/ }
  .tv-filter-list__user-name.ie-fix {
    white-space: nowrap;
    width: auto; }

.tv-filter-list__filter-count-badge {
  padding: 0px 5px;
  color: white;
  background-color: #7e7676;
  border-radius: 25px;
  width: 5px; }

.tv-filter-list__button {
  font-family: "Circular Std", "Circular Std";
  color: #313131;
  text-transform: uppercase; }
  .tv-filter-list__button:before {
    content: '\e900';
    font-family: 'transvoice-icon';
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: 'liga';
    color: #c5c5c5;
    margin-right: 9px; }
  .tv-filter-list__button:after {
    margin-left: 8px;
    content: '\e933';
    font-family: 'transvoice-icon';
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: 'liga';
    color: #313131;
    font-size: 0.8rem; }
  .tv-filter-list__button:hover {
    text-decoration: none; }

.tv-filter-list__menu {
  font-family: "Circular Std", "Circular Std";
  position: absolute;
  margin: 12px 0 0 0;
  padding: 0;
  width: -webkit-max-content;
  width: max-content; }
  .tv-filter-list__menu-item {
    border-bottom: 2px solid #f3f5f7;
    list-style: none; }
    .tv-filter-list__menu-item:last-child {
      border-bottom: 0; }
    .tv-filter-list__menu-item button {
      font-family: "Circular Std", "Circular Std";
      color: #313131;
      padding: 18px 24px 15px 14px;
      width: 100%;
      text-align: left; }

.tv-avatar-list__menu {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  box-shadow: 2px 1px 4px 0 rgba(158, 158, 158, 0.522);
  box-sizing: border-box;
  border-radius: 4px;
  position: absolute;
  top: 16px;
  right: -8px;
  min-width: 300px;
  z-index: 100;
  text-align: left;
  padding-bottom: 16px; }
  .tv-avatar-list__menu-availability-btn {
    font: 300 1rem/1.4375rem "Circular Std", "Circular Std";
    padding: 8px 20px;
    margin-top: 16px; }
  .tv-avatar-list__menu-item {
    display: block;
    color: #873bff;
    display: block;
    padding: 8px 16px 8px 16px;
    font: 500 16px/20px "Circular Std", "Circular Std"; }
    .tv-avatar-list__menu-item.active {
      background-color: #eeeeee; }
    .tv-avatar-list__menu-item:hover {
      text-decoration: none;
      background-color: #eeeeee;
      color: #873bff; }
    .tv-avatar-list__menu-item-icon {
      margin-right: 10px;
      font-size: 0.9375rem; }
    .tv-avatar-list__menu-item--name {
      padding: 16px;
      font: 500 16px/20px "Circular Std", "Circular Std";
      border-bottom: 1px solid #e0e0e0; }
      .tv-avatar-list__menu-item--name.tv-avatar-list__menu-item:hover {
        background-color: #fff; }
    .tv-avatar-list__menu-item--customer-name {
      padding: 16px;
      font: 500 16px/20px "Circular Std", "Circular Std";
      border-bottom: 1px solid #e0e0e0;
      margin-bottom: 8px;
      color: #252525; }
      .tv-avatar-list__menu-item--customer-name.tv-avatar-list__menu-item:hover {
        background-color: #fff; }
    .tv-avatar-list__menu-item--customer-number {
      font: 0.875rem/1.25rem "Circular Std", "Circular Std";
      color: #a9a9a9;
      letter-spacing: 0.3px;
      border-bottom: 1px solid #e5e5e5; }
      .tv-avatar-list__menu-item--customer-number.tv-avatar-list__menu-item:hover {
        background-color: #fff; }
    .tv-avatar-list__menu-item--id {
      color: #873bff;
      padding-top: 0px;
      padding-bottom: 17px;
      border-bottom: 1px solid #e5e5e5;
      font-size: 0.875rem; }
      .tv-avatar-list__menu-item--id.tv-avatar-list__menu-item:hover {
        background-color: #fff; }
    .tv-avatar-list__menu-item--profile {
      padding: 16px 16px 0 16px;
      font: 500 16px/20px "Circular Std", "Circular Std"; }
    .tv-avatar-list__menu-item--logout {
      padding: 8px 16px 8px 16px;
      font: 500 16px/20px "Circular Std", "Circular Std"; }

.tx-input-text {
  background-color: transparent;
  outline: none;
  outline-style: none;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #ededed;
  padding: 10px 0px;
  font: 0.875rem/1.25rem "Circular Std", "Circular Std";
  color: #313131; }
  .tx-input-text:focus {
    border-bottom: 1px solid #6478e6; }
  .tx-input-text.tv-form__control--error {
    border-bottom: 1px solid red; }
  .tx-input-text--inactive {
    color: #a9a9a9;
    -webkit-text-fill-color: #a9a9a9;
    -webkit-opacity: 1; }
  .tx-input-text--border {
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    padding: 13px 20px; }
    .tx-input-text--border:focus {
      border-color: #873bff; }

.tv-toggle-button {
  display: inline-block;
  min-width: 145px;
  height: 49px;
  border: 1px solid transparent;
  border-radius: 4px;
  background-color: #6478e6;
  font-family: "Circular Std", "Circular Std";
  font-size: 14px;
  line-height: 49px;
  text-align: center;
  color: white;
  cursor: pointer;
  outline: none; }
  .tv-toggle-button--phone:before {
    content: '\e91a';
    margin-right: 15px;
    font-family: 'transvoice-icon';
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: 'liga'; }
  .tv-toggle-button--onsite:before {
    content: '\e910';
    margin-right: 15px;
    font-family: 'transvoice-icon';
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: 'liga'; }
  .tv-toggle-button--unselected {
    border-radius: 5px;
    border: 1px solid #6478e6;
    background-color: white;
    color: #313131; }
    .tv-toggle-button--unselected.tv-toggle-button--phone:before {
      color: #c5c5c5; }
    .tv-toggle-button--unselected.tv-toggle-button--onsite:before {
      color: #c5c5c5; }

.tv-date-picker {
  height: 52px;
  width: 188px;
  padding: 16px 20px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  background-color: #ffffff;
  position: relative;
  font-family: 'PT Serif';
  font-size: 14px;
  line-height: 20px;
  color: #313131;
  cursor: pointer; }
  .tv-date-picker--inactive {
    color: #a9a9a9; }
  .tv-date-picker::after {
    content: '\e933';
    font-family: 'transvoice-icon';
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: 'liga';
    font-size: 10px;
    color: #c5c5c5;
    position: absolute;
    top: 0px;
    right: 0px;
    margin-right: 20px;
    margin-top: 14px; }

.tv-attachment {
  width: 130px;
  border: none;
  padding: 0;
  outline: none;
  cursor: pointer; }
  .tv-attachment__header {
    height: 80px;
    border-radius: 5px 5px 0 0;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center; }
    .tv-attachment__header--doc, .tv-attachment__header--docx {
      background-color: #6478e6; }
      .tv-attachment__header--doc::after, .tv-attachment__header--docx::after {
        content: '\e93a';
        font-family: 'transvoice-icon';
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizeLegibility;
        -moz-osx-font-smoothing: grayscale;
        font-feature-settings: 'liga';
        font-size: 38px;
        color: #fff; }
    .tv-attachment__header--pdf {
      background-color: #ff9ba0; }
      .tv-attachment__header--pdf::after {
        content: '\e93b';
        font-family: 'transvoice-icon';
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizeLegibility;
        -moz-osx-font-smoothing: grayscale;
        font-feature-settings: 'liga';
        font-size: 40px;
        color: #fff; }
    .tv-attachment__header--image {
      background-color: #454c98; }
      .tv-attachment__header--image::after {
        content: '\e940';
        font-family: 'transvoice-icon';
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizeLegibility;
        -moz-osx-font-smoothing: grayscale;
        font-feature-settings: 'liga';
        font-size: 40px;
        color: #fff; }
    .tv-attachment__header--other {
      background-color: #454c98; }
      .tv-attachment__header--other::after {
        content: '\e930';
        font-family: 'transvoice-icon';
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizeLegibility;
        -moz-osx-font-smoothing: grayscale;
        font-feature-settings: 'liga';
        font-size: 40px;
        color: #fff; }
  .tv-attachment__details {
    height: 40px;
    border: 1px solid #eaeaea;
    border-top: none;
    border-radius: 0 0 5px 5px;
    background-color: #f8f8f8;
    color: #873bff;
    font: 0.625rem/2.5rem "Circular Std", "Circular Std";
    text-align: center; }
    .tv-attachment__details::before {
      content: '\e93c';
      font-family: 'transvoice-icon';
      -webkit-font-smoothing: antialiased;
      text-rendering: optimizeLegibility;
      -moz-osx-font-smoothing: grayscale;
      font-feature-settings: 'liga';
      font-size: 10px;
      color: #322978;
      margin-right: 8px; }

.tv-file {
  border: none;
  padding: 0;
  outline: none;
  cursor: pointer;
  background-color: transparent; }
  .tv-file__details {
    background-color: transparent;
    margin-top: 16px;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    color: #873bff;
    font: 0.875rem/1.5rem "Circular Std", "Circular Std"; }
    .tv-file__details::before {
      content: '\e91e';
      font-family: 'transvoice-icon';
      -webkit-font-smoothing: antialiased;
      text-rendering: optimizeLegibility;
      -moz-osx-font-smoothing: grayscale;
      font-feature-settings: 'liga';
      font-size: 25px;
      color: #873bff;
      margin-right: 8px; }
    .tv-file__details-text {
      text-decoration: underline; }

.filter-title {
  margin-top: 27px !important;
  font-size: 0.875rem !important;
  font-weight: bold; }

.tv-filter-container__menu {
  background-color: #fff;
  box-shadow: 3px -5px 16px 0 rgba(159, 159, 159, 0.52);
  position: absolute;
  display: -webkit-flex;
  display: flex;
  font-size: 0.875rem;
  -webkit-flex-direction: column;
          flex-direction: column;
  top: 38px;
  left: -8px;
  margin-top: 12px;
  padding-left: 20px;
  min-width: 250px;
  z-index: 100;
  font-family: "Circular Std", "Circular Std"; }
  .tv-filter-container__menu:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    margin-top: -16px;
    margin-left: 19px;
    border-width: 8px;
    border-style: solid;
    border-color: transparent transparent #fff transparent; }

.tv-filter-container__action-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row; }

.tv-filter-container__sub-menu {
  min-width: -webkit-max-content;
  min-width: max-content;
  width: 200px;
  margin-left: 5px;
  font-size: 0.875rem; }
  .tv-filter-container__sub-menu > span {
    text-transform: uppercase; }
  .tv-filter-container__sub-menu > span > h4 {
    -webkit-margin-after: 0em !important;
            margin-block-end: 0em !important; }
  .tv-filter-container__sub-menu > ul {
    -webkit-padding-start: 0px !important;
            padding-inline-start: 0px !important; }

.tv-filter-container__active-filters {
  width: 100%;
  float: left;
  padding-left: 10px; }
  .tv-filter-container__active-filters > button {
    border-radius: 4px;
    position: relative;
    border: 1px solid #807a7a;
    padding: 6px 30px 6px 10px;
    text-transform: uppercase;
    margin: 10px 10px 10px 0px;
    color: white;
    background: rgba(135, 127, 127, 0.823);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
    cursor: pointer;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    transition: all 0.3s; }
    .tv-filter-container__active-filters > button:after {
      content: 'x';
      position: absolute;
      color: #ffff;
      top: 5px;
      right: 6px; }
    .tv-filter-container__active-filters > button:hover {
      -webkit-transform: scale(1.03);
              transform: scale(1.03);
      color: #000000fa;
      background: rgba(0, 0, 0, 0.13);
      box-shadow: 0 2px 20px rgba(0, 0, 0, 0.15); }
    .tv-filter-container__active-filters > button:active {
      -webkit-transform: scale(0.96);
              transform: scale(0.96); }
      .tv-filter-container__active-filters > button:active .badge {
        -webkit-transform: scale(1.2);
                transform: scale(1.2); }

.tv-single-assign-form__hr {
  border-top: 1px solid #969696; }

.tv-single-assign-form__title {
  color: #353535;
  font: 1.5rem/2rem "Circular Std", "Circular Std";
  font-weight: 500;
  letter-spacing: -0.2px;
  text-align: center; }

.tv-single-assign-form__container {
  background: #fff; }
  .tv-single-assign-form__container--order {
    margin: 0 auto 24px auto; }
    .tv-single-assign-form__container--order .tv-single-assign-form__control {
      color: #313131; }
    .tv-single-assign-form__container--order .tv-single-assign-form__panel {
      padding-bottom: 30px; }
  .tv-single-assign-form__container .tv-row {
    margin-right: -12px;
    margin-left: -12px; }
    .tv-single-assign-form__container .tv-row > [class^='tv-col-'] {
      padding-right: 12px;
      padding-left: 12px; }
  .tv-single-assign-form__container .tv-form__error {
    margin: 4px 0 3px;
    min-height: 1px;
    display: block; }
  .tv-single-assign-form__container .tv-form__control--error {
    margin-bottom: 0;
    border-color: red; }

.tv-single-assign-form__action-container {
  margin: 0 auto 32px auto;
  -webkit-justify-content: flex-end;
          justify-content: flex-end; }
  .tv-single-assign-form__action-container button {
    box-shadow: 0 2px 30px 0 rgba(153, 153, 153, 0.5);
    margin-left: 24px;
    font: 1rem "Circular Std", "Circular Std";
    padding: 13px 16px 12px;
    font-weight: 300; }

.tv-single-assign-form__panel {
  padding: 10px 48px; }
  .tv-single-assign-form__panel--secondary {
    background: #f8f8f9;
    border-top: 1px solid #e6e6e6;
    border-bottom: 1px solid #e6e6e6;
    padding: 0px 48px 20px; }

.tv-single-assign-form__sub-title {
  color: #873bff;
  font: 1.125rem/1.75rem "Circular Std", "Circular Std";
  font-weight: 300;
  letter-spacing: -0.2px;
  margin: 24px 0 12px 0; }

.tv-single-assign-form__label, .tv-single-assign-form__input {
  color: #313131;
  font: 0.875rem/1.5rem "Circular Std", "Circular Std";
  line-height: 24px; }
  .tv-single-assign-form__label .tv-date-range-picker__container, .tv-single-assign-form__input .tv-date-range-picker__container {
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    display: block;
    width: 100%; }
    .tv-single-assign-form__label .tv-date-range-picker__container .tv-form__date-field, .tv-single-assign-form__input .tv-date-range-picker__container .tv-form__date-field {
      font: 0.875rem "Circular Std", "Circular Std";
      padding: 16px 20px;
      color: #a9a9a9;
      position: relative; }
      .tv-single-assign-form__label .tv-date-range-picker__container .tv-form__date-field:after, .tv-single-assign-form__input .tv-date-range-picker__container .tv-form__date-field:after {
        font: 1rem;
        position: absolute;
        content: '\e929';
        font-family: 'transvoice-icon';
        top: 19px;
        right: 20px; }

.tv-single-assign-form__label {
  margin: 16px 0 5px 0; }

.tv-single-assign-form__input {
  position: relative; }
  .tv-single-assign-form__input-arrow:after {
    content: '\e933';
    position: absolute;
    font: 0.7rem 'transvoice-icon';
    right: 27px;
    top: 20px;
    color: #313131; }
  .tv-single-assign-form__input-sameabove {
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    -webkit-align-self: center;
            align-self: center;
    margin: 15px 0 0 0; }
    .tv-single-assign-form__input-sameabove .tv-toggleSwitch__wrapper {
      margin-left: 10px; }
  .tv-single-assign-form__input--my-home {
    margin-top: 15px; }

.tv-single-assign-form__control {
  border: 1px solid #969696;
  border-radius: 4px;
  width: 100%;
  font: 0.875rem "Circular Std", "Circular Std";
  outline: none;
  color: #a9a9a9;
  margin-bottom: 8px; }

.tv-single-assign-form__dropdownlist {
  padding: 14px 20px;
  -webkit-appearance: none;
          appearance: none; }

.tv-single-assign-form__textfield {
  padding: 14px 20px; }
  .tv-single-assign-form__textfield-disabled {
    background-color: #f8f8f9; }

.tv-single-assign-form__textarea {
  height: 120px; }

.tv-single-assign-form__file-container {
  margin: 0;
  padding: 0;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap; }

.tv-single-assign-form__file-element {
  list-style: none;
  margin: 0 8px 0 0;
  width: calc((100% - 24px) / 4); }
  .tv-single-assign-form__file-element:nth-child(4n + 0) {
    margin-right: 0; }

.tv-single-assign-form__file-name {
  border: 1px solid #eaeaea;
  border-radius: 0 0 5px 5px;
  background: #f8f8f8;
  font: 0.625rem "Circular Std", "Circular Std";
  color: #873bff;
  bottom: 0;
  position: absolute;
  display: -webkit-flex;
  display: flex;
  width: 100%;
  padding: 10px 0; }
  .tv-single-assign-form__file-name:before {
    content: '\e93c';
    color: #873bff;
    font: 0.75rem 'transvoice-icon';
    margin: 0 8px; }

.tv-single-assign-form__file-item {
  min-height: 120px;
  border-radius: 5px;
  position: relative;
  -webkit-justify-content: center;
          justify-content: center;
  display: -webkit-flex;
  display: flex; }
  .tv-single-assign-form__file-item:before {
    color: #fff;
    font: 2.6rem 'transvoice-icon';
    margin-top: 20px; }
  .tv-single-assign-form__file-item--pdf {
    background: #ff9ba0; }
    .tv-single-assign-form__file-item--pdf:before {
      content: '\e93b'; }
  .tv-single-assign-form__file-item--doc, .tv-single-assign-form__file-item--docx, .tv-single-assign-form__file-item--png, .tv-single-assign-form__file-item--jpg {
    background: #6478e6; }
    .tv-single-assign-form__file-item--doc:before, .tv-single-assign-form__file-item--docx:before, .tv-single-assign-form__file-item--png:before, .tv-single-assign-form__file-item--jpg:before {
      content: '\e93a'; }
  .tv-single-assign-form__file-item--other {
    background: #6478e6; }
    .tv-single-assign-form__file-item--other:before {
      content: ''; }
  .tv-single-assign-form__file-item--dragdrop {
    background: #fff;
    border: 1px dashed #cbcbcb;
    outline: none;
    font: 0.75rem "Circular Std", "Circular Std";
    font-weight: 300;
    text-transform: uppercase;
    color: #c5c5c5;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    text-align: center;
    padding: 0 20px;
    height: 120px;
    cursor: pointer; }
    .tv-single-assign-form__file-item--dragdrop:before {
      content: '\e93d';
      color: #873bff;
      font: 1.4rem 'transvoice-icon';
      margin-bottom: 4px; }

.tv-single-assign-form__action-container .tv-single-assign-form__button-save {
  border-color: #fff;
  background: #fff; }

.tv-single-assign-form__button-addSession {
  border: 1px dashed #873bff;
  border-radius: 5px;
  width: 100%;
  background: #fff;
  cursor: pointer;
  color: #873bff;
  font: 1rem/1.5rem "Circular Std", "Circular Std";
  font-weight: bold;
  text-align: center;
  outline: none;
  padding: 8px 0 10px 0;
  margin-top: 16px; }

.tv-single-assign-form__button-removeSession {
  background: #873bff;
  height: 22px;
  width: 22px;
  border-radius: 50%;
  border: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-align-self: center;
          align-self: center;
  -webkit-justify-content: center;
          justify-content: center;
  cursor: pointer;
  outline: none; }
  .tv-single-assign-form__button-removeSession:before {
    content: '\e926';
    color: #fff;
    font-family: 'transvoice-icon'; }

.tv-single-assign-form__button-removeFile {
  color: #873bff;
  border: 0;
  background: transparent;
  display: -webkit-flex;
  display: flex;
  outline: none;
  font: 0.875rem "Circular Std", "Circular Std";
  cursor: pointer;
  margin: 10px 0; }
  .tv-single-assign-form__button-removeFile:before {
    background: #873bff;
    content: '\e926';
    color: #fff;
    font: 0.45rem 'transvoice-icon';
    border-radius: 50%;
    height: 16px;
    width: 16px;
    padding: 4px;
    display: inline-block;
    margin: 3px 7px 0 0; }

.tv-single-assign-form__preview-text {
  font: bold 1rem/1.5rem "Circular Std", "Circular Std";
  color: #313131; }

.tv-single-assign-form__preview-last {
  margin-bottom: 24px; }

.tv-single-assign-form__back-corner {
  position: absolute;
  font: 0.875rem/1.5rem "Circular Std", "Circular Std";
  color: #873bff; }
  .tv-single-assign-form__back-corner::before {
    content: '\e934';
    font-family: 'transvoice-icon';
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: 'liga';
    font-size: 20px;
    padding: 0 5px 0 0; }

.tv-multiple-assign-form__title {
  color: #353535;
  font: 1.5rem/2rem "Circular Std", "Circular Std";
  font-weight: 500;
  letter-spacing: -0.2px;
  text-align: center; }

.tv-multiple-assign-form__panel-left {
  margin-right: 37px; }

.tv-multiple-assign-form__panel-tab {
  width: 323px;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.12);
  cursor: pointer;
  padding: 20px 24px;
  margin-bottom: 18px;
  border: 1px solid #fff; }
  .tv-multiple-assign-form__panel-tab--error {
    border: 1px solid #873bff; }
  .tv-multiple-assign-form__panel-tab--selected {
    border: 1px solid #873bff; }
    .tv-multiple-assign-form__panel-tab--selected:after, .tv-multiple-assign-form__panel-tab--selected:before {
      left: 100%;
      top: 32px;
      border: solid transparent;
      content: ' ';
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none; }
    .tv-multiple-assign-form__panel-tab--selected:after {
      border-left-color: #fff;
      border-width: 6px; }
    .tv-multiple-assign-form__panel-tab--selected:before {
      border-left-color: #873bff;
      border-width: 8px;
      top: 30px; }
    .tv-multiple-assign-form__panel-tab--selected.tv-multiple-assign-form__panel-tab--error {
      border: 1px solid #873bff; }
      .tv-multiple-assign-form__panel-tab--selected.tv-multiple-assign-form__panel-tab--error:before {
        border-left-color: #873bff; }

.tv-multiple-assign-form__panel-title {
  color: #313131;
  font: 1.125rem "Circular Std", "Circular Std";
  font-weight: 300;
  letter-spacing: -0.2px;
  border-bottom: 1px solid #e8ecef;
  margin: 0 0 10px 0;
  padding: 0 0 10px 0; }

.tv-multiple-assign-form__attributes {
  margin: 0;
  padding: 0; }
  .tv-multiple-assign-form__attributes li {
    list-style: none;
    margin: 5px 0;
    font: 0.875rem/1.5rem "Circular Std", "Circular Std"; }
    .tv-multiple-assign-form__attributes li.level::before {
      content: '\e927';
      font-family: 'transvoice-icon';
      padding: 0 5px 0 0;
      color: #a9a9a9; }
    .tv-multiple-assign-form__attributes li.category::before {
      content: '\e92c';
      font-family: 'transvoice-icon';
      padding: 0 5px 0 0;
      color: #a9a9a9; }
    .tv-multiple-assign-form__attributes li.datetime::before {
      content: '\e929';
      font-family: 'transvoice-icon';
      padding: 0 5px 0 0;
      color: #a9a9a9; }

.tv-multiple-assign-form__button-add-more {
  border: 1px dashed #873bff;
  box-shadow: 0 2px 30px 0 rgba(153, 153, 153, 0.5);
  border-radius: 5px;
  color: #873bff;
  background: #fff;
  font: 1rem "Circular Std", "Circular Std";
  font-weight: bold;
  width: 100%;
  margin-top: 6px;
  padding: 10px 0;
  cursor: pointer; }

.tv-multiple-assign-form__button-delete {
  color: #873bff;
  font: 1rem "Circular Std", "Circular Std";
  font-weight: bold;
  background: transparent;
  border: 0;
  position: absolute;
  right: 19px;
  top: 15px;
  cursor: pointer;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }
  .tv-multiple-assign-form__button-delete:before {
    content: '\e926';
    color: #873bff;
    font: 0.5rem 'transvoice-icon';
    height: 8px;
    width: 8px;
    display: inline-block;
    margin: 0 7px 0 0; }

.tv-multiple-assign-form__back-corner {
  font: 0.875rem/1.5rem "Circular Std", "Circular Std";
  color: #873bff; }
  .tv-multiple-assign-form__back-corner::before {
    content: '\e934';
    font-family: 'transvoice-icon';
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: 'liga';
    font-size: 20px;
    padding: 0 5px 0 0; }

.tv-form__checkbox-container {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }
  .tv-form__checkbox-container input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0; }
    .tv-form__checkbox-container input:checked ~ .tv-form__checkbox-checkmark {
      background-color: #873bff;
      border-color: #fff; }
      .tv-form__checkbox-container input:checked ~ .tv-form__checkbox-checkmark:after {
        content: '\e939';
        font: 7px 'transvoice-icon';
        color: #fff;
        position: absolute;
        top: 4px;
        left: 3px; }
  .tv-form__checkbox-container--small-text {
    font-size: 0.75rem; }

.tv-form__checkbox-circular-container {
  font-size: 14px !important;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: inherit;
          align-items: inherit;
  margin: 0px 8px 8px 8px; }
  .tv-form__checkbox-circular-container .tv-form__checkbox-checkmark {
    border-radius: 10px !important;
    margin-right: 8px; }

.tv-form__checkbox-checkmark {
  top: 0;
  left: 0;
  height: 17px;
  width: 17px;
  margin-right: 10px;
  position: relative;
  border-radius: 2px;
  border: 1px solid #e0e0e0;
  cursor: pointer;
  padding-right: 15px; }

.tv-form__error, .tv-form__warning {
  font: 12px "Circular Std", "Circular Std";
  font-weight: 300;
  color: red;
  display: inline-block;
  min-width: 20px;
  line-height: 19px; }

.tv-form__warning {
  color: #6478e6;
  margin-top: 8px; }

.tv-form__date-field {
  background-color: #fff; }
  .tv-form__date-field:after {
    content: '\e929';
    font-family: 'transvoice-icon'; }

.k-option {
  cursor: pointer;
  background-color: #f7f7f7; }

.k-option .k-item.k-state-selected {
  color: #ff4081;
  background-color: #f7f7f7; }

.k-option > .k-item {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-align-content: center;
          align-content: center;
  cursor: pointer; }

.tv-left-pannel__wrapper {
  -webkit-flex: 1 1;
          flex: 1 1;
  max-width: 250px;
  background-color: #fff;
  box-shadow: 0 0 13px 0 rgba(223, 223, 223, 0.5);
  z-index: 10; }

.tv-left-pannel__logo {
  font-family: "Circular Std", "Circular Std";
  letter-spacing: -1px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  cursor: pointer; }
  .tv-left-pannel__logo a {
    text-decoration: none; }
  .tv-left-pannel__logo img {
    width: 175px; }

.tv-left-pannel__sidebar a {
  text-decoration: none; }

.tv-left-pannel__sidebar .tv-icon-fixed-width {
  display: inline-block;
  width: 24px;
  text-align: center; }

.tv-left-pannel__sidebar-item {
  display: -webkit-flex;
  display: flex;
  padding: 20px 10px 20px 20px;
  cursor: pointer;
  color: #313131;
  font: 0.875rem/1.5rem "Circular Std", "Circular Std";
  border-left: 2px solid transparent; }
  .tv-left-pannel__sidebar-item:hover, .tv-left-pannel__sidebar-item.active {
    background-color: #fafafa;
    border-left: 2px solid #873bff;
    font-weight: bold;
    color: #873bff; }
    .tv-left-pannel__sidebar-item:hover i:before, .tv-left-pannel__sidebar-item.active i:before {
      color: #873bff; }

.tv-left-pannel__header-menu {
  display: -webkit-flex;
  display: flex;
  cursor: pointer;
  color: #313131;
  font: 1rem/1.5rem "Circular Std", "Circular Std";
  border-left: 2px solid transparent; }
  .tv-left-pannel__header-menu .k-state-selected {
    font-weight: bold; }
    .tv-left-pannel__header-menu .k-state-selected .icon-schedule:before {
      color: #873bff !important; }
  .tv-left-pannel__header-menu:hover, .tv-left-pannel__header-menu.active {
    background-color: #fafafa;
    border-left: 2px solid #873bff;
    font-weight: bold;
    color: #873bff; }
    .tv-left-pannel__header-menu:hover .icon-schedule:before, .tv-left-pannel__header-menu.active .icon-schedule:before {
      color: #873bff; }

.tv-left-pannel__header-menu {
  display: -webkit-flex;
  display: flex;
  cursor: pointer;
  color: #313131;
  font: 1rem/1.5rem "Circular Std", "Circular Std";
  border-left: 2px solid transparent; }
  .tv-left-pannel__header-menu .k-state-selected {
    font-weight: bold; }
    .tv-left-pannel__header-menu .k-state-selected .icon-schedule:before {
      color: #873bff !important; }
  .tv-left-pannel__header-menu:hover, .tv-left-pannel__header-menu.active {
    background-color: #fafafa;
    border-left: 2px solid #873bff;
    font-weight: bold;
    color: #873bff; }
    .tv-left-pannel__header-menu:hover .icon-schedule:before, .tv-left-pannel__header-menu.active .icon-schedule:before {
      color: #873bff; }

.tv-left-pannel__sidebar-icon {
  font-size: 21px;
  margin-right: 10px; }

.tv-right-pannel-wrapper {
  -webkit-flex: 4.76 1;
          flex: 4.76 1;
  min-height: 100vh;
  position: relative;
  background-color: #f7f9ff; }
  .tv-right-pannel-wrapper .tv-right-status {
    width: 100%;
    height: 72px;
    background-color: #d2d5db; }
    .tv-right-pannel-wrapper .tv-right-status .tv-avatar {
      display: inline;
      position: relative;
      cursor: pointer; }
      .tv-right-pannel-wrapper .tv-right-status .tv-avatar__img {
        border-radius: 50%;
        width: 40px;
        height: 40px;
        object-fit: cover; }
      .tv-right-pannel-wrapper .tv-right-status .tv-avatar .tv-notification {
        display: inline;
        margin-left: 30px;
        position: relative;
        cursor: pointer; }
        .tv-right-pannel-wrapper .tv-right-status .tv-avatar .tv-notification__icon {
          vertical-align: middle;
          font-size: 1.4rem;
          color: #c5c5c5; }
        .tv-right-pannel-wrapper .tv-right-status .tv-avatar .tv-notification__circle {
          width: 11px;
          height: 11px;
          border-radius: 50%;
          background-color: #873bff;
          display: inline-block;
          position: absolute;
          top: -2px;
          left: 10px; }
      .tv-right-pannel-wrapper .tv-right-status .tv-avatar .tv-search {
        margin-left: auto; }
        .tv-right-pannel-wrapper .tv-right-status .tv-avatar .tv-search .form-inline {
          display: -webkit-flex;
          display: flex;
          -webkit-align-items: center;
                  align-items: center; }
          .tv-right-pannel-wrapper .tv-right-status .tv-avatar .tv-search .form-inline i {
            margin-right: 15px;
            font-size: 20px;
            cursor: pointer; }
          .tv-right-pannel-wrapper .tv-right-status .tv-avatar .tv-search .form-inline input {
            width: 16rem;
            border: none;
            font-family: 'PT Serif';
            font-size: 1rem;
            color: #313131; }
  .tv-right-pannel-wrapper .tv-right-content {
    padding-top: 8px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 0;
    margin-left: auto;
    margin-right: auto; }

.tv-bg-login-page {
  background-color: #fff;
  width: 100vw;
  height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
          align-items: center; }

@media screen and (max-width: 768px) {
  .tv-login-page__wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    height: 100vh; } }

.tv-login-page__form-container {
  display: -webkit-flex;
  display: flex;
  margin: auto;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: center;
  justify-content: center; }

.tv-login-page__right-wrapper {
  background-color: #fff;
  -webkit-flex: 1.4 1;
          flex: 1.4 1;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center; }
  .tv-login-page__right-wrapper .tab-panel-content {
    padding: 52px;
    max-height: 100vh;
    overflow-y: scroll; }
  @media screen and (max-width: 768px) {
    .tv-login-page__right-wrapper {
      -webkit-flex: none;
              flex: none;
      margin-top: 25px; } }

.tv-login-page__right-footer-wrapper {
  position: absolute;
  font-family: "Circular Std", "Circular Std";
  bottom: 0;
  margin: auto auto 26px auto; }
  @media screen and (max-width: 768px) {
    .tv-login-page__right-footer-wrapper {
      position: relative;
      margin: 26px auto 26px auto; } }

.tv-login-page__right-footer-text {
  font: 0.875rem/1.5rem "Circular Std", "Circular Std"; }
  .tv-login-page__right-footer-text span {
    margin-left: 20px;
    margin-right: 20px; }

.tv-login__wrapper {
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  width: 502px; }
  @media screen and (max-width: 768px) {
    .tv-login__wrapper {
      box-shadow: none;
      border: none; } }

.tv-login__title {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.2px;
  margin-bottom: 40px; }

.tv-login__description {
  padding: 10px 30px 10px 30px;
  font: 0.875rem/1.5rem "Circular Std", "Circular Std";
  text-align: center;
  color: #313131; }

.tv-login__form-wrapper {
  width: 100%;
  padding: 36px 56px 56px 56px;
  font-family: "Circular Std", "Circular Std";
  margin-top: 16px; }
  .tv-login__form-wrapper .tv-form__control {
    border: 1px solid #e0e0e0;
    height: 48px;
    box-sizing: border-box;
    padding: 14px 16px;
    border-radius: 4px;
    margin: 8px 0;
    outline: none;
    font-weight: 400;
    background-color: #ffffff !important; }
  @media screen and (max-width: 768px) {
    .tv-login__form-wrapper {
      margin: 16px;
      width: calc(100vw - 32px);
      padding: 0 16px;
      box-shadow: none; } }

.tv-login__form-label {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #252525;
  margin-bottom: 8px; }

.tv-login__form-email-input {
  width: 100%; }

.tv-login__form-interpreter-id-label {
  margin-top: 15px; }

.tv-login__form-interpreterId-input {
  width: 100%; }

.tv-login__form-submit.tv-buttons__element {
  background: #873bff;
  border-color: #873bff;
  width: 100%;
  margin-top: 26px;
  padding: 13px 0px 13px 0px;
  font-weight: 300;
  line-height: 1.4375rem; }

.tv-workassignment__wrapper {
  margin: 30px 20px 0 16px; }

.tv-workassignment__status-wrapper {
  margin-left: 15px;
  margin-right: 10px; }

.tv-workassignment__main-title {
  font-family: "Circular Std", "Circular Std";
  font-size: 24px;
  line-height: 30px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  letter-spacing: 0.2px; }

.tv-workassignment__title, .tv-youravailability__title {
  color: #353535;
  font-weight: 500;
  font: 1.5rem/2rem "Circular Std", "Circular Std";
  margin: 0 0 20px; }

.tv-workassignment__control-panel {
  margin: 0 0 20px; }
  .tv-workassignment__control-panel.h-pdl--30 {
    padding-left: 30px; }
  .tv-workassignment__control-panel .h-fs--16 {
    font-size: 16px; }

.tv-workassignment__date_picker {
  margin: 15px 0 4px;
  font-family: "Circular Std", "Circular Std"; }
  .tv-workassignment__date_picker-title {
    font-size: 16px;
    padding: 6px 0; }
  .tv-workassignment__date_picker-input {
    padding: 3px 12px 0 15px;
    background: #f4f6f9;
    border: 1px solid #d2d2d2;
    border-radius: 4px;
    height: 47px;
    width: 100%;
    font: 400 16px/20px "Circular Std", "Circular Std";
    text-transform: capitalize; }
  .tv-workassignment__date_picker-icon {
    position: absolute;
    top: 45px;
    left: 288px;
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg); }

.tv-workassignment__list-item {
  cursor: pointer;
  margin: 0;
  padding: 8px;
  height: 47px;
  font: 400 12px/15px 'Circular Std', sans-serif;
  z-index: 8;
  border-radius: 0;
  background: #f1f3f4;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  width: 100%;
  -webkit-align-items: center;
          align-items: center; }
  .tv-workassignment__list-item.selected {
    background: #873bff !important; }
    .tv-workassignment__list-item.selected .tv-reportquality__item-text {
      color: #fff !important; }
    .tv-workassignment__list-item.selected .icon-hearing:before {
      background-color: #ffffff; }
  .tv-workassignment__list-item-child {
    margin: 0 13px;
    box-shadow: inset 0 10px 18px -12px rgba(58, 68, 131, 0.2), 0 10px 18px -12px rgba(58, 68, 131, 0.2); }
  .tv-workassignment__list-item-label {
    display: none; }
  .tv-workassignment__list-item:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px; }
  .tv-workassignment__list-item:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px; }
  .tv-workassignment__list-item:nth-child(even) {
    background: #f1f3f4; }
  .tv-workassignment__list-item:nth-child(odd) {
    background: #f7f9ff; }

.tv-workassignment__item-status {
  width: 21.6%;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }

.tv-workassignment__item-icon {
  padding: 15px 40px; }

.tv-workassignment__item-order {
  width: 30%;
  margin-right: 10px; }

.tv-workassignment__item-name {
  width: 29.1%; }

.tv-workassignment__item-category {
  width: 16.7%; }

.tv-workassignment__item-datetime {
  width: 27.6%; }

.tv-workassignment__item-type {
  width: 12%;
  margin-right: 10px; }

.tv-workassignment__item-more_button {
  outline: none;
  border: none;
  background: none;
  text-transform: uppercase;
  font-size: 14px; }
  .tv-workassignment__item-more_button:after {
    content: '\e931';
    font-family: 'transvoice-icon';
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: 'liga';
    color: #c5c5c5;
    margin-left: 4px;
    font-size: 10px;
    color: #000; }

.tv-workassignment__item-menu {
  width: 7%;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }
  .tv-workassignment__item-menu-button:before {
    font-family: 'transvoice-icon';
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: 'liga';
    font-size: 1.5rem; }

.tv-workassignment__item-link-button {
  color: #873bff;
  font-weight: 500;
  font-size: 14px; }
  .selected .tv-workassignment__item-link-button {
    color: #fff; }

.tv-workassignment__item-label {
  font-size: 0.975rem;
  color: #000;
  margin: 0 0 11px;
  font-family: "Circular Std", "Circular Std"; }

.tv-workassignment__item-text {
  color: #252525;
  font: 400 13px/18px "Circular Std", "Circular Std"; }
  .tv-workassignment__item-text.text-max-lenght {
    max-width: calc(70vw / 7);
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .tv-workassignment__item-text .icon {
    text-align: center;
    font-size: 16px; }
    .tv-workassignment__item-text .icon:before {
      color: #74737b; }
  .tv-workassignment__item-text-date {
    font: 400 13px/18px "Circular Std", "Circular Std"; }
  @supports (-webkit-background-clip: text) {
    .tv-workassignment__item-text .stars {
      --percent: var(--rating);
      line-height: 8px;
      display: inline-block;
      font-family: Times;
      -webkit-text-stroke: 1px #ffb074; }
      .tv-workassignment__item-text .stars::before {
        content: '★★★★★';
        font-size: 15px;
        letter-spacing: 3px;
        background: linear-gradient(90deg, #ff9b4e var(--percent), transparent var(--percent));
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent; } }
  .tv-workassignment__item-text .stars {
    color: #ffb074;
    font-family: Times;
    letter-spacing: 3px;
    font-size: 15px; }
  .tv-workassignment__item-text-available-until {
    font-weight: bold;
    color: #873bff; }
  .selected .tv-workassignment__item-text {
    color: #fff; }
    .selected .tv-workassignment__item-text .icon {
      text-align: center;
      font-size: 16px; }
      .selected .tv-workassignment__item-text .icon:before {
        color: #fff; }

.tv-workassignment__status {
  font: 12px/16px "Circular Std", "Circular Std";
  color: #fff;
  border-radius: 4px;
  text-transform: capitalize;
  padding: 6px 8px;
  letter-spacing: 0.5px; }
  .tv-workassignment__status--available {
    background: #ff9b4e; }
  .tv-workassignment__status--accepted {
    background: #6478e6; }
  .tv-workassignment__status--rejected {
    background: #e94242; }
  .tv-workassignment__status--fullfilled {
    background: #18ad79;
    color: #fff; }
  .tv-workassignment__status--cancelled {
    background: #74737b; }
  .tv-workassignment__status--losted {
    background: #696969; }
  .tv-workassignment__status--traveling, .tv-workassignment__status--working {
    background: #fbbcdb;
    color: black; }
  .tv-workassignment__status--done {
    background: #ffa500;
    color: black; }
  .tv-workassignment__status--submitted {
    background: #008000; }

.tv-workassignment__notification {
  background: #f1f3f4;
  border-radius: 4px;
  padding: 15px 8px;
  margin: 8px 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between; }
  .tv-workassignment__notification__title {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    color: #252525;
    margin-bottom: 7px; }
  .tv-workassignment__notification__more {
    font-size: 12px;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    color: #677adf; }
  .tv-workassignment__notification__new-label {
    background: #e94242;
    border-radius: 30px;
    color: #ffffff;
    font-size: 8px;
    height: 16px;
    width: 35px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-content: center;
            align-content: center;
    -webkit-justify-content: center;
            justify-content: center;
    padding: 4px 8px;
    font-weight: 500;
    margin-top: 13px; }

.tv-workassignment__detail-wrapper {
  font-family: "Circular Std", "Circular Std";
  margin-top: 8px; }

.tv-workassignment__detail-article-icon {
  font-size: 20px; }
  .tv-workassignment__detail-article-icon--available {
    color: #ff9b4e !important; }
  .tv-workassignment__detail-article-icon--accepted {
    color: #6478e6 !important; }
  .tv-workassignment__detail-article-icon--rejected {
    color: #e94242 !important; }
  .tv-workassignment__detail-article-icon--fullfilled {
    color: #18ad79 !important; }
  .tv-workassignment__detail-article-icon--cancelled {
    color: #74737b !important; }

.tv-workassignment__detail-articlename {
  font-size: 0.875rem;
  margin: 5px 10px 5px 0; }

.tv-workassignment__detail-status {
  font-size: 1rem;
  font-weight: 400;
  color: #252525;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between; }

.tv-workassignment__detail-orderno {
  color: #454c98; }

.tv-workassignment__detail-name {
  font: 1.5rem/2.1875rem "Circular Std", "Circular Std";
  font-weight: 300;
  color: #a9a9a9;
  color: #353535;
  margin: 0 10px 0 0; }

.tv-workassignment__detail-datetime {
  font-size: 0.875rem;
  color: #873bff;
  margin: 6px 0 24px 32px; }
  .tv-workassignment__detail-datetime .icon-calendar {
    margin-right: 14px; }

.tv-workassignment__detail-title {
  font: 1rem "Circular Std", "Circular Std";
  color: #313131;
  background-color: #f9f9f9;
  padding: 14px 0 13px 32px; }

.tv-workassignment__detail-description {
  margin: 16px 32px 0;
  font: 0.875rem/1.5rem "Circular Std", "Circular Std";
  color: #313131; }

.tv-workassignment__detail-buttons {
  margin: 15px 0 24px;
  font-weight: 600;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-flex-direction: row-reverse;
          flex-direction: row-reverse; }
  .tv-workassignment__detail-buttons .tv-buttons__element {
    padding: 13px 0 12px;
    width: 47.04%;
    font-weight: 500;
    font-size: 16px; }
    .tv-workassignment__detail-buttons .tv-buttons__element-link {
      width: 47.04%; }
      .tv-workassignment__detail-buttons .tv-buttons__element-link button {
        width: 100%; }
    .tv-workassignment__detail-buttons .tv-buttons__element-primary {
      background-color: #873bff;
      color: #fff; }
    .tv-workassignment__detail-buttons .tv-buttons__element--report {
      padding: 13px 24px 12px;
      width: unset; }

.tv-workassignment__detail-links {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  font-family: "Circular Std", "Circular Std";
  margin-right: 10px; }
  @supports (display: flex) {
    .tv-workassignment__detail-links {
      -webkit-align-items: flex-end;
              align-items: flex-end;
      -webkit-justify-content: flex-end;
              justify-content: flex-end; } }
  .tv-workassignment__detail-links-item {
    display: -webkit-flex;
    display: flex;
    min-width: 70px;
    letter-spacing: 0.5px;
    font-size: 14px !important;
    color: #873bff;
    margin-left: 10px;
    margin-top: 5px;
    cursor: pointer; }
    .tv-workassignment__detail-links-item:hover {
      opacity: 1;
      color: #873bff;
      -webkit-text-decoration: underline #873bff 2px;
              text-decoration: underline #873bff 2px; }
    .tv-workassignment__detail-links-item-button {
      font-weight: 600;
      color: #000;
      text-decoration: none; }
      .tv-workassignment__detail-links-item-button:hover {
        color: #000;
        -webkit-text-decoration: underline #873bff 2px;
                text-decoration: underline #873bff 2px; }

.tv-workassignment__detail-footer {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 100%;
  position: absolute;
  bottom: -30px;
  left: 0;
  padding: 0 12px; }

.tv-workassignment__detail-sub-status {
  font: 300 12px/15px "Circular Std", "Circular Std";
  padding: 8px 16px;
  border-radius: 8px;
  margin-top: 16px;
  letter-spacing: 0.4px;
  text-align: center; }
  .tv-workassignment__detail-sub-status--searching-for-interpreter {
    background-color: rgba(255, 155, 78, 0.32); }
  .tv-workassignment__detail-sub-status--interpreter-accepted {
    background-color: rgba(100, 120, 230, 0.32); }
  .tv-workassignment__detail-sub-status--order-in-progress {
    background-color: rgba(100, 120, 230, 0.32); }
  .tv-workassignment__detail-sub-status--assigning-interpreter {
    background-color: rgba(100, 120, 230, 0.32); }
  .tv-workassignment__detail-sub-status--awaiting-time-report {
    background-color: rgba(24, 173, 121, 0.32); }
  .tv-workassignment__detail-sub-status--time-report-received {
    background-color: rgba(24, 173, 121, 0.32); }
  .tv-workassignment__detail-sub-status--deviation-registered {
    background-color: #74737b;
    color: #fff; }
  .tv-workassignment__detail-sub-status--order-cancelled {
    background-color: rgba(233, 66, 66, 0.32); }
  .tv-workassignment__detail-sub-status--order-rejected {
    background-color: rgba(233, 66, 66, 0.32); }
  .tv-workassignment__detail-sub-status--unexpected-event-occured {
    background-color: rgba(233, 66, 66, 0.32); }
  .tv-workassignment__detail-sub-status--unknown-status {
    background-color: #fff; }

.tv-workassignment__attach-title {
  padding: 14px 0 13px 32px;
  color: #a9a9a9;
  font-family: 'PT Serif';
  font-size: 14px;
  letter-spacing: 0.3px;
  line-height: 20px; }

.tv-workassignment__attach-list {
  padding: 0 0 17px 32px; }
  .tv-workassignment__attach-list .tv-attachment {
    margin-right: 8px;
    margin-bottom: 8px;
    background-color: transparent; }

.tv-workassignment__modal {
  top: 0 !important;
  position: absolute;
  z-index: 65; }
  .tv-workassignment__modal-header {
    font: 500 18px/20px "Circular Std", "Circular Std";
    text-align: left;
    padding: 18px 10px;
    height: 56px;
    color: #000;
    border-bottom: 1px solid #e0e0e0;
    letter-spacing: 0.2px; }
    .tv-workassignment__modal-header > .secondary-heading {
      font: 400 14px/18px "Circular Std", "Circular Std";
      color: #74737b; }
  .tv-workassignment__modal-subheader {
    font: 600 16px/18px "Circular Std", "Circular Std";
    padding: 10px 0 16px; }
  .tv-workassignment__modal-detail-container {
    margin-top: 12px; }
  .tv-workassignment__modal-container {
    overflow: hidden;
    margin-top: 5px;
    border-bottom: 1px solid #e0e0e0;
    font-family: "Circular Std", "Circular Std";
    text-align: left;
    margin-right: -10px;
    min-width: 605px;
    height: 30vh !important;
    min-height: 250px; }
  .tv-workassignment__modal-scroll-container {
    overflow: hidden;
    margin-top: 5px;
    border-top: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
    margin-right: -10px;
    min-width: 605px; }
    .tv-workassignment__modal-scroll-container > div > div:nth-child(2) {
      display: none; }
  .tv-workassignment__modal .tv-modal__overlay {
    top: 0 !important;
    left: -10px !important;
    width: 100vw !important;
    height: var(--vh) !important; }
  .tv-workassignment__modal .tv-modal__holder {
    border-radius: 4px;
    width: 620px;
    max-width: 620px;
    max-height: unset;
    top: 63px;
    background-color: #fff;
    overflow-x: hidden; }
    .tv-workassignment__modal .tv-modal__holder .icon-close::before {
      color: #313131; }
  .tv-workassignment__modal-info-label {
    font: 600 14px/18px "Circular Std", "Circular Std";
    color: #252525;
    text-align: left;
    margin-bottom: 8px; }
    .tv-workassignment__modal-info-label.labelled {
      color: #20175b; }
  .tv-workassignment__modal-info-content {
    font: 14px/18px "Circular Std", "Circular Std";
    color: #252525;
    text-align: left;
    white-space: nowrap;
    margin-left: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 16px; }
    .tv-workassignment__modal-info-content.red-text {
      color: #873bff; }
    .tv-workassignment__modal-info-content.labelled {
      color: #20175b; }
  .tv-workassignment__modal-info-condition {
    font: 400 11px/14px "Circular Std", "Circular Std";
    color: #252525;
    text-align: left;
    margin-bottom: 16px; }
  .tv-workassignment__modal-status {
    font: 900 0.975rem "Circular Std", "Circular Std";
    margin-left: 0.5rem;
    padding: 10px 15px 0; }

.tv-workassignment__info-bank-id {
  width: 25px;
  height: 16px;
  background-image: url("/images/bankid.png");
  background-repeat: no-repeat;
  margin-right: 12px;
  margin-left: -5px;
  background-size: contain; }

.tv-workassignment__info-wrapper {
  margin: 0 0 10px;
  padding: 0;
  font-weight: 300;
  overflow-y: scroll;
  scroll-behavior: smooth; }

.tv-workassignment__info-item {
  list-style: none;
  margin: 0 0 17px;
  font-size: 12px;
  color: #252525;
  display: -webkit-flex;
  display: flex; }
  .tv-workassignment__info-item.red-text {
    color: #873bff;
    font-weight: 500; }
  .tv-workassignment__info-item.labelled {
    background-color: #f4d0dd;
    padding: 8px 16px;
    border-radius: 8px; }
  .tv-workassignment__info-item i {
    color: #74737b;
    margin-right: 12px;
    font-size: 14px;
    min-width: 14px; }
  .tv-workassignment__info-item > span ~ a {
    margin-left: 5px; }
  .tv-workassignment__info-item a {
    display: inline;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #873bff; }
  .tv-workassignment__info-item .tv-workassignment__item-text > .stars {
    font-size: 24px; }
    .tv-workassignment__info-item .tv-workassignment__item-text > .stars::before {
      font-size: 24px; }
  .tv-workassignment__info-item.icon-none-icon:before {
    content: '';
    margin-right: 30px; }

.tv-workassignment__info-agree {
  margin: 17px 32px 0;
  color: #313131;
  font: italic 0.875rem/1.5rem "Circular Std", "Circular Std"; }
  .tv-workassignment__info-agree-terms {
    cursor: pointer;
    text-decoration: underline; }

.tv-workassignment__drawer-overlay {
  content: '';
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 16; }

.tv-workassignment__drawer-holder {
  width: 468px;
  background: #fff;
  position: fixed;
  height: 100vh;
  overflow-y: auto;
  top: 0;
  right: 0;
  z-index: 20;
  box-shadow: -5px 0 15px 0 rgba(137, 136, 150, 0.27);
  transition: all 0.5s ease;
  -webkit-transform: translateX(100%);
          transform: translateX(100%); }
  .js-slide-in .tv-workassignment__drawer-holder {
    -webkit-transform: translateX(0%);
            transform: translateX(0%); }

.tv-workassignment__drawer-button-wrapper {
  width: 100%;
  text-align: left;
  position: absolute;
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 9px; }

.tv-workassignment__drawer-next {
  top: 12px;
  right: 40px;
  font-size: 13px;
  margin-left: 30px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-content: center;
          align-content: center; }
  .tv-workassignment__drawer-next:before {
    color: #313131;
    font-size: 1rem; }
  .tv-workassignment__drawer-next:after {
    padding-left: 4px; }
  .tv-workassignment__drawer-next:disabled {
    color: darkgrey; }

.tv-workassignment__drawer-previous {
  top: 12px;
  right: 140px;
  font-size: 13px; }
  .tv-workassignment__drawer-previous:before {
    color: #313131;
    font-size: 0.8rem;
    padding-right: 5px; }
  .tv-workassignment__drawer-previous:disabled {
    color: darkgrey; }

.tv-workassignment__drawer--next {
  top: 0;
  right: 0;
  font-size: 12px;
  padding-right: 8px;
  cursor: pointer;
  display: -webkit-flex;
  display: flex;
  -webkit-align-content: center;
          align-content: center; }
  .tv-workassignment__drawer--next:after {
    padding-left: 5px;
    font-size: 1rem;
    position: absolute;
    top: -2px;
    right: 5px;
    line-height: 20px !important; }
  .tv-workassignment__drawer--next:disabled {
    color: darkgrey;
    cursor: not-allowed; }

.tv-workassignment__drawer--previous {
  top: 0;
  left: 0;
  cursor: pointer;
  font-size: 12px;
  padding-left: 8px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-content: center;
          align-content: center; }
  .tv-workassignment__drawer--previous:before {
    padding-right: 5px;
    font-size: 1rem;
    position: absolute;
    top: -2px;
    left: 5px;
    line-height: 18px !important; }
  .tv-workassignment__drawer--previous:disabled {
    color: darkgrey;
    cursor: not-allowed; }

.tv-workassignment__drawer-close {
  position: absolute;
  top: 10px;
  right: 10px; }
  .tv-workassignment__drawer-close:before {
    color: #313131;
    font-size: 1.2rem; }

.tv-workassignment__drawer2-overlay2 {
  content: '';
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 5; }

.tv-workassignment__drawer2-holder2 {
  width: 560px;
  background: #fff;
  position: fixed;
  height: 100vh;
  overflow-y: auto;
  top: 0;
  right: 0;
  z-index: 15;
  box-shadow: -5px 0 15px 0 rgba(137, 136, 150, 0.27);
  transition: all 0.5s ease;
  -webkit-transform: translateX(100%);
          transform: translateX(100%); }
  .js-slide-in2 .tv-workassignment__drawer2-holder2 {
    -webkit-transform: translateX(0%);
            transform: translateX(0%); }

.tv-workassignment__drawer2-close2 {
  position: absolute;
  top: 10px;
  right: 10px; }
  .tv-workassignment__drawer2-close2:before {
    color: #313131;
    font-size: 1.2rem; }

.tv-workassignment__filter-date-range {
  position: relative; }
  .tv-workassignment__filter-date-range__content {
    position: absolute;
    box-shadow: 1px 1px 4px 1px #969696;
    background: #fff;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    z-index: 150;
    border-radius: 4px;
    left: 15px; }
    .tv-workassignment__filter-date-range__content .calendar-small .rdrDateRangePickerWrapper {
      font-family: "Circular Std", "Circular Std" !important;
      border-radius: 4px;
      margin-bottom: 12px;
      border: none;
      box-shadow: none;
      width: 100%; }
    .tv-workassignment__filter-date-range__content .tv-buttons__element {
      width: 30%;
      margin: 0 auto 10px; }

@media (max-width: 768px) {
  .tv-workassignment__wrapper .filter-border {
    border-radius: 4px;
    border: 1px solid #e0e0e0;
    padding: 16px; }
  .tv-workassignment__item-icon {
    padding: 0 12px;
    height: 24px;
    -webkit-order: 1;
            order: 1; }
    .tv-workassignment__item-icon .icon {
      font-size: 24px !important; }
  .tv-workassignment__item-status {
    width: 30%;
    -webkit-order: 4;
            order: 4;
    max-width: unset;
    padding-right: 5px; }
  .tv-workassignment__item-order {
    width: 30%;
    -webkit-order: 2;
            order: 2;
    max-width: unset;
    padding: 5px; }
    .tv-workassignment__item-order .tv-workassignment__item-text {
      font-weight: 600 !important; }
    .tv-workassignment__item-order .label {
      display: none; }
  .tv-workassignment__item-name {
    width: 50%;
    -webkit-order: 5;
            order: 5;
    max-width: unset;
    margin-top: 10px;
    padding-right: 0;
    text-align: end; }
  .tv-workassignment__item-category {
    width: 30%;
    -webkit-order: 3;
            order: 3;
    max-width: unset;
    padding: 4px; }
    .tv-workassignment__item-category .label {
      display: none; }
    .tv-workassignment__item-category .tv-workassignment__item-text {
      max-width: 80px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden; }
  .tv-workassignment__item-datetime {
    width: 50%;
    -webkit-order: 5;
            order: 5;
    max-width: unset;
    -webkit-flex: 0 0 40%;
            flex: 0 0 40%;
    margin-top: 10px; }
  .tv-workassignment__item-type {
    width: 12%;
    -webkit-order: 6;
            order: 6;
    max-width: unset; }
  .tv-workassignment__item-text {
    display: -webkit-flex;
    display: flex;
    font: 400 12px/15px "Circular Std", "Circular Std";
    margin-top: 17px; }
    .tv-workassignment__item-text:last-child {
      margin-bottom: 17px; }
    .tv-workassignment__item-text-label {
      font: 400 12px/15px "Circular Std", "Circular Std";
      margin: 0 8px 0 0; }
      .tv-workassignment__item-text-label i {
        color: #74737b; }
  .tv-workassignment__item .list-small-rating > label:before {
    font-size: 12px !important; }
  .tv-workassignment__list {
    padding-bottom: 70px;
    margin-right: auto;
    margin-bottom: 80px; }
    .tv-workassignment__list-item {
      height: 200px;
      border-radius: 4px;
      position: relative;
      border: 1px solid #e0e0e0;
      margin: 16px 0 !important;
      padding: 16px; }
      .tv-workassignment__list-item .tv-workassignment__detail-status {
        margin-top: 17px;
        font: 500 16px/20px "Circular Std", "Circular Std"; }
      .tv-workassignment__list-item.selected .tv-workassignment__detail-status {
        color: #fff !important; }
  .tv-workassignment__filter-date-range__content {
    position: fixed;
    left: 35px;
    border-radius: 4px; }
  .tv-workassignment__detail-links {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column; }
  .tv-workassignment__detail-footer {
    bottom: 10px; }
  .tk-assigment-list-filter-footer {
    position: fixed;
    z-index: 60;
    bottom: 0;
    padding: 14px 24px;
    left: 0;
    width: 100vw;
    background-color: #fff; } }

.list-small-rating > label {
  padding-right: 0 !important; }

.list-small-rating > label:before {
  font-size: 12px !important; }

.tv-single-assign-form__control {
  height: 48px !important;
  font: 400 16px/20px "Circular Std", "Circular Std"; }
  .tv-single-assign-form__control .k-input {
    font: 400 16px/20px "Circular Std", "Circular Std" !important;
    height: 100% !important; }

button:focus {
  outline: none; }

.tk-calendar__main-title {
  font: 15px/19px "Circular Std", "Circular Std";
  color: #74737b;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  letter-spacing: 0.2px;
  bottom: 10px;
  position: fixed; }

.tk-calendar__banner-container {
  position: relative;
  display: inline-block; }
  .tk-calendar__banner-container .banner {
    width: 320px;
    height: 209px;
    object-fit: cover;
    margin-bottom: 10px;
    border-radius: 2px; }
  .tk-calendar__banner-container .banner-text {
    position: absolute;
    top: 16px;
    left: 53px;
    color: black;
    width: 214px;
    font: 700 26px/26px "Circular Std", "Circular Std";
    height: 52px; }
  .tk-calendar__banner-container .banner_google-play-image {
    position: absolute;
    top: 162px;
    left: 27px;
    width: 108px;
    height: 32px; }
  .tk-calendar__banner-container .banner_apple-store-image {
    position: absolute;
    top: 167px;
    left: 196px;
    width: 96px;
    height: 32px; }
  .tk-calendar__banner-container .inside-image {
    width: 100%;
    height: auto;
    object-fit: cover; }

.tk-calendar__wrapper {
  border-radius: 5px; }

.tk-calendar__header {
  display: -webkit-flex;
  display: flex;
  font-size: 16px;
  background-color: #f7f9ff;
  z-index: 2; }

.tk-calendar__title {
  display: -webkit-flex;
  display: flex;
  color: #873bff;
  font-family: "Circular Std", "Circular Std";
  margin: 0 0 16px 0;
  height: 48px;
  background: #fff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 24px; }
  .tk-calendar__title span {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    text-align: center;
    margin-left: 16px;
    min-width: 120px; }

.tk-calendar__btn-filter {
  display: none; }

.tk-calendar__btn-newbook {
  display: none; }

.tk-calendar__button {
  padding: 0;
  outline: none;
  cursor: pointer;
  min-height: 45px; }
  .tk-calendar__button:before {
    font-family: 'transvoice-icon';
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: 'liga';
    color: #a5a4a4;
    cursor: pointer; }
  .tk-calendar__button--previous:before {
    content: "\e932";
    margin: 0 15px; }
  .tk-calendar__button--next:before {
    content: "\e931";
    margin: 0 15px; }

.tk-calendar__tabs-wrapper {
  margin: 0 0 16px 24px;
  padding: 0 24px;
  display: -webkit-inline-flex;
  display: inline-flex;
  grid-gap: 24px;
  gap: 24px;
  width: 168px;
  background: #fff;
  height: 48px;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 24px;
  overflow: hidden; }
  .tk-calendar__tabs-wrapper > li {
    list-style: none;
    cursor: pointer;
    width: 50%; }

.tk-calendar__tabs-button {
  border: 0;
  padding: 0;
  cursor: pointer;
  font: 1rem/1.4375rem "Circular Std", "Circular Std";
  border-radius: 4px;
  background: #fff;
  color: #969696;
  height: 100%;
  width: 100%;
  outline: none;
  margin: auto; }
  .tk-calendar__tabs-button:hover {
    color: #873bff; }
  .tk-calendar__tabs-button.selected {
    pointer-events: none;
    cursor: pointer;
    background: #fff;
    color: #873bff; }

.tk-dialog__wrapper {
  position: absolute;
  min-height: 150px;
  width: 387px;
  left: calc(100% + 10px);
  top: -2px;
  box-shadow: 0 0 18px 0 rgba(197, 197, 197, 0.65);
  border-radius: 5px;
  z-index: 2;
  padding: 23px 35px;
  background: #f7f9ff;
  cursor: default; }
  .tk-dialog__wrapper--before {
    right: 100%;
    top: 10px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-right-color: #fff;
    border-width: 5px; }

.tk-dialog__btn-close {
  position: absolute;
  right: 0;
  top: 0;
  padding: 18px !important;
  height: 56px;
  z-index: 4; }
  .tk-dialog__btn-close:before {
    content: "\e926";
    color: #000;
    font-size: 16px; }

.tv-general-customTable {
  width: 100%;
  margin-top: 30px;
  border-collapse: separate;
  border-spacing: 1px 5px; }
  .tv-general-customTable__wrapper {
    position: relative;
    margin-top: 30px;
    min-height: 200px; }
  .tv-general-customTable__wrapper-scroll {
    max-height: 350px;
    overflow: auto;
    margin-top: 20px; }
  .tv-general-customTable > thead > th {
    margin: 0 10px;
    padding: 10px 10px; }
  .tv-general-customTable > thead > tr {
    position: absolute;
    z-index: 16;
    top: -20px;
    font: 0.875rem/1.75rem "Circular Std", sans-serif;
    font-weight: 300;
    letter-spacing: -0.2px;
    box-shadow: 0 10px 18px -12px rgba(58, 68, 131, 0.2);
    height: 50px;
    background-color: #ffffff; }
    .tv-general-customTable > thead > tr th {
      margin: 0 10px;
      padding: 10px 10px; }
  .tv-general-customTable > tbody {
    margin-top: 70px; }
    .tv-general-customTable > tbody > tr:nth-child(1) {
      height: 10px; }
    .tv-general-customTable > tbody > tr {
      font: 0.725rem/1.75rem "Circular Std", sans-serif;
      font-weight: 100;
      letter-spacing: -0.2px;
      box-shadow: 0 10px 18px -12px #74767d;
      height: 50px;
      background-color: #ffffff; }
      .tv-general-customTable > tbody > tr > td:nth-child(1) {
        text-align: center; }
  .tv-general-customTable__badge {
    position: relative;
    width: 60px;
    border-radius: 5px;
    text-transform: uppercase;
    font: 0.675rem "Circular Std", sans-serif;
    margin: 5px;
    text-align: center;
    background-color: #fff;
    padding: 5px;
    box-shadow: 0px 2px 5px 2px rgba(117, 118, 124, 0.29); }
    .tv-general-customTable__badge.date-timeSlot {
      width: 150px;
      font-size: 0.775rem;
      margin: 5px 15px; }
    .tv-general-customTable__badge.timeSlot {
      width: 80px;
      margin: 5px 15px; }
      .tv-general-customTable__badge.timeSlot .tv-customTable__badge-edit-icon.fa-pencil::before {
        content: ""; }
    .tv-general-customTable__badge-available {
      color: #6566b9; }
    .tv-general-customTable__badge-unavailable {
      color: #873bff; }
    .tv-general-customTable__badge.active {
      text-align: left;
      background-color: #fff; }
    .tv-general-customTable__badge.inactive {
      text-align: left;
      color: #c2c2c5;
      background-color: #757576cd; }
    .tv-general-customTable__badge.edit {
      min-height: 24px;
      width: 80px;
      cursor: pointer;
      text-align: right; }
      .tv-general-customTable__badge.edit-icon-only {
        width: 180px;
        padding-top: 6px;
        box-shadow: 0px 0px 0px 0px #75767c4a; }
      .tv-general-customTable__badge.edit.active {
        background-color: #fff; }
        .tv-general-customTable__badge.edit.active > div {
          left: 3px;
          top: 4px;
          position: absolute; }
          .tv-general-customTable__badge.edit.active > div:before {
            content: "\e939";
            font: 5px transvoice-icon;
            border: 1px solid #873bff;
            border-radius: 50%;
            padding: 4px;
            color: #fff;
            background-color: #873bff; }
      .tv-general-customTable__badge.edit.inactive {
        color: #000;
        background-color: #fff; }
        .tv-general-customTable__badge.edit.inactive > div {
          left: 3px;
          top: 4px;
          position: absolute; }
          .tv-general-customTable__badge.edit.inactive > div:before {
            content: "\e939";
            font: 5px transvoice-icon;
            border: 1px solid #873bff;
            border-radius: 50%;
            padding: 4px;
            color: #fff;
            background-color: #fff; }
  .tv-general-customTable__defaultAvailability > tbody td {
    margin-top: 10px;
    margin-bottom: 10px; }
  .tv-general-customTable__defaultAvailability .disabled-func {
    color: #fff !important;
    cursor: not-allowed;
    background-color: #757576cd; }
  .tv-general-customTable__defaultAvailability .tv-display-flex {
    display: -webkit-flex;
    display: flex;
    width: 100%;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap; }
  .tv-general-customTable__defaultAvailability > thead tr {
    width: 100%; }
    .tv-general-customTable__defaultAvailability > thead tr th {
      margin-top: auto;
      margin-bottom: 10px; }
    .tv-general-customTable__defaultAvailability > thead tr th:nth-child(1) {
      width: 40vw; }
    .tv-general-customTable__defaultAvailability > thead tr th:nth-child(2) {
      width: 40vw; }
    .tv-general-customTable__defaultAvailability > thead tr th:nth-child(3) {
      width: 20vw; }
  .tv-general-customTable__defaultAvailability > tbody td:nth-child(1) {
    padding-left: 10px;
    width: 40vw; }
  .tv-general-customTable__defaultAvailability > tbody td:nth-child(2) {
    padding-left: 10px;
    width: 40vw; }
  .tv-general-customTable__defaultAvailability > tbody td:nth-child(3) {
    width: 20vw; }
    .tv-general-customTable__defaultAvailability > tbody td:nth-child(3) > div {
      -webkit-justify-content: space-around;
              justify-content: space-around; }
  .tv-general-customTable__button {
    width: 30px;
    border-radius: 6px;
    font: 0.875rem "Circular Std", sans-serif;
    line-height: 0.4rem;
    margin: 5px;
    cursor: pointer;
    text-align: center;
    background-color: #873bff;
    color: #fff;
    padding: 6px 5px;
    box-shadow: 0px 2px 5px 2px rgba(117, 118, 124, 0.29); }
    .tv-general-customTable__button .disabled-func {
      color: black !important;
      cursor: not-allowed;
      background-color: #75767c4a; }
    .tv-general-customTable__button-submit {
      width: 80px;
      padding: 10px 5px;
      background-color: #873bff; }
    .tv-general-customTable__button-cancel {
      width: 80px;
      padding: 10px 5px;
      color: #873bff;
      background-color: #fff; }
    .tv-general-customTable__button-flow {
      width: 20px;
      box-shadow: 0px 0px 0px 0px #75767c4a;
      padding: 10px 5px;
      background-color: #873bff; }
  .tv-general-customTable__default-nodata {
    width: 100%;
    font: 0.875rem/1.75rem "Circular Std", sans-serif;
    text-align: center;
    padding-top: 10px;
    font-weight: 300;
    letter-spacing: -0.2px;
    box-shadow: 0 10px 18px -12px rgba(58, 68, 131, 0.2);
    height: 50px;
    background-color: #ffffff; }

.tk-preference-title {
  background: transparent;
  font: 500 1.5rem/2rem "Circular Std", sans-serif;
  letter-spacing: -0.2px;
  color: #353535;
  margin: 15px auto 15px auto;
  width: 80%; }

.tk-preference__submit {
  position: fixed;
  bottom: 3px;
  right: 12%;
  background: transparent; }

.tk-preference__wrapper {
  width: 80%;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.12);
  margin: 0px auto 0 auto;
  min-height: 65vh; }
  .tk-preference__wrapper label {
    font: 0.875rem/1rem "PT Serif", serif;
    cursor: pointer; }
  .tk-preference__wrapper input[type="checkbox"] {
    visibility: hidden; }
  .tk-preference__wrapper input[type="checkbox"] + label:before {
    border: 1px solid #873bff;
    content: "\00a0";
    display: inline-block;
    font: 700 14px/1em sans-serif;
    height: 16px;
    margin: 0 0.5em 0 0;
    padding: 0;
    vertical-align: top;
    width: 16px; }
  .tk-preference__wrapper input[type="checkbox"]:checked + label:before {
    background: #873bff;
    color: #fff;
    content: "\2713";
    text-align: center; }
  .tk-preference__wrapper input[type="checkbox"]:checked:disabled + label:before {
    background: #b9b3b3;
    color: #a3a3a3;
    content: "\2713";
    text-align: center; }
  .tk-preference__wrapper input[type="checkbox"]:disabled + label:before {
    background: #b9b3b3;
    color: #b9b3b3;
    content: "\2713";
    text-align: center; }
  .tk-preference__wrapper input[type="checkbox"]:checked + label:after {
    font-weight: bold; }
  .tk-preference__wrapper input[type="checkbox"]:focus + label::before {
    outline: #3b99fc auto 5px; }

.tk-preference-subtitle {
  background: transparent;
  font: 500 0.975rem "Circular Std", sans-serif;
  letter-spacing: -0.2px;
  color: #353535;
  margin: 15px 0px 15px 0px; }

.tk-preference .fixed_collapse {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  max-height: 90vh; }

.tk-preference__container-scroll {
  height: 65vh !important;
  width: 100% !important;
  border-top: 1px solid #f1f1f1; }
  .tk-preference__container-scroll > div:nth-child(1) {
    height: 65vh;
    overflow-x: hidden !important; }
  .tk-preference__container-scroll > div:nth-child(2) {
    height: 50vh; }
    .tk-preference__container-scroll > div:nth-child(2) > div {
      display: none !important; }
  .tk-preference__container-scroll > div:nth-child(3) {
    overflow: hidden !important; }

.tk-preference__contract-group {
  width: 50%; }
  .tk-preference__contract-group > div {
    width: 250px;
    border-radius: 10px; }

.tk-left-drawer {
  position: absolute;
  top: 120px;
  left: 10px;
  min-height: 650px;
  max-height: 1000px;
  min-width: 320px;
  padding-top: 30px;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  -webkit-animation: slide-in 0.5s forwards;
          animation: slide-in 0.5s forwards;
  background-color: #f7f9ff;
  z-index: 50; }
  .tk-left-drawer__close-icon {
    position: absolute;
    top: 5px;
    right: 20px;
    cursor: pointer; }
    .tk-left-drawer__close-icon::before {
      font-size: 14px;
      font-weight: 400; }
  .tk-left-drawer .tk-dialog__wrapper {
    box-shadow: none; }
    .tk-left-drawer .tk-dialog__wrapper .tk-dialog__wrapper--before {
      display: none !important; }
  .tk-left-drawer .tk-dialog__body {
    height: 100%; }

.tk-assignment-detail__container {
  left: 0;
  top: 0;
  width: calc(100% - 10px);
  height: 625px !important;
  border: 1px solid #e0e0e0;
  background-color: #fbfbfb;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 5px 10px; }
  .tk-assignment-detail__container .tk-dialog__btn-close {
    position: absolute;
    right: 0;
    font-family: "Circular Std", "Circular Std";
    top: 0;
    padding: 18px !important;
    height: 56px;
    z-index: 4;
    position: absolute; }
  .tk-assignment-detail__container .icon-close::before {
    font-size: 0.8rem !important;
    font-weight: 700 !important; }

.tk-assignment-detail__drawer {
  z-index: 5; }

.tk-assignment-list-box__container {
  left: 0;
  top: 0;
  width: calc(100% - 10px);
  height: 550px !important;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 5px 10px; }
  .tk-assignment-list-box__container .tk-dialog__btn-close {
    position: absolute;
    right: 0;
    top: 0;
    padding: 18px !important;
    height: 56px;
    z-index: 4; }
  .tk-assignment-list-box__container .icon-close::before {
    font-size: 0.8rem !important;
    font-weight: 700 !important; }

.tk-assignment-list-box_header {
  font: 18px/23px "Circular Std", "Circular Std";
  padding: 10px 10px 10px 16px;
  letter-spacing: 0.2px;
  font-weight: 500;
  text-transform: capitalize; }

.tk-assignment-list-box_drawer {
  height: 74vh; }

.tk-assignment-list-box_body {
  border-top: 1px solid #e0e0e0;
  padding: 10px;
  width: 100%;
  height: calc(100% - 50px);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }
  .tk-assignment-list-box_body-text {
    padding: 5px;
    color: #252525;
    font: 14px/18px "Circular Std", "Circular Std"; }
  .tk-assignment-list-box_body-scroll {
    height: calc(100% - 80px) !important; }

.tk-assignment-list-box_footer {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 100%;
  position: absolute;
  bottom: -30px;
  left: 0;
  padding: 0 12px; }

.tk-assignment-list-box__event-item {
  font: 0.6875rem/1rem "Circular Std", "Circular Std";
  position: absolute;
  background: aliceblue;
  padding: 2px 5px 2px 5px;
  width: calc(100% + 2px);
  left: -1px;
  cursor: pointer;
  border-radius: 3px;
  line-height: 12px;
  color: #873bff; }
  .tk-assignment-list-box__event-item--available {
    color: #ff9b4e;
    border: 1px solid #ff9b4e; }
  .tk-assignment-list-box__event-item--accepted {
    color: #6478e6;
    border: 1px solid #6478e6; }
  .tk-assignment-list-box__event-item--rejected {
    color: #e94242;
    border: 1px solid #e94242; }
  .tk-assignment-list-box__event-item--losted {
    color: #696969;
    border: 1px solid #696969; }
  .tk-assignment-list-box__event-item--traveling, .tk-assignment-list-box__event-item--working {
    color: #fbbcdb;
    border: 1px solid #fbbcdb; }
  .tk-assignment-list-box__event-item--fullfilled {
    color: #fff;
    border: 1px solid #18ad79; }
  .tk-assignment-list-box__event-item--done {
    color: #ffa500;
    border: 1px solid #ffa500; }
  .tk-assignment-list-box__event-item--cancelled {
    color: #74737b;
    border: 1px solid #74737b; }
  .tk-assignment-list-box__event-item--submitted {
    color: #008000;
    border: 1px solid #008000; }
  .tk-assignment-list-box__event-item--custom {
    border: 1px solid #9c69ad;
    color: #9c69ad; }

.tk-assignment-list-box__event-accepted {
  background-color: #50c427 !important; }

.tk-assignment-list-box__event-working {
  background-color: #6aa5fe !important; }

.tk-assignment-list-box__event-fullfilled {
  background-color: #b4b4b4 !important; }

.tk-assignment-list-box__event-submitted {
  background-color: #fa0960 !important; }

.tk-assignment-list-box__event-cancelled {
  background-color: #fa0960 !important; }

.tk-assignment-list-box__event-available {
  background-color: #2b7af1 !important; }

.tk-assignment-list-box__event-single {
  color: #74737b;
  background-color: #fff;
  -webkit-filter: drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.16));
          filter: drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.16));
  border-radius: 4px;
  text-align: left;
  cursor: pointer;
  margin: 8px;
  padding: 9px; }
  .tk-assignment-list-box__event-single_container1 {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    font-family: "Circular Std", "Circular Std";
    margin-bottom: 5px; }
    .tk-assignment-list-box__event-single_container1 .header {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-between;
              justify-content: space-between;
      border-bottom: 1px solid #efefef;
      font: 400 14px/18px "Circular Std", "Circular Std"; }
      .tk-assignment-list-box__event-single_container1 .header .notification {
        color: #873bff; }
    .tk-assignment-list-box__event-single_container1 .skill {
      margin-bottom: 8px; }
    .tk-assignment-list-box__event-single_container1 .status {
      font-size: 8px;
      background: white;
      line-height: 20px;
      border-radius: 13px;
      color: black;
      padding: 1px 8px 0px;
      width: -moz-fit-content;
      width: -webkit-fit-content;
      width: fit-content;
      text-transform: uppercase; }
    @media (max-width: 1024px) {
      .tk-assignment-list-box__event-single_container1 .skill {
        overflow: hidden;
        text-overflow: ellipsis; } }
  .tk-assignment-list-box__event-single_container2 {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    font-family: "Circular Std", "Circular Std"; }
    .tk-assignment-list-box__event-single_container2 .fields {
      font: 400 12px/14px "Circular Std", "Circular Std";
      margin: 0 auto 10px 0;
      display: -webkit-flex;
      display: flex;
      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: normal;
      letter-spacing: 0.4px;
      -webkit-align-items: flex-start;
              align-items: flex-start;
      width: 50%; }
      .tk-assignment-list-box__event-single_container2 .fields i {
        font-size: 16px; }
      .tk-assignment-list-box__event-single_container2 .fields:last-child {
        margin-bottom: 0px; }
      .tk-assignment-list-box__event-single_container2 .fields .briefcase {
        width: 16px;
        height: 16px;
        margin-right: 8px; }
  .tk-assignment-list-box__event-single .time:before {
    color: white;
    margin-right: 10px; }
  .tk-assignment-list-box__event-single .contactPerson:before {
    color: white;
    margin-right: 10px; }

.tk-assignment-list-box__event-content {
  height: 100%;
  overflow: hidden;
  line-height: 1.4rem; }

.tk-assignment-list-box__event-time {
  font-size: 0.5rem;
  font-weight: bold; }

.stepper__item {
  -webkit-flex: 100% 1;
          flex: 100% 1;
  position: relative;
  height: 50px;
  background: #e0e0e0;
  margin: 0 0 0 -12px;
  border-radius: 4px;
  -webkit-clip-path: polygon(20px 50%, 0% 0%, calc(100% - 20px) 0%, 100% 50%, calc(100% - 20px) 100%, 0% 100%);
  clip-path: polygon(20px 50%, 0% 0%, calc(100% - 20px) 0%, 100% 50%, calc(100% - 20px) 100%, 0% 100%);
  border: 1px solid #e0e0e0; }
  .stepper__item.current {
    background: #fff;
    font-weight: bold; }
  .stepper__item > div {
    z-index: 3;
    width: 100%;
    padding-right: 5px;
    font-size: 12px;
    font-family: "Circular Std", "Circular Std";
    position: absolute;
    top: 32%; }
  .stepper__item:before {
    content: "";
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    -webkit-clip-path: polygon(20px 50%, 0% 0%, calc(100% - 20px) 0%, 100% 50%, calc(100% - 20px) 100%, 0% 100%);
    clip-path: polygon(20px 50%, 0% 0%, calc(100% - 20px) 0%, 100% 50%, calc(100% - 20px) 100%, 0% 100%);
    background: #fff;
    display: block;
    position: absolute;
    border-radius: 4px;
    top: 1px;
    left: 2px; }
  .stepper__item.complete {
    background: #873bff; }
    .stepper__item.complete::before {
      background: #873bff; }
    .stepper__item.complete > div {
      color: #fff; }
  .stepper__item:first-child {
    -webkit-clip-path: polygon(0% 0%, calc(100% - 20px) 0%, 100% 50%, calc(100% - 20px) 100%, 0% 100%);
            clip-path: polygon(0% 0%, calc(100% - 20px) 0%, 100% 50%, calc(100% - 20px) 100%, 0% 100%); }
  .stepper__item:last-child {
    -webkit-clip-path: polygon(20px 50%, 0% 0%, 100% 0%, 100% 100%, 0% 100%);
            clip-path: polygon(20px 50%, 0% 0%, 100% 0%, 100% 100%, 0% 100%); }
    .stepper__item:last-child::before {
      -webkit-clip-path: polygon(20px 50%, 0% 0%, 100% 0%, 100% 100%, 0% 100%);
              clip-path: polygon(20px 50%, 0% 0%, 100% 0%, 100% 100%, 0% 100%); }

@media (max-width: 768px) {
  .tk-calendar__mobile-fixed-bottom {
    position: fixed;
    background-color: #fff;
    display: -webkit-flex;
    display: flex;
    left: 0;
    bottom: 0;
    width: 100vw; }
    .tk-calendar__mobile-fixed-bottom i.fa {
      -webkit-text-stroke: 1px #873bff; }
  .tk-calendar__btn-newbook {
    font-family: "Circular Std", "Circular Std";
    display: -webkit-flex;
    display: flex;
    background-color: #873bff;
    color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    max-height: 48px;
    -webkit-justify-content: center;
            justify-content: center;
    padding: 14px 10px;
    width: 50%;
    -webkit-align-self: center;
            align-self: center;
    margin: 16px 16px 16px 0; }
  .tk-calendar__btn-filter {
    font-family: "Circular Std", "Circular Std";
    -webkit-justify-content: space-around;
            justify-content: space-around;
    display: -webkit-flex;
    display: flex;
    font-size: 14px;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    padding: 14px 0;
    -webkit-align-self: center;
            align-self: center;
    width: 50%;
    margin: 16px; }
  .tk-calendar__header {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    line-height: 16px;
    font-size: 12px !important;
    margin-bottom: 15px; }
    .tk-calendar__header button.tk-calendar__tabs-button {
      font-size: 14px !important; }
    .tk-calendar__header .tk-calendar__title {
      margin: 0px; }
      .tk-calendar__header .tk-calendar__title span {
        min-width: 90px; }
    .tk-calendar__header .tk-calendar__tabs-wrapper {
      position: relative;
      margin: 0px 0px 0px auto; }
  .tk-calendar__main-title {
    font-family: "Circular Std", sans-serif;
    font-size: 20px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    position: relative;
    -webkit-justify-content: space-between;
            justify-content: space-between; }
  .tk-left-drawer {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 50; }
    .tk-left-drawer__close-icon {
      top: 15px; }
    .tk-left-drawer .tv-drawer_buttons {
      width: calc(100vw - 20px);
      bottom: 30px; }
      .tk-left-drawer .tv-drawer_buttons .tv_dashboard__add-button {
        margin-bottom: 0; }
  .tk-assignment-list-box__container,
  .tk-assignment-detail__container {
    height: 100vh !important;
    width: 100vw;
    border-radius: 0; }
  .tk-assignment-list-box_body,
  .tk-assignment-detail_body {
    height: calc(100vh - 50px) !important;
    padding: 0px;
    border-top: none; }
  .tk-assignment-list-box_body-scroll,
  .tk-assignment-detail_body-scroll {
    height: calc(100% - 130px) !important; }
  .tv-advanced-order__scroll-container {
    height: calc(100vh - 125px) !important; }
    .tv-advanced-order__scroll-container > div {
      overflow-x: hidden !important; }
    .tv-advanced-order__scroll-container .tv-checkbox .custom-control-input ~ .custom-control-label {
      font: 400 16px/18px "Circular Std", sans-serif !important;
      font-size: 16px !important;
      letter-spacing: 0px !important;
      text-transform: capitalize !important;
      background-color: #fff !important; }
  .tv-workassignment__modal {
    top: 0 !important;
    left: 0 !important;
    width: 100vw;
    position: fixed;
    height: 100vh; }
    .tv-workassignment__modal .tv-modal__overlay {
      top: 0 !important;
      left: 0 !important; }
    .tv-workassignment__modal-scroll-container, .tv-workassignment__modal-container {
      min-width: 100% !important;
      height: auto;
      overflow-y: scroll;
      margin-bottom: 150px; }
    .tv-workassignment__modal-detail-container {
      min-width: 100% !important;
      height: 100%;
      overflow-y: scroll;
      overflow-x: hidden; }
    .tv-workassignment__modal-footer {
      position: fixed;
      bottom: 0;
      left: 0;
      padding: 0 10px;
      width: 100vw;
      background-color: #fff; }
    .tv-workassignment__modal .tv-modal__holder {
      width: calc(100vw);
      max-width: 100vw;
      height: 100vh;
      max-height: 100vh;
      margin-top: 0;
      top: 0;
      border-radius: 0; }
    .tv-workassignment__modal .tv-workassignment__modal-body {
      height: calc(100vh - 140px);
      height: calc(100svh - 140px); }
    .tv-workassignment__modal .tv-workassignment__modal-status > div:nth-child(1) {
      text-align: left;
      width: 55%; }
  .tv-single-assign-form__preview-property .property-value.edit-field {
    padding: 5px;
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; } }

@media (max-width: 1024px) {
  .tv-right-status {
    padding: 0 32px 0 24px !important; }
  .filter-border {
    border-radius: 4px;
    border: 1px solid #e0e0e0;
    padding: 16px; }
  .tv-display-mobile-hide.tabs {
    display: none !important; }
  .tv-display-mobile-show.tabs {
    display: -webkit-flex;
    display: flex; } }

.tv-youravailability__wrapper {
  margin-top: 32px; }

.tv-youravailability__list {
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap; }
  .tv-youravailability__list-item {
    position: relative;
    cursor: default;
    margin: 0 1.5% 16px 0;
    font-family: "Circular Std", "Circular Std";
    width: 31%;
    box-shadow: 0 0 13px 0 rgba(223, 223, 223, 0.5); }
    .tv-youravailability__list-item-drawer {
      position: relative;
      cursor: default;
      margin: 0 1.5% 16px 0;
      font-family: "Circular Std", "Circular Std";
      width: 90%;
      box-shadow: 0 0 13px 0 rgba(223, 223, 223, 0.5); }

.tv-youravailability__container {
  padding: 24px;
  width: 100%; }

.tv-youravailability__item-hr {
  border: 0.5px solid rgba(223, 223, 223, 0.5); }

.tv-youravailability__item-space {
  padding-left: 5px; }

.tv-youravailability__item-label {
  color: #a9a9a9;
  font: 0.875rem/1.5rem;
  letter-spacing: 0.3; }

.tv-youravailability__item-text {
  color: #313131;
  font: 0.875rem/1.5rem "Circular Std", "Circular Std";
  letter-spacing: 0.3; }
  .tv-youravailability__item-text-mainlables {
    color: #873bff;
    font: 300 0.875rem/1.75rem "Circular Std", "Circular Std";
    letter-spacing: -0.2px;
    min-height: 56px; }
  .tv-youravailability__item-text-datetime {
    color: #873bff;
    font: 300 1.125rem/1.75rem "Circular Std", "Circular Std";
    letter-spacing: -0.2px;
    min-height: 56px; }

.tv-youravailability__item-menu {
  cursor: pointer;
  padding-top: 19px;
  position: absolute;
  top: 0;
  right: 10px; }
  .tv-youravailability__item-menu-button:before {
    font-family: 'transvoice-icon';
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: 'liga';
    font-size: 1.5rem; }

.tv-youravailability__dropdown .tv-filter-list__menu {
  width: 90px;
  margin: 0;
  right: 0;
  box-shadow: 0 0 13px 0 rgba(223, 223, 223, 0.5); }

.tv-youravailability__dropdown .tv-buttons__type {
  padding: 10px;
  text-align: left; }

.dashboard__report-container {
  display: -webkit-flex;
  display: flex;
  margin-top: 18px; }

.dashboard__filter-holder {
  -webkit-flex: 0.8 1;
          flex: 0.8 1;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding: 0 16px;
  grid-gap: 24px;
  gap: 24px;
  background-color: #f7f9ff;
  -webkit-align-items: center;
          align-items: center; }
  .dashboard__filter-holder .tv-buttons__element {
    margin-top: 8px;
    border-radius: 100px;
    width: 100%; }
  .dashboard__filter-holder .info-message {
    font-weight: 450;
    font-size: 14px;
    font-style: italic; }
  .dashboard__filter-holder .total-assignments {
    font-weight: 400;
    font-size: 16px; }
  .dashboard__filter-holder .error-message {
    font-size: 12px;
    font-weight: 450;
    color: #873bff;
    margin-top: -16px; }

.dashboard__order-count-container {
  -webkit-flex: 1 1;
          flex: 1 1;
  color: black; }

.dashboard__report-holder {
  margin-left: 22px;
  -webkit-flex: 3 1;
          flex: 3 1;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  font-family: 'Circular Std', sans-serif;
  padding-right: 15px; }

.dashboard__chart-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  grid-gap: 32px;
  gap: 32px;
  margin-bottom: 32px; }

.dashboard__single-chart-container {
  padding: 20px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.16);
  -webkit-flex-basis: 31%;
          flex-basis: 31%; }

.dashboard__display-card {
  padding: 10px 5px;
  display: flow-root;
  width: 100%;
  height: auto;
  border: 1px solid #efefef;
  background-color: white; }
  .dashboard__display-card-header {
    display: block;
    width: 100%;
    font-size: 1.2rem;
    padding: 15px; }
  .dashboard__display-card_nodata {
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
    padding-top: 50px;
    width: 100%;
    height: 100%;
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    background-color: rgba(255, 255, 255, 0.65); }

.dashboard__samll-display-card {
  padding: 10px 5px;
  display: grid;
  height: auto;
  width: 100%;
  -webkit-align-items: center;
          align-items: center;
  border: 1px solid #efefef;
  background-color: white; }
  .dashboard__samll-display-card:nth-child(1) {
    -ms-grid-column: 1;
    grid-column: 1;
    -ms-grid-row: 1;
    grid-row: 1; }
  .dashboard__samll-display-card:nth-child(2) {
    -ms-grid-column: 2;
    grid-column: 2;
    -ms-grid-row: 1;
    grid-row: 1; }
  .dashboard__samll-display-card-header {
    display: block;
    width: 100%;
    font-size: 1.2rem;
    padding: 15px; }
    .dashboard__samll-display-card-header span {
      width: 70%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }

.dashboard_samll-detail-card {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: auto auto;
  grid-template-columns: auto auto; }
  .dashboard_samll-detail-card-header {
    display: block;
    width: 100%;
    font-size: 1rem;
    padding: 15px; }
    .dashboard_samll-detail-card-header span {
      width: 190px;
      white-space: normal;
      white-space: initial;
      overflow: hidden;
      text-overflow: ellipsis; }
  .dashboard_samll-detail-card-content {
    display: grid;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    max-height: 2rem;
    font-weight: 100;
    float: left;
    background-color: white;
    color: #716464;
    font-size: 1rem;
    padding: 15px; }

.report-card-header {
  font-family: 'Circular Std', sans-serif;
  font-size: 1rem; }

.round-border {
  border-radius: 10px; }

.p-1 {
  padding: 5px; }

.auto-row-1 {
  display: grid;
  display: -ms-grid;
  grid-template-rows: auto;
  -ms-grid-rows: minmax(min-content, 1fr); }

.auto-row-2 {
  display: grid;
  display: -ms-grid;
  grid-template-rows: auto auto;
  -ms-grid-rows: minmax(min-content, 1fr) 2px minmax(min-content, 1fr); }

.auto-col-1 {
  display: grid;
  display: -ms-grid;
  grid-template-columns: auto;
  grid-row-gap: 15px; }

.auto-col-2 {
  display: grid;
  display: -ms-grid;
  grid-template-columns: auto auto;
  -ms-grid-columns: minmax(min-content, 1fr) 2px minmax(min-content, 1fr);
  grid-gap: 20px; }

.exact-col-4 {
  display: grid;
  display: -ms-grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  -ms-grid-columns: 1fr 10px 1fr 10px 1fr 10px 1fr;
  grid-gap: 10px; }

.exact-col-2 {
  display: grid;
  display: -ms-grid;
  grid-template-columns: 1fr 1fr;
  -ms-grid-columns: 1fr 20px 1fr;
  grid-gap: 20px; }

.exact-col-1 {
  display: grid;
  display: -ms-grid;
  grid-template-columns: 1fr;
  -ms-grid-columns: 1fr;
  grid-gap: 20px; }

.popup-content {
  padding: 30px;
  color: #787878;
  background-color: #fcf7f8;
  border: 1px solid rgba(0, 0, 0, 0.05); }

.float-filter {
  display: grid;
  display: -ms-grid;
  grid-template-columns: auto auto;
  -ms-grid-columns: minmax(min-content, 1fr) 2px minmax(min-content, 1fr);
  position: fixed;
  z-index: 1000000;
  bottom: 35%;
  right: 8%; }
  .float-filter-icon-box {
    background-color: #873bff;
    border-radius: 10px 10px 0px 10px;
    width: 60px;
    color: white;
    height: 60px;
    box-shadow: 0 3px 6px #0000001a, 0 3px 6px #00000078, 0 0px 0px #0000001a, 0 3px 6px #0000001a; }
    .float-filter-icon-box span {
      font-size: 2rem;
      padding: 10px; }
  .float-filter-content {
    background-color: #873bff;
    color: white;
    -webkit-align-self: end;
            align-self: end;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 5px;
    height: 40px;
    width: 100%;
    box-shadow: 0 3px 6px #0000001a, 0 3px 6px #00000078, 0 3px 6px #0000001a, 0 0px 0px #0000001a; }
    .float-filter-content span {
      float: left;
      padding: 5px; }
  .float-filter-clear-btn {
    width: auto;
    border-left: 3px white solid;
    height: auto;
    cursor: pointer;
    font-size: 1.4rem; }

.text-float-right {
  padding-right: 5% !important;
  max-width: 200px !important;
  text-align: right !important; }

.report___sidemenu-scroll {
  height: 200px !important;
  width: inherit !important; }

@media (max-width: 768px) {
  .tv-left-pannel__wrapper {
    display: none; }
  .tv-right-pannel-wrapper .tv-right-status {
    padding: 22px 32px; }
    .tv-right-pannel-wrapper .tv-right-status .tv-avatar {
      width: 38px;
      display: block;
      margin-right: 10px; } }

.contact_card_wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-bottom: 230px;
  width: 100%;
  height: auto; }

.contact_card__item:first-of-type {
  margin-right: 16px; }
  @media screen and (max-width: 768px) {
    .contact_card__item:first-of-type {
      margin-right: 0; } }

:root {
  --page-margin: max(25px, min(5%, 25px)); }

.page-title {
  display: -webkit-flex;
  display: flex;
  font: 400 28px/35px "Circular Std", "Circular Std";
  margin-top: 60px; }
  @media screen and (max-width: 768px) {
    .page-title {
      margin-top: 40px;
      font: 400 24px/30px "Circular Std", "Circular Std";
      -webkit-flex-direction: column;
              flex-direction: column; } }

.page-section-body {
  font: 100 16px/24px "Circular Std", "Circular Std";
  margin-top: 20px;
  text-align: justify; }
  .page-section-body a {
    color: #e94242; }

.title {
  width: 50%; }

.page-footer {
  display: -webkit-flex;
  display: flex;
  position: absolute;
  left: 0;
  height: 160px;
  bottom: 0;
  -webkit-justify-content: center;
          justify-content: center;
  background-color: #f8f8f8;
  width: 100%; }
  .page-footer img {
    float: right;
    margin: 0 32px 40px 0; }
  @media screen and (max-width: 768px) {
    .page-footer {
      height: 94px;
      -webkit-justify-content: center;
              justify-content: center; }
      .page-footer img {
        float: unset !important;
        margin: 0 auto 32px; } }

.generic_card {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-top: 25px;
  min-width: 300px;
  width: 100%;
  min-height: 320px;
  border-radius: 4px; }
  @media screen and (max-width: 768px) {
    .generic_card {
      height: 150px;
      border-radius: 4px;
      background: #fff;
      width: auto; } }

.contact_image {
  min-height: 276px; }
  @media screen and (max-width: 768px) {
    .contact_image {
      min-height: 180px; }
      .contact_image img {
        width: 100% !important;
        height: 100% !important;
        object-fit: cover; } }
  .contact_image img {
    width: 80%;
    height: auto;
    object-fit: cover; }

.contact_city {
  padding-top: 15px;
  margin-bottom: 8px;
  margin-top: 16px;
  font: 24px/30px "Circular Std", "Circular Std"; }
  @media screen and (max-width: 768px) {
    .contact_city {
      margin-top: 0px;
      font: 24px/30px "Circular Std", "Circular Std"; } }

.contact_address_wrapper {
  font: 16px/20px "Circular Std", "Circular Std"; }
  @media screen and (max-width: 768px) {
    .contact_address_wrapper {
      font: 16px/20px "Circular Std", "Circular Std"; } }

.contact_method_wrapper {
  padding-top: 15px;
  display: -webkit-inline-flex;
  display: inline-flex;
  padding-bottom: 15px;
  font: 16px/20px "Circular Std", "Circular Std"; }
  .contact_method_wrapper-number a {
    color: #873bff;
    margin-right: 5px; }
    .contact_method_wrapper-number a ::before {
      content: ':';
      color: #252525; }
  @media screen and (max-width: 768px) {
    .contact_method_wrapper {
      font: 16px/20px "Circular Std", "Circular Std"; } }

.faq_wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin: 0px 0 72px 0; }
  @media screen and (max-width: 768px) {
    .faq_wrapper {
      margin: 0px 0 0px 0; } }

.drop_accordian {
  border-radius: 4px;
  margin-top: 20px;
  display: -webkit-flex;
  display: flex;
  border: 1px solid #e0e0e0;
  font: 16px/20px 'Circular Std', sans-serif; }
  @media screen and (max-width: 768px) {
    .drop_accordian {
      width: 100%; } }

.question_content {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
  font: 500 19px/24px 'Circular Std', sans-serif;
  padding: 0px 22px 0px 24px; }
  .question_content :hover {
    cursor: pointer; }

.heading {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  width: 100%;
  font-weight: 500;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 24px 0 24px 0; }
  @media screen and (max-width: 768px) {
    .heading {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
              flex-direction: row;
      -webkit-justify-content: space-between;
              justify-content: space-between; } }

.icon_wrapper :hover {
  cursor: pointer; }

@media screen and (max-width: 768px) {
  .icon_wrapper {
    text-align: center; } }

.icon {
  font-size: 16px; }

.body {
  display: -webkit-flex;
  display: flex;
  font: 16px/20px 'Circular Std', sans-serif;
  -webkit-flex-direction: column;
          flex-direction: column; }
  .body p {
    margin-bottom: 0;
    margin-top: 16px;
    font-weight: 100; }
    .body p:first-child {
      margin-top: 0px; }
    .body p:last-child {
      margin-bottom: 24px; }

.page_content {
  color: #252525;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin: 0 max(25px, min(5%, 25px));
  margin: 0 var(--page-margin);
  overflow: hidden; }
  @supports not (margin: var(--page-margin)) {
    .page_content {
      margin: 0 16px; } }
  @media screen and (max-width: 768px) {
    .page_content {
      margin: 0; } }

.contact_now_button {
  font-family: "Circular Std", "Circular Std";
  background: #873bff;
  border: none;
  outline: none;
  color: white;
  padding: 14px;
  width: 30%;
  border-radius: 6px;
  margin-bottom: 15px; }
  @media screen and (max-width: 768px) {
    .contact_now_button {
      width: 100%; } }

.contact_now_button_wrapper {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  -webkit-justify-content: flex-end;
          justify-content: flex-end; }

.tv-reportquality {
  text-align: left; }
  .tv-reportquality__container {
    width: 100%; }
  .tv-reportquality__wrapper {
    background-color: #f7f9ff; }
  .tv-reportquality__btn-filter {
    font-family: "Circular Std", "Circular Std";
    display: block;
    background-color: #c2c7ce;
    border: 1px solid #c2c7ce;
    border-radius: 8px;
    padding: 15px;
    width: 90%;
    margin: 10px; }
  .tv-reportquality-sendbutton {
    margin: 10px 15px 10px 10px;
    padding: 16px;
    width: 100%;
    border: none;
    background: #c4c4c4;
    border-radius: 4px;
    font-weight: 600; }
  .tv-reportquality__list__mobile-fixed-bottom {
    display: none; }
  .tv-reportquality__list-item {
    cursor: pointer;
    margin: 10px 15px 10px 10px;
    padding: 18px 0;
    font-family: 'PT Serif', serif;
    z-index: 10;
    background: #f4f6f9;
    border-radius: 8px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    width: 100%; }
    sales .tv-reportquality__list-item-child {
      margin: 0 13px;
      box-shadow: inset 0 10px 18px -12px rgba(58, 68, 131, 0.2), 0 10px 18px -12px rgba(58, 68, 131, 0.2); }
  .tv-reportquality__item-status {
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center; }
    .tv-reportquality__item-status--1 {
      background-color: #ffa500 !important; }
    .tv-reportquality__item-status--2 {
      background-color: #007bff !important; }
    .tv-reportquality__item-status--3 {
      background-color: #008000 !important; }
  .tv-reportquality__item-icon {
    padding: 15px 40px; }
    .tv-reportquality__item-icon .icon:before {
      color: black; }
  .tv-reportquality__item-more_button {
    outline: none;
    border: none;
    background: none;
    text-transform: uppercase;
    font-size: 14px; }
    .tv-reportquality__item-more_button:after {
      content: '\e931';
      font-family: 'transvoice-icon';
      -webkit-font-smoothing: antialiased;
      text-rendering: optimizeLegibility;
      -moz-osx-font-smoothing: grayscale;
      font-feature-settings: 'liga';
      color: #c5c5c5;
      margin-left: 4px;
      font-size: 10px;
      color: #000; }
  .tv-reportquality__item-menu {
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center; }
    .tv-reportquality__item-menu-button:before {
      font-family: 'transvoice-icon';
      -webkit-font-smoothing: antialiased;
      text-rendering: optimizeLegibility;
      -moz-osx-font-smoothing: grayscale;
      font-feature-settings: 'liga';
      font-size: 1.5rem; }
  .tv-reportquality__item-label {
    font-size: 0.975rem;
    color: #000;
    margin: 0 0 11px 0;
    font-family: "Circular Std", "Circular Std"; }
  .tv-reportquality__item-text {
    color: #313131;
    font-size: 14px;
    font-weight: 300;
    font-family: "Circular Std", "Circular Std"; }
    .tv-reportquality__item-text-available-until {
      font-weight: bold;
      color: #873bff; }
  .tv-reportquality__status {
    font: 12px "Circular Std", "Circular Std";
    color: #fff;
    border-radius: 7px;
    text-transform: uppercase;
    padding: 12px 22px;
    letter-spacing: 0.5px; }
    .tv-reportquality__status--default {
      background: #414141; }
  .tv-reportquality-drawer__container {
    font-family: "Circular Std", "Circular Std";
    left: 0;
    top: 0;
    width: calc(100% - 10px);
    box-shadow: 0 10px 18px -12px rgba(58, 68, 131, 0.04);
    box-sizing: border-box;
    border-radius: 8px;
    padding: 5px 10px 20px;
    border: 1px solid #ebebeb; }
  .tv-reportquality-drawer-back {
    margin-bottom: 20px; }
    .tv-reportquality-drawer-back-btn:before {
      margin: 0;
      color: black;
      font-size: 23px; }
  .tv-reportquality-drawer-matter {
    font-weight: 600; }
    .tv-reportquality-drawer-matter hr {
      margin-top: 5px; }
  .tv-reportquality-drawer-mobile-only {
    display: none; }
  .tv-reportquality-drawer_body {
    border-top: 1px solid #ebebeb;
    padding-top: 16px;
    width: 100%; }
  .tv-reportquality-drawer-btn {
    margin-top: 5px;
    text-align: center; }
  .tv-reportquality-drawer-content {
    font-size: 14px;
    padding: 10px 14px; }
  .tv-reportquality-drawer-value {
    font-size: 14px;
    font-weight: 200;
    margin-top: 5px; }
  .tv-reportquality-drawer_title {
    padding: 10px 0;
    text-align: center;
    font-weight: 600; }
  .tv-reportquality__modal {
    top: 0 !important;
    left: -11px !important;
    width: 100vw !important;
    height: 100vh !important;
    position: absolute; }
    .tv-reportquality__modal .tv-modal__overlay {
      top: -175px !important;
      left: -10px !important;
      width: 100vw !important;
      height: unset; }
    .tv-reportquality__modal .tv-modal__holder {
      border-radius: 8px;
      min-width: 35vw;
      max-width: 620px;
      background-color: #fff; }
  .tv-reportquality-form {
    width: 100%; }
    .tv-reportquality-form__paragraph {
      padding: 7px 15px 2px; }
    .tv-reportquality-form__label {
      font-weight: 500;
      font-family: "Circular Std", "Circular Std"; }
    .tv-reportquality-form__textfield {
      padding: 14px 20px; }
    .tv-reportquality-form__dropdownlist {
      padding: 7px 13px 5px 13px; }
    .tv-reportquality-form__textarea {
      height: 130px; }
    .tv-reportquality-form__buttons {
      margin: 0 16px 20px; }
    .tv-reportquality-form__cancel {
      border-radius: 6px;
      background: none;
      color: #000000;
      font-weight: 500;
      border: 2px solid #c4c4c4;
      font-weight: 500; }
    .tv-reportquality-form__submit {
      border: unset;
      border-radius: 6px;
      width: 100%;
      padding: 10px;
      color: #252733;
      background: #c2c7ce;
      font-weight: 500; }
    .tv-reportquality-form-mobiletitle {
      text-align: center;
      font-weight: 600;
      font-size: 35px;
      font-family: "Circular Std", "Circular Std"; }

@media screen and (max-width: 768px) {
  .tv-reportquality__item-icon {
    padding: 0px 12px;
    height: 24px;
    -webkit-order: 1;
            order: 1; }
    .tv-reportquality__item-icon .icon {
      font-size: 24px !important; }
  .tv-reportquality__item-status {
    -webkit-order: 4;
            order: 4;
    max-width: unset;
    padding-right: 5px; }
  .tv-reportquality__item-order {
    -webkit-order: 2;
            order: 2;
    max-width: unset;
    padding: 5px; }
    .tv-reportquality__item-order .tv-workassignment__item-text {
      font-weight: 600 !important; }
  .tv-reportquality__item-name {
    -webkit-order: 4;
            order: 4;
    max-width: unset;
    margin-top: 10px;
    padding-right: 0; }
  .tv-reportquality__item-category {
    -webkit-order: 5;
            order: 5;
    max-width: unset;
    margin-top: 10px; }
    .tv-reportquality__item-category .tv-workassignment__item-text {
      max-width: 80px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden; }
  .tv-reportquality__item-datetime {
    -webkit-order: 1;
            order: 1;
    max-width: unset;
    -webkit-flex: 0 0 25%;
            flex: 0 0 25%;
    margin-top: 4px; }
  .tv-reportquality__item-type {
    -webkit-order: 6;
            order: 6;
    max-width: unset; }
  .tv-reportquality__item-label {
    margin: 0 0 6px 0; }
  .tv-reportquality__item .list-small-rating > label:before {
    font-size: 12px !important; }
  .tv-reportquality__list {
    height: calc(100vh - 200px);
    padding-bottom: 70px;
    overflow: hidden; }
    .tv-reportquality__list__mobile-fixed-bottom {
      position: fixed;
      background-color: #fff;
      text-align: center;
      display: block;
      left: 0;
      bottom: 0;
      width: 100vw; }
    .tv-reportquality__list-item {
      margin: 10px 15px 10px 2px !important; }
  .tv-reportquality-drawer__container {
    width: 100%;
    margin: auto;
    position: fixed;
    background: unset;
    border: unset; }
    .tv-reportquality-drawer__container .tk-dialog__btn-close {
      position: absolute;
      right: 0;
      top: 0;
      padding: 18px !important;
      height: 56px;
      z-index: 4; }
      .tv-reportquality-drawer__container .tk-dialog__btn-close::after {
        font-size: 15px; }
  .tv-reportquality-drawer-area {
    border: 1px solid #ebebeb;
    background: unset;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px; }
  .tv-reportquality-drawer-mobile-only {
    display: block; }
  .tv-reportquality__filter-date-range__content {
    position: fixed;
    left: 35px;
    border-radius: 8px; }
  .tv-reportquality__item-order .tv-reportquality__item-label, .tv-reportquality__item-datetime .tv-reportquality__item-label, .tv-reportquality__item-name .tv-reportquality__item-label, .tv-reportquality__item-category .tv-reportquality__item-label {
    font-size: 13px; }
  .tv-reportquality__status {
    padding: 9px 3px;
    letter-spacing: 0.5px;
    font-size: 9px; } }

.tv-form__control--error {
  margin-bottom: 0;
  border: 2px solid red !important; }

.tv-form__error:empty {
  display: none; }

textarea.tv-single-assign-form__control {
  height: auto !important;
  padding: 16px !important;
  max-height: 120px !important;
  min-height: 48px; }

.tv-deviation-message {
  text-align: center; }
  .tv-deviation-message-title {
    font: 500 32px/20px "Circular Std", "Circular Std";
    padding: 20px; }
  .tv-deviation-message-paragraph {
    font: 400 18px/23px "Circular Std", "Circular Std";
    color: #74737B;
    padding: 15px; }
  .tv-deviation-message-button {
    margin: 10px auto;
    padding: 16px;
    margin-bottom: 24px;
    width: 250px;
    border: none;
    font: 500 16px/20px "Circular Std", "Circular Std";
    background: #873bff;
    border-radius: 4px;
    color: #FFF; }

.star-group span {
  -webkit-text-stroke: 1.5px #ffb074; }

@font-face {
  font-family: 'Circular Std';
  src: url("/fonts/Circular Font/CircularStd-Medium.otf");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'Circular Std';
  src: url("/fonts/Circular Font/CircularStd-Book.otf");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'transvoice-icon';
  src: url("/fonts/transvoice-icon/transvoice-icon.eot?ysq9j9");
  src: url("/fonts/transvoice-icon/transvoice-icon.eot?ysq9j9#iefix") format("embedded-opentype"), url("/fonts/transvoice-icon/transvoice-icon.ttf?ysq9j9") format("truetype"), url("/fonts/transvoice-icon/transvoice-icon.woff?ysq9j9") format("woff"), url("/fonts/transvoice-icon/transvoice-icon.svg?ysq9j9#transvoice-icon") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^='icon-']:before,
[class*=' icon-']:before,
[class^='icon-']:after,
[class*=' icon-']:after {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'transvoice-icon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-filter:before {
  content: '\e900';
  color: #c5c5c5; }

.icon-your-compensation:before {
  content: '\e901';
  color: #c5c5c5; }

.icon-warning:before {
  content: '\e902';
  color: #c0c2c3; }

.icon-waiting-to-be-submitted:before {
  content: '\e903';
  color: #fff; }

.icon-trophy:before {
  content: '\e904';
  color: #bdbdbd; }

.icon-timer:before {
  content: '\e905';
  color: #c5c5c5; }

.icon-the-envelope:before {
  content: '\e906';
  color: #c5c5c5; }

.icon-task:before {
  content: '\e907';
  color: #c5c5c5; }

.icon-submit-time:before {
  content: '\e908';
  color: #c5c5c5; }

.icon-small:before {
  content: '\e909';
  color: #bdbdbd; }

.icon-share:before {
  content: '\e90a';
  color: #c5c5c5; }

.icon-search:before {
  content: '\e90b'; }

.icon-schedule:before {
  content: '\e90c';
  color: #bdbdbd; }

.icon-report-quality:before {
  content: '\e90d';
  color: #c5c5c5; }

.icon-question:before {
  content: '\e90e';
  color: #c0c2c3; }

.icon-profile:before {
  content: '\e90f';
  color: #c5c5c5; }

.icon-pointer:before {
  content: '\e910';
  color: #c5c5c5; }

.icon-photo:before {
  content: '\e911';
  color: #9e9e9e; }

.icon-news:before {
  content: '\e912';
  color: #c5c5c5; }

.icon-lock:before {
  content: '\e913';
  color: #c0c2c3; }

.icon-interprete-now:before {
  content: '\e914';
  color: #c5c5c5; }

.icon-interpret-now-2:before {
  content: '\e915';
  color: #fff; }

.icon-user:before {
  content: '\e916';
  color: #bdbdbd; }

.icon-hourglass:before {
  content: '\e917';
  color: #c9c9c9; }

.icon-home:before {
  content: '\e918';
  color: #c5c5c5; }

.icon-handset:before {
  content: '\e919';
  color: #c5c5c5; }

.icon-handset-2:before {
  content: '\e91a';
  color: #c5c5c5; }

.icon-flag:before {
  content: '\e91b';
  color: #bdbdbd; }

.icon-fire:before {
  content: '\e91c';
  color: #fff; }

.icon-drawing-pin:before {
  content: '\e91d';
  color: #bdbdbd; }

.icon-download-to-cloud:before {
  content: '\e91e';
  color: #c0c2c3; }

.icon-dollar:before {
  content: '\e91f';
  color: #fff; }

.icon-dollar-2:before {
  content: '\e920';
  color: #d1d1d1; }

.icon-create-new:before {
  content: '\e921';
  color: #fff; }

.icon-contact:before {
  content: '\e922';
  color: #c5c5c5; }

.icon-contact-2:before {
  content: '\e923';
  color: #c5c5c5; }

.icon-comment:before {
  content: '\e924';
  color: #c0c2c3; }

.icon-comment-2:before {
  content: '\e925';
  color: #fff; }

.icon-close:before {
  content: '\e926';
  color: #313131; }

.icon-next:after {
  content: '\e931';
  color: #313131; }

.icon-previous:before {
  content: '\e932';
  color: #313131; }

.icon-certificate:before {
  content: '\e927';
  color: #bdbdbd; }

.icon-camera:before {
  content: '\e928';
  color: #20175b; }

.icon-calendar:before {
  content: '\e929';
  color: #a5a4a4; }

.icon-browse-assignments:before {
  content: '\e92a';
  color: #c5c5c5; }

.icon-book:before {
  content: '\e92b';
  color: #c5c5c5; }

.icon-book-2:before {
  content: '\e92c';
  color: #c0c2c3; }

.icon-bell:before {
  content: '\e92d';
  color: #c5c5c5; }

.icon-available-request:before {
  content: '\e92e';
  color: #c5c5c5; }

.icon-available-request-2:before {
  content: '\e92f';
  color: #fff; }

.icon-attach:before {
  content: '\e930';
  color: #9e9e9e; }

.icon-arrow-more:before {
  content: '\e931';
  color: #c5c5c5; }

.icon-arrow-more-2:before {
  content: '\e932';
  color: #c5c5c5; }

.icon-arrow-expand:before {
  content: '\e933'; }

.icon-arrow-down:after {
  content: '\e933'; }

.icon-arrow-top:after {
  content: '\e931';
  display: inline-block;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg); }

.icon-arrow-back:before {
  content: '\e934'; }

.icon-settings:before {
  content: '\e935';
  color: #c5c5c5; }

.icon-envelope:before {
  content: '\e936';
  color: #c5c5c5; }

.icon-log-out:before {
  content: '\e937';
  color: #20175b; }

.icon-more:before {
  content: '\e938';
  color: #c5c5c5; }

.icon-check:before {
  content: '\e939';
  color: #c5c5c5; }

.icon-doc:before {
  content: '\e93a';
  color: #c5c5c5; }

.icon-pdf:before {
  content: '\e93b';
  color: #c5c5c5; }

.icon-download-file:before {
  content: '\e93c';
  color: #c5c5c5; }

.icon-upload-to-cloud:before {
  content: '\e93d';
  color: #c5c5c5; }

.icon-single:before {
  content: '\e93e';
  color: #c5c5c5; }

.icon-multi:before {
  content: '\e93f';
  color: #c5c5c5; }

.icon-image-file:before {
  content: '\e940';
  color: #c5c5c5; }

.icon-mobile-phone:before {
  content: '\e941';
  color: #c5c5c5; }

.icon-customer-order-number:before {
  content: '\e942';
  color: #c5c5c5; }

.icon-edit:before {
  content: '\2639';
  color: '#000'; }

.icon-task-list:before {
  content: '\2630';
  color: #c5c5c5; }

.feedBack {
  padding: 10px 34px;
  width: 800px;
  background-color: #fff;
  margin: 0 auto 10px; }
  .feedBack__modal .tv-modal__holder {
    min-width: 672px; }
  .feedBack input:disabled {
    background: #fff; }
  .feedBack select:disabled {
    color: #a9a9a9; }
  .feedBack__rating {
    min-height: 150px;
    background-color: #f7f7f7;
    text-align: center;
    margin: 48px 0 36px;
    padding: 15px 32px 5px; }
    .feedBack__rating-text {
      color: #a9a9a9;
      font-family: "Circular Std", "Circular Std";
      font-size: 14px;
      font-size: 0.875rem;
      letter-spacing: 0.3px;
      line-height: 1.25rem; }
  .feedBack .form-wrapper {
    padding: 0px; }
  .feedBack .tv-single-assign-form__control {
    margin-bottom: 24px; }
  .feedBack textarea.tv-single-assign-form__control {
    height: auto !important;
    padding: 16px !important;
    max-height: 120px !important;
    min-height: 48px; }
  .feedBack .tv-single-assign-form__input-arrow {
    position: relative;
    margin-top: 5px; }
  .feedBack .tv-single-assign-form__textfield {
    padding: 16px 20px; }
    .feedBack .tv-single-assign-form__textfield:disabled {
      color: #a9a9a9; }
  .feedBack .tv-single-assign-form__label {
    margin: 0 0 0 0;
    font-size: 15px;
    font-weight: 400; }
    .feedBack .tv-single-assign-form__label.icon-calendar > div {
      display: inline-block;
      padding-left: 10px; }
    .feedBack .tv-single-assign-form__label.icon-submit-time > div {
      display: inline-block;
      padding-left: 10px; }
  .feedBack .tv-single-assign-form__input {
    margin-left: 0; }
  .feedBack_modal .tv-modal__holder {
    text-align: center;
    padding: 20px 90px 30px; }
    .feedBack_modal .tv-modal__holder .image-upload > input {
      display: none; }
    .feedBack_modal .tv-modal__holder .image-upload .icon-upload-to-cloud::before {
      color: #873bff;
      font-size: 22px;
      font-size: 1.375rem; }
    .feedBack_modal .tv-modal__holder .image-upload__title {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 1.5rem;
      color: #873bff;
      margin: 5px; }
    .feedBack_modal .tv-modal__holder .image-preview {
      width: 120px;
      border: 1px solid #979797;
      border-radius: 2px;
      margin: 20px auto 0;
      position: relative; }
      .feedBack_modal .tv-modal__holder .image-preview img {
        max-width: 100%;
        height: auto;
        display: block;
        margin: 0 auto; }
      .feedBack_modal .tv-modal__holder .image-preview i.icon-close {
        cursor: pointer;
        position: absolute;
        top: -12px;
        right: -12px;
        background-color: #873bff;
        width: 24px;
        border-radius: 50%;
        height: 24px; }
        .feedBack_modal .tv-modal__holder .image-preview i.icon-close::before {
          color: white;
          font-size: 12px;
          font-size: 0.75rem; }
    .feedBack_modal .tv-modal__holder .icon-upload-to-cloud {
      cursor: pointer;
      border: 1px solid #873bff;
      border-radius: 50%;
      display: inline-block;
      padding-top: 5px;
      margin-top: 20px;
      width: 40px;
      height: 40px; }
      .feedBack_modal .tv-modal__holder .icon-upload-to-cloud [class*='path']::before {
        /* use !important to prevent issues with browser extensions that change fonts */
        font-family: 'transvoice-icon' !important;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-feature-settings: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        /* Better Font Rendering =========== */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 24px;
        font-size: 1.5rem; }
    .feedBack_modal .tv-modal__holder .path1::before {
      color: #873bff; }
  .feedBack_modal__header {
    color: #313131;
    font: 1.125rem/1.75rem "Circular Std", "Circular Std";
    font-weight: 300;
    letter-spacing: -0.2px; }
  .feedBack .input-group {
    min-width: 275px;
    padding: 28px 0 24px; }
    .feedBack .input-group.view-data {
      padding: 0;
      min-width: 0; }
    .feedBack .input-group__label {
      margin-right: -1px;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      padding: 8px 16px;
      margin-bottom: 0;
      font-size: 1rem;
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 1.5rem;
      color: #313131;
      text-align: center;
      white-space: nowrap;
      border: 1px solid #e8e8e8;
      border-radius: 0.25rem;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .feedBack .input-group__value {
      min-width: 100px;
      border: 1px solid #e8e8e8;
      border-radius: 4px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      margin-bottom: 0;
      padding: 8px 16px;
      color: #969696;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
    .feedBack .input-group__textfield {
      border: 1px solid #e8e8e8;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      margin-bottom: 0;
      padding: 8px 16px;
      color: #873bff; }
  @media (max-width: 768px) {
    .feedBack__modal .tv-modal__holder {
      width: calc(100vw);
      min-width: 100vw !important;
      height: 100vh;
      max-height: 100vh;
      top: 0;
      border-radius: 0; }
      .feedBack__modal .tv-modal__holder button.tv-rate-submit-btn {
        width: 100%; }
      .feedBack__modal .tv-modal__holder-content .detailed-rating > label:before {
        font-size: 1.5rem !important; } }
  .feedBack-header {
    display: -webkit-flex;
    display: flex;
    width: 100%;
    -webkit-justify-content: center;
            justify-content: center;
    font-family: "Circular Std", "Circular Std";
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 20px;
    margin-top: 48px;
    color: #252525; }
  .feedBack-content {
    display: -webkit-flex;
    display: flex;
    width: 100%;
    -webkit-justify-content: center;
            justify-content: center;
    font-family: "Circular Std", "Circular Std";
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    padding-top: 20px;
    color: #74737b; }
  .feedBack .rating-row {
    -webkit-justify-content: center;
            justify-content: center; }
  .feedBack .star-group {
    display: -webkit-flex;
    display: flex;
    margin: 40px 0 32px 0;
    -webkit-justify-content: center;
            justify-content: center; }
    .feedBack .star-group .react-stars span {
      margin: 0 13px; }
  .feedBack .rating-label {
    margin: 5px 0px;
    font: 400 16px/20px 'Circular Std', sans-serif; }
  .feedBack .redirect-checkbox {
    padding: 0px 5px; }
  .feedBack .redirect-checkbox label {
    font: 400 16px/20px 'Circular Std', sans-serif; }
  .feedBack .tv-single-assign-form__dropdownlist {
    padding: 10px 10px !important;
    background-image: linear-gradient(45deg, transparent 50%, #696767 50%), linear-gradient(135deg, #696767 50%, transparent 50%), linear-gradient(to right, white, white) !important;
    background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px), 100% 0 !important;
    background-size: 5px 5px, 5px 5px, 2.5em 2.5em !important;
    background-repeat: no-repeat !important; }
    .feedBack .tv-single-assign-form__dropdownlist:after {
      content: '\e933';
      position: absolute;
      font-family: 'transvoice-icon';
      top: 12px;
      left: 2px; }
  .feedBack .comment-text {
    font-style: italic;
    font-size: 0.875rem;
    color: #313131 !important;
    padding: 2px 6px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row; }
  .feedBack .label-height {
    height: 8px; }
  .feedBack .field-wrapper {
    margin-top: 10px; }
  .feedBack .tv-form__control--error {
    margin-bottom: 0;
    border: 2px solid red !important; }
  .feedBack .feedback-create-deviation {
    color: #873bff;
    font-family: "Circular Std", "Circular Std";
    font-size: 16px;
    line-height: 23px;
    cursor: pointer; }
    .feedBack .feedback-create-deviation:hover {
      text-decoration: underline; }

@font-face {
  font-family: 'Circular Std';
  src: url("/fonts/Circular Font/CircularStd-Medium.otf");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'Circular Std';
  src: url("/fonts/Circular Font/CircularStd-Book.otf");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'transvoice-icon';
  src: url("/fonts/transvoice-icon/transvoice-icon.eot?ysq9j9");
  src: url("/fonts/transvoice-icon/transvoice-icon.eot?ysq9j9#iefix") format("embedded-opentype"), url("/fonts/transvoice-icon/transvoice-icon.ttf?ysq9j9") format("truetype"), url("/fonts/transvoice-icon/transvoice-icon.woff?ysq9j9") format("woff"), url("/fonts/transvoice-icon/transvoice-icon.svg?ysq9j9#transvoice-icon") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^='icon-']:before,
[class*=' icon-']:before,
[class^='icon-']:after,
[class*=' icon-']:after {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'transvoice-icon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-filter:before {
  content: '\e900';
  color: #c5c5c5; }

.icon-your-compensation:before {
  content: '\e901';
  color: #c5c5c5; }

.icon-warning:before {
  content: '\e902';
  color: #c0c2c3; }

.icon-waiting-to-be-submitted:before {
  content: '\e903';
  color: #fff; }

.icon-trophy:before {
  content: '\e904';
  color: #bdbdbd; }

.icon-timer:before {
  content: '\e905';
  color: #c5c5c5; }

.icon-the-envelope:before {
  content: '\e906';
  color: #c5c5c5; }

.icon-task:before {
  content: '\e907';
  color: #c5c5c5; }

.icon-submit-time:before {
  content: '\e908';
  color: #c5c5c5; }

.icon-small:before {
  content: '\e909';
  color: #bdbdbd; }

.icon-share:before {
  content: '\e90a';
  color: #c5c5c5; }

.icon-search:before {
  content: '\e90b'; }

.icon-schedule:before {
  content: '\e90c';
  color: #bdbdbd; }

.icon-report-quality:before {
  content: '\e90d';
  color: #c5c5c5; }

.icon-question:before {
  content: '\e90e';
  color: #c0c2c3; }

.icon-profile:before {
  content: '\e90f';
  color: #c5c5c5; }

.icon-pointer:before {
  content: '\e910';
  color: #c5c5c5; }

.icon-photo:before {
  content: '\e911';
  color: #9e9e9e; }

.icon-news:before {
  content: '\e912';
  color: #c5c5c5; }

.icon-lock:before {
  content: '\e913';
  color: #c0c2c3; }

.icon-interprete-now:before {
  content: '\e914';
  color: #c5c5c5; }

.icon-interpret-now-2:before {
  content: '\e915';
  color: #fff; }

.icon-user:before {
  content: '\e916';
  color: #bdbdbd; }

.icon-hourglass:before {
  content: '\e917';
  color: #c9c9c9; }

.icon-home:before {
  content: '\e918';
  color: #c5c5c5; }

.icon-handset:before {
  content: '\e919';
  color: #c5c5c5; }

.icon-handset-2:before {
  content: '\e91a';
  color: #c5c5c5; }

.icon-flag:before {
  content: '\e91b';
  color: #bdbdbd; }

.icon-fire:before {
  content: '\e91c';
  color: #fff; }

.icon-drawing-pin:before {
  content: '\e91d';
  color: #bdbdbd; }

.icon-download-to-cloud:before {
  content: '\e91e';
  color: #c0c2c3; }

.icon-dollar:before {
  content: '\e91f';
  color: #fff; }

.icon-dollar-2:before {
  content: '\e920';
  color: #d1d1d1; }

.icon-create-new:before {
  content: '\e921';
  color: #fff; }

.icon-contact:before {
  content: '\e922';
  color: #c5c5c5; }

.icon-contact-2:before {
  content: '\e923';
  color: #c5c5c5; }

.icon-comment:before {
  content: '\e924';
  color: #c0c2c3; }

.icon-comment-2:before {
  content: '\e925';
  color: #fff; }

.icon-close:before {
  content: '\e926';
  color: #313131; }

.icon-next:after {
  content: '\e931';
  color: #313131; }

.icon-previous:before {
  content: '\e932';
  color: #313131; }

.icon-certificate:before {
  content: '\e927';
  color: #bdbdbd; }

.icon-camera:before {
  content: '\e928';
  color: #20175b; }

.icon-calendar:before {
  content: '\e929';
  color: #a5a4a4; }

.icon-browse-assignments:before {
  content: '\e92a';
  color: #c5c5c5; }

.icon-book:before {
  content: '\e92b';
  color: #c5c5c5; }

.icon-book-2:before {
  content: '\e92c';
  color: #c0c2c3; }

.icon-bell:before {
  content: '\e92d';
  color: #c5c5c5; }

.icon-available-request:before {
  content: '\e92e';
  color: #c5c5c5; }

.icon-available-request-2:before {
  content: '\e92f';
  color: #fff; }

.icon-attach:before {
  content: '\e930';
  color: #9e9e9e; }

.icon-arrow-more:before {
  content: '\e931';
  color: #c5c5c5; }

.icon-arrow-more-2:before {
  content: '\e932';
  color: #c5c5c5; }

.icon-arrow-expand:before {
  content: '\e933'; }

.icon-arrow-down:after {
  content: '\e933'; }

.icon-arrow-top:after {
  content: '\e931';
  display: inline-block;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg); }

.icon-arrow-back:before {
  content: '\e934'; }

.icon-settings:before {
  content: '\e935';
  color: #c5c5c5; }

.icon-envelope:before {
  content: '\e936';
  color: #c5c5c5; }

.icon-log-out:before {
  content: '\e937';
  color: #20175b; }

.icon-more:before {
  content: '\e938';
  color: #c5c5c5; }

.icon-check:before {
  content: '\e939';
  color: #c5c5c5; }

.icon-doc:before {
  content: '\e93a';
  color: #c5c5c5; }

.icon-pdf:before {
  content: '\e93b';
  color: #c5c5c5; }

.icon-download-file:before {
  content: '\e93c';
  color: #c5c5c5; }

.icon-upload-to-cloud:before {
  content: '\e93d';
  color: #c5c5c5; }

.icon-single:before {
  content: '\e93e';
  color: #c5c5c5; }

.icon-multi:before {
  content: '\e93f';
  color: #c5c5c5; }

.icon-image-file:before {
  content: '\e940';
  color: #c5c5c5; }

.icon-mobile-phone:before {
  content: '\e941';
  color: #c5c5c5; }

.icon-customer-order-number:before {
  content: '\e942';
  color: #c5c5c5; }

.icon-edit:before {
  content: '\2639';
  color: '#000'; }

.icon-task-list:before {
  content: '\2630';
  color: #c5c5c5; }

.timesheet {
  padding: 0 32px 24px 32px;
  width: 100%;
  background-color: #fff;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }
  .timesheet__modal .tv-modal__holder {
    min-width: 672px;
    letter-spacing: 0.1px; }
    .timesheet__modal .tv-modal__holder h6 {
      letter-spacing: 0.1px; }
    .timesheet__modal .tv-modal__holder .timesheet__travel_time:nth-child(2) {
      margin-left: 32px; }
  .timesheet-button_cancel {
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    width: 100%;
    height: 48px;
    font: 400 16px/20px "Circular Std", "Circular Std";
    padding: 10px;
    background: #fff;
    margin: 20px 0 2px; }
  .timesheet-button_submit {
    border: none;
    font: 400 16px/20px "Circular Std", "Circular Std";
    border-radius: 4px;
    width: 100%;
    height: 48px;
    padding: 10px;
    color: #fff;
    background-color: #873bff;
    margin: 20px 0 2px; }
  .timesheet__divider {
    border-top: 1px solid #e0e0e0;
    margin-top: 8px; }
  .timesheet__attached-file__container {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    font-size: 14px;
    margin-top: 16px;
    margin-right: 32px; }
    .timesheet__attached-file__container i {
      cursor: pointer; }
  .timesheet__attached-file__amount {
    min-width: 50px; }
  .timesheet__attached-file__content {
    display: -webkit-flex;
    display: flex; }
  .timesheet__attached-file__name {
    width: 90px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .timesheet input:disabled {
    background: #fff; }
  .timesheet select:disabled {
    color: #a9a9a9; }
  .timesheet__header {
    font-family: "Circular Std", "Circular Std"; }
    .timesheet__header-title {
      background-color: #873bff;
      border-radius: 4px 4px 0 0;
      color: #fff;
      font-size: 24px;
      font-size: 1.5rem;
      line-height: 2.1875rem;
      padding: 16px 24px; }
    .timesheet__header-content {
      padding: 24px 0;
      font-weight: 300;
      letter-spacing: -0.5px;
      border-bottom: 1px solid #e0e0e0; }
      .timesheet__header-content .redirect-checkbox label {
        padding-left: 15px;
        font: 400 16px/20px "Circular Std", "Circular Std" !important; }
      .timesheet__header-content .tv-single-assign-form__control {
        height: 48px;
        margin-top: 8px;
        margin-bottom: 16px; }
        .timesheet__header-content .tv-single-assign-form__control .k-input {
          padding: 16px 14px;
          font: 400 16px/20px "Circular Std", "Circular Std" !important;
          height: 48px;
          -webkit-align-content: center;
                  align-content: center;
          display: -webkit-flex;
          display: flex; }
      .timesheet__header-content textarea.tv-single-assign-form__control {
        margin-top: 8px;
        height: 70px !important; }
      .timesheet__header-content .tv-rate-submit-btn {
        width: 100px;
        font: 400 16px/20px "Circular Std", "Circular Std" !important;
        color: #fff;
        background-color: #873bff;
        padding: 8px !important;
        height: 40px; }
      .timesheet__header-content .rating-label {
        font: 400 16px/20px "Circular Std", "Circular Std" !important;
        margin-bottom: 8px;
        margin-top: 16px; }
      .timesheet__header-content button {
        color: #000;
        background: #c4c4c4;
        border: #c4c4c4;
        font-weight: 500;
        font-size: 15px !important; }
      .timesheet__header-content-id {
        color: #000;
        font: 400 18px/23px "Circular Std", "Circular Std";
        margin: 0px 10px 18px 0;
        float: left; }
      .timesheet__header-content-type {
        color: #252525;
        font: 400 14px/18px "Circular Std", "Circular Std";
        margin: 0;
        text-align: left; }
      .timesheet__header-content-date {
        color: #a9a9a9;
        font: 0.875rem/1.25rem "Circular Std", "Circular Std";
        letter-spacing: 0.3px;
        margin: 10px 0 0 0;
        text-align: left; }
        .timesheet__header-content-date i {
          margin-right: 15px; }
    .timesheet__header-rating {
      margin-top: -3px;
      background: white;
      border-radius: 12px; }
      .timesheet__header-rating.rating > label {
        padding-right: 2px !important; }
  .timesheet__travel_time {
    padding: 16px 0px 0px;
    font-family: "Circular Std", "Circular Std";
    margin-top: 0px;
    text-align: left;
    border-radius: 4px;
    width: 100%; }
    .timesheet__travel_time .read-only-data {
      margin: 0;
      -webkit-flex-direction: column;
              flex-direction: column;
      display: -webkit-flex;
      display: flex; }
      .timesheet__travel_time .read-only-data > div {
        -webkit-align-items: center;
                align-items: center;
        margin-bottom: 16px; }
      .timesheet__travel_time .read-only-data label {
        font: 300 14px/18px "Circular Std", "Circular Std" !important;
        margin-bottom: 0px !important; }
      .timesheet__travel_time .read-only-data span {
        font: 300 14px/18px "Circular Std", "Circular Std" !important; }
    .timesheet__travel_time .tv-form__date-field {
      padding: 11px 8px !important; }
      .timesheet__travel_time .tv-form__date-field:after {
        top: 13px !important;
        right: 11px !important; }
    .timesheet__travel_time.travel-home {
      background-color: #f4f4f4;
      padding: 20px 32px;
      border: 1px solid #969696; }
    .timesheet__travel_time__box {
      padding: 10px 0; }
      .timesheet__travel_time__box .tv-col-6 {
        padding-top: 20px; }
      .timesheet__travel_time__box__content {
        width: 100%;
        border: 1px solid #969696;
        border-radius: 5px;
        padding: 20px; }
        .timesheet__travel_time__box__content-file-name {
          padding-top: 12px;
          color: #873bff;
          font-size: 14px;
          font-size: 0.875rem;
          line-height: 1.125rem;
          padding: 10px 0; }
          .timesheet__travel_time__box__content-file-name span {
            padding-left: 10px; }
        .timesheet__travel_time__box__content .view-data {
          -webkit-flex-grow: 1;
                  flex-grow: 1; }
        .timesheet__travel_time__box__content-remove {
          color: #873bff;
          font-size: 14px;
          font-size: 0.875rem;
          display: -webkit-flex;
          display: flex;
          min-width: 90px;
          position: relative;
          cursor: pointer; }
          .timesheet__travel_time__box__content-remove i {
            margin: auto;
            cursor: pointer;
            position: absolute;
            background-color: #873bff;
            width: 16px;
            border-radius: 50%;
            height: 16px;
            top: 50%;
            -webkit-transform: translate(50%, -50%);
                    transform: translate(50%, -50%); }
            .timesheet__travel_time__box__content-remove i::before {
              color: #fff;
              padding: 4px;
              display: block;
              font-size: 8px;
              font-size: 0.5rem; }
          .timesheet__travel_time__box__content-remove .title-close {
            padding-left: 20px;
            margin: auto; }
    .timesheet__travel_time-rating-phone {
      margin-left: auto; }
      .timesheet__travel_time-rating-phone .timesheet__rating-text {
        margin: auto;
        padding-right: 10px; }
      .timesheet__travel_time-rating-phone .rating {
        border: none;
        display: inline-block; }
        .timesheet__travel_time-rating-phone .rating > input {
          display: none; }
        .timesheet__travel_time-rating-phone .rating > label {
          padding-right: 2px; }
          .timesheet__travel_time-rating-phone .rating > label:before {
            font-size: 24px;
            font-size: 1.5rem; }
    .timesheet__travel_time-title {
      font: 1.125rem/1.75rem "Circular Std", "Circular Std";
      letter-spacing: -0.2px;
      font-weight: 300;
      color: #000;
      line-height: 2.2; }
    .timesheet__travel_time-content {
      margin-top: 0; }
      .timesheet__travel_time-content .tv-form__date-field {
        padding: 11px 8px !important; }
        .timesheet__travel_time-content .tv-form__date-field:after {
          top: 13px !important;
          right: 11px !important; }
      .timesheet__travel_time-content .tv-single-assign-form__input-arrow:after {
        display: none; }
      .timesheet__travel_time-content .button-plus {
        position: relative;
        font-size: 18px;
        font-size: 1.125rem;
        line-height: 1.75rem;
        padding: 11px 28px 11px 40px; }
        .timesheet__travel_time-content .button-plus i {
          position: absolute;
          left: 20px;
          margin-top: 3px; }
        .timesheet__travel_time-content .button-plus::after {
          content: '+';
          position: absolute;
          right: 10px;
          color: #873bff;
          margin-top: 3px;
          font-size: 20px;
          font-weight: 900;
          line-height: 25px; }
      .timesheet__travel_time-content .button-minus {
        position: relative;
        font-size: 18px;
        font-size: 1.125rem;
        line-height: 1.75rem;
        padding: 11px 28px 11px 40px; }
        .timesheet__travel_time-content .button-minus i {
          position: absolute;
          left: 20px;
          margin-top: 3px; }
        .timesheet__travel_time-content .button-minus::after {
          content: '-';
          position: absolute;
          right: 10px;
          color: #873bff;
          margin-top: 3px;
          font-size: 20px;
          font-weight: 900;
          line-height: 25px; }
    .timesheet__travel_time-label {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 1.5rem;
      color: #313131;
      padding-bottom: 5px;
      margin: 0 0 0 0;
      font-size: 15px;
      font-weight: 400;
      font-family: "Circular Std", "Circular Std"; }
      .timesheet__travel_time-label::before {
        margin-right: 10px; }
    .timesheet__travel_time-input {
      height: 52px;
      padding: 16px 20px; }
    .timesheet__travel_time .timesheet_time input {
      width: 100%;
      height: 40px;
      border: 1px solid #bdbdbd !important;
      padding: 10px;
      color: black; }
    .timesheet__travel_time .timesheet_time .rc-time-picker-input {
      border: 1px solid #969696; }
    .timesheet__travel_time .timesheet_time i {
      margin-top: 22px; }
    .timesheet__travel_time .tv-interperet-now__start-time-wrapper {
      padding-top: 5px;
      margin: 0; }
    .timesheet__travel_time .tv-row > [class^='tv-col-'] {
      padding-right: 12px;
      padding-left: 12px; }
  .timesheet__checkbox {
    font-size: 14px;
    font-size: 0.875rem;
    padding-top: 20px; }
    .timesheet__checkbox .tv-form__checkbox-checkmark {
      background-color: #fff;
      border: 1px solid #873bff; }
  .timesheet__rating {
    min-height: 150px;
    background-color: #f7f7f7;
    text-align: center;
    margin: 48px 0 36px;
    padding: 15px 32px 5px; }
    .timesheet__rating-text {
      color: #a9a9a9;
      font-family: "Circular Std", "Circular Std";
      font-size: 14px;
      font-size: 0.875rem;
      letter-spacing: 0.3px;
      line-height: 1.25rem; }
  .timesheet__submit {
    padding: 10px 0 40px;
    width: 200px;
    margin: 0 auto; }
    .timesheet__submit button {
      padding: 15px 0; }
  .timesheet .tv-single-assign-form__input-arrow {
    position: relative;
    margin-top: 5px; }
  .timesheet .tv-single-assign-form__textfield {
    padding: 16px 20px; }
    .timesheet .tv-single-assign-form__textfield:disabled {
      color: #a9a9a9; }
  .timesheet .tv-single-assign-form__label {
    margin: 0 0 0 0;
    font-size: 15px;
    font-weight: 400; }
    .timesheet .tv-single-assign-form__label.icon-calendar > div {
      display: inline-block;
      padding-left: 10px; }
    .timesheet .tv-single-assign-form__label.icon-submit-time > div {
      display: inline-block;
      padding-left: 10px; }
  .timesheet .tv-single-assign-form__input {
    margin-left: 0; }
  .timesheet .tv-col-2 .tv-form__date-field {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  .timesheet .pdt0 {
    padding-top: 0; }
  .timesheet .mgt0 {
    margin-top: 0; }
  .timesheet .read-only {
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    cursor: not-allowed;
    pointer-events: none; }
    .timesheet .read-only .icon-arrow-expand {
      display: none; }
  .timesheet__signature span {
    display: block; }
  .timesheet__signature img {
    max-height: 150px; }

.tv-single-assign-form__control {
  color: #313131; }

.timesheet_modal .tv-modal__holder {
  text-align: center;
  padding: 20px 90px 30px; }
  .timesheet_modal .tv-modal__holder .image-upload > input {
    display: none; }
  .timesheet_modal .tv-modal__holder .image-upload .icon-upload-to-cloud::before {
    color: #873bff;
    font-size: 22px;
    font-size: 1.375rem; }
  .timesheet_modal .tv-modal__holder .image-upload__title {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 1.5rem;
    color: #873bff;
    margin: 5px; }
  .timesheet_modal .tv-modal__holder .image-preview {
    width: 120px;
    border: 1px solid #979797;
    border-radius: 2px;
    margin: 20px auto 0;
    position: relative; }
    .timesheet_modal .tv-modal__holder .image-preview img {
      max-width: 100%;
      height: auto;
      display: block;
      margin: 0 auto; }
    .timesheet_modal .tv-modal__holder .image-preview i.icon-close {
      cursor: pointer;
      position: absolute;
      top: -12px;
      right: -12px;
      background-color: #873bff;
      width: 24px;
      border-radius: 50%;
      height: 24px; }
      .timesheet_modal .tv-modal__holder .image-preview i.icon-close::before {
        color: white;
        font-size: 12px;
        font-size: 0.75rem; }
  .timesheet_modal .tv-modal__holder .icon-upload-to-cloud {
    cursor: pointer;
    border: 1px solid #873bff;
    border-radius: 50%;
    display: inline-block;
    padding-top: 5px;
    margin-top: 20px;
    width: 40px;
    height: 40px; }
    .timesheet_modal .tv-modal__holder .icon-upload-to-cloud [class*='path']::before {
      /* use !important to prevent issues with browser extensions that change fonts */
      font-family: 'transvoice-icon' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-feature-settings: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      /* Better Font Rendering =========== */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 24px;
      font-size: 1.5rem; }
  .timesheet_modal .tv-modal__holder .path1::before {
    color: #873bff; }

.timesheet_modal__header {
  color: #313131;
  font: 1.125rem/1.75rem "Circular Std", "Circular Std";
  font-weight: 300;
  letter-spacing: -0.2px; }

.input-group {
  min-width: 275px;
  padding: 28px 0 24px; }
  .input-group.view-data {
    padding: 0;
    min-width: 0; }
  .input-group__label {
    margin-right: -1px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    padding: 8px 16px;
    margin-bottom: 0;
    font-size: 1rem;
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 1.5rem;
    color: #313131;
    text-align: center;
    white-space: nowrap;
    border: 1px solid #e8e8e8;
    border-radius: 0.25rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group__value {
    min-width: 100px;
    border: 1px solid #e8e8e8;
    border-radius: 4px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin-bottom: 0;
    padding: 8px 16px;
    color: #969696;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .input-group__textfield {
    border: 1px solid #e8e8e8;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin-bottom: 0;
    padding: 8px 16px;
    color: #873bff; }

@media (max-width: 768px) {
  .timesheet__modal .tv-modal__holder {
    width: calc(100vw);
    min-width: 100vw;
    height: 100vh;
    max-height: 100vh;
    margin-top: 0;
    top: 0;
    border-radius: 0; }
    .timesheet__modal .tv-modal__holder .timesheet__travel_time:nth-child(2) {
      margin-left: 0px; }
    .timesheet__modal .tv-modal__holder button.tv-rate-submit-btn {
      width: 100%;
      margin-left: 0;
      margin-bottom: 20px; }
    .timesheet__modal .tv-modal__holder .detailed-rating.timesheet__header-rating {
      width: 100%; }
      .timesheet__modal .tv-modal__holder .detailed-rating.timesheet__header-rating .rating {
        width: 100%;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row-reverse;
                flex-direction: row-reverse;
        -webkit-justify-content: space-between;
                justify-content: space-between;
        margin-bottom: 20px; }
        .timesheet__modal .tv-modal__holder .detailed-rating.timesheet__header-rating .rating > label:before {
          font-size: 33px !important; }
    .timesheet__modal .tv-modal__holder .footer-wrapper {
      -webkit-justify-content: space-between;
              justify-content: space-between;
      -webkit-align-items: center;
              align-items: center;
      width: 100%; }
      .timesheet__modal .tv-modal__holder .footer-wrapper > .feedback-create-deviation,
      .timesheet__modal .tv-modal__holder .footer-wrapper .tv-rate-submit-btn {
        margin: 0px !important; } }

.tk-notification-detail__container {
  left: 0;
  top: 0;
  width: calc(100% - 10px);
  height: 625px !important;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 5px 10px; }

.tk-notification-detail__drawer {
  z-index: 60; }

@media screen and (max-width: 768px) {
  .tk-notification-detail__container {
    width: 100%;
    height: 100vh !important; } }

.tk-notification-item__container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  height: 72px;
  padding: 18px 8px 18px 11px;
  border-bottom: 1px solid #ebebeb; }
  .tk-notification-item__container .custom-control-label {
    padding: 0 !important;
    background: unset !important;
    color: unset !important;
    border-radius: 0 !important;
    text-transform: none !important; }
  .tk-notification-item__container .tv-control {
    margin: 6px 0px;
    position: relative;
    z-index: 1;
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5rem;
    -webkit-print-color-adjust: exact; }

.tk-notification-item__date {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  letter-spacing: 0.5px;
  color: #252525; }

.tk-notification-item__title {
  font-size: 12px;
  line-height: 15px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  letter-spacing: 0.5px;
  color: #808080; }

.tk-notification-item__label-new {
  background: #e94242;
  border-radius: 30px;
  color: #ffffff;
  font-size: 8px;
  height: 16px;
  width: 35px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-content: center;
          align-content: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 4px 8px;
  font-weight: 500;
  margin-top: 13px; }

.tv_dashboard__add-button {
  font-family: "Circular Std", "Circular Std";
  font-size: 16px;
  background: #873bff;
  border: none;
  outline: none;
  color: white;
  max-height: 48px;
  padding: 14px 24px;
  margin-left: auto;
  border-radius: 4px;
  margin-bottom: 16px; }
  .tv_dashboard__add-button i.fa {
    -webkit-text-stroke: 1px #873bff;
    margin-right: 13px; }

.tv_dashboard__current-time {
  color: #74737b;
  margin: auto 0 16px auto;
  -webkit-align-self: center;
          align-self: center;
  font-weight: 500;
  font-size: 20px; }

.tv_dashboard__buttons {
  margin-bottom: 20px; }
  .tv_dashboard__buttons a:hover {
    text-decoration: none; }

.tv_dashboard__button-create-new-assignment.tv-buttons__element {
  margin-right: 32px;
  padding: 20px 38px 20px 34px;
  font: 1.125rem/1.75rem "Circular Std", "Circular Std";
  font-weight: 300;
  cursor: pointer;
  letter-spacing: -0.2px;
  box-shadow: 0 10px 18px -12px rgba(58, 68, 131, 0.2); }
  .tv_dashboard__button-create-new-assignment.tv-buttons__element:before {
    font-family: 'transvoice-icon';
    margin-right: 18px; }

.tv_dashboard__button-create-new-assignment:before {
  content: '\e921';
  color: #fff; }

.tv_dashboard__news {
  width: 100%;
  background: #fff;
  padding: 30px 25px;
  margin-top: 30px;
  border-radius: 5px;
  box-shadow: 0 10px 18px -12px rgba(58, 68, 131, 0.2); }
  .tv_dashboard__news.tv-right-news {
    padding: 13px 21px; }
    .tv_dashboard__news.tv-right-news .tv-right-news__title {
      color: #353535;
      font: 1.5rem/2rem "Circular Std", "Circular Std";
      font-weight: 500;
      letter-spacing: -0.2px;
      margin: 10px 0; }
    .tv_dashboard__news.tv-right-news .tv-right-news__description {
      color: #313131;
      font: 1rem/1.5rem "Circular Std", "Circular Std";
      padding-bottom: 20px; }
  .tv_dashboard__news .tv-right-news__footer {
    padding: 13px 0;
    border-top: 1px solid #e8ecef; }
    .tv_dashboard__news .tv-right-news__footer--avarta {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      margin-right: 15px; }
    .tv_dashboard__news .tv-right-news__footer--name {
      color: #313131;
      font: 0.875rem/1.5rem "Circular Std", "Circular Std";
      font-weight: 600; }
    .tv_dashboard__news .tv-right-news__footer--position {
      color: #313131;
      font: 0.875rem/1.5rem "Circular Std", "Circular Std"; }
  .tv_dashboard__news__header {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    padding-bottom: 25px; }
    .tv_dashboard__news__header-heading {
      color: #7f7f7f;
      font-family: 'SF Compact Display';
      font-size: 16px;
      font-size: 1rem;
      line-height: 1rem; }
    .tv_dashboard__news__header-title {
      padding-top: 5px;
      color: #353535;
      font: 1.5rem/2rem "Circular Std", "Circular Std";
      font-weight: 500;
      letter-spacing: -0.2px; }
  .tv_dashboard__news__content img {
    width: 100%;
    height: auto;
    display: block; }
  .tv_dashboard__news__content-item {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%; }
    .tv_dashboard__news__content-item iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }

.tv_dashboard__main-panel {
  display: -webkit-flex;
  display: flex; }
  .tv_dashboard__main-panel .tv-interperet-now__location-wrapper {
    padding: 11px 27px 30px 27px; }
  .tv_dashboard__main-panel .tv-toggle-button--phone {
    margin-right: 10px; }
  .tv_dashboard__main-panel .tv-search-box input {
    margin: 0px 0px 8px 0px;
    width: 100%;
    background-color: #f1f3f4; }
  .tv_dashboard__main-panel .tv-search-box i {
    top: 14px;
    left: 14px; }
  .tv_dashboard__main-panel .tv-status-filter {
    margin-bottom: 0;
    padding-bottom: 0; }

.tv_dashboard__calendar-panel {
  width: 100%; }
  .tv_dashboard__calendar-panel__information {
    padding-top: 30px;
    color: #873bff;
    font-size: 16px;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: bold; }
    .tv_dashboard__calendar-panel__information .icon-check {
      background-color: #873bff;
      border-radius: 50%;
      width: 27px;
      height: 27px;
      display: inline-block;
      margin-right: 13px; }
      .tv_dashboard__calendar-panel__information .icon-check::before {
        font-size: 13px;
        font-size: 0.8125rem;
        color: #fff;
        padding: 4px; }

.tv_dashboard__availability-panel {
  margin-right: 20px; }

.tv_dashboard__req-wrapper {
  width: 33.333333%;
  margin-left: 32px; }

.tv_dashboard__req-header {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between; }

.tv_dashboard__req-title {
  color: #353535;
  font: 1.5rem "Circular Std", "Circular Std";
  font-weight: 500;
  letter-spacing: -0.2px;
  margin: 0 0 10px 0; }

.tv_dashboard__req-view-all {
  color: #873bff;
  font: 1.125rem/1.75rem "Circular Std", "Circular Std";
  font-weight: 300;
  letter-spacing: -0.2px;
  border: 0;
  background: transparent;
  cursor: pointer;
  margin-top: 3px; }
  .tv_dashboard__req-view-all:hover {
    text-decoration: none; }

.tv_dashboard__req-item {
  margin-top: 16px;
  padding: 13px 21px;
  border-radius: 4px;
  box-shadow: 0 10px 18px -12px rgba(58, 68, 131, 0.2);
  background-color: white;
  cursor: pointer; }
  .tv_dashboard__req-item-title {
    color: #313131;
    font: 1.125rem/1.75rem Circular Std;
    font-weight: 300;
    letter-spacing: -0.2px;
    padding: 0 0 10px 0; }
  .tv_dashboard__req-item-number {
    color: #313131;
    font: 1.125rem/1.75rem Circular Std;
    font-weight: 300;
    letter-spacing: -0.2px; }
  .tv_dashboard__req-item-time {
    color: #873bff;
    font: 0.875rem/1.5rem "Circular Std", "Circular Std";
    border-top: 1px solid #e8ecef;
    padding: 10px 0 0 0; }
    .tv_dashboard__req-item-time i::before {
      color: #873bff; }
    .tv_dashboard__req-item-time-period {
      color: #a9a9a9;
      font: 0.875rem/1.5rem "Circular Std", "Circular Std";
      padding: 10px 0 0 0; }
  .tv_dashboard__req-item-child {
    margin: 0 13px;
    box-shadow: inset 0 10px 18px -12px rgba(58, 68, 131, 0.2), 0 10px 18px -12px rgba(58, 68, 131, 0.2); }

.tv-drawer__title-mobile {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 24px;
  font: 1.125rem/1.75rem "Circular Std", "Circular Std"; }

.tv-drawer_buttons {
  bottom: 170px;
  left: 10px;
  display: -webkit-flex;
  display: flex;
  position: absolute;
  width: calc(100% - 40px);
  font: 1rem/1.5rem "Circular Std", "Circular Std";
  z-index: 999; }
  .tv-drawer_buttons button {
    width: 100%;
    max-height: 50px; }
  .tv-drawer_buttons.list_drawer {
    bottom: 10px;
    left: unset;
    display: -webkit-flex;
    display: flex; }

.tv-interperet-now .tv-modal__btn-close {
  font-size: 1rem;
  top: 24px;
  right: 24px; }

.tv-interperet-now__wrapper {
  background-color: #fff;
  width: 550px; }

.tv-interperet-now__header-text {
  background-color: #873bff;
  border-radius: 4px 4px 0 0;
  padding: 17px 24px 17px 32px;
  color: #fff;
  font: 500 1.5rem/2.1875rem "Circular Std", "Circular Std";
  letter-spacing: -0.2px; }

.tv-interperet-now__content-wrapper {
  font: 1rem/1.5rem "Circular Std", "Circular Std";
  color: #313131; }

.tv-interperet-now__content-title {
  background-color: #f9f9f9;
  font-size: 14px;
  font-size: 0.875rem;
  padding: 15px 0px 15px 32px; }

.tv-interperet-now__location-wrapper {
  background-color: #fff;
  padding: 24px 32px; }

.tv-interperet-now__location-phone {
  width: 231px; }

.tv-interperet-now__location-onsite {
  width: 231px;
  margin-left: 24px; }

.tv-interperet-now__postal-code-title {
  margin-top: 20px;
  font: 300 1.25rem/1.8125rem "Circular Std", "Circular Std"; }

.tv-interperet-now__postal-code-input {
  width: 100%; }

.tv-interperet-now__date-control-wrapper {
  padding: 24px 32px 50px 32px;
  font: 0.875rem/1.5rem "Circular Std", "Circular Std";
  color: #313131; }

.tv-interperet-now__date-wrapper {
  -webkit-flex: 1.6 1;
          flex: 1.6 1;
  margin-right: 40px; }

.tv-interperet-now__date-icon {
  margin-right: 9px; }

.tv-interperet-now__start-time-wrapper {
  -webkit-flex: 1 1;
          flex: 1 1;
  margin-right: 40px; }

.tv-interperet-now__start-time-icon {
  margin-right: 9px; }

.tv-interperet-now__end-time-wrapper {
  -webkit-flex: 1 1;
          flex: 1 1; }

.tv-interperet-now__end-time-icon {
  margin-right: 9px; }

.tv-interperet-now__submit-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  padding-bottom: 32px; }

.tv-interperet-now__submit-wrapper .tv-interperet-now__submit-btn {
  padding: 13px 47px; }

.confirm-avaibility .tv-modal__holder {
  background-color: #fff; }
  .confirm-avaibility .tv-modal__holder .icon-close:before {
    color: #313131; }

.confirm-avaibility__content {
  text-align: center;
  padding: 2.0625rem 5rem; }
  .confirm-avaibility__content i {
    font-size: 46px;
    font-size: 2.875rem; }
    .confirm-avaibility__content i::before {
      color: #873bff; }
  .confirm-avaibility__content-title {
    color: #313131;
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 1.5rem; }
  .confirm-avaibility__content-description {
    color: #873bff;
    font-size: 16px;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 600; }
  .confirm-avaibility__content-confirm {
    width: 214px;
    padding: 20px 0; }

.tv-block-wrapper .submit-time,
.confirm-avaibility__content button {
  margin-top: 20px;
  padding: 20px 0; }

.PreviewArea {
  box-shadow: 1px 1px 81px rgba(41, 60, 74, 0.18); }

.rdrDefinedRangesWrapper {
  display: none; }

.tv_assignment_detail__button {
  -webkit-flex-grow: 1;
          flex-grow: 1;
  margin-left: 5px;
  margin-right: 5px; }

.sticky {
  position: fixed;
  top: 0;
  width: 100%; }

.sticky + .tk-weekly__main.tk-monthly-calendar__main {
  padding-top: 70px; }

.tv-search-order-bar__loader {
  position: absolute;
  color: #6478e6;
  top: 0;
  right: 0;
  display: -webkit-flex;
  display: flex;
  padding: 18px 10px; }

.popup-content {
  padding: 5px;
  color: #787878;
  border-radius: 8px;
  box-shadow: 0 2px 4px -1px rgba(117, 114, 114, 0.2) !important;
  background-color: #fcf7f8;
  border: 1pxsolid rgba(0, 0, 0, 0.05); }

.tv-single-assign-form__hr {
  border-top: 1px solid #969696; }

.tv-single-assign-form__title {
  color: #353535;
  font: 1.5rem/2rem "Circular Std", "Circular Std";
  font-weight: 500;
  letter-spacing: -0.2px;
  text-align: center; }

.tv-single-assign-form__container {
  background: #fff; }
  .tv-single-assign-form__container--order {
    margin: 0 auto 24px auto; }
    .tv-single-assign-form__container--order .tv-single-assign-form__control {
      color: #313131; }
    .tv-single-assign-form__container--order .tv-single-assign-form__panel {
      padding-bottom: 30px; }
  .tv-single-assign-form__container .tv-row {
    margin-right: -12px;
    margin-left: -12px; }
    .tv-single-assign-form__container .tv-row > [class^='tv-col-'] {
      padding-right: 12px;
      padding-left: 12px; }
    .tv-single-assign-form__container .tv-row > .tv-single-assign-form__input--icon-add {
      padding: 10px 0; }
  .tv-single-assign-form__container .tv-form__error {
    margin: 4px 0 3px;
    min-height: 1px;
    display: block; }
  .tv-single-assign-form__container .tv-form__control--error {
    margin-bottom: 0;
    border: 2px solid red !important; }

.tv-single-assign-form__action-container {
  margin: 0 auto 32px auto;
  -webkit-justify-content: flex-end;
          justify-content: flex-end; }
  .tv-single-assign-form__action-container button {
    box-shadow: 0 2px 30px 0 rgba(153, 153, 153, 0.5);
    margin-left: 24px;
    font: 1rem "Circular Std", "Circular Std";
    padding: 13px 16px 12px;
    font-weight: 300; }

.tv-single-assign-form__panel {
  padding: 10px 48px; }
  .tv-single-assign-form__panel--secondary {
    background: #f8f8f9;
    border-top: 1px solid #e6e6e6;
    border-bottom: 1px solid #e6e6e6;
    padding: 0px 48px 20px; }
  .tv-single-assign-form__panel .text-normal {
    color: #313131;
    font: 0.875rem/1.5rem 'PT Serif', serif;
    line-height: 24px; }
  .tv-single-assign-form__panel .text-bold {
    font-weight: bold; }
  .tv-single-assign-form__panel.tv-single-assign-form__panel--preview {
    padding: 10px 48px 50px 48px; }

.tv-single-assign-form__sub-title {
  color: #873bff;
  font: 1.125rem/1.75rem "Circular Std", "Circular Std";
  font-weight: 300;
  letter-spacing: -0.2px;
  margin: 24px 0 12px 0;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding-bottom: 3px; }
  .tv-single-assign-form__sub-title .tv-single-assign-form__button-preview {
    float: right; }

.tv-single-assign-form__label, .tv-single-assign-form__input {
  color: #313131;
  font: 0.875rem/1.5rem "Circular Std", "Circular Std";
  line-height: 24px; }
  .tv-single-assign-form__label .tv-date-range-picker__container, .tv-single-assign-form__input .tv-date-range-picker__container {
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    display: block;
    width: 100%; }
    .tv-single-assign-form__label .tv-date-range-picker__container .tv-form__date-field, .tv-single-assign-form__input .tv-date-range-picker__container .tv-form__date-field {
      font: 300 0.8125rem "Circular Std", "Circular Std";
      padding: 16px 20px;
      color: #000;
      position: relative; }
      .tv-single-assign-form__label .tv-date-range-picker__container .tv-form__date-field:after, .tv-single-assign-form__input .tv-date-range-picker__container .tv-form__date-field:after {
        font: 1rem;
        position: absolute;
        content: '\e929';
        font-family: 'transvoice-icon';
        top: 19px;
        right: 20px; }
  .tv-single-assign-form__label.h__pdb, .tv-single-assign-form__input.h__pdb {
    padding-bottom: 16px; }

.tv-single-assign-form__label {
  margin: 16px 0 5px 0; }
  .tv-single-assign-form__label--contact-note {
    color: #873bff;
    text-align: center; }

.tv-single-assign-form__input {
  position: relative; }
  .tv-single-assign-form__input-arrow:after {
    pointer-events: none;
    content: '\e933';
    position: absolute;
    font: 0.7rem 'transvoice-icon';
    right: 27px;
    top: 20px;
    color: #313131; }
  .tv-single-assign-form__input-arrow.adornment-end.tv-col-3 {
    min-width: 180px; }
    .tv-single-assign-form__input-arrow.adornment-end.tv-col-3 + .tv-single-assign-form__input--icon-add {
      max-width: 15px;
      margin-right: 12px; }
  .tv-single-assign-form__input-arrow.adornment-end.tv-col-5 {
    min-width: 180px; }
    .tv-single-assign-form__input-arrow.adornment-end.tv-col-5 + .tv-single-assign-form__input--icon-add {
      max-width: 15px;
      margin-right: 36px; }
  .tv-single-assign-form__input-arrow.ie-fix::after {
    display: none; }
  .tv-single-assign-form__input-sameabove {
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    -webkit-align-self: center;
            align-self: center;
    margin: 0; }
    .tv-single-assign-form__input-sameabove .tv-toggleSwitch__wrapper {
      margin-left: 10px; }
  .tv-single-assign-form__input--my-home {
    margin-top: 15px; }
  .tv-single-assign-form__input--icon-add {
    padding: 0; }
  .tv-single-assign-form__input textarea {
    resize: none; }

.tv-single-assign-form__control {
  border: 1px solid #969696;
  background: #fff;
  border-radius: 4px;
  width: 100%;
  font: 300 0.8125rem "Circular Std", "Circular Std";
  outline: none;
  color: #313131;
  margin-bottom: 8px; }
  .tv-single-assign-form__control span[role='listbox'] {
    border-bottom: none; }

.tv-single-assign-form__dropdownlist {
  padding: 14px 20px;
  -webkit-appearance: none;
          appearance: none; }
  .tv-single-assign-form__dropdownlist.ellipsis {
    padding-right: 35px;
    display: block;
    width: 100%;
    max-width: 100%;
    overflow: hidden !important;
    text-overflow: ellipsis;
    white-space: nowrap; }

.tv-single-assign-form__textfield {
  padding: 14px 20px; }

.tv-single-assign-form__textarea {
  height: 120px; }

.tv-single-assign-form__file-container {
  margin: 0 0 23px 0;
  padding: 0;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap; }

.tv-single-assign-form__file-element {
  list-style: none;
  margin: 0 8px 0 0;
  width: calc((100% - 24px) / 4); }
  .tv-single-assign-form__file-element:nth-child(4n + 0) {
    margin-right: 0; }

.tv-single-assign-form__file-name {
  border: 1px solid #eaeaea;
  border-radius: 0 0 5px 5px;
  background: #f8f8f8;
  font: 0.625rem "Circular Std", "Circular Std";
  color: #873bff;
  bottom: 0;
  position: absolute;
  display: -webkit-flex;
  display: flex;
  width: 100%;
  padding: 10px 0; }
  .tv-single-assign-form__file-name:before {
    content: '\e93c';
    color: #873bff;
    font: 0.75rem 'transvoice-icon';
    margin: 0 8px; }

.tv-single-assign-form__file-item {
  min-height: 120px;
  border-radius: 5px;
  position: relative;
  -webkit-justify-content: center;
          justify-content: center;
  display: -webkit-flex;
  display: flex; }
  .tv-single-assign-form__file-item:before {
    color: #fff;
    font: 2.6rem 'transvoice-icon';
    margin-top: 20px; }
  .tv-single-assign-form__file-item--pdf {
    background: #ff9ba0; }
    .tv-single-assign-form__file-item--pdf:before {
      content: '\e93b'; }
  .tv-single-assign-form__file-item--doc, .tv-single-assign-form__file-item--docx {
    background: #6478e6; }
    .tv-single-assign-form__file-item--doc:before, .tv-single-assign-form__file-item--docx:before {
      content: '\e93a'; }
  .tv-single-assign-form__file-item--image {
    background: #454c98; }
    .tv-single-assign-form__file-item--image:before {
      content: '\e940'; }
  .tv-single-assign-form__file-item--other {
    background: #6478e6; }
    .tv-single-assign-form__file-item--other:before {
      content: ''; }
  .tv-single-assign-form__file-item--dragdrop {
    background: #fff;
    border: 1px dashed #cbcbcb;
    outline: none;
    font: 0.75rem "Circular Std", "Circular Std";
    font-weight: 300;
    text-transform: uppercase;
    color: #c5c5c5;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    text-align: center;
    padding: 0 20px;
    height: 120px;
    cursor: pointer; }
    .tv-single-assign-form__file-item--dragdrop:before {
      content: '\e93d';
      color: #873bff;
      font: 1.4rem 'transvoice-icon';
      margin-bottom: 4px; }

.tv-single-assign-form__action-container .tv-single-assign-form__button-save {
  border-color: #fff;
  background: #fff; }

.tv-single-assign-form__button-addSession {
  border: 1px dashed #873bff;
  border-radius: 5px;
  width: 100%;
  background: #fff;
  cursor: pointer;
  color: #873bff;
  font: 0.8125rem/1.5rem "Circular Std", "Circular Std";
  font-weight: bold;
  text-align: center;
  outline: none;
  padding: 8px 0 10px 0;
  margin-top: 16px; }

.tv-single-assign-form__button-removeSession {
  background: #873bff;
  height: 22px;
  width: 22px;
  border-radius: 50%;
  border: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-align-self: center;
          align-self: center;
  -webkit-justify-content: center;
          justify-content: center;
  cursor: pointer;
  outline: none; }
  .tv-single-assign-form__button-removeSession:before {
    content: '\e926';
    color: #fff;
    font-family: 'transvoice-icon'; }

.tv-single-assign-form__button-removeFile {
  color: #873bff;
  border: 0;
  background: transparent;
  display: -webkit-flex;
  display: flex;
  outline: none;
  font: 0.875rem "Circular Std", "Circular Std";
  cursor: pointer;
  margin: 10px 0; }
  .tv-single-assign-form__button-removeFile:before {
    background: #873bff;
    content: '\e926';
    color: #fff;
    font: 0.45rem 'transvoice-icon';
    border-radius: 50%;
    height: 16px;
    width: 16px;
    padding: 4px;
    display: inline-block;
    margin: 3px 7px 0 0; }

.tv-single-assign-form__preview-text {
  font: bold 1rem/1.5rem "Circular Std", "Circular Std";
  color: #313131;
  overflow: hidden;
  text-overflow: ellipsis; }

.tv-single-assign-form__preview-last {
  margin-bottom: 24px; }

.tv-single-assign-form__preview-headings {
  font: 500 16px/20px "Circular Std", "Circular Std";
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }
  .tv-single-assign-form__preview-headings > .icon-number::before {
    display: none; }

div:nth-child(1) > .tv-single-assign-form__preview-headings > .icon-number::before {
  content: '\278A'; }

div:nth-child(2) > .tv-single-assign-form__preview-headings > .icon-number::before {
  content: '\278B'; }

div:nth-child(3) > .tv-single-assign-form__preview-headings > .icon-number::before {
  content: '\278C'; }

.tv-single-assign-form__preview-sub-container {
  font-family: "Circular Std", "Circular Std";
  border-radius: 4px;
  padding: 10px 10px 0px 10px;
  width: 100%;
  text-align: left;
  margin: 10px 10px 0 0; }
  .tv-single-assign-form__preview-sub-container span {
    font-size: 1rem; }

.tv-single-assign-form__preview-property {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-top: 10px;
  margin-bottom: 15px;
  width: 100%; }
  .tv-single-assign-form__preview-property .property-label {
    font-weight: 700 !important;
    margin-bottom: 5px; }
  .tv-single-assign-form__preview-property form {
    display: unset; }
  .tv-single-assign-form__preview-property .property-value {
    font-size: 14px;
    min-width: 200px !important;
    font-weight: 100 !important;
    width: 50%;
    -webkit-align-self: flex-end;
            align-self: flex-end; }
    .tv-single-assign-form__preview-property .property-value.edit-field {
      padding: 5px; }
  .tv-single-assign-form__preview-property .property-condition {
    font-size: 14px;
    font-weight: 400 !important; }
  .tv-single-assign-form__preview-property input.property-value {
    background-color: #e0e0e0 !important;
    border-radius: 3px;
    border: 1px solid #e0e0e0;
    padding: 5px 8px;
    margin-right: 5px;
    -webkit-align-self: flex-start;
            align-self: flex-start; }
  .tv-single-assign-form__preview-property button.tv-buttons__element {
    font: 400 14px/18px "Circular Std", "Circular Std";
    -webkit-align-self: center;
            align-self: center;
    padding: 2px;
    height: 15px;
    line-height: 4px; }

.tv-single-assign-form__back-corner {
  position: absolute;
  font: 0.875rem/1.5rem "Circular Std", "Circular Std";
  color: #873bff; }
  .tv-single-assign-form__back-corner::before {
    content: '\e934';
    font-family: 'transvoice-icon';
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: 'liga';
    font-size: 20px;
    padding: 0 5px 0 0; }

.tv-single-assign-form__modal--order {
  margin: 0 auto 24px auto; }
  .tv-single-assign-form__modal--order .tv-single-assign-form__control {
    color: #313131; }
  .tv-single-assign-form__modal--order .tv-single-assign-form__panel {
    padding-bottom: 30px; }

.tv-single-assign-form__modal-wrap {
  padding: 0 50px; }

.tv-single-assign-form__modal .tv-row {
  margin-right: -12px;
  margin-left: -12px; }
  .tv-single-assign-form__modal .tv-row > [class^='tv-col-'] {
    padding-right: 12px;
    padding-left: 12px; }

.tv-single-assign-form__modal .tv-form__error {
  margin: 4px 0 3px;
  min-height: 1px;
  display: block; }

.tv-single-assign-form__modal .tv-form__control--error {
  margin-bottom: 0; }

.tv-single-assign-form__modal .tv-modal__btn-close {
  font-size: 1rem;
  top: 24px;
  right: 24px; }

.tv-single-assign-form__modal .tv-modal__holder {
  max-width: 700px;
  background-color: #fff;
  padding: 0;
  -ms-overflow-style: scrollbar; }
  .tv-single-assign-form__modal .tv-modal__holder .icon-close::before {
    color: #fff; }
  .tv-single-assign-form__modal .tv-modal__holder.ie-fix-height {
    top: 50px; }

.tv-single-assign-form__modal .tv-interperet-now__header-text.ie-fix-width {
  width: 516px; }

.tv-single-assign-form__modal-icon {
  font-size: 3rem;
  margin-top: 32px; }
  .tv-single-assign-form__modal-icon .icon-warning::before {
    color: #873bff; }

.tv-single-assign-form__modal-text {
  color: #873bff;
  font: 300 1.125rem/1.75rem "Circular Std", "Circular Std";
  letter-spacing: -0.2px;
  text-align: center;
  padding: 0 68px; }

.tv-single-assign-form__modal-assignment {
  border: 1px solid #969696;
  background-color: #f7f7f7;
  margin: 16px 36px 45px;
  padding: 9px 16px; }
  .tv-single-assign-form__modal-assignment-id {
    color: #873bff; }
  .tv-single-assign-form__modal-assignment-title {
    color: #353535; }
  .tv-single-assign-form__modal-assignment-id, .tv-single-assign-form__modal-assignment-title {
    font: 300 1rem/1.5rem "Circular Std", "Circular Std";
    text-align: center; }

.tv-single-assign-form__modal-buttons {
  -webkit-justify-content: center;
          justify-content: center;
  margin: 10px 20px 30px; }
  .tv-single-assign-form__modal-buttons button {
    width: 169px;
    padding: 13px 0px; }

.tv-multiple-assign-form__title {
  color: #353535;
  font: 1.5rem/2rem "Circular Std", "Circular Std";
  font-weight: 500;
  letter-spacing: -0.2px;
  text-align: center; }

.tv-multiple-assign-form__panel-left {
  margin-right: 37px; }

.tv-multiple-assign-form__panel-tab {
  width: 323px;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.12);
  cursor: pointer;
  padding: 20px 24px;
  margin-bottom: 18px;
  border: 1px solid #fff; }
  .tv-multiple-assign-form__panel-tab--error {
    border: 1px solid #873bff; }
    .tv-multiple-assign-form__panel-tab--error::before {
      left: -4px;
      top: -4px;
      border-radius: 5px;
      content: ' ';
      height: 175px;
      width: 330px;
      position: absolute;
      pointer-events: none; }
    .tv-multiple-assign-form__panel-tab--error.highlight::before {
      -webkit-animation: tv-anim-effect-2 2s infinite;
              animation: tv-anim-effect-2 2s infinite; }
    .tv-multiple-assign-form__panel-tab--error.thick-hightlight {
      border: 2.5px solid #873bff !important; }
  .tv-multiple-assign-form__panel-tab--selected {
    border: 1px solid #873bff; }
    .tv-multiple-assign-form__panel-tab--selected:after, .tv-multiple-assign-form__panel-tab--selected:before {
      left: 100%;
      top: 32px;
      border: solid transparent;
      content: ' ';
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none; }
    .tv-multiple-assign-form__panel-tab--selected:after {
      border-left-color: #fff;
      border-width: 6px; }
    .tv-multiple-assign-form__panel-tab--selected:before {
      border-left-color: #873bff;
      border-width: 8px;
      top: 30px; }
    .tv-multiple-assign-form__panel-tab--selected.tv-multiple-assign-form__panel-tab--error {
      border: 1px solid #873bff; }
      .tv-multiple-assign-form__panel-tab--selected.tv-multiple-assign-form__panel-tab--error:before {
        border-left-color: #873bff; }

.tv-multiple-assign-form__panel-title {
  color: #313131;
  font: 1.125rem "Circular Std", "Circular Std";
  font-weight: 300;
  letter-spacing: -0.2px;
  border-bottom: 1px solid #e8ecef;
  margin: 0 0 10px 0;
  padding: 0 0 10px 0; }

.tv-multiple-assign-form__attributes {
  margin: 0;
  padding: 0; }
  .tv-multiple-assign-form__attributes li {
    list-style: none;
    margin: 5px 0;
    font: 0.875rem/1.5rem "Circular Std", "Circular Std"; }
    .tv-multiple-assign-form__attributes li.level::before {
      content: '\e927';
      font-family: 'transvoice-icon';
      padding: 0 5px 0 0;
      color: #a9a9a9; }
    .tv-multiple-assign-form__attributes li.category::before {
      content: '\e92c';
      font-family: 'transvoice-icon';
      padding: 0 5px 0 0;
      color: #a9a9a9; }
    .tv-multiple-assign-form__attributes li.datetime::before {
      content: '\e929';
      font-family: 'transvoice-icon';
      padding: 0 5px 0 0;
      color: #a9a9a9; }

.tv-multiple-assign-form__button-add-more {
  border: 1px dashed #873bff;
  box-shadow: 0 2px 30px 0 rgba(153, 153, 153, 0.5);
  border-radius: 5px;
  color: #873bff;
  background: #fff;
  font: 1rem "Circular Std", "Circular Std";
  font-weight: bold;
  width: 100%;
  margin-top: 6px;
  padding: 10px 0;
  cursor: pointer; }

.tv-multiple-assign-form__button-delete {
  color: #873bff;
  font: 1rem "Circular Std", "Circular Std";
  font-weight: bold;
  background: transparent;
  border: 0;
  position: absolute;
  right: 19px;
  top: 15px;
  cursor: pointer;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }
  .tv-multiple-assign-form__button-delete:before {
    content: '\e926';
    color: #873bff;
    font: 0.5rem 'transvoice-icon';
    height: 8px;
    width: 8px;
    display: inline-block;
    margin: 0 7px 0 0; }

.tv-multiple-assign-form__back-corner {
  font: 0.875rem/1.5rem "Circular Std", "Circular Std";
  color: #873bff; }
  .tv-multiple-assign-form__back-corner::before {
    content: '\e934';
    font-family: 'transvoice-icon';
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: 'liga';
    font-size: 20px;
    padding: 0 5px 0 0; }

.tv-chooseassignment__wrapper {
  -webkit-flex: 1.4 1;
          flex: 1.4 1;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding-top: 38px; }

.tv-chooseassignment__container {
  width: 700px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.12); }

.tv-chooseassignment__heading {
  margin: 4vh 184px 0;
  -webkit-align-items: center;
          align-items: center; }
  .tv-chooseassignment__heading-text {
    color: #353535;
    font: 500 1.5rem/2.1875rem "Circular Std", "Circular Std";
    letter-spacing: -0.2px;
    text-align: center; }

.tv-chooseassignment__content {
  margin: 3vh 84px 0; }

.tv-chooseassignment-single__wrapper {
  width: 50%;
  padding-right: 16px; }

.tv-chooseassignment-single__button {
  height: 200px;
  width: 250px;
  border-radius: 5px;
  background-color: #ffd2cd; }
  .tv-chooseassignment-single__button--active {
    background-color: #ff9ba0; }

.tv-chooseassignment-multi__wrapper {
  width: 50%;
  padding-left: 16px; }

.tv-chooseassignment-multi__button {
  height: 200px;
  width: 250px;
  border-radius: 5px;
  background-color: #e1e6ff; }
  .tv-chooseassignment-multi__button--active {
    background-color: #6478e6; }

.tv-chooseassignment__text {
  color: #a9a9a9;
  font: 0.875rem/1.25rem "Circular Std", "Circular Std";
  letter-spacing: 0.3px;
  text-align: center;
  margin: 1vh 16px 1vh 16px; }
  .tv-chooseassignment__text-button {
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    color: #fff;
    font: 300 1.125rem/1.75rem "Circular Std", "Circular Std";
    letter-spacing: -0.2px;
    text-align: center;
    height: 78px; }

.tv-chooseassignment__buttons {
  margin: 2vh 251px 54px;
  -webkit-justify-content: center;
          justify-content: center; }
  .tv-chooseassignment__buttons button {
    padding: 14px 50px;
    border-radius: 5px;
    box-shadow: 0 2px 30px 0 rgba(153, 153, 153, 0.2); }

.tv-chooseassignment__icon {
  text-align: center;
  padding-top: 61px; }
  .tv-chooseassignment__icon::before {
    color: #fff !important;
    font-size: 3.75rem; }

.tv-form__control {
  background-color: #fff; }
  .tv-form__control.tv-white-disabled:disabled {
    background-color: #fff; }
  .tv-form__control:disabled {
    background-color: #d1d1d1; }

.icon-create-new {
  font-size: 22px; }
  .icon-create-new::before {
    color: #6478e6; }

.icon-create-new--alt {
  font-size: 22px; }
  .icon-create-new--alt::before {
    content: '\e921';
    color: #6478e6; }

.calendar-small .rdrDayToday {
  cursor: not-allowed; }
  .calendar-small .rdrDayToday .rdrDayNumber span {
    color: #aeb9bf !important; }

.k-input,
.k-list-container {
  font: 300 0.8125rem "Circular Std", "Circular Std" !important; }

@-webkit-keyframes tv-anim-effect-1 {
  0%,
  100% {
    opacity: 0;
    border: 0px; }
  40%,
  60% {
    opacity: 1;
    border: 2px solid #873bff; } }

@-webkit-keyframes tv-anim-effect-2 {
  0%,
  100% {
    opacity: 0; }
  40%,
  60% {
    opacity: 1; } }

@keyframes tv-anim-effect-2 {
  0%,
  100% {
    opacity: 0; }
  40%,
  60% {
    opacity: 1; } }

@keyframes tv-anim-effect-2 {
  0% {
    -webkit-transform: scale3d(0.96, 0.96, 1);
    box-shadow: 0px 0px 11px 5px #dd59597c;
    transform: scale3d(0.96, 0.96, 1);
    opacity: 1; }
  100% {
    -webkit-transform: scale3d(1.04, 1.04, 1);
    box-shadow: 0px 0px 11px 5px #dd59593d;
    transform: scale3d(1.04, 1.04, 1);
    opacity: 0; } }

@-webkit-keyframes tv-anim-effect-1 {
  0%,
  100% {
    -webkit-transform: scale(0.96, 0.96, 1);
    transform: scale(0.96, 0.96, 1); }
  40%,
  60% {
    -webkit-transform: scale3d(1.04, 1.04, 1);
    transform: scale(1.04, 1.04, 1); } }

.text-field-style {
  height: 40px; }

.bank-id {
  margin-top: 35px; }

.contact-description {
  font: 1rem "Circular Std", "Circular Std"; }

.tv-deviation-message {
  text-align: center; }
  .tv-deviation-message-title {
    font: 500 32px/20px "Circular Std", "Circular Std";
    padding: 20px; }
  .tv-deviation-message-paragraph {
    font: 400 18px/23px "Circular Std", "Circular Std";
    color: #74737b;
    padding: 15px; }
  .tv-deviation-message-button {
    margin: 10px auto;
    padding: 16px;
    margin-bottom: 24px;
    width: 250px;
    border: none;
    font: 500 16px/20px "Circular Std", "Circular Std";
    background: #873bff;
    border-radius: 4px;
    color: #fff; }

.quick-order-cont {
  margin-top: 24px; }
  .quick-order-cont .form-group .dropdown {
    height: 48px; }
  .quick-order-cont .form-group .custom-control-label {
    background-color: #fff !important; }
  .quick-order-cont .form-group label {
    font: 500 16px/20px "Circular Std", "Circular Std";
    color: #252525; }
  .quick-order-cont .form-group input,
  .quick-order-cont .form-group select {
    font: 400 16px/20px "Circular Std", "Circular Std" !important;
    height: 48px !important; }
  .quick-order-cont .form-group .tv-single-assign-form__control {
    font: 400 16px/20px "Circular Std", "Circular Std" !important;
    height: 48px !important;
    padding: 14px 20px; }
    .quick-order-cont .form-group .tv-single-assign-form__control.tv-single-assign-form__textfield {
      min-height: 72px !important;
      height: auto !important; }
    .quick-order-cont .form-group .tv-single-assign-form__control .k-input {
      font: 400 16px/20px "Circular Std", "Circular Std" !important; }
    .quick-order-cont .form-group .tv-single-assign-form__control span[role="listbox"] {
      border-bottom: none !important; }
  .quick-order-cont .w-90 {
    width: 90%; }
  .quick-order-cont .plus_button {
    margin-top: 8px;
    position: relative;
    left: 4px; }
  .quick-order-cont .dropdown {
    height: 42px; }
    .quick-order-cont .dropdown .rc-time-picker-input {
      height: inherit;
      font: 400 16px/20px "Circular Std", "Circular Std" !important; }
      .quick-order-cont .dropdown .rc-time-picker-input::-ms-clear {
        display: none; }
    .quick-order-cont .dropdown ul.panel {
      scroll-behavior: smooth;
      padding-left: 15px;
      top: 50px; }
      .quick-order-cont .dropdown ul.panel li {
        font: 100 13px/16px "Circular Std", "Circular Std" !important;
        height: 40px;
        padding: 14px 0; }
    .quick-order-cont .dropdown .rc-time-picker-select-option {
      height: 48px;
      width: 30px;
      position: absolute;
      right: 0;
      cursor: pointer; }
      .quick-order-cont .dropdown .rc-time-picker-select-option::after {
        content: "";
        top: 20px;
        display: inline-block;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: 5px solid #888888; }
  .quick-order-cont .tv-form__error {
    width: 100%;
    text-align: end;
    margin-top: 5px; }
  .quick-order-cont .contact-person-email-edit {
    font-weight: bold;
    cursor: pointer;
    padding-left: 4px; }
    .quick-order-cont .contact-person-email-edit:hover {
      text-decoration: underline; }
  .quick-order-cont .tv-date-range-picker__dialog {
    left: 0; }
  .quick-order-cont.side .tv-date-range-picker__dialog {
    position: fixed;
    left: 25px;
    margin-top: 2px; }
  .quick-order-cont .tv-form__date-field {
    font: 400 16px/20px "Circular Std", "Circular Std" !important;
    padding: 14px 16px !important; }
    .quick-order-cont .tv-form__date-field:after {
      top: 12px !important; }
  .quick-order-cont .tv-form__control--error {
    margin-bottom: 0;
    border: 2px solid red !important; }
  .quick-order-cont .tv-single-assign-form__dropdownlist {
    padding: 10px 10px !important;
    background-image: linear-gradient(45deg, transparent 50%, #696767 50%), linear-gradient(135deg, #696767 50%, transparent 50%), linear-gradient(to right, white, white);
    background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px), 100% 0;
    background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
    background-repeat: no-repeat; }
    .quick-order-cont .tv-single-assign-form__dropdownlist:after {
      content: "\e933";
      position: absolute;
      font-family: "transvoice-icon";
      top: 12px;
      left: 2px; }
  .quick-order-cont .tv-single-assign-form__label {
    margin: 8px -12px 8px -12px; }
  .quick-order-cont .form-button-cont {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
    -webkit-align-content: space-around;
            align-content: space-around;
    -webkit-align-items: flex-start;
    align-items: flex-start; }
    .quick-order-cont .form-button-cont button {
      margin-left: 5px; }
  .quick-order-cont .tv-single-assign-form__input {
    line-height: 10px; }
  .quick-order-cont .same-width {
    min-width: 110px; }
  .quick-order-cont .add-contact-cont {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between; }
  .quick-order-cont .links-add {
    width: 150px;
    font: 400 14px/16px "Circular Std", sans-serif;
    border-radius: 4px;
    color: #873bff;
    margin-top: 10px;
    padding: 5px;
    text-align: left;
    cursor: pointer; }
    .quick-order-cont .links-add:hover {
      font-weight: 900;
      letter-spacing: 0.4px; }
  .quick-order-cont .links-add-container {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    margin-top: -22px; }
  .quick-order-cont .links-add-secondary {
    margin: 0;
    width: 100%;
    text-align: end; }
  .quick-order-cont .reminder-sms__container {
    display: -webkit-flex;
    display: flex;
    padding-block: 20px 15px;
    -webkit-justify-content: space-between;
            justify-content: space-between; }
    .quick-order-cont .reminder-sms__container-text {
      font: 500 14px/20px "Circular Std", "Circular Std";
      letter-spacing: 0.2px;
      width: 230px; }
    .quick-order-cont .reminder-sms__container .tv-toggleSwitch .tv-toggleSwitch__wrapper .Slider.isChecked {
      background-color: #34c759; }
  .quick-order-cont .tv-checkbox .custom-control-input ~ .custom-control-label {
    text-transform: none;
    text-wrap: nowrap;
    padding-left: 5px; }
    .quick-order-cont .tv-checkbox .custom-control-input ~ .custom-control-label.tv-checkbox-inactive::after {
      background-image: none; }
  .quick-order-cont .tv-checkbox .custom-control-input:disabled ~ .custom-control-label.tv-checkbox-active::before {
    background-color: #adb5bd !important; }
  .quick-order-cont .tv-checkbox .custom-control-input:disabled ~ .custom-control-label::before {
    border-color: #adb5bd; }

.tv-single-assign-form__modal-wrap.quick-order-preview {
  padding: 10px 33px 10px 33px;
  height: auto;
  margin-bottom: 13px;
  width: auto;
  min-width: 688px; }
  .tv-single-assign-form__modal-wrap.quick-order-preview .tv-single-assign-form__container {
    width: 100%;
    background: #fff;
    border-radius: 5px;
    box-shadow: none;
    margin: 0 auto 32px auto; }
  .tv-single-assign-form__modal-wrap.quick-order-preview .tv-single-assign-form__panel--secondary {
    padding: 0px 14px 8px;
    background-color: #fff;
    border-top: none;
    border-bottom: none; }
  .tv-single-assign-form__modal-wrap.quick-order-preview .edit-text {
    padding: 1px 11px 1px 11px;
    background-color: #6381bb;
    cursor: pointer;
    color: #fff;
    border-radius: 50px;
    margin: 0 10px 0 10px;
    float: right; }

.plus_button {
  margin-top: 10px;
  position: relative;
  left: -15px; }

.submit-button-center {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 8px 0px 12px 0px;
  position: fixed;
  width: 688px;
  height: 75px;
  bottom: 35px;
  background-color: #fff; }
  .submit-button-center button {
    padding: 10px 80px 10px 80px; }

.tv-modal__holder {
  max-height: calc(100vh - 70px);
  z-index: 20;
  padding: 0 10px;
  -webkit-animation: zoomIn 0.5s;
          animation: zoomIn 0.5s;
  max-width: 708px;
  overflow-y: auto;
  position: absolute; }

@media (min-width: 768px) {
  .tv-quick-order__scroll-container {
    height: calc(100vh - 255px) !important; }
    .tv-quick-order__scroll-container > div {
      overflow-x: hidden !important; }
  .tk-assignment-creation__container {
    left: 0;
    top: 0;
    width: calc(100% - 10px);
    height: auto !important;
    border: 1px solid #e0e0e0;
    background-color: #fbfbfb;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 5px 10px; }
    .tk-assignment-creation__container .tv-workassignment__modal {
      top: -170px; }
    .tk-assignment-creation__container .tv-modal__overlay {
      top: -170px !important; }
    .tk-assignment-creation__container .tk-dialog__btn-close {
      position: absolute;
      right: 0;
      top: 0;
      padding: 18px !important;
      height: 56px;
      z-index: 4; }
      .tk-assignment-creation__container .tk-dialog__btn-close::before {
        font-size: 15px; } }

@media (max-width: 768px) {
  .tk-assignment-creation__container {
    left: 0;
    width: calc(100vw);
    height: 100%;
    padding: 16px;
    overflow: scroll; }
    .tk-assignment-creation__container .tk-assignment-list-box_header {
      text-align: center; }
    .tk-assignment-creation__container .tk-dialog__btn-close {
      top: 8px; }
  .tk-assignment-creation__footer {
    position: fixed;
    bottom: 0px;
    padding: 14px 24px;
    left: 0;
    width: 100vw;
    background-color: #fff;
    z-index: 1; }
  .quick-order-cont {
    margin-bottom: 300px; }
    .quick-order-cont .calendar-small .rdrMonth {
      padding: 0;
      width: auto; }
      .quick-order-cont .calendar-small .rdrMonth .rdrWeekDays,
      .quick-order-cont .calendar-small .rdrMonth .rdrDays {
        width: auto; }
    .quick-order-cont > div.form-group.col-12.pl-1.mb-5 {
      margin-bottom: 220px !important; }
    .quick-order-cont .tv-date-range-picker__dialog {
      position: fixed;
      left: 32px !important; }
  .tv-quick-order__scroll-container {
    height: calc(100vh - 170px) !important;
    width: calc(100vw - 34px); }
    .tv-quick-order__scroll-container > div:nth-child(1) {
      overflow-x: hidden !important; } }

.tv-dropdown__footer-conactperson-add {
  display: -webkit-flex;
  display: flex;
  padding: 5px 24px;
  text-align: center;
  cursor: pointer;
  background-color: #e0e0e0;
  font-weight: bolder;
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important; }

.k-list-container {
  border-radius: 8px !important; }
  .k-list-container .k-popup {
    border-radius: 8px !important; }

.text-field-style {
  height: 40px; }

.k-list .k-item:hover.k-state-selected {
  color: #873bff; }

.k-list .k-item.k-state-selected {
  color: #873bff;
  background-color: #ebebeb; }

.k-list-optionlabel:hover.k-state-selected {
  color: #873bff; }

.k-list-optionlabel.k-state-selected {
  color: #873bff; }

.tv-status-filter {
  font: 500 16px/20px "Circular Std", "Circular Std";
  padding: 16px 16px 16px 0px;
  background-color: #f7f9ff;
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: 16px; }
  .tv-status-filter-list {
    list-style: none; }
  .tv-status-filter__title {
    font: 500 16px/20px "Circular Std", "Circular Std";
    margin-bottom: 15px; }
    .tv-status-filter__title__wrapper {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-between;
              justify-content: space-between; }
    .tv-status-filter__title__sub {
      font-size: 14px;
      line-height: 18px;
      color: #74737b; }
      .tv-status-filter__title__sub :hover {
        cursor: pointer; }
  .tv-status-filter .sub {
    font: 400 14px "Circular Std", "Circular Std";
    line-height: 18px;
    color: #873bff;
    cursor: pointer; }

.tv-search-box {
  position: relative; }
  .tv-search-box input {
    background-color: #ffffff !important;
    border: 1px solid #e0e0e0;
    border-radius: 24px;
    height: 48px;
    font-size: 16px;
    font: 400 16px/18px 'Circular Std', sans-serif;
    margin: 0px;
    width: 100%;
    padding: 0px 12px 0 40px; }
  .tv-search-box i {
    position: absolute;
    color: #e0e0e0;
    top: 14px;
    left: 16px; }

@media (max-width: 768px) {
  .tv-right-status {
    padding: 0px 16px 0 16px !important;
    margin-bottom: 24px;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.04); }
    .tv-right-status .nav-container {
      grid-gap: 0 !important;
      gap: 0 !important; }
    .tv-right-status .navigation {
      display: none; }
    .tv-right-status .menu-button {
      display: inline-block !important;
      font-size: 24px;
      border: none;
      top: -3px;
      background: none; }
    .tv-right-status .icon-search {
      color: #000; }
    .tv-right-status .mobile-nav {
      margin-left: 0px !important;
      -webkit-align-items: center;
              align-items: center;
      display: -webkit-flex;
      display: flex; }
    .tv-right-status .tv-avatar {
      width: unset !important;
      margin-right: unset !important;
      display: inline-grid !important;
      right: 10px; }
    .tv-right-status .menu-button {
      display: none; }
  a:hover {
    text-decoration: none; } }

.tv-filter-list__element .tv-avatar-list__menu {
  position: fixed !important;
  top: 60px !important;
  right: 8px; }

.tv-filter-list__user-name {
  display: none; }

.tv-search {
  display: none; }

.tv-right-status {
  padding: 0 32px 0 24px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  background-color: #f7f9ff !important; }
  .tv-right-status .nav-container {
    display: -webkit-flex;
    display: flex;
    grid-gap: 40px;
    gap: 40px;
    -webkit-align-items: center;
            align-items: center; }
  .tv-right-status .navbar-expand {
    margin-left: 25px; }
    .tv-right-status .navbar-expand .navbar-nav {
      -webkit-align-items: center;
              align-items: center;
      grid-gap: 8px;
      gap: 8px;
      margin-right: -8px; }
  .tv-right-status .nav-item {
    margin: 5px 0;
    padding: 8px 16px;
    color: #8f8f8f;
    font-family: "Circular Std", "Circular Std";
    font-weight: 500;
    font-size: 14px;
    border-radius: 5px; }
    .tv-right-status .nav-item:hover {
      color: #873bff;
      text-decoration: underline;
      cursor: pointer; }
  .tv-right-status .nav-active {
    color: #873bff;
    background-color: #e1e6ff; }
  @supports (display: grid) {
    .tv-right-status .tv-left-pannel__logo {
      padding-top: unset !important; } }
  @supports (display: -ms-grid) {
    .tv-right-status .tv-left-pannel__logo {
      padding-top: 10px; } }
  .tv-right-status .menu-button {
    display: none; }
  .tv-right-status .booking-button {
    margin-left: 0;
    margin-bottom: 0;
    line-height: 12px;
    font-family: 'Circular Std', 'Circular Std';
    font-size: 14px;
    background: #873bff;
    border: none;
    outline: none;
    color: white;
    max-height: 48px;
    padding: 14px 24px;
    border-radius: 40px;
    width: 133px; }
    .tv-right-status .booking-button i.fa {
      -webkit-text-stroke: 1px #873bff;
      margin-right: 13px; }
  .tv-right-status .icon-search {
    color: #000; }
  .tv-right-status a:hover {
    text-decoration: none; }
  .tv-right-status .tk-left-drawer {
    top: 72px; }

.button-style {
  margin-top: 15px;
  margin-left: 10pxs; }

.tv-bookDirect-button-nav {
  border-radius: 40px;
  font-size: 14px;
  padding: 8px 22px;
  height: auto;
  color: #873bff; }
  .tv-bookDirect-button-nav__wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    -webkit-align-items: center;
            align-items: center;
    position: relative; }

.mobile-app-info {
  z-index: 21; }
  .mobile-app-info .tv-modal__holder {
    background-color: #fff;
    margin: auto 20px; }
    .mobile-app-info .tv-modal__holder .header {
      font: 500 18px/20px "Circular Std", "Circular Std";
      text-align: left;
      padding: 18px 10px;
      height: 56px;
      color: #000;
      border-bottom: 1px solid #e0e0e0;
      letter-spacing: 0.2px; }
    .mobile-app-info .tv-modal__holder .content-container {
      padding: 20px;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      grid-gap: 20px;
      gap: 20px; }
      .mobile-app-info .tv-modal__holder .content-container .button-container {
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: center;
                justify-content: center; }
        .mobile-app-info .tv-modal__holder .content-container .button-container .download-button {
          text-decoration: none;
          background: white;
          max-height: 60px; }
          .mobile-app-info .tv-modal__holder .content-container .button-container .download-button .store-badge {
            max-width: 160px;
            height: 60px;
            object-fit: contain; }

.tv-booknow {
  text-align: left; }
  .tv-booknow__container {
    background-color: #fff;
    padding: 18px 14px; }
  .tv-booknow_row {
    padding: 0px 12px;
    padding-left: 0px; }
    .tv-booknow_row .rc-time-picker-input {
      border: 1px solid #e0e0e0; }
    @media screen and (max-width: 768px) {
      .tv-booknow_row {
        padding-left: 0px; } }
  .tv-booknow_col {
    padding: 8px 8px 0px !important; }
  .tv-booknow__link {
    color: #873bff; }
    .tv-booknow__link:hover {
      color: #873bff;
      text-decoration: underline !important; }
  .tv-booknow__title {
    padding: 25px 0 0 0;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    font: 400 24px/20px "Circular Std", "Circular Std"; }
    .tv-booknow__title-text {
      color: #313131;
      font: 400 16px/20px "Circular Std", "Circular Std";
      margin-top: 15px;
      text-align: center;
      height: 40px;
      padding: auto 16px; }
      .tv-booknow__title-text.text-medium {
        font: 300 1.375rem/2.5rem "Circular Std", "Circular Std"; }
  .tv-booknow__text {
    font-family: "Circular Std", "Circular Std";
    font-size: 12px;
    color: #000;
    font-weight: 450;
    line-height: 15px;
    margin-top: 14px; }
    .tv-booknow__text-date {
      font-family: "Circular Std", "Circular Std";
      margin-top: 16px;
      margin-bottom: 28px;
      color: #252525; }
    .tv-booknow__text-unit {
      font-family: "Circular Std", "Circular Std"; }
  .tv-booknow__modal {
    top: 0 !important;
    left: -11px !important;
    height: 100vh !important;
    position: absolute;
    z-index: 62; }
    .tv-booknow__modal-hidden {
      display: none; }
    .tv-booknow__modal .form-group {
      margin-bottom: 8px !important; }
    .tv-booknow__modal-cancel {
      z-index: 62;
      padding-bottom: 24px; }
      .tv-booknow__modal-cancel__container {
        padding: 0 10px 24px; }
      .tv-booknow__modal-cancel .tv-modal__holder {
        overflow-y: unset; }
    .tv-booknow__modal-header, .tv-booknow__modal-cancel-header {
      font: 500 18px/20px "Circular Std", "Circular Std";
      text-align: left;
      padding: 18px 10px;
      height: 56px;
      color: #000;
      border-bottom: 1px solid #e0e0e0;
      letter-spacing: 0.2px; }
    .tv-booknow__modal .title {
      display: -webkit-flex;
      display: flex;
      width: 100%;
      -webkit-justify-content: center;
              justify-content: center;
      font-family: "Circular Std", "Circular Std";
      font-style: normal;
      font-weight: 500;
      font-size: 32px;
      line-height: 20px;
      margin-top: 48px;
      margin-bottom: 28px;
      color: #252525; }
    .tv-booknow__modal .text {
      font-size: 18px;
      color: #74737b;
      text-align: center;
      margin-bottom: 30px;
      font-family: "Circular Std", "Circular Std"; }
    .tv-booknow__modal .tv-modal__overlay {
      top: -175px !important;
      left: -10px !important;
      width: 100vw !important;
      height: unset;
      border-radius: 4px; }
    .tv-booknow__modal .tv-modal__holder {
      border-radius: 8px;
      min-width: 35vw;
      max-width: -webkit-max-content;
      max-width: max-content;
      background-color: #fff;
      width: 800px;
      max-height: unset;
      top: 63px;
      background-color: #fff;
      overflow-x: hidden; }
      .tv-booknow__modal .tv-modal__holder.booknow-holder {
        width: 672px;
        padding: 140px 48px 36px;
        border-radius: 24px;
        max-width: unset; }
        .tv-booknow__modal .tv-modal__holder.booknow-holder .message-container {
          padding: 18px 72px 0; }
          .tv-booknow__modal .tv-modal__holder.booknow-holder .message-container .icon-holder {
            width: 96px;
            height: 96px;
            margin: 24px auto; }
            .tv-booknow__modal .tv-modal__holder.booknow-holder .message-container .icon-holder img {
              max-width: 100%;
              max-height: 100%;
              object-fit: contain; }
          .tv-booknow__modal .tv-modal__holder.booknow-holder .message-container .tv-booknow__form__buttons {
            padding: 0; }
            .tv-booknow__modal .tv-modal__holder.booknow-holder .message-container .tv-booknow__form__buttons button {
              margin: 0 64px; }
            .tv-booknow__modal .tv-modal__holder.booknow-holder .message-container .tv-booknow__form__buttons .contact-link {
              text-decoration: none;
              display: block;
              margin: auto;
              padding: 14px 44px;
              background-color: #873bff;
              border-radius: 100px;
              color: #fff;
              font-size: 16px;
              font-weight: 500; }
      .tv-booknow__modal .tv-modal__holder .tv-modal__btn-close {
        top: 6px;
        right: 6px; }
        .tv-booknow__modal .tv-modal__holder .tv-modal__btn-close::before {
          color: #fff; }
      .tv-booknow__modal .tv-modal__holder__custom-header {
        position: absolute;
        width: 100%;
        height: 140px;
        left: 0;
        top: 0;
        z-index: 3;
        background-color: #873bff;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        grid-gap: 36px;
        gap: 36px;
        padding-left: 46px; }
        .tv-booknow__modal .tv-modal__holder__custom-header img {
          width: 72px;
          height: auto;
          object-fit: contain; }
        .tv-booknow__modal .tv-modal__holder__custom-header h2 {
          color: #fff;
          font-weight: 500;
          font-size: 32px;
          line-height: 40px; }
    .tv-booknow__modal-actions-button_cancel {
      margin-top: 24px;
      border: 1px solid #e0e0e0;
      border-radius: 4px;
      width: 100%;
      height: 48px;
      font: 400 16px/20px "Circular Std", "Circular Std";
      padding: 10px;
      background: #fff;
      margin: 20px 0 2px; }
    .tv-booknow__modal-actions-button_submit {
      margin-top: 24px;
      border: none;
      font: 400 16px/20px "Circular Std", "Circular Std";
      border-radius: 4px;
      width: 100%;
      height: 48px;
      padding: 10px;
      color: #fff;
      background-color: #873bff;
      margin: 20px 0 2px; }
  .tv-booknow__form {
    margin-top: 12px;
    width: 100%; }
    .tv-booknow__form__buttons {
      padding: 24px 0 10px 0; }
      .tv-booknow__form__buttons button {
        -webkit-flex: 1 1;
                flex: 1 1;
        margin: 0 8px;
        border-radius: 100px; }
    .tv-booknow__form .dropdown {
      height: 42px; }
      .tv-booknow__form .dropdown .rc-time-picker-input {
        height: inherit;
        font: 400 16px/20px "Circular Std", "Circular Std" !important; }
        .tv-booknow__form .dropdown .rc-time-picker-input::-ms-clear {
          display: none; }
      .tv-booknow__form .dropdown ul.panel {
        scroll-behavior: smooth;
        padding-left: 15px;
        top: 50px; }
        .tv-booknow__form .dropdown ul.panel li {
          font: 100 13px/16px "Circular Std", "Circular Std" !important;
          height: 40px;
          padding: 14px 0; }
      .tv-booknow__form .dropdown .rc-time-picker-select-option {
        height: 48px;
        width: 39px;
        position: absolute;
        right: 0;
        cursor: pointer; }
        .tv-booknow__form .dropdown .rc-time-picker-select-option::after {
          content: '';
          top: 20px;
          display: inline-block;
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
          border-top: 5px solid #888888; }
    .tv-booknow__form .tv-date-range-picker__dialog {
      left: 0; }
    .tv-booknow__form.side .tv-date-range-picker__dialog {
      position: fixed;
      left: 25px;
      margin-top: 2px; }
    .tv-booknow__form .tv-form__date-field {
      font: 400 16px/20px "Circular Std", "Circular Std" !important;
      padding: 14px 16px !important; }
      .tv-booknow__form .tv-form__date-field:after {
        top: 12px !important; }
    .tv-booknow__form .tv-form__control--error {
      margin-bottom: 0;
      border: 2px solid red !important; }
    .tv-booknow__form .tv-form__control--error--timePicker input {
      margin-bottom: 0;
      border: 2px solid red !important; }
    .tv-booknow__form .tv-single-assign-form__dropdownlist {
      padding: 10px 10px !important;
      background-image: linear-gradient(45deg, transparent 50%, #696767 50%), linear-gradient(135deg, #696767 50%, transparent 50%), linear-gradient(to right, white, white);
      background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px), 100% 0;
      background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
      background-repeat: no-repeat; }
      .tv-booknow__form .tv-single-assign-form__dropdownlist:after {
        content: '\e933';
        position: absolute;
        font-family: 'transvoice-icon';
        top: 12px;
        left: 2px; }
  @media screen and (max-width: 768px) {
    .tv-booknow__container {
      height: 100vh; }
    .tv-booknow_col {
      padding: 8px 0 !important; }
    .tv-booknow__text {
      font-family: "Circular Std", "Circular Std";
      font-size: 16px;
      color: #74737b; }
      .tv-booknow__text-date {
        font-family: "Circular Std", "Circular Std";
        margin-top: 16px;
        color: #252525; }
      .tv-booknow__text-unit {
        font-family: "Circular Std", "Circular Std"; }
    .tv-booknow__container {
      margin-top: 12px;
      padding: 18px 6px; }
    .tv-booknow__form {
      margin-bottom: 0; }
      .tv-booknow__form form {
        padding-bottom: 82px; }
      .tv-booknow__form .p-2 {
        padding: 0.5rem 0 !important; }
      .tv-booknow__form .row {
        margin: 0; }
    .tv-booknow__modal {
      top: 0 !important;
      left: 0 !important;
      width: 100vw;
      position: fixed;
      height: 100vh; }
      .tv-booknow__modal .tv-modal__holder {
        position: fixed;
        top: 0;
        max-height: 100vh;
        height: 100vh;
        position: absolute;
        position: relative;
        min-width: 270px !important;
        width: 100%;
        max-width: unset; }
        .tv-booknow__modal .tv-modal__holder.booknow-holder {
          border-radius: 0;
          padding-left: 16px;
          padding-right: 16px; }
          .tv-booknow__modal .tv-modal__holder.booknow-holder .message-container {
            padding: 36px 16px;
            display: -webkit-flex;
            display: flex;
            -webkit-flex-direction: column;
                    flex-direction: column; }
            .tv-booknow__modal .tv-modal__holder.booknow-holder .message-container .tv-booknow__form__buttons button {
              margin: 0 32px; }
          .tv-booknow__modal .tv-modal__holder.booknow-holder .tv-booknow__loading-container {
            height: 100%;
            -webkit-justify-content: center;
                    justify-content: center; }
          .tv-booknow__modal .tv-modal__holder.booknow-holder .tv-available-interpreters__container.message-container {
            padding: 18px 16px;
            height: 100%;
            display: -webkit-flex;
            display: flex;
            -webkit-flex-direction: column;
                    flex-direction: column;
            -webkit-justify-content: center;
                    justify-content: center; }
          .tv-booknow__modal .tv-modal__holder.booknow-holder .tv-available-interpreters__container .tv-available-interpreters-description {
            margin-top: 20px;
            grid-gap: 16px;
            gap: 16px; }
          .tv-booknow__modal .tv-modal__holder.booknow-holder .tv-available-interpreters__container .tv-available-interpreters-slot-container {
            grid-column-gap: 12px;
            -webkit-column-gap: 12px;
                    column-gap: 12px;
            margin-bottom: 12px; }
            .tv-booknow__modal .tv-modal__holder.booknow-holder .tv-available-interpreters__container .tv-available-interpreters-slot-container .tv-available-interpreters__row {
              width: 48%;
              -webkit-align-items: center;
                      align-items: center; }
              .tv-booknow__modal .tv-modal__holder.booknow-holder .tv-available-interpreters__container .tv-available-interpreters-slot-container .tv-available-interpreters__row .user-icons .fa-user:not(:first-of-type) {
                display: none; }
          .tv-booknow__modal .tv-modal__holder.booknow-holder .tv-booknow-complete__container {
            display: -webkit-flex;
            display: flex;
            -webkit-flex-direction: column;
                    flex-direction: column;
            grid-gap: 16px;
            gap: 16px;
            margin-top: 16px; }
            .tv-booknow__modal .tv-modal__holder.booknow-holder .tv-booknow-complete__container .tv-booknow-complete__interpreter-details {
              font-size: 18px; }
            .tv-booknow__modal .tv-modal__holder.booknow-holder .tv-booknow-complete__container .tv-booknow-complete__data-container {
              width: 100%; }
            .tv-booknow__modal .tv-modal__holder.booknow-holder .tv-booknow-complete__container .tv-booknow-complete__data-footer-text {
              font-size: 14px; }
          .tv-booknow__modal .tv-modal__holder.booknow-holder .tv-booknow-complete__buttons {
            margin-top: 16px; }
            .tv-booknow__modal .tv-modal__holder.booknow-holder .tv-booknow-complete__buttons button {
              width: 70%; }
      .tv-booknow__modal-cancel {
        z-index: 62;
        padding-bottom: 24px; }
        .tv-booknow__modal-cancel .tv-modal__btn-close {
          display: none; }
        .tv-booknow__modal-cancel-header {
          display: none; }
        .tv-booknow__modal-cancel .title {
          margin-bottom: 24px; }
        .tv-booknow__modal-cancel .text {
          margin-bottom: 4px; }
        .tv-booknow__modal-cancel .tv-modal__overlay {
          top: 0 !important;
          left: 0 !important; }
        .tv-booknow__modal-cancel .tv-modal__holder {
          border-radius: 4px;
          margin: 0 10px;
          max-width: 400px;
          max-height: unset;
          background-color: #fff;
          overflow-x: hidden;
          z-index: 63;
          padding: 0 10px 24px;
          -webkit-animation: zoomIn 0.5s;
                  animation: zoomIn 0.5s;
          max-width: 708px;
          overflow-y: auto;
          height: auto;
          width: 332px;
          padding-bottom: 24px; } }
  .tv-booknow__loading-container {
    padding: 24px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    grid-gap: 32px;
    gap: 32px; }
    .tv-booknow__loading-container-title {
      font-size: 20px;
      font-weight: 600; }
    .tv-booknow__loading-container-animation-container {
      height: 250px;
      width: 100%;
      position: relative;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center; }
      .tv-booknow__loading-container-animation-container-images img {
        width: 95px;
        object-fit: contain;
        position: absolute;
        opacity: 0;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%); }
      .tv-booknow__loading-container-animation-container-images .person-1 {
        -webkit-animation: slide-in-left 2s ease 0s infinite normal forwards, hide 12s ease 0s infinite;
                animation: slide-in-left 2s ease 0s infinite normal forwards, hide 12s ease 0s infinite; }
      .tv-booknow__loading-container-animation-container-images .person-4 {
        -webkit-animation: slide-out-right 2s ease 2s infinite normal forwards, hide 12s ease 2s infinite;
                animation: slide-out-right 2s ease 2s infinite normal forwards, hide 12s ease 2s infinite; }
      .tv-booknow__loading-container-animation-container-images .person-2 {
        -webkit-animation: slide-in-left 2s ease 4s infinite normal forwards, hide 12s ease 4s infinite;
                animation: slide-in-left 2s ease 4s infinite normal forwards, hide 12s ease 4s infinite; }
      .tv-booknow__loading-container-animation-container-images .person-5 {
        -webkit-animation: slide-out-right 2s ease 6s infinite normal forwards, hide 12s ease 6s infinite;
                animation: slide-out-right 2s ease 6s infinite normal forwards, hide 12s ease 6s infinite; }
      .tv-booknow__loading-container-animation-container-images .person-3 {
        -webkit-animation: slide-in-left 2s ease 8s infinite normal forwards, hide 12s ease 8s infinite;
                animation: slide-in-left 2s ease 8s infinite normal forwards, hide 12s ease 8s infinite; }
      .tv-booknow__loading-container-animation-container-images .person-6 {
        -webkit-animation: slide-out-right 2s ease 10s infinite normal forwards, hide 12s ease 10s infinite;
                animation: slide-out-right 2s ease 10s infinite normal forwards, hide 12s ease 10s infinite; }
      .tv-booknow__loading-container-animation-container-magnifier {
        max-width: 250px;
        max-height: 250px;
        object-fit: contain;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%); }

.tv-available-interpreters__container {
  padding: 18px 14px 25px; }
  .tv-available-interpreters__container.message-container {
    padding: 18px 72px 25px; }
    .tv-available-interpreters__container.message-container .tv-booknow__form__buttons button {
      margin: 0 64px; }
  .tv-available-interpreters__container .tv-booknow__form__buttons .back-button {
    color: #6478e6;
    font-size: 14px;
    font-weight: 600;
    text-decoration: underline;
    border: none; }

.tv-available-interpreters-description {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center; }
  .tv-available-interpreters-description__sub-heading {
    font-size: 20px;
    font-weight: 500;
    color: #000;
    margin-bottom: 16px;
    font-family: "Circular Std", "Circular Std"; }
  .tv-available-interpreters-description__text {
    font-size: 16px;
    color: #000;
    margin-bottom: 32px;
    font-family: "Circular Std", "Circular Std"; }

.tv-available-interpreters__title {
  font-family: "Circular Std", "Circular Std";
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  color: #000;
  margin-top: 22px; }

.tv-available-interpreters__message {
  font-family: "Circular Std", "Circular Std";
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #000;
  margin-top: 20px;
  margin-bottom: 24px; }

.tv-available-interpreters__header {
  font-family: "Circular Std", "Circular Std";
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  font-size: 14px;
  color: #74737b;
  padding-bottom: 15px;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 16px; }

.tv-available-interpreters-slot-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  grid-gap: 16px;
  gap: 16px; }

.tv-available-interpreters__row {
  width: 45%;
  border-radius: 8px;
  font-family: "Circular Std", "Circular Std";
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 16px;
  color: #000;
  background-color: #e1e6ff;
  box-sizing: border-box; }
  .tv-available-interpreters__row-active {
    box-shadow: inset 0 0 0 2px #6478e6; }
  .tv-available-interpreters__row .user-icons {
    display: -webkit-flex;
    display: flex; }
    .tv-available-interpreters__row .user-icons .plus {
      font-size: 14px;
      font-weight: 600;
      margin-right: 5px;
      line-height: 14px; }
  .tv-available-interpreters__row i {
    margin-right: 6px;
    font-size: 12px;
    vertical-align: middle;
    margin-bottom: 2px; }

.tv-booknow-complete__container {
  padding: 0 14px; }
  .tv-booknow-complete__container hr {
    margin: 16px 0 24px; }

.tv-booknow-complete__heading {
  font-family: "Circular Std", "Circular Std";
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  color: #000;
  margin-top: 24px; }

.tv-booknow-complete__interpreter-details {
  font-family: "Circular Std", "Circular Std";
  font-size: 16px;
  font-weight: 450;
  text-align: center;
  color: #000;
  margin-top: 16px;
  text-align: center; }
  .tv-booknow-complete__interpreter-details div {
    margin-bottom: 8px; }
  .tv-booknow-complete__interpreter-details a {
    text-decoration: underline;
    font-size: 18px;
    font-weight: 500;
    color: #3772ce; }

.tv-booknow-complete__reference-number {
  font-family: "Circular Std", "Circular Std";
  margin-top: 28px;
  margin-bottom: 36px;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  color: #74737b; }

.tv-booknow-complete__data-container {
  border-radius: 20px;
  padding: 24px;
  margin: 16px auto;
  width: 60%;
  box-shadow: 0px 1px 5px 0px #00000029; }

.tv-booknow-complete__data-table {
  display: grid;
  grid-template-columns: repeat(2, 1fr); }

.tv-booknow-complete__data-heading {
  font-family: "Circular Std", "Circular Std";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #000;
  margin-bottom: 16px;
  text-align: center; }

.tv-booknow-complete__data-item {
  font-family: "Circular Std", "Circular Std";
  display: -webkit-flex;
  display: flex;
  padding: 8px 0;
  font-size: 14px;
  color: #535252;
  -webkit-align-items: center;
          align-items: center;
  margin-left: 10px; }
  .tv-booknow-complete__data-item-label {
    font-size: 20px;
    margin-right: 10px; }
    .tv-booknow-complete__data-item-label svg,
    .tv-booknow-complete__data-item-label path {
      width: 20px;
      height: 20px;
      fill: #535252; }
  .tv-booknow-complete__data-item-value {
    -webkit-flex: 2 1;
            flex: 2 1; }
  .tv-booknow-complete__data-item__sent-to {
    margin-top: 16px;
    font-family: "Circular Std", "Circular Std";
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    font-size: 14px;
    color: #252525; }

.tv-booknow-complete__data-footer-text {
  font-size: 12px;
  text-align: center;
  color: #535252; }

.tv-booknow-complete__buttons {
  padding: 16px 0 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center !important;
          justify-content: center !important; }
  .tv-booknow-complete__buttons button {
    -webkit-flex: unset;
            flex: unset;
    width: 50%;
    -webkit-align-self: center;
            align-self: center; }

@-webkit-keyframes hide {
  0% {
    opacity: 1; }
  15% {
    opacity: 1; }
  17% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@keyframes hide {
  0% {
    opacity: 1; }
  15% {
    opacity: 1; }
  17% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@-webkit-keyframes slide-in-left {
  0% {
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    left: -50%;
    opacity: 1; }
  45% {
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    left: 50%;
    opacity: 1; }
  50% {
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    left: 52%; }
  55% {
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    left: 50%;
    opacity: 1; }
  100% {
    left: 50%;
    opacity: 1; } }

@keyframes slide-in-left {
  0% {
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    left: -50%;
    opacity: 1; }
  45% {
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    left: 50%;
    opacity: 1; }
  50% {
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    left: 52%; }
  55% {
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    left: 50%;
    opacity: 1; }
  100% {
    left: 50%;
    opacity: 1; } }

@-webkit-keyframes slide-out-right {
  0% {
    left: 50%;
    opacity: 1; }
  50% {
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    left: 50%; }
  100% {
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    left: 150%;
    opacity: 1; } }

@keyframes slide-out-right {
  0% {
    left: 50%;
    opacity: 1; }
  50% {
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    left: 50%; }
  100% {
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    left: 150%;
    opacity: 1; } }

.tv-notification-icon__container {
  height: 30px;
  padding-top: 4px; }

.tv-notification-icon__button {
  border: none;
  outline: none;
  background-color: transparent; }
  @media (max-width: 768px) {
    .tv-notification-icon__button {
      padding-top: 3px; } }
  .tv-notification-icon__button i {
    color: #74737b; }
  .tv-notification-icon__button:focus {
    outline: none; }

.tv-notification-icon-active {
  color: #873bff !important;
  background-image: url("/images/bell-active.svg");
  height: 17.72px;
  width: 18.82px;
  background-size: cover;
  background-position-y: center;
  margin: auto; }

.tv-notification-icon-inactive {
  color: #873bff !important;
  background-image: url("/images/bell-inactive.svg");
  height: 17.72px;
  width: 18.82px;
  background-size: cover;
  background-position-y: center;
  margin: auto; }

.tv-notification-icon__count {
  width: 16.44px;
  height: 16.44px;
  border: 1.5px solid #873bff;
  background-color: white;
  border-radius: 50%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  position: relative;
  bottom: 15px;
  left: 15px;
  font-size: 10px;
  color: #873bff; }

.tv-general-notification__container {
  padding: 22px 21px 16px;
  border-bottom: 1px solid #e0e0e0;
  font-family: "Circular Std", "Circular Std"; }

.tv-general-notification__wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between; }

.tv-general-notification__title {
  display: -webkit-flex;
  display: flex;
  font-weight: bold;
  font-size: 14px;
  color: #252525;
  font-family: "Circular Std", "Circular Std"; }

.tv-general-notification__new-label {
  background: #e94242;
  border-radius: 30px;
  color: #ffffff;
  font-size: 8px;
  height: 16px;
  width: 35px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-content: center;
          align-content: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 4px 8px;
  font-weight: 500; }
  .tv-general-notification__new-label-forpage {
    margin-right: 8px; }

.tv-general-notification__message {
  font-size: 12px;
  color: #252525;
  margin-top: 11px;
  margin-bottom: 22px;
  font-family: "Circular Std", "Circular Std"; }
  .tv-general-notification__message-forpage {
    margin-top: 24px;
    margin-bottom: 4px; }

.tv-general-notification__date {
  font-size: 12px;
  color: #252525; }

.tv-general-notification__mark-as-read {
  margin-left: auto;
  font-size: 12px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  padding-right: 18px;
  height: 0;
  font-family: "Circular Std", "Circular Std"; }
  .tv-general-notification__mark-as-read .tv-control {
    margin: 0 !important;
    bottom: 4px !important; }
  .tv-general-notification__mark-as-read-label {
    margin-right: 11px;
    font-size: 12px;
    color: #000000; }
  .tv-general-notification__mark-as-read .custom-control-label {
    padding: 0 !important;
    background: unset !important;
    color: unset !important;
    border-radius: 0 !important;
    text-transform: none !important; }
    .tv-general-notification__mark-as-read .custom-control-label:before {
      border: 2px solid black; }

.tv-general-notification__buttons {
  margin-left: auto;
  font-size: 12px;
  display: -webkit-flex;
  display: flex; }
  .tv-general-notification__buttons button {
    height: 24px;
    outline: none;
    border: none;
    width: 80px; }
  .tv-general-notification__buttons-accept {
    background: #873bff;
    border-radius: 4px;
    color: white; }
  .tv-general-notification__buttons-decline {
    background: #ffffff;
    border: 1px solid #e0e0e0 !important;
    border-radius: 4px;
    margin-right: 8px; }

.tv-general-notification__checkbox {
  position: relative;
  top: -32px;
  height: 0; }
  .tv-general-notification__checkbox .tv-checkbox-active:before {
    background-color: black !important; }
  .tv-general-notification__checkbox .tv-checkbox-inactive:before {
    background-color: white !important; }

.tv-notification__page {
  width: 80%;
  margin-left: auto; }
  .tv-notification__page__title {
    font-weight: 500;
    font-size: 24px;
    color: #252525;
    margin-bottom: 0;
    font-family: "Circular Std", "Circular Std"; }

.tv-notification__container {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 4px;
  position: absolute;
  top: 45px;
  right: 0;
  min-width: 300px;
  z-index: 100;
  text-align: left;
  height: auto;
  width: 321px;
  font-family: "Circular Std", "Circular Std"; }
  .tv-notification__container-fluid {
    width: 100%; }

.tv-notification__list {
  max-height: 60vh;
  overflow-y: scroll;
  z-index: 10;
  overflow-y: scroll; }
  .tv-notification__list .tv-checkbox .custom-control-input ~ .custom-control-label:before {
    background: black; }

.tv-notification__date {
  font-family: "Circular Std", "Circular Std";
  font-size: 16px;
  letter-spacing: 0.2px;
  color: #7c7c7c;
  padding: 24px 21px 8px; }

.tv-notification__button {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  padding: 14px;
  -webkit-justify-content: center;
          justify-content: center;
  border: none;
  outline: none;
  background-color: transparent;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #873bff; }
  .tv-notification__button:focus {
    outline: none; }

@media (max-width: 768px) {
  .tv-notification__container {
    overflow-y: scroll; }
  .tv-notification_header {
    margin-right: 24px; }
  .tv-notification__page {
    width: 100%; }
    .tv-notification__page__title {
      display: none; }
    .tv-notification__page .tv-assignment-type__container {
      -webkit-align-items: center;
              align-items: center; }
    .tv-notification__page .tv-assignment-type-article-name {
      font-weight: 500;
      line-height: 20px;
      font-size: 14px;
      color: #252525; }
    .tv-notification__page .tv-assignment-type-article-icon {
      margin-right: 11px !important;
      color: #74737b;
      font-size: 20px; }
      .tv-notification__page .tv-assignment-type-article-icon:before {
        font-size: 13px; }
      .tv-notification__page .tv-assignment-type-article-icon:before {
        font-size: 20px; }
    .tv-notification__page .tv-notification-request__container {
      padding: 0; }
      .tv-notification__page .tv-notification-request__container .tv-assignment-item-mobile__container {
        margin-bottom: 0; }
  .tv-notification__container {
    position: fixed;
    width: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    top: 72px;
    overflow-y: auto; }
  .tv-notification__list {
    max-height: none;
    overflow: none;
    overflow-y: scroll; } }

.tv-assignment-type-selection-container {
  position: absolute;
  top: 56px;
  right: 0;
  -webkit-transform: translateX(25%);
          transform: translateX(25%);
  width: -webkit-max-content;
  width: max-content;
  max-width: 390px;
  max-height: 210px;
  border-radius: 24px;
  background-color: #fff;
  padding: 16px;
  box-shadow: -4px 0px 10px rgba(144, 144, 144, 0.15), 4px 4px 10px rgba(145, 145, 145, 0.15);
  z-index: 100;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }
  .tv-assignment-type-selection-container .selection-option {
    padding: 8px;
    border-radius: 16px;
    cursor: pointer;
    display: -webkit-flex;
    display: flex;
    grid-gap: 12px;
    gap: 12px; }
    .tv-assignment-type-selection-container .selection-option:hover {
      background-color: #f7f9ff; }
    .tv-assignment-type-selection-container .selection-option .option-icon {
      min-width: 72px;
      height: 72px;
      border-radius: 16px;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center; }
      .tv-assignment-type-selection-container .selection-option .option-icon img {
        display: block;
        max-width: 40px;
        max-height: 40px;
        width: auto;
        height: auto; }
      .tv-assignment-type-selection-container .selection-option .option-icon.prebook {
        background-color: #e1e6ff; }
      .tv-assignment-type-selection-container .selection-option .option-icon.directbook {
        background-color: #873bff; }
    .tv-assignment-type-selection-container .selection-option .option-text .option-title {
      font-weight: 700;
      font-size: 16px;
      line-height: 20px; }
    .tv-assignment-type-selection-container .selection-option .option-text .option-description {
      font-weight: 450;
      font-size: 12px;
      line-height: 15px; }
    .tv-assignment-type-selection-container .selection-option .option-arrow {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      color: #74737b; }

.tv-single-assign-form__control {
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  width: 100%;
  font: 400 16px/20px "Circular Std", "Circular Std";
  outline: none;
  color: #313131;
  margin-bottom: 8px; }
  .tv-single-assign-form__control span[role='listbox'] {
    border-bottom: none; }

.type_wrapper {
  margin-left: 15px;
  width: 93%; }

.label {
  font: 16px "Circular Std", "Circular Std";
  margin: 0;
  padding: 0; }

.tv-workassignment__filter-date-range__content .calendar-small .rdrDayToday {
  cursor: pointer; }

@media screen and (max-width: 1024px) {
  .tv-settings__wrapper {
    margin: 0px 0px !important;
    font-family: "Circular Std", "Circular Std"; }
  .tv-settings__panel-wrapper .tv-settings-term-tabs ul.react-tabs__tab-list {
    padding: 0px 0 16px 0 !important;
    margin-bottom: unset !important;
    border-radius: 4px; }
    .tv-settings__panel-wrapper .tv-settings-term-tabs ul.react-tabs__tab-list li {
      margin-right: unset !important;
      font-family: "Circular Std", "Circular Std" !important;
      font-style: normal;
      font-weight: normal;
      height: 48px;
      border: 1px solid #e0e0e0 !important;
      background-color: #fff !important;
      width: 100%;
      padding: 0px 0px !important;
      font-size: 16px !important;
      line-height: 20px !important;
      text-decoration: none !important;
      display: -webkit-flex !important;
      display: flex !important;
      color: #74737b;
      -webkit-align-items: center;
              align-items: center;
      text-align: center; }
      .tv-settings__panel-wrapper .tv-settings-term-tabs ul.react-tabs__tab-list li .react-tabs__tab--selected {
        border: 1px solid #873bff !important; }
        .tv-settings__panel-wrapper .tv-settings-term-tabs ul.react-tabs__tab-list li .react-tabs__tab--selected button {
          color: #873bff !important; }
      .tv-settings__panel-wrapper .tv-settings-term-tabs ul.react-tabs__tab-list li .react-tabs__tab button {
        color: #74737b;
        width: 100% !important;
        background-color: #fff;
        height: 48px !important;
        border: 1px solid #e0e0e0; }
      .tv-settings__panel-wrapper .tv-settings-term-tabs ul.react-tabs__tab-list li:first-child button {
        border-right: none !important; }
      .tv-settings__panel-wrapper .tv-settings-term-tabs ul.react-tabs__tab-list li:last-child button {
        border-left: none !important; }
  .tv-settings__panel-wrapper .tv-settings-term-tabs_1 {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px; }
  .tv-settings__panel-wrapper .tv-settings-term-tabs_2 {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px; }
  .tv-settings-manageuser-wrapper {
    height: 100vh;
    overflow-y: scroll; }
  .tv-settings__tabs-wrapper {
    display: block !important; }
    .tv-settings__tabs-wrapper .react-tabs__tab-list {
      margin-right: unset !important; }
    .tv-settings__tabs-wrapper .react-tabs__tab-panel {
      margin-left: unset !important; }
      .tv-settings__tabs-wrapper .react-tabs__tab-panel--selected {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column; }
  .tv-settings__datapolicy {
    max-width: 900px;
    font-size: 14px;
    line-height: 18px; }
    .tv-settings__datapolicy p {
      font-size: 14px;
      line-height: 18px; }
    .tv-settings__datapolicy li {
      width: 100%;
      padding: 0px 0px !important;
      margin-left: 16px; }
    .tv-settings__datapolicy_panel {
      margin-left: unset !important; }
  .tv-settings__tabs-wrapper .react-tabs__tab {
    border-radius: 4px;
    border: 1px solid #e0e0e0 !important;
    display: -webkit-flex;
    display: flex;
    text-align: center;
    padding: 15px !important;
    margin: 0px 0px 8px 0px !important;
    font: 500 16px/20px 'Circular Std', sans-serif !important; }
  .tv-settings__tabs-wrapper .react-tabs__tab--selected {
    border: 1px solid #873bff !important;
    border-radius: 4px !important; } }

.tv-settings__wrapper {
  margin: 32px 16px;
  font-family: "Circular Std", "Circular Std"; }

.tv-settings__footer {
  position: fixed;
  bottom: 10px;
  left: 0;
  letter-spacing: 0.2px;
  z-index: 20;
  font: 15px/19px "Circular Std", "Circular Std";
  color: #74737b;
  width: 100%;
  background-color: #f7f9ff;
  padding-left: 10px; }
  @media screen and (max-width: 768px) {
    .tv-settings__footer {
      bottom: 0; } }
  @media (max-width: 1024px) and (orientation: landscape) {
    .tv-settings__footer {
      bottom: 0;
      padding-left: 15px; } }

.tv-settings-term-tabs {
  margin-top: 32px; }
  .tv-settings-term-tabs ul.react-tabs__tab-list {
    padding: 0px 0 16px 0 !important;
    margin-bottom: unset !important;
    border-radius: 4px; }
    .tv-settings-term-tabs ul.react-tabs__tab-list li {
      margin-right: unset !important;
      font-family: "Circular Std", "Circular Std";
      font-style: normal;
      font-weight: normal;
      height: 48px;
      padding: 19px 14px;
      font-size: 16px;
      line-height: 20px;
      display: -webkit-flex;
      display: flex;
      background-color: #fff !important;
      color: #74737b;
      -webkit-align-items: center;
              align-items: center;
      text-align: center; }
      .tv-settings-term-tabs ul.react-tabs__tab-list li.react-tabs__tab--selected button {
        color: #873bff !important;
        font-weight: 500; }
      .tv-settings-term-tabs ul.react-tabs__tab-list li.react-tabs__tab button {
        color: #74737b;
        background-color: #fff;
        width: 100% !important;
        height: 48px !important;
        border: 1px solid #e0e0e0; }
      .tv-settings-term-tabs ul.react-tabs__tab-list li:first-child button {
        border-right: none; }
      .tv-settings-term-tabs ul.react-tabs__tab-list li:last-child button {
        border-left: none; }
  .tv-settings-term-tabs_1 {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px; }
  .tv-settings-term-tabs_2 {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px; }

.tv-settings__title {
  background: transparent;
  font: 500 1.5rem/2rem "Circular Std", "Circular Std";
  letter-spacing: -0.2px;
  color: #353535;
  margin: 15px 0px 15px 0px; }

.tv-settings__tab-text-main {
  font: 17px "Circular Std", "Circular Std";
  color: #000;
  letter-spacing: -0.2px; }

.tv-settings__tab-text-sub {
  font: 0.875rem/1.25rem "Circular Std", "Circular Std";
  color: #a9a9a9; }

.tv-settings__panel-title {
  font: 500 28px/35px "Circular Std", "Circular Std"; }

.tv-settings__panel-description {
  padding: 10px 30px 0px 0px;
  font: 0.875rem/1.5rem "Circular Std", "Circular Std";
  color: #313131; }

.tv-settings__account-wrapper {
  padding-top: 6px;
  font-family: "Circular Std", "Circular Std"; }
  .tv-settings__account-wrapper .tx-input-text {
    font-size: 16px;
    line-height: 20px;
    font-family: "Circular Std", "Circular Std";
    width: 100%;
    padding: 10px 11px 10px; }
  .tv-settings__account-wrapper .tv-form__error:empty {
    display: none; }
  @media (max-width: 768px) {
    .tv-settings__account-wrapper {
      margin-bottom: 20px; } }

.tv-settings__datapolicy {
  max-width: 900px;
  font-size: 14px;
  line-height: 18px; }
  .tv-settings__datapolicy p {
    font-size: 14px;
    line-height: 18px; }
  .tv-settings__datapolicy li {
    margin-left: 16px; }
  .tv-settings__datapolicy_panel {
    margin-left: unset !important; }

.tv-settings__password-label {
  color: #252525;
  font-size: 16px;
  line-height: 20px;
  font-family: "Circular Std", "Circular Std";
  margin-bottom: 8px;
  margin-top: 24px;
  font-weight: 500; }

.tv-settings__password-field::-webkit-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  opacity: 1;
  /* Firefox */
  font-size: 14px;
  font-size: 0.875rem; }

.tv-settings__password-field:-ms-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  opacity: 1;
  /* Firefox */
  font-size: 14px;
  font-size: 0.875rem; }

.tv-settings__password-field::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  opacity: 1;
  /* Firefox */
  font-size: 14px;
  font-size: 0.875rem; }

.tv-settings__password-field:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-size: 14px;
  font-size: 0.875rem; }

.tv-settings__password-field::-ms-input-placeholder {
  /* Microsoft Edge */
  font-size: 14px;
  font-size: 0.875rem; }

.tv-settings__change-password-btn {
  background-color: #c4c4c4;
  width: 100%;
  color: black;
  font-weight: 500;
  padding: 15px;
  margin: 30px 0 0 0px; }

.tv-settings__calendar-wrapper {
  padding-top: 46px;
  font-family: "Circular Std", "Circular Std"; }
  .tv-settings__calendar-wrapper .tv-toggleSwitch {
    margin-left: auto; }

.tv-settings__calendar-submit {
  margin: auto 0px 0px auto; }
  .tv-settings__calendar-submit.tv-buttons__element {
    padding: 13px 31px; }

.tv-settings__contact-text {
  font: 0.875rem/1.25rem "Circular Std", "Circular Std";
  color: #a9a9a9;
  letter-spacing: 0.3px;
  margin-top: 24px; }

.tv-settings__contact-btn {
  margin-top: 50px; }
  .tv-settings__contact-btn.tv-buttons__element {
    font-weight: 300;
    padding: 15px 24px;
    width: 248px; }

.tv-settings__term-text {
  color: #a9a9a9;
  font-family: 'PT Serif';
  font-size: 14px;
  letter-spacing: 0.3px;
  line-height: 20px;
  height: 100%; }

.tv-settings__tabs-wrapper {
  display: -webkit-flex;
  display: flex;
  position: relative; }
  .tv-settings__tabs-wrapper .react-tabs__tab-list {
    -webkit-flex: 0.5 1;
            flex: 0.5 1;
    margin: 0;
    padding: 0;
    border: none;
    margin-right: 10px; }
  .tv-settings__tabs-wrapper .react-tabs__tab {
    margin: 0;
    padding: 0;
    border: none;
    display: -webkit-flex;
    display: flex;
    padding: 15px 0px 15px 0px;
    margin: 0px 0px 0px;
    font: 500 16px/20px "Circular Std", "Circular Std" !important; }
    .tv-settings__tabs-wrapper .react-tabs__tab [class*='icon-']::before {
      font-size: 2.1875rem;
      display: inline-block;
      margin-right: 17px;
      line-height: 46px; }
    .tv-settings__tabs-wrapper .react-tabs__tab .tv-settings__tab-text-main {
      color: #8f8f8f !important; }
    .tv-settings__tabs-wrapper .react-tabs__tab--selected {
      border-radius: 0;
      color: #873bff !important; }
      .tv-settings__tabs-wrapper .react-tabs__tab--selected [class*='icon-']::before,
      .tv-settings__tabs-wrapper .react-tabs__tab--selected .tv-settings__tab-text-main {
        color: #873bff !important; }
    .tv-settings__tabs-wrapper .react-tabs__tab:focus {
      box-shadow: none;
      border-color: none;
      outline: none; }
      .tv-settings__tabs-wrapper .react-tabs__tab:focus:after {
        content: none; }
  .tv-settings__tabs-wrapper .react-tabs__tab-panel {
    -webkit-flex: 1.5 1;
            flex: 1.5 1;
    padding: 0px 54px 0px 54px;
    border-radius: 5px; }
    @media (max-width: 1024px) and (orientation: landscape) {
      .tv-settings__tabs-wrapper .react-tabs__tab-panel {
        max-height: calc(100vh - 200px);
        overflow-y: auto;
        overflow-x: hidden; } }
    .tv-settings__tabs-wrapper .react-tabs__tab-panel--selected {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column !important;
              flex-direction: column !important; }
  .tv-settings__tabs-wrapper .react-tabs--vertical .react-tabs__tab-list {
    width: 100%;
    -webkit-flex: unset;
            flex: unset;
    background-color: transparent;
    position: unset;
    padding: 38px 0 38px 0;
    margin-bottom: 20px; }
  .tv-settings__tabs-wrapper .react-tabs--vertical .react-tabs__tab {
    color: #a9a9a9;
    display: inline;
    border-bottom: none;
    padding: 0;
    position: unset;
    font-size: 18px;
    line-height: 28px; }
    .tv-settings__tabs-wrapper .react-tabs--vertical .react-tabs__tab:first-child {
      margin-right: 32px; }
    .tv-settings__tabs-wrapper .react-tabs--vertical .react-tabs__tab--selected {
      border-right: none;
      text-decoration: underline;
      color: #873bff; }
  .tv-settings__tabs-wrapper .react-tabs--vertical .react-tabs__tab-panel {
    padding: 0;
    -webkit-flex: unset;
            flex: unset;
    -webkit-flex-direction: unset;
            flex-direction: unset; }
    .tv-settings__tabs-wrapper .react-tabs--vertical .react-tabs__tab-panel .tab-panel-content {
      color: #a9a9a9;
      font: 0.875rem/1.25rem 'PT Serif', serif;
      text-align: left;
      font-size: 14px;
      line-height: 20px;
      overflow-y: scroll;
      max-height: 567px; }
      .tv-settings__tabs-wrapper .react-tabs--vertical .react-tabs__tab-panel .tab-panel-content__paragraph {
        font-size: 14px;
        line-height: 18px; }

.tv-myprofile__field {
  margin: 10px 0;
  font: 500 16px/18px "Circular Std", "Circular Std"; }

.tv-myprofile_value {
  font-weight: 100;
  margin-top: 15px; }

.tv-myprofile_hr {
  border-width: 1px;
  border-color: #e0e0e0;
  margin: 40px 0px; }

.tv-myprofile .tx-input-text--border {
  width: 100%; }

.tv-myprofile .tv-row {
  margin-bottom: 32px; }

.tv-myprofile__header-wrapper {
  padding: 32px 0 0px 0;
  position: relative;
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex; }

.tv-myprofile__header-text {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }

.tv-myprofile__name {
  color: #353535;
  font: 500 20px/25px "Circular Std", "Circular Std";
  margin-left: 32px; }

.tv-myprofile__client-number {
  margin-top: 10px;
  font: 500 16px/18px "Circular Std", "Circular Std";
  letter-spacing: 0.3px;
  margin-left: 32px; }

.tv-myprofile__avatar-img {
  border-radius: 50%;
  width: 75px;
  height: 75px;
  object-fit: cover; }

.tv-myprofile__content-wrapper {
  padding: 38px 0 0px 0; }

.tv-myprofile__input-label {
  color: #313131;
  font: 0.875rem/1.5rem "Circular Std", "Circular Std";
  margin-bottom: 5px; }

.tv-myprofile__change-avatar-btn, .tv-myprofile__edit-btn {
  margin-left: 32px;
  margin-bottom: 60px; }
  .tv-myprofile__change-avatar-btn.tv-buttons__element, .tv-myprofile__edit-btn.tv-buttons__element {
    font-weight: 300;
    padding: 13px 31px;
    line-height: 23px; }

.tv-myprofile__change-avatar-btn {
  display: -webkit-flex;
  display: flex;
  width: 220px;
  -webkit-justify-content: center;
          justify-content: center; }
  .tv-myprofile__change-avatar-btn + input[type='file'] {
    display: none; }
  .tv-myprofile__change-avatar-btn:before {
    content: '\e928';
    font-family: 'transvoice-icon';
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: 'liga';
    font-size: 20px;
    color: #873bff;
    margin-right: 16px; }

.tv-myprofile__certification-wrapper {
  margin-bottom: 32px; }

.tv-myprofile__certification-header {
  font: 300 1.125rem/1.75rem "Circular Std", "Circular Std";
  color: #313131;
  letter-spacing: -0.2px;
  position: relative;
  padding-left: 33px; }
  .tv-myprofile__certification-header::before {
    content: '\e927';
    font-family: 'transvoice-icon';
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: 'liga';
    color: #873bff;
    font-size: 23px;
    position: absolute;
    top: 0;
    left: 0; }

.tv-myprofile__certification-data {
  font: 0.875rem/1.5rem "Circular Std", "Circular Std";
  color: #313131;
  letter-spacing: 0.3px; }

.tv-myprofile__submit-wrapper {
  -webkit-justify-content: center;
          justify-content: center; }

.tv-myprofile__submit-btn {
  margin-top: 50px; }
  .tv-myprofile__submit-btn.tv-buttons__element {
    font-weight: 300;
    padding: 13px 48px;
    line-height: 23px; }

.tv-contactperson-tabs {
  padding-left: 15px; }
  .tv-contactperson-tabs-tab-1 {
    border: 1px solid #e0e0e0;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    padding: 9px 19px 6px; }
  .tv-contactperson-tabs-tab-2 {
    border: 1px solid #e0e0e0;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    padding: 9px 19px 6px; }

.tv-contactperson__list-item {
  margin: 10px 15px 10px 10px;
  padding: 18px 0;
  font-family: 'PT Serif', serif;
  z-index: 10;
  background: #d8d8d8;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around; }
  sales .tv-contactperson__list-item-child {
    margin: 0 13px;
    box-shadow: inset 0 10px 18px -12px rgba(58, 68, 131, 0.2), 0 10px 18px -12px rgba(58, 68, 131, 0.2); }

.tv-contactperson__item-status {
  width: 21.6%;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }

.tv-contactperson__item-name {
  width: 27.6%; }

.tv-contactperson__item-email {
  width: 30%;
  margin-right: 10px; }

.tv-contactperson__item-contactnum {
  width: 12%; }

.tv-contactperson__item-editbtn {
  width: 16.7%; }

.tv-contactperson__item-label {
  font-size: 0.975rem;
  color: #000;
  margin: 0 0 11px 0;
  font-family: "Circular Std", "Circular Std"; }

.tv-contactperson__item-text {
  color: #313131;
  font-size: 13px;
  font-weight: 300;
  font-family: "Circular Std", "Circular Std"; }
  .tv-contactperson__item-text-available-until {
    font-weight: bold;
    color: #873bff; }

.tv-contactperson__item-edit {
  color: #873bff;
  font-size: 14px;
  font-weight: 300;
  font-family: "Circular Std", "Circular Std";
  text-align: right;
  cursor: pointer; }

.tv-contactperson__add-button {
  font-family: 'Circular Std', sans-serif;
  font-size: 16px;
  background: #873bff;
  border: none;
  outline: none;
  color: white;
  max-height: 48px;
  padding: 15px;
  margin-left: auto;
  border-radius: 4px; }

.tv-contactperson__wrapper {
  height: calc(100vh - 275px); }

.tv-contactperson__delete {
  font-size: 16px;
  line-height: 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  color: #873bff;
  cursor: pointer; }
  .tv-contactperson__delete > i {
    margin-right: 10px; }

.tv-contactperson__list-wrapper {
  overflow-y: scroll;
  height: calc(100vh - 270px); }

@media (max-width: 768px) {
  .tv-contactperson__list-item {
    display: block !important;
    padding-left: 25px; }
  .tv-contactperson__item-status {
    width: 100%;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center; }
  .tv-contactperson__item-name {
    width: 100%;
    padding: unset !important; }
  .tv-contactperson__item-email {
    width: 100%;
    margin-right: 10px; }
  .tv-contactperson__item-contactnum {
    width: 100%; }
  .tv-contactperson__item-editbtn {
    width: 100%; }
  .tv-contactperson__item-label {
    font-size: 0.975rem;
    color: #000;
    margin: 0 0 7px 0;
    font-family: "Circular Std", "Circular Std"; }
  .tv-contactperson__item-text {
    color: #313131;
    font-size: 14px;
    font-weight: 300;
    font-family: "Circular Std", "Circular Std";
    margin-bottom: 18px; }
    .tv-contactperson__item-text-available-until {
      font-weight: bold;
      color: #873bff; }
  .tv-contactperson__wrapper {
    height: 50vh; }
  .tv-contactperson__list-wrapper {
    overflow-y: scroll;
    height: calc(100vh - 320px); }
  .tv-contactperson__edit-modal {
    margin-bottom: 100px; }
    .tv-contactperson__edit-modal-actions {
      position: fixed;
      bottom: 0;
      width: 100vw;
      background-color: #fff;
      left: 0; }
  .tv-contactperson-item--mobile {
    display: -webkit-flex !important;
    display: flex !important;
    -webkit-flex-direction: column !important;
            flex-direction: column !important; } }

.custom {
  cursor: auto !important; }

.tv-delete-user-modal {
  z-index: 65 !important;
  position: relative; }
  @supports (display: flex) {
    .tv-delete-user-modal {
      position: fixed; } }

.tv-profile__wrapper {
  width: 800px;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.12);
  margin: 30px auto 0 auto;
  min-height: 100vh; }

.tv-profile .tx-input-text--border {
  width: 100%; }

.tv-profile .tv-row {
  margin-bottom: 32px; }

.tv-profile__header-wrapper {
  height: 134px;
  background-color: #f9f9f9;
  padding: 66px 72px 0px 72px;
  position: relative; }

.tv-profile__name {
  color: #353535;
  font: 500 1.5rem/2rem "Circular Std", "Circular Std";
  letter-spacing: -0.2px;
  margin-left: 190px; }

.tv-profile__client-number {
  font: 0.875rem/1.25rem "Circular Std", "Circular Std";
  letter-spacing: 0.3px;
  color: #a9a9a9;
  margin-left: 190px; }

.tv-profile__avatar {
  position: absolute;
  top: 49px; }

.tv-profile__avatar-img {
  border-radius: 50%;
  width: 158px;
  height: 158px;
  object-fit: cover; }

.tv-profile__content-wrapper {
  padding: 130px 72px 20px 72px; }

.tv-profile__input-label {
  font-family: 'Circular Std', sans-serif;
  color: #313131;
  font: 0.875rem/1.5rem "Circular Std", "Circular Std";
  margin-bottom: 5px; }

.tv-profile__change-avatar-btn, .tv-profile__edit-btn {
  margin-left: 190px;
  margin-bottom: 60px; }
  .tv-profile__change-avatar-btn.tv-buttons__element, .tv-profile__edit-btn.tv-buttons__element {
    font-weight: 300;
    padding: 13px 31px;
    line-height: 23px; }

.tv-profile__change-avatar-btn {
  display: -webkit-flex;
  display: flex;
  width: 220px;
  -webkit-justify-content: center;
          justify-content: center; }
  .tv-profile__change-avatar-btn + input[type='file'] {
    display: none; }
  .tv-profile__change-avatar-btn:before {
    content: '\e928';
    font-family: 'transvoice-icon';
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: 'liga';
    font-size: 20px;
    color: #873bff;
    margin-right: 16px; }

.tv-profile__certification-wrapper {
  margin-bottom: 32px; }

.tv-profile__certification-header {
  font: 300 1.125rem/1.75rem "Circular Std", "Circular Std";
  color: #313131;
  letter-spacing: -0.2px;
  position: relative;
  padding-left: 33px; }
  .tv-profile__certification-header::before {
    content: '\e927';
    font-family: 'transvoice-icon';
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: 'liga';
    color: #873bff;
    font-size: 23px;
    position: absolute;
    top: 0;
    left: 0; }

.tv-profile__certification-data {
  font: 0.875rem/1.5rem "Circular Std", "Circular Std";
  color: #313131;
  letter-spacing: 0.3px; }

.tv-profile__submit-wrapper {
  -webkit-justify-content: center;
          justify-content: center; }

.tv-profile__submit-btn {
  margin-top: 50px; }
  .tv-profile__submit-btn.tv-buttons__element {
    font-weight: 300;
    padding: 13px 48px;
    line-height: 23px; }

.tv-cancelassignment__wrapper {
  -webkit-flex: 1.4 1;
          flex: 1.4 1;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding-top: 33px; }

.tv-cancelassignment__container {
  width: 100%;
  background-color: #fff; }
  .tv-cancelassignment__container form {
    width: 100%; }

.tv-cancelassignment__heading {
  border-radius: 4px 4px 0 0;
  background-color: #873bff;
  height: 64px;
  -webkit-align-items: center;
          align-items: center; }
  .tv-cancelassignment__heading-text {
    color: #fff;
    font: 500 1.5rem/2.1875rem "Circular Std", "Circular Std";
    letter-spacing: -0.2px;
    padding-left: 24px; }

.tv-cancelassignment__warning-icon {
  -webkit-justify-content: center;
          justify-content: center;
  margin-top: 46px; }
  .tv-cancelassignment__warning-icon img {
    width: 100px;
    height: 100px; }

.tv-cancelassignment__title {
  padding: 25px 0 0 0;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font: 400 24px/20px "Circular Std", "Circular Std"; }
  .tv-cancelassignment__title-text {
    color: #313131;
    font: 400 16px/20px "Circular Std", "Circular Std";
    margin-top: 10px;
    text-align: center;
    padding-inline: 60px; }
    .tv-cancelassignment__title-text.text-medium {
      font: 300 1.375rem/2.5rem "Circular Std", "Circular Std"; }

.tv-cancelassignment__reasons {
  margin: 20px auto;
  text-align: left; }
  .tv-cancelassignment__reasons label {
    font-family: "Circular Std", "Circular Std"; }
  .tv-cancelassignment__reasons-list {
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap; }
    .tv-cancelassignment__reasons-list-item {
      color: #fff;
      padding: 13px 37px;
      margin: 0 18px 16px 0; }
  .tv-cancelassignment__reasons-list .tv-cancelassignment__reasons-list-item {
    border: 1px solid #873bff;
    border-radius: 5px;
    font: 300 1rem/1.4375rem "Circular Std", "Circular Std"; }
    .tv-cancelassignment__reasons-list .tv-cancelassignment__reasons-list-item.tv-toggle-button--unselected {
      color: #873bff; }
  .tv-cancelassignment__reasons-title {
    color: #313131;
    font: 0.875rem/1.5rem "Circular Std", "Circular Std"; }
  .tv-cancelassignment__reasons.no-reasons {
    margin: 19px 40.73px 40.73px 40.73px; }
    .tv-cancelassignment__reasons.no-reasons .tv-cancelassignment__reasons-title {
      text-align: center; }

.tv-cancelassignment__buttons {
  margin: 0 0 32px 0;
  -webkit-justify-content: center;
          justify-content: center; }
  .tv-cancelassignment__buttons button {
    width: 160px; }
  .tv-cancelassignment__buttons .tv-buttons__element--no-border {
    color: #873bff;
    font: 400 16px/20px "Circular Std", "Circular Std";
    letter-spacing: -0.2px; }

.tv-cancelassignment__modal {
  z-index: 56; }
  .tv-cancelassignment__modal .tv-modal__holder {
    border-radius: 4px;
    margin: 0 10px;
    max-width: 400px;
    max-height: unset;
    top: 63px;
    background-color: #fff;
    overflow-x: hidden; }
    .tv-cancelassignment__modal .tv-modal__holder .icon-close::before {
      display: none; }
  .tv-cancelassignment__modal-container {
    overflow: hidden;
    margin-top: 5px;
    border-bottom: 1px solid #e0e0e0;
    font-family: "Circular Std", sans-serif;
    text-align: left;
    margin-right: -10px;
    min-width: 350px;
    height: 30vh !important;
    min-height: 250px; }
  .tv-cancelassignment__modal-icon {
    font-size: 3rem;
    margin-top: 32px; }
    .tv-cancelassignment__modal-icon .icon-warning::before {
      color: #873bff; }
  .tv-cancelassignment__modal-text {
    color: #873bff;
    font: 300 1.125rem/1.75rem "Circular Std", "Circular Std";
    letter-spacing: -0.2px;
    text-align: center;
    padding: 0 68px; }
  .tv-cancelassignment__modal-assignment {
    border: 1px solid #969696;
    background-color: #f7f7f7;
    margin: 16px 36px 45px;
    padding: 9px 16px; }
    .tv-cancelassignment__modal-assignment-id {
      color: #873bff; }
    .tv-cancelassignment__modal-assignment-title {
      color: #353535; }
    .tv-cancelassignment__modal-assignment-id, .tv-cancelassignment__modal-assignment-title {
      font: 300 1rem/1.5rem "Circular Std", "Circular Std";
      text-align: center; }
  .tv-cancelassignment__modal-buttons {
    margin: 0 0 32px 0;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    margin: 0 36px 40px; }
    .tv-cancelassignment__modal-buttons button {
      width: 169px;
      padding: 13px 0px; }

.tv-cancelassignment__sms-conatiner {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 100%;
  -webkit-align-items: center;
          align-items: center;
  padding-top: 20px;
  padding-inline: 10px 10px;
  padding-bottom: 25px; }
  .tv-cancelassignment__sms-conatiner__text {
    font: 500 14px/20px "Circular Std", "Circular Std";
    width: 230px;
    text-align: start; }
  .tv-cancelassignment__sms-conatiner .tv-toggleSwitch .tv-toggleSwitch__wrapper .Slider.isChecked {
    background-color: #34c759; }

.tv-cancelassignment__phone-box {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: start;
          align-items: start;
  padding-inline: 10px 10px; }
  .tv-cancelassignment__phone-box label {
    font: 500 16px/20px "Circular Std", "Circular Std"; }

.thanks-wrapper {
  width: 65%;
  height: 100%;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.12);
  margin: 0 auto;
  margin-top: 32px;
  padding: 40px 80px 40px;
  text-align: center;
  color: #313131;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  font: 1rem/1.5rem "Circular Std", "Circular Std"; }
  .thanks-wrapper__header {
    height: 200px;
    width: 240px;
    background-image: url("/images/thank-you.svg");
    background-size: cover;
    background-position-y: center;
    margin: 0 auto; }
  .thanks-wrapper__content {
    padding-top: 20px; }
    .thanks-wrapper__content h1 {
      color: #313131;
      font: 2rem/2.5rem "Circular Std", "Circular Std";
      font-weight: 300;
      letter-spacing: -0.5px; }
  .thanks-wrapper__footer {
    margin-top: 30px; }
    .thanks-wrapper__footer button {
      padding: 15px 100px; }
  .thanks-wrapper .tv-youravailability__list {
    padding-left: 20px;
    padding-top: 20px;
    margin-top: 30px;
    -webkit-justify-content: center;
            justify-content: center;
    max-height: 340px;
    overflow-y: scroll; }
    .thanks-wrapper .tv-youravailability__list .tv-youravailability__list-item {
      box-shadow: 0 1px 16px 0 rgba(0, 0, 0, 0.15);
      margin-right: 10px;
      margin-bottom: 10px; }
      .thanks-wrapper .tv-youravailability__list .tv-youravailability__list-item .tv-youravailability__container {
        padding: 8px; }
      .thanks-wrapper .tv-youravailability__list .tv-youravailability__list-item .tv-youravailability__item-text-datetime {
        min-height: unset; }
      .thanks-wrapper .tv-youravailability__list .tv-youravailability__list-item .tv-workassignment__info-wrapper {
        text-align: left; }
      .thanks-wrapper .tv-youravailability__list .tv-youravailability__list-item .tv-workassignment__info-wrapper {
        margin: 0;
        padding: 0 0 0 16px; }
      .thanks-wrapper .tv-youravailability__list .tv-youravailability__list-item .tv-youravailability__item-hr {
        margin: 0 16px; }

.tv-not-found {
  width: 100%;
  height: calc(100vh - 200px);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }
  .tv-not-found__image {
    height: 342px;
    width: 324px;
    background-image: url("/images/not-found.svg");
    background-size: cover;
    background-position-y: center;
    margin: auto; }

.tv-title-result {
  font: 1.5rem/2rem "Circular Std", "Circular Std";
  font-weight: 500;
  letter-spacing: -0.2px;
  color: #353535; }
  .tv-title-result__number {
    color: #873bff; }
  .tv-title-result__order {
    color: #873bff; }

.tv-reset-password__wrapper {
  width: 400px;
  -webkit-flex-flow: column;
          flex-flow: column;
  -webkit-align-items: center;
          align-items: center; }

.tv-reset-password__title {
  font: 3rem/3.375rem "Circular Std", "Circular Std";
  color: #313131;
  font-weight: 500;
  letter-spacing: -0.5px; }

.tv-reset-password__description {
  padding: 10px 30px 10px 30px;
  font: 0.875rem/1.5rem "Circular Std", "Circular Std";
  text-align: center;
  color: #313131; }

.tv-reset-password__form-wrapper {
  width: 100%;
  border-radius: 3px;
  box-shadow: 0 10px 37px 0 rgba(0, 0, 0, 0.12);
  padding: 36px 30px 41px 30px;
  margin-top: 16px; }

.tv-reset-password__form-label {
  font: 1.25rem/1.8125rem "Circular Std", "Circular Std";
  color: #313131; }

.tv-reset-password__form-retype-password-label {
  margin-top: 15px; }

.tv-reset-password__form-password-input, .tv-reset-password__form-retype-password-input {
  width: 100%; }
  .tv-reset-password__form-password-input.tx-input-text, .tv-reset-password__form-retype-password-input.tx-input-text {
    font-size: 20px;
    font-size: 1.25rem; }
    .tv-reset-password__form-password-input.tx-input-text::-webkit-input-placeholder, .tv-reset-password__form-retype-password-input.tx-input-text::-webkit-input-placeholder {
      font-size: 14px;
      font-size: 0.875rem; }
    .tv-reset-password__form-password-input.tx-input-text:-ms-input-placeholder, .tv-reset-password__form-retype-password-input.tx-input-text:-ms-input-placeholder {
      font-size: 14px;
      font-size: 0.875rem; }
    .tv-reset-password__form-password-input.tx-input-text::placeholder, .tv-reset-password__form-retype-password-input.tx-input-text::placeholder {
      font-size: 14px;
      font-size: 0.875rem; }

.tv-reset-password__form-submit.tv-buttons__element {
  width: 100%;
  margin-top: 26px;
  padding: 13px 0px 13px 0px;
  font-weight: 300;
  line-height: 1.4375rem; }

.tv-set-new-password__wrapper {
  border: 1px solid #e0e0e0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  box-sizing: border-box;
  box-shadow: 0px 8px 24px #e0e0e0;
  border-radius: 4px;
  width: 502px;
  padding: 48px 54px 56px;
  min-height: 414px;
  margin: auto; }

.tv-set-new-password__title {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.2px; }

.tv-set-new-password__description {
  padding: 10px 30px 0px 30px;
  font: 0.875rem/1.5rem "Circular Std", "Circular Std";
  text-align: center;
  color: #313131; }

.tv-set-new-password__form-wrapper {
  width: 100%; }

.tv-set-new-password__form-label {
  font: 1.25rem/1.8125rem "Circular Std", "Circular Std";
  color: #313131; }

.tv-set-new-password__form-retype-password-label {
  margin-top: 15px; }

.tv-set-new-password__form-password-input, .tv-set-new-password__form-retype-password-input {
  width: 100%;
  font-family: "Circular Std", "Circular Std"; }
  .tv-set-new-password__form-password-input.tx-input-text, .tv-set-new-password__form-retype-password-input.tx-input-text {
    font-size: 20px;
    font-size: 1.25rem; }
    .tv-set-new-password__form-password-input.tx-input-text::-webkit-input-placeholder, .tv-set-new-password__form-retype-password-input.tx-input-text::-webkit-input-placeholder {
      font-size: 14px;
      font-size: 0.875rem; }
    .tv-set-new-password__form-password-input.tx-input-text:-ms-input-placeholder, .tv-set-new-password__form-retype-password-input.tx-input-text:-ms-input-placeholder {
      font-size: 14px;
      font-size: 0.875rem; }
    .tv-set-new-password__form-password-input.tx-input-text::placeholder, .tv-set-new-password__form-retype-password-input.tx-input-text::placeholder {
      font-size: 14px;
      font-size: 0.875rem; }

.tv-set-new-password__form-submit.tv-buttons__element {
  width: 100%;
  margin-top: 26px;
  padding: 13px 0px 13px 0px;
  font-weight: 300;
  background-color: #873bff !important;
  border: none;
  line-height: 1.4375rem; }

.tv-submittedcancel__title {
  margin-top: 36px;
  font: 500 1.5rem/2rem "Circular Std", "Circular Std";
  letter-spacing: -0.2px; }

.tv-submittedcancel__data {
  margin-top: 16px;
  font: 300 1.5rem/2.1875rem "Circular Std", "Circular Std"; }

.tv-submittedcancel__number {
  color: #873bff; }

.tv-submittedcancel__content {
  color: #353535; }

.tv-reportquality {
  text-align: left; }
  .tv-reportquality__container {
    width: 100%; }
  .tv-reportquality__wrapper {
    background-color: #f7f9ff; }
    .tv-reportquality__wrapper .tk-left-drawer {
      top: 85px; }
    @media (max-width: 768px) {
      .tv-reportquality__wrapper .tk-left-drawer {
        top: 0; } }
  .tv-reportquality_align-unset {
    -webkit-align-items: unset;
            align-items: unset; }
  .tv-reportquality__btn-filter {
    font-family: "Circular Std", "Circular Std";
    display: block;
    background-color: #c2c7ce;
    border: 1px solid #c2c7ce;
    border-radius: 8px;
    padding: 15px;
    width: 90%;
    margin: 10px; }
  .tv-reportquality-sendbutton {
    margin: 10px 15px 10px 10px;
    padding: 16px;
    width: 100%;
    border: none;
    background: #c4c4c4;
    border-radius: 4px;
    font-weight: 600; }
  .tv-reportquality__list__mobile-fixed-bottom {
    display: none; }
  .tv-reportquality__list-item {
    cursor: pointer;
    margin: 0;
    padding: 8px;
    height: 47px;
    font: 400 12px/15px "Circular Std", "Circular Std";
    z-index: 10;
    border-radius: 0;
    background: #f1f3f4;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    width: 100%;
    -webkit-align-items: center;
            align-items: center; }
    .tv-reportquality__list-item-header {
      font: 400 12px/15px "Circular Std", "Circular Std";
      border-bottom: 1px solid #e0e0e0;
      padding: 8px;
      margin: 0 0 16px 0;
      margin-bottom: 16px !important;
      display: -webkit-flex;
      display: flex;
      height: 47px;
      -webkit-justify-content: space-around;
              justify-content: space-around;
      width: 100%;
      -webkit-align-items: center;
              align-items: center;
      z-index: 10; }
    .tv-reportquality__list-item.selected {
      background: #873bff !important; }
      .tv-reportquality__list-item.selected .tv-reportquality__item-text {
        color: #fff !important; }
    .tv-reportquality__list-item-child {
      margin: 0 13px;
      box-shadow: inset 0 10px 18px -12px rgba(58, 68, 131, 0.2), 0 10px 18px -12px rgba(58, 68, 131, 0.2); }
    .tv-reportquality__list-item-label {
      display: none; }
    .tv-reportquality__list-item:first-child {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px; }
    .tv-reportquality__list-item:last-child {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px; }
    .tv-reportquality__list-item:nth-child(even) {
      background: #f1f3f4; }
    .tv-reportquality__list-item:nth-child(odd) {
      background: #f7f9ff; }
  .tv-reportquality__item-status {
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    text-align: center;
    background-color: #74737b;
    color: #fff !important;
    border-radius: 4px;
    padding: 6px 8px; }
  .tv-reportquality__item-icon {
    padding: 15px 40px; }
    .tv-reportquality__item-icon .icon:before {
      color: black; }
  .tv-reportquality__item-more_button {
    outline: none;
    border: none;
    background: none;
    text-transform: uppercase;
    font-size: 14px; }
    .tv-reportquality__item-more_button:after {
      content: '\e931';
      font-family: 'transvoice-icon';
      -webkit-font-smoothing: antialiased;
      text-rendering: optimizeLegibility;
      -moz-osx-font-smoothing: grayscale;
      font-feature-settings: 'liga';
      color: #c5c5c5;
      margin-left: 4px;
      font-size: 10px;
      color: #000; }
  .tv-reportquality__item-menu {
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center; }
    .tv-reportquality__item-menu-button:before {
      font-family: 'transvoice-icon';
      -webkit-font-smoothing: antialiased;
      text-rendering: optimizeLegibility;
      -moz-osx-font-smoothing: grayscale;
      font-feature-settings: 'liga';
      font-size: 1.5rem; }
  .tv-reportquality__item-label {
    font-size: 0.975rem;
    color: #000;
    margin: 0 0 11px 0;
    font-family: "Circular Std", "Circular Std"; }
  .tv-reportquality__item-text {
    color: #74737b;
    font: 12px/16px "Circular Std", "Circular Std"; }
    .tv-reportquality__item-text-assignments {
      display: -webkit-flex;
      display: flex;
      grid-gap: 5px;
      gap: 5px;
      -webkit-justify-content: start;
              justify-content: start;
      -webkit-align-items: center;
              align-items: center;
      -webkit-user-select: none;
          -ms-user-select: none;
              user-select: none; }
    .tv-reportquality__item-text-available-until {
      font-weight: bold;
      color: #873bff; }
  .tv-reportquality__item-sort-buttons {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column; }
    .tv-reportquality__item-sort-buttons > div:first-of-type {
      margin-top: 1px;
      margin-bottom: -10px; }
    .tv-reportquality__item-sort-buttons-asc > div:first-of-type {
      color: #74737b; }
    .tv-reportquality__item-sort-buttons-asc > div:last-of-type {
      color: #c4c4c4; }
    .tv-reportquality__item-sort-buttons-desc > div:first-of-type {
      color: #c4c4c4; }
    .tv-reportquality__item-sort-buttons-desc > div:last-of-type {
      color: #74737b; }
    .tv-reportquality__item-sort-buttons-none div {
      color: #c4c4c4; }
  .tv-reportquality__status {
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    text-align: center;
    background-color: #74737b;
    color: #fff !important;
    border-radius: 4px;
    padding: 6px 8px;
    font: 12px "Circular Std", "Circular Std"; }
    .tv-reportquality__status--default {
      background: #74737b; }
  .tv-reportquality-drawer__container {
    font-family: "Circular Std", "Circular Std";
    left: 0;
    top: 0;
    width: calc(100% - 10px);
    box-shadow: 0 10px 18px -12px rgba(58, 68, 131, 0.04);
    box-sizing: border-box;
    border-radius: 8px;
    padding: 5px 10px 20px;
    border: 1px solid #ebebeb;
    background-color: #fbfbfb; }
  .tv-reportquality-drawer-back {
    margin-bottom: 20px; }
    .tv-reportquality-drawer-back-btn:before {
      margin: 0;
      color: black;
      font-size: 23px; }
  .tv-reportquality-drawer-matter {
    font-weight: 600; }
    .tv-reportquality-drawer-matter hr {
      margin-top: 5px; }
  .tv-reportquality-drawer-mobile-only {
    display: none; }
  .tv-reportquality-drawer_body {
    border-top: 1px solid #ebebeb;
    padding-top: 16px;
    width: 100%; }
  .tv-reportquality-drawer-btn {
    margin-top: 5px;
    text-align: center; }
  .tv-reportquality-drawer-content {
    font-size: 14px;
    padding: 10px 14px; }
  .tv-reportquality-drawer-label {
    font: 600 14px/20px "Circular Std", "Circular Std"; }
  .tv-reportquality-drawer-value {
    font-size: 14px;
    font-weight: 200;
    margin-top: 5px; }
  .tv-reportquality-drawer_title {
    padding: 10px 0;
    text-align: center;
    font-weight: 600; }
  .tv-reportquality__modal {
    top: 0 !important;
    left: -11px !important;
    width: 100vw !important;
    height: 100vh !important;
    position: fixed;
    z-index: 51; }
    .tv-reportquality__modal .tv-modal__overlay {
      top: 0 !important;
      left: -10px !important;
      width: 100vw !important;
      height: unset; }
    .tv-reportquality__modal .tv-modal__holder {
      border-radius: 8px;
      top: 63px;
      min-width: 35vw;
      max-width: 620px;
      background-color: #fff; }
  .tv-reportquality-form {
    width: 100%; }
    .tv-reportquality-form__paragraph {
      font: 400 14px/16px "Circular Std", "Circular Std";
      padding: 0;
      margin: 24px; }
    .tv-reportquality-form__label {
      font: 400 16px/20px "Circular Std", "Circular Std"; }
    .tv-reportquality-form__textfield {
      padding: 14px 20px; }
    .tv-reportquality-form__dropdownlist {
      padding: 7px 13px 5px 13px; }
    .tv-reportquality-form__textarea {
      height: 130px; }
    .tv-reportquality-form__buttons {
      margin: 0 16px 20px; }
    .tv-reportquality-form__cancel {
      border-radius: 6px;
      background: none;
      color: #000000;
      font-weight: 500;
      border: 2px solid #c4c4c4;
      font-weight: 500; }
    .tv-reportquality-form__submit {
      border: unset;
      border-radius: 6px;
      width: 100%;
      padding: 10px;
      color: #252733;
      background: #c2c7ce;
      font-weight: 500; }
    .tv-reportquality-form-mobiletitle {
      text-align: center;
      font-weight: 500;
      font-size: 18px;
      font-family: "Circular Std", "Circular Std"; }

@media screen and (max-width: 768px) {
  .tv-reportquality__item-icon {
    padding: 0px 12px;
    height: 24px;
    -webkit-order: 1;
            order: 1; }
    .tv-reportquality__item-icon .icon {
      font-size: 24px !important; }
  .tv-reportquality__item-status {
    -webkit-order: 4;
            order: 4;
    max-width: unset;
    padding-right: 5px; }
  .tv-reportquality__item-text {
    display: -webkit-flex;
    display: flex;
    font: 400 12px/15px "Circular Std", "Circular Std"; }
    .tv-reportquality__item-text.large {
      font: 500 16px/20px "Circular Std", "Circular Std" !important;
      margin-bottom: 5px; }
  .tv-reportquality__item-order {
    -webkit-order: 2;
            order: 2;
    max-width: unset;
    padding: 5px; }
    .tv-reportquality__item-order .tv-workassignment__item-text {
      font-weight: 600 !important; }
  .tv-reportquality__item-name {
    -webkit-order: 4;
            order: 4;
    max-width: unset;
    margin-top: 10px;
    padding-right: 0; }
  .tv-reportquality__item-category {
    -webkit-order: 5;
            order: 5;
    max-width: unset;
    margin-top: 10px; }
    .tv-reportquality__item-category .tv-workassignment__item-text {
      max-width: 80px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden; }
  .tv-reportquality__item-datetime {
    -webkit-order: 1;
            order: 1;
    max-width: unset;
    -webkit-flex: 0 0 25%;
            flex: 0 0 25%;
    margin-top: 4px; }
  .tv-reportquality__item-type {
    -webkit-order: 6;
            order: 6;
    max-width: unset; }
  .tv-reportquality__item-label {
    margin: 0 0 6px 0; }
  .tv-reportquality__item .list-small-rating > label:before {
    font-size: 12px !important; }
  .tv-reportquality__list {
    height: calc(100vh - 200px);
    padding-bottom: 70px;
    overflow: hidden; }
    .tv-reportquality__list__mobile-fixed-bottom {
      position: fixed;
      display: -webkit-flex !important;
      display: flex !important;
      -webkit-flex-direction: row;
              flex-direction: row;
      width: 100% !important;
      padding: 16px;
      background-color: #fff;
      text-align: center;
      display: block;
      left: 0;
      bottom: 0;
      z-index: 40;
      width: 100vw; }
    .tv-reportquality__list-item {
      height: 160px;
      border-radius: 4px;
      position: relative;
      margin: 10px 15px 10px 2px !important;
      border: 1px solid #e0e0e0; }
      .tv-reportquality__list-item:before {
        content: '';
        position: absolute;
        top: 50px;
        height: 2px;
        width: 100%;
        border-top: 2px solid #e0e0e0; }
      .tv-reportquality__list-item:last-child {
        margin-bottom: 80px !important; }
      .tv-reportquality__list-item-label {
        display: block;
        font-weight: 500;
        margin-right: 5px; }
        .tv-reportquality__list-item-label i {
          color: #74737b; }
  .tv-reportquality-drawer__container {
    width: 100%;
    margin: 0;
    background: unset;
    border: unset; }
  .tv-reportquality-drawer .tk-dialog__btn-close {
    position: absolute;
    right: 0;
    top: 0;
    padding: 18px !important;
    height: 56px;
    z-index: 4; }
    .tv-reportquality-drawer .tk-dialog__btn-close::after {
      font-size: 15px; }
  .tv-reportquality-drawer-area {
    border: none;
    background: unset;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px; }
  .tv-reportquality-drawer-title {
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 24px;
    font-family: "Circular Std", "Circular Std"; }
  .tv-reportquality-drawer-mobile-only {
    display: block; }
  .tv-reportquality__modal {
    left: 0 !important; }
    .tv-reportquality__modal .tv-modal__holder {
      width: calc(100vw);
      max-width: 100vw;
      height: 100vh;
      max-height: 100vh;
      margin-top: 0;
      top: 0;
      border-radius: 0;
      background-color: #fff; }
  .tv-reportquality__filter-date-range__content {
    position: fixed;
    left: 35px;
    border-radius: 8px; }
  .tv-reportquality__item-order .tv-reportquality__item-label, .tv-reportquality__item-datetime .tv-reportquality__item-label, .tv-reportquality__item-name .tv-reportquality__item-label, .tv-reportquality__item-category .tv-reportquality__item-label {
    font-size: 13px; }
  .tv-reportquality .tv-reportquality__item-text.large {
    font: 400 16px/20px "Circular Std", "Circular Std" !important; }
  .tv-reportquality__status {
    padding: 6px 8px;
    letter-spacing: 0.5px;
    font-size: 9px; } }

.tv-form__error:empty {
  display: none; }

.deviation-status-wrapper {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content; }

.tv-date-range-picker-container {
  width: 100%;
  position: relative; }
  .tv-date-range-picker-container-picker {
    top: 74px;
    left: 12px;
    position: absolute;
    -webkit-filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.16));
            filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.16));
    border-radius: 8px;
    overflow: hidden; }
  .tv-date-range-picker-container-picker.calendar-small .rdrMonthAndYearWrapper .rdrYearPicker,
  .tv-date-range-picker-container-picker.calendar-small .rdrMonthAndYearWrapper .rdrMonthPicker {
    display: -webkit-flex;
    display: flex;
    -webkit-flex: 1 1;
            flex: 1 1;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center; }
    .tv-date-range-picker-container-picker.calendar-small .rdrMonthAndYearWrapper .rdrYearPicker select,
    .tv-date-range-picker-container-picker.calendar-small .rdrMonthAndYearWrapper .rdrMonthPicker select {
      width: 100%;
      -webkit-appearance: none;
              appearance: none;
      background: transparent; }
  .tv-date-range-picker-container-picker.calendar-small .rdrMonthAndYearWrapper .rdrYearPicker select {
    font-size: 16px; }
  .tv-date-range-picker-container-picker.calendar-small .rdrMonthAndYearWrapper .rdrNextPrevButton.rdrNextButton {
    margin: 0;
    padding-left: 24px; }
    .tv-date-range-picker-container-picker.calendar-small .rdrMonthAndYearWrapper .rdrNextPrevButton.rdrNextButton::before {
      margin: 0; }
  .tv-date-range-picker-container-picker.calendar-small .rdrDayEndPreview {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border: 0;
    background-color: #e1e7fe;
    z-index: 0; }
  .tv-date-range-picker-container-picker.calendar-small .rdrDayStartPreview {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border: 0;
    background-color: #e1e7fe;
    z-index: 0; }
  .tv-date-range-picker-container-picker.calendar-small .rdrDayInPreview {
    border: 0;
    background-color: #e1e7fe;
    z-index: 0; }
  .tv-date-range-picker-container-picker.calendar-small .rdrDayStartPreview.rdrDayEndPreview {
    background-color: #f1f3f4;
    border: none;
    z-index: 0; }
  .tv-date-range-picker-container-picker.calendar-small .rdrDayNumber span {
    font-weight: 500;
    color: #252525; }
  .tv-date-range-picker-container-picker.calendar-small .rdrDay[data-class-name='before'] .rdrDayInPreview {
    background-color: transparent; }
  .tv-date-range-picker-container-picker.calendar-small .rdrDay[data-class-name='before'] .rdrDayStartPreview {
    background-color: #f1f3f4; }
  .tv-date-range-picker-container-picker.calendar-small .rdrDayToday {
    cursor: pointer; }
    .tv-date-range-picker-container-picker.calendar-small .rdrDayToday .rdrDayNumber span {
      color: #873bff !important; }
  .tv-date-range-picker-container-picker.calendar-small .rdrDayEndOfMonth .rdrInRange,
  .tv-date-range-picker-container-picker.calendar-small .rdrDayEndOfMonth .rdrStartEdge,
  .tv-date-range-picker-container-picker.calendar-small .rdrDayEndOfWeek .rdrInRange,
  .tv-date-range-picker-container-picker.calendar-small .rdrDayEndOfWeek .rdrStartEdge {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px; }
  .tv-date-range-picker-container-picker.calendar-small .rdrDayStartOfMonth .rdrInRange,
  .tv-date-range-picker-container-picker.calendar-small .rdrDayStartOfMonth .rdrEndEdge,
  .tv-date-range-picker-container-picker.calendar-small .rdrDayStartOfWeek .rdrInRange,
  .tv-date-range-picker-container-picker.calendar-small .rdrDayStartOfWeek .rdrEndEdge {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px; }
  .tv-date-range-picker-container-picker.calendar-small .rdrDayEndOfMonth .rdrInRange,
  .tv-date-range-picker-container-picker.calendar-small .rdrDayEndOfMonth .rdrStartEdge,
  .tv-date-range-picker-container-picker.calendar-small .rdrDayEndOfWeek .rdrInRange,
  .tv-date-range-picker-container-picker.calendar-small .rdrDayEndOfWeek .rdrStartEdge {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px; }
  .tv-date-range-picker-container-picker.calendar-small .rdrDayStartOfMonth .rdrDayInPreview,
  .tv-date-range-picker-container-picker.calendar-small .rdrDayStartOfMonth .rdrDayEndPreview,
  .tv-date-range-picker-container-picker.calendar-small .rdrDayStartOfWeek .rdrDayInPreview,
  .tv-date-range-picker-container-picker.calendar-small .rdrDayStartOfWeek .rdrDayEndPreview {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px; }
  .tv-date-range-picker-container-picker.calendar-small .rdrDayEndOfMonth .rdrDayInPreview,
  .tv-date-range-picker-container-picker.calendar-small .rdrDayEndOfMonth .rdrDayStartPreview,
  .tv-date-range-picker-container-picker.calendar-small .rdrDayEndOfWeek .rdrDayInPreview,
  .tv-date-range-picker-container-picker.calendar-small .rdrDayEndOfWeek .rdrDayStartPreview {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px; }
  .tv-date-range-picker-container-picker.calendar-small .rdrDay:not(.rdrDayPassive) .rdrEndEdge + .rdrDayNumber {
    background: #e1e7fe; }
  .tv-date-range-picker-container-picker.calendar-small .rdrDay:not(.rdrDayPassive) .rdrEndEdge + span + .rdrDayNumber {
    background: #e1e7fe; }
  .tv-date-range-picker-container-picker.calendar-small .rdrDay:not(.rdrDayPassive) .rdrStartEdge + .rdrDayNumber {
    background: unset; }
  .tv-date-range-picker-container-picker.calendar-small .rdrDay:not(.rdrDayPassive) .rdrStartEdge + span + .rdrDayNumber {
    background: unset; }
  .tv-date-range-picker-container-picker.calendar-small .rdrDay:not(.rdrDayPassive) .rdrStartEdge.rdrEndEdge + .rdrDayNumber {
    background: unset; }
  .tv-date-range-picker-container-picker.calendar-small .rdrDayPassive .rdrDayNumber span {
    color: #808080; }

.tv-date-range-picker-fields {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  grid-gap: 16px;
  gap: 16px; }
  .tv-date-range-picker-fields-section {
    -webkit-flex: 1 1;
            flex: 1 1; }
    .tv-date-range-picker-fields-section-title {
      font-size: 14px; }
    .tv-date-range-picker-fields-section-date {
      outline: none;
      width: 100%;
      border: 1px solid transparent;
      text-align: left;
      background-color: #fff;
      padding: 14px 16px;
      border-radius: 24px;
      box-shadow: 4px 4px 5px rgba(179, 181, 187, 0.05);
      color: #535252;
      box-sizing: border-box;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: space-between;
              justify-content: space-between; }
      .tv-date-range-picker-fields-section-date .placeholder {
        color: #74737b; }
      .tv-date-range-picker-fields-section-date:focus {
        border: 1px solid #6478e6; }
      .tv-date-range-picker-fields-section-date.error {
        border: 1px solid #ff9ba0; }
    .tv-date-range-picker-fields-section-icon {
      color: #74737b;
      cursor: pointer; }

.dashboard__single-chart-container .percentage-chart {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  min-height: 400px;
  padding: 4px; }
  .dashboard__single-chart-container .percentage-chart-title {
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 32px; }
  .dashboard__single-chart-container .percentage-chart-table {
    display: grid;
    grid-template-columns: 1.8rem 1fr 3rem;
    grid-column-gap: 12px;
    -webkit-column-gap: 12px;
            column-gap: 12px;
    grid-row-gap: 8px;
    row-gap: 8px;
    font-size: 15px;
    font-weight: 450;
    color: #222222; }
    .dashboard__single-chart-container .percentage-chart-table .number,
    .dashboard__single-chart-container .percentage-chart-table .value {
      text-align: right; }
    .dashboard__single-chart-container .percentage-chart-table .value {
      color: #74737b;
      font-weight: 400; }

.dashboard__single-chart-container .percentage-bar-chart {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  min-height: 400px;
  padding: 4px; }
  .dashboard__single-chart-container .percentage-bar-chart-title {
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 32px; }
  .dashboard__single-chart-container .percentage-bar-chart-table {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    grid-gap: 16px;
    gap: 16px;
    font-size: 15px;
    font-weight: 450;
    color: #222222; }
    .dashboard__single-chart-container .percentage-bar-chart-table-row {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      grid-gap: 4px;
      gap: 4px; }
      .dashboard__single-chart-container .percentage-bar-chart-table-row .data-container {
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        grid-gap: 8px;
        gap: 8px; }
        .dashboard__single-chart-container .percentage-bar-chart-table-row .data-container .bar {
          width: 90%;
          height: 6px;
          border-radius: 8px; }
          .dashboard__single-chart-container .percentage-bar-chart-table-row .data-container .bar-fill {
            height: 100%;
            border-radius: 8px; }
      .dashboard__single-chart-container .percentage-bar-chart-table-row .value {
        color: #74737b;
        font-weight: 400; }

.tv-radialgauge-container {
  position: relative; }

.tv-radialgauge-inner_detail {
  position: absolute;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  text-align: center;
  bottom: 10px;
  left: 0px;
  width: 100%; }

@media screen and (max-width: 768px) {
  .dashboard__report-container {
    -webkit-flex-direction: column;
            flex-direction: column; } }

@media screen and (min-width: 768px) and (max-width: 1440px) {
  .dashboard__report-container .dashboard__filter-holder .tv-date-range-picker-fields-section-date {
    font-size: 14px;
    padding: 10px 12px; } }

.dashboard__report-container .dashboard__single-chart-container.custom-topic-padding {
  padding-top: 10px; }

@media screen and (max-width: 768px) {
  .dashboard__report-holder .dashboard__chart-container {
    -webkit-flex-direction: column;
            flex-direction: column;
    margin: 16px 0 32px 0; } }

.mobile-wrapper {
  left: -200px; }

.tv-mobile-navigation {
  background: #fff;
  padding: 14px 16px;
  display: none;
  width: 100%;
  z-index: 60;
  position: fixed;
  right: 0;
  height: 100vh; }
  .tv-mobile-navigation .tv-left-pannel__logo {
    padding-top: 5px;
    float: left;
    -webkit-justify-content: end;
            justify-content: end; }
  .tv-mobile-navigation .nav-item-mobile {
    padding: 10px;
    text-align: center;
    display: block;
    font-weight: 600;
    color: #873bff;
    font-size: 17px;
    font-family: "Circular Std", "Circular Std";
    margin: 3px 20px; }

.tv-bookDirect-button {
  border-radius: 40px;
  color: #873bff;
  margin: 0px 80px 25px 80px; }

.release-news__container {
  padding: 0 32px;
  width: min(900px, 80%);
  margin: 32px auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  font-family: 'Circular Std', sans-serif !important; }
  @media screen and (max-width: 768px) {
    .release-news__container {
      padding: 0 !important;
      width: 100% !important;
      margin: 32px 0; } }
  .release-news__container li {
    margin-bottom: 5px;
    margin-top: 5px; }
  .release-news__container ul {
    margin-top: 10px; }
  .release-news__container .page-wrapper {
    display: -webkit-flex;
    display: flex;
    height: calc(90vh - 94px);
    -webkit-flex-direction: column;
            flex-direction: column;
    padding-bottom: 17px;
    margin-bottom: 20px;
    margin-top: 5%;
    margin-left: 5%;
    margin-right: 5%;
    overflow: hidden; }
  .release-news__container .page-title {
    font-style: normal;
    font-size: 28px;
    line-height: 35px;
    font-weight: 600;
    letter-spacing: 0;
    margin: 0px 0 0 0; }
  .release-news__container .icon {
    font-size: 48px;
    line-height: 30px;
    padding-right: 16px; }
    @media screen and (max-width: 768px) {
      .release-news__container .icon {
        text-align: left;
        margin-bottom: 5px; } }
    .release-news__container .icon p {
      font-size: 16px;
      line-height: 150%; }
  .release-news__container .wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 100%;
    height: auto;
    border-radius: 4px;
    border-bottom: 1px solid #e0e0e0;
    padding: 40px 0; }
    .release-news__container .wrapper p {
      font-size: 16px;
      line-height: 150%; }
  .release-news__container .container-styled {
    padding: 15px; }
  .release-news__container .title {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    width: 100%;
    line-height: 24px;
    letter-spacing: 0.2px;
    padding: 8px 0 24px;
    color: #252525; }
  .release-news__container .date {
    padding-top: 10px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #252525;
    opacity: 0.6; }

